.sales-medica,
.sales-healthcare,
.sales-accountants{
    font-size:18px;
    font-weight: 300;
    line-height: 1.56;
    .article-heading{
        @include font("title",48px,$dark,400,1.21);
        margin-top:0;
        @include media("<=675"){
            font-size: 32px;

            line-height: 1.14em;
        }
    }
    .btn{
        margin-top:2em;
        text-align:center;
    }
}

.sales-accountants{
    background:url($sales-bg-img);
    background-size:cover;
    background-position-x: right;
    @include media("<=phoneland"){
        padding-bottom:0;
    }
}

.sales-accountants-head{
    font-size:18px;
    font-weight: 300;
    line-height: 1.56;
    background-color: $cod-grey;
    @if $site == 'norway' {
        background-color: $nor-lightgrey;
    }

    .intro-one{
        color: $accent-primary;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.8;

        @include media("<=phoneland"){
            font-size: 18px;
        }

    }

    .intro-two{
        color: $white;
        @if $site == 'norway' {
            color:$body-text;
        }
        font-size: 16px;
        font-weight: 300;
        line-height: 1.75;
        @include media("<=phoneland"){
            font-size: 15px;
            line-height: 1.6;
        }
    }
    @if $site == 'norway' {
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: calc(100% - 200px);
            left: calc(50% - 158px);
            width: 316px;
            height: 316px;
            background: inherit;
            border-radius: 0% 100% 0% 100%;
            clip-path: polygon(0% 130%, 130% 0%, 100% 100%, 100% 100%);
            transform: scale(1, 0.56) rotate(45deg);
        }
    }


 }

.icon-circle-bullet{
    fill:$accent-primary;
    margin-right:0.75em;
}
.sales-list{
    list-style-type: none;
    li{
        padding:3px 0;
        position:relative;
        svg{
            position:absolute;
            left:-20px;
            top:12px;
        }
    }
}

.counter-list{
    list-style-type: none;
    margin-left:0;
    padding-left:0;
    padding-bottom:2em;
    .counter{
        width:36px;
        line-height:36px;
        color:$white;
        display:inline-block;
        text-align:center;
        vertical-align: middle;
        margin-right:.5em;
        font-weight:400;
        border-radius:100%;
        background:#5f7f8d;

        font-size: 20px;
        font-weight: 700;
        height: 36px;
        @include media("<=phoneland"){
            position:absolute;
            left:0;
        }
    }
    li{
        margin-bottom:3px;
        position:relative;
        @include media("<=phoneland"){
            margin-bottom:.5em;
            padding-left:44px;
        }
    }
}
