
.jsonForm,
.pf-admin{

    // General style (help icons)

    .help-trigger,
    dfn svg[class*=icon] {
        position: relative;
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-left:.3em;
    }

    .help-trigger,
    dfn {
        svg[class*=icon] {
            color: $white;
            fill: $cerulean2;
        }
        &:hover {
            svg[class*=icon] {
                fill: $cerulean3;
            }
        }
    }

    // Slide down help boxes

    .help-trigger {
        margin-top: -4px;
        font-size: 0;
        line-height: 0;
        vertical-align: middle;
        color: transparent;
        cursor: help !important;

        svg {
            width: 100%;
            height: 100%;
        }

        &:hover {
            cursor: help;

            & + .help {
                height: auto;
                margin: 10px 0;
                padding: 17px 42px 21px 47px;
                opacity: 1;
                border-color: $tooltip2-border;
            }
        }
    }

    .help {
        position: relative;
        display: block;
        max-width: $wrapper-limit;
        height: 0;
        margin: 0;
        padding: 0 42px 0 47px;
        font-size: $tooltip-fs;
        line-height: $tooltip-lh;
        font-weight: normal;
        overflow: hidden;
        opacity: 0;
        color: $tooltip2-color;
        background: $tooltip2-bg url("/img/source/help.svg") no-repeat 15px 16px;
        border: 1px solid transparent;
        transition: all .5s;
    }

    // <dfn> tooltip help boxes
    dfn {
        position: relative;
        font-style: normal;
        white-space: normal;
        cursor: help;


        &::before,
        &::after {
            position: absolute;
            display: block;
            opacity: 0;
            transition: opacity 0.25s ease-out;
        }

        &::before {
            content: attr(data-title);
            left: 100%;
            left: calc(100% - 42px);
            bottom: 100%;
            bottom: calc(100% + 15px);
            min-width: 250px;
            max-height: 0;
            margin: 0;
            padding: 0;
            @include font('body', $tooltip-fs, $tooltip-color, $tooltip-fw,  $tooltip-lh);
            text-align: left;
            white-space: normal;
            overflow: hidden;
            background: $tooltip-bg url("/img/source/help-white.svg") no-repeat 11px 12px;
            box-shadow: $tooltip-shadow;
            z-index: 99999;
        }


        svg[class*=icon] {
            margin-top: -3px;
            vertical-align: middle;
        }

        &::after {
            content: "";
            left: 100%;
            left: calc(100% - 20px);
            bottom: 100%;
            bottom: calc(100% + 5px);
            z-index: 99999;
        }

        &:hover {
            cursor: help;

            &::before {
                max-height: none;
                min-height: 40px;
                padding: 12px 20px 15px 42px;
                overflow: auto;
                opacity: 1;
            }
            &::after {
                opacity: 1;
                border: 12px solid transparent;
                border-top: 10px solid $tooltip-bg;
                border-bottom: none;
            }
        }
    }

    th dfn {
        display: inline;
        white-space: nowrap;
    }
	
	
	.dialogue-message dfn {
		&::before {
			left: auto;
			right: -42px;
		}
	}

    @include media('<wide-tab') {
        .help-trigger,
        dfn svg[class*=icon] {
            margin-left: 4px;
        }
    }
}

.pf-admin {
    dfn{
        min-width:280px;
        &::before {
            white-space: pre-wrap;
        }
    }
}

