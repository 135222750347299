// THIS FILE IS DEPRECATED

#websitehny {
  position: absolute;
  display: block;
  left: -99999px;
  top: -99999px;
}

#loginform input[type="text"] {
  width: 100%;
  display: block;
  padding: 5px;
  margin-bottom: 10px;
  border: solid 1px #dedddd;
  color: #dedddd;
}
#loginform input[type="submit"] {
  margin: 10px 0;
}
#loginform input[type="file"] {
  border: solid 1px #dedddd;
  color: #dedddd;
  background: $white;
}

@if($site == mobility){
  .homepage .article-heading{
      // padding-left: 34px;
      &:before {
          position: absolute;
          top: 4px;
          left: 0;

          display: inline-block;

          width: 17px;
          height: 17px;
          margin-right: 17px;

          content: '';
          vertical-align: middle;

          background-color: $rouge-two;
      }
  }
  .homepage .article-heading:before{
      top:11px;
  }
}

.block-content-banner-summary{
  max-width: 602px;
}

//@if($site == accountants){
//  .homepage .article-heading,
//  .column-title,
//  .content-wrap.carousel,
//  .single-block-container .single-block,
//  .resources-page .single-block .block-title {
//      // padding-left: 34px;
//      &:before {
//          position: absolute;
//          top: 4px;
//          left: 0;
//
//          display: inline-block;
//
//          width: 17px;
//          height: 17px;
//          margin-right: 17px;
//
//          content: '';
//          vertical-align: middle;
//
//          background-color: $accent-secondary;
//      }
//  }
//  .homepage .article-heading:before{
//      top:11px;
//  }
//  .single-block-container .single-block:before {
//      top:7px;
//  }
//  .resources-page .single-block .block-title:before{
//      top:11px;
//      @include media("<=phoneland") {
//          top:7px;
//      }
//  }
//  .resources-page .text-search-container .single-block .block-title:before{
//      background:$rouge;
//      top:19px;
//      @include media("<=phoneland") {
//          top:15px;
//      }
//  }
//  .resources-page .single-block:before{
//      display:none;
//  }
//  .right-column .column-title:before,
//  .single-block-container .single-block:before {
//      background-color: $accent-primary;
//
//  }
//}

