.overview-block {
    font-size: 15px;

    a,
    a:visited,
    a:hover {
        position: relative;

        display: block;
        overflow: hidden;

        width: 100%;

        border-bottom: 12px solid transparent;

        color: $white;
        background: $dark;
        @if $site == 'norway' {
            background: $nor-darkestgrey;
        }
    }
    .square-content {
        h2 {
            font-size: px;
            font-weight: normal;

            @include font('body', 16px, false, false, 20px);

            margin: 0;
            padding: 25px;

            text-transform: uppercase;

            background: $tundora;
            @if $site == 'norway' {
                background: $nor-darkgrey;
            }

            @include media("<=1180px") {
                font-size: 16px;
            }
        }
        .o-cell {
            padding: 54px 16px 66px;
        }
        .o-cell {
            padding: 54px 16px 66px;
        }
        .quotes-cell{
            padding-top: 42px;
            padding-bottom: 52px;
        }
        .text {
            text-align: center;

            @include media("<=1180px") {
                font-size: 14px;
            }
        }
        .number {
            font-size: 46px;
            font-weight: 600;
            line-height: 1.2em;
            text-align:center;

            @include media("<=1180px") {
                font-size: 26px;
                font-weight: 600;
            }
        }
    }
}

.overview-title {
    display: flex;
    h2 {
        flex: 1 1 90%;
    }
    .overview-icon {
        flex: 0 0 68px;
        height: 68px;
        overflow: hidden;
        svg {
            fill: $white;
            transform: translateY(0);
            transition: all 300ms ease 0s;

            @at-root .overview-block:hover & {
                transform: translateY(-68px);
            }
        }
        .download-icon {

            transition: all 300ms ease 0s;
            transform: translateY(68px);

            @at-root .overview-block:hover & {
                transform: translateY(0);
            }
        }
        div {
            padding: 18px;
            height: 68px;
            width: 68px;
        }
    }
}

.overview-block.progress a {
    border-bottom-color: #F7941E;
}

.overview-block.started a {
    border-bottom-color: #1C75BC;
}

.overview-block.submitted a {
    border-bottom-color: #009444;
}

.overview-block.client a {
    border-bottom-color: #975CC1;
}

.overview-block.progress .overview-icon {
    background: #F7941E;
}

.overview-block.started .overview-icon {
    background: #1C75BC;
}

.overview-block.submitted .overview-icon {
    background: #009444;
}

.overview-block.client .overview-icon {
    background: #975CC1;
}

.overview-block.progress a .square-content .number {
    color: #F7941E;
}

.overview-block.started a .square-content .number {
    color: #1C75BC;
}

.overview-block.submitted a .square-content .number {
    color: #009444;
}

.overview-block.client a .square-content .number {
    color: #975CC1;
}

@include media("<=wide-tab"){
	.admin-overview.flex-parent{
		.overview-block{
			flex-basis: 50%;
		}
	}
}




