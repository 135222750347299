/* Table to show basic view data
 * EG - Quotes/Transaction detail page
 */

.detail-table{
    width:100%;
    max-width:600px;
    td{
        padding:5px;
    }
}
