.pf-dashboard {

  .doughnutChart {
    position: relative;

    display: inline-block;

    width: 120px;
    height: 120px;
    margin: 0;
    padding: 0;

    vertical-align: middle;
  }
  .doughnutSummary {
    display: none;
  }
  .doughnutTip {
    font-size: 12px;
    line-height: 1em;

    position: absolute;

    display: block;

    padding: 5px;

    color: #CCCCCC;
    background: #333333;
  }
  .doughnutTip:after {
    content: '%';
  }


}