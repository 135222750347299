/* ==========================================================================
   Normalise Forms
   ========================================================================== */


 .jsonForm {

    //Define consistent border, margin, and padding.

    fieldset {
        margin: 0 2px;
        padding: 0.35em 0.625em 0.75em;

        fieldset {
            border: none;
            margin: 0;
            padding: 0;
        }
    }

    /**
     * 1. Correct color not being inherited in IE 6/7/8/9.
     * 2. Correct text not wrapping in Firefox 3.
     * 3. Correct alignment displayed oddly in IE 6/7.
     */

    legend {
        border: 0; /* 1 */
        padding: 0;
        white-space: normal; /* 2 */
        *margin-left: -7px; /* 3 */
        font-weight: bold;
    }

    /**
     * 1. Correct font size not being inherited in all browsers.
     * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
     *    and Chrome.
     * 3. Improve appearance and consistency in all browsers.
     */
    button,
    input,
    select,
    textarea {
        font-size: 100%; /* 1 */
        margin: 0; /* 2 */
        vertical-align: baseline; /* 3 */
        *vertical-align: middle; /* 3 */
        border-radius: 0;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: none;
    }

    *:focus {
        outline: none;
    }

    /**
     * Address Firefox 3+ setting `line-height` on `input` using `!important` in
     * the UA stylesheet.
     */
    button,
    input {
        line-height: normal;
    }

    /**
     * Address inconsistent `text-transform` inheritance for `button` and `select`.
     * All other form control elements do not inherit `text-transform` values.
     * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
     * Correct `select` style inheritance in Firefox 4+ and Opera.
     */
    button,
    select {
        text-transform: none;
    }

    /**
     * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
     *    and `video` controls.
     * 2. Correct inability to style clickable `input` types in iOS.
     * 3. Improve usability and consistency of cursor style between image-type
     *    `input` and others.
     * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
     *    Known issue: inner spacing remains in IE 6.
     */
    //normalise
    button,
    input[type="button"], /* 1 */
    input[type="reset"],
    input[type="submit"] {
        -webkit-appearance: button; /* 2 */
        cursor: pointer; /* 3 */
        *overflow: visible;  /* 4 */
    }

    /**
     * Re-set default cursor for disabled elements.
     */
    //normalise
    button[disabled],
    input[disabled],
    *:disabled,
    button[read-only],
    input[read-only],
    button[readonly],
    input[readonly],
    input.readonly {
        opacity: 0.6;
        //background-color: $el-bg-disabled !important;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
    }

    input[disabled] + label.onoffswitch-label {
        opacity: 0.6;
        //background-color: $el-bg-disabled !important;
        box-shadow: none;
        cursor: default;
        filter: grayscale(1);
        pointer-events: none;
    }

    /**
     * 1. Address box sizing set to content-box in IE 8/9.
     * 2. Remove excess padding in IE 8/9.
     * 3. Remove excess padding in IE 7.
     *    Known issue: excess padding remains in IE 6.
     */

    input[type="checkbox"],
    input[type="radio"] {
        box-sizing: border-box; /* 1 */
        padding: 0; /* 2 */
        *height: 13px; /* 3 */
        *width: 13px; /* 3 */
    }

    /**
     * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
     * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
     *    (include `-moz` to future-proof).
     */
    input[type="search"] {
        -webkit-appearance: textfield; /* 1 */
        box-sizing: content-box;
    }

    /**
     * Remove inner padding and search cancel button in Safari 5 and Chrome
     * on OS X.
     */
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    /**
     * Remove inner padding and border in Firefox 3+.
     */
    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    /**
     * 1. Remove default vertical scrollbar in IE 6/7/8/9.
     * 2. Improve readability and alignment in all browsers.
     */
    textarea {
        overflow: auto; /* 1 */
        vertical-align: top; /* 2 */
        resize: vertical;
        min-height: 26px;
        height: 6em;
    }

    textarea.ckeditor {
        min-height: 50px;
    }
	
	 button.rowButton {
		 padding: 6px 11px;
		 height: 36px;
		 width: 100%;
		 font-size: 15px;
	 }
}

/**
 * 1. Firefox 18-
 * 2. Firefox 19+
 */
.jsonForm *::-webkit-input-placeholder {
    color: $shady-lady;
}
.jsonForm *:-moz-placeholder { /* 1 */
    color: $shady-lady;
}
.jsonForm *::-moz-placeholder {  /* 2 */
    color: $shady-lady;
}
.jsonForm *:-ms-input-placeholder {
    color: $shady-lady;
}

