
.featured-pages {
	margin-bottom: 9px;

	//@if($site == accountants) {
	//	background: $background-grey;
	//	padding-top:32px;
	//}

	@include media("<tabport") {
		margin-bottom: 1px;
		//@if($site == accountants) {
		//	margin-bottom: 10px;
		//}
	}
	

	//@if($site == accountants) {
	//	.article-heading{
	//		margin-bottom:0.5em;
	//	}
	//}

	.three-column-grid {
		padding: 0;

		@if($site == accountants) {
			margin:0 -16px;
		}

		.block {
			position: relative;

			@if($site == accountants) {
				//@include media("<1200px") {
				//	flex-basis:32%!important;
				//}
				//@include media("<tabport") {
				//	flex-basis: 100%!important;
				//}
			} @else {
				height: 232px;
				padding: 0;
				
				@media only screen and (max-width: 1199px) {
					height: 170px;
				}
				@include media("<tabport") {
					display: block;
					height: 232px;
					width: 100%;
					-ms-flex: none;
		    		-webkit-flex: none;
		            flex: none;
				}
			}



			a.block-link {
				display: block;
				//@if($site != accountants) {
				//	position: absolute;
				//}
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 5;
				background: no-repeat center center;
				background-size: cover;

				.title {
					display: inline-block;
					margin-top: 27px;
					padding: 8px 20px;
					font-size: 18px;
					line-height: 21px;
					text-transform: capitalize;
					background: $a_accent;
					color: $white;
					transition:all 300ms;

					@include media("<wide-tab") {
						margin-top: 0;
						padding: 8px 13px;
					}
					@include media("<tabport") {
						margin-top: 27px;
						padding: 8px 20px;
					}
				}

				&:hover, &:active {
					.title {
						background: $charcoal-grey;
					}
				}
			}
		}
	}
}