
.jsonForm {
    .jf-input-type-textarea {
        & ~ .jf-input-type-file {
            margin-top: 10px;
        }
    }

    @media only screen and (max-width: 600px) {
        .fileuploader .cropDisplay .imageInput,
        .fileuploader .cropDisplay .imageOutput {
            display: block;
            width: auto;
            margin: 4px;
        }
        .fileuploader .cropDisplay .imageOutput {
            margin-top: 0px;
        }
    }
}