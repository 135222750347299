.uw-requests-list{
    .send{
        margin-top:32px;
    }
}




.page-container .rfqCreate{
  font-size: 14px;
  font-weight: 300;
}
  .page-container .rfqCreate .rfqCreateSubHeader{
    font-size: 22px;
    padding-bottom: 5px;
    padding-top: 0;
  }
  .page-container .rfqCreateLabel{
    padding-top: 14px;
    padding-bottom: 12px;
  }
  .page-container .rfqCreateLine{
    padding-bottom: 8px;
    padding-top: 10px;
  }
  .page-container .rfqCreateButton{
  }
  .page-container .rfqCreateFieldSet{
    margin-bottom: 0;
  }
  .page-container .rfqCreateButtonFieldSet{
    margin: 0;
  }
  .page-container .rfqCreateExecLabel{
    padding-bottom: 0;
  }
  .page-container .rfqCreateExecSummary{
    margin-top: 30px;
  }
  .rfqExecSummary{
    display:flex;
    flex-wrap:wrap;
  }
  .rfqExecLabel{
    flex-basis:30%;
  }
  .rfqExecValue{
    flex-basis:70%;
  }

.rfqRequest-header-text{
  font-size: 22px;
  color: #060708;
  padding-bottom: 28px;
}
  .rfqRequest-flex{
    display:flex;
    flex-wrap:wrap;
    max-width:600px;
    font-size: 13px;
    line-height: 1.43;
    color: #20262a;
  }
  .rfqRequest-block{
    flex: 1 1 22%;
    margin-bottom:6px;
  }
  .rfqRequest-label{
  }
  .rfqRequest-value{
    flex-basis:60%;
  }






  .bounds .dataTable_overflow_wrapper .rfqRequestDetail .rfqDeclined td{
    background: #f2f4f4;
  }
  .stickyFootMain .bounds .rfqRequestDetail{
    border-collapse: unset;
  }

  .stickyFootMain .bounds .rfqRequestDetail td{
    border: 0;
    background: white;
  }
  .stickyFootMain .bounds .rfqRequestDetail th{
    border: 0;
    background: white;
    text-align:left;
  }
  .stickyFootMain .bounds .rfqRequestDetailHeader {
    border-bottom: 5px solid black;
    border-top: 5px solid black;
    border-collapse: collapse;
  }


.rfqrDetailContainer{
  display:flex;
  flex-wrap:wrap;
  padding: 0 20px;
}
  .rfqrMainContainer{
    text-align: left;
  }
  .rfqExecLabel{
    flex-basis:30%;
    padding: 5px 15px;
  }
  .rfqExecValue{
    flex-basis:70%;
    padding: 5px;
  }
  .rfqrDetailLabel{
    flex-basis:30%;
    text-align: left;
    padding: 5px 15px;
  }
  .rfqrDetailValue{
    flex-basis:70%;
    text-align: left;
    padding: 5px;
  }
  .rfqrDetailTitle {
    background: #edeeee;
    @include font('title', 30px, $dark-grey-three, 300,  1.14);
    padding: 20px 15px 0 35px;
    margin: 0 0 25px 0;
    display: flex;
    justify-content: space-between;
  }
  .rfqrDetailSubTitle {
    @include font('body', 20px, $dark-grey-three, 300,  1.14);
    font-size: 20px;
    padding: 15px 35px;
  }
  .rfqrDetailReference {
    font-size: 20px;
    line-height: 2.65;
    position: relative;
    top: -10px;
    left: -15px;
  }
  .rfqrDetailBold {
    font-weight: 600;
  }

  .rfqrDetailSeperator{
    padding: 10px  35px 10px 35px;
  }

  .rfqrDetailFooter{
    padding:10px;
  }
  .rfqrStatusText{
    background: #424e56;
    color: white;
    padding: 6px 14px 4px 14px;
    white-space: nowrap;;
  }
  .rfqrStatusContainer{
    display: flex;
    padding: 15px 35px 18px 35px;
  }
  .rfqrStatusAwarded{
    background: #60b602;
  }
  .rfqrStatusAccepted{
    background: #538a17;
  }
  .rfqrStatusDeclined{
    background: #bbbbbb;
  }
  .rfqrStatusAwaitingResponse{
    background: #f28921;
  }
  
  .rfqrDetailBody{
    font-size: 14px;
  }

.underwriter{
	.crfqFavouriteContainer{
		display: flex;
		justify-content: space-around;
	}
	
	.crfqFavourite svg{
		fill: #df4053;
	}
	.crfqNotFavourite svg{
		fill: #a2a1a2;
	}
	
	.stickyFootWrap .crfqStatusDeclined td {
		color: #9B9D9F;
	}
	
	.crfqSelected {
		background-color:#fee3c7;
	}
	
	
	.qo{
  min-height: 100px;
}

  table.list {
    width: 480px;
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-width: 2px;
    border-color: #7ea8f8;
    border-style: solid;
    color: #000000;
  }

  table.list td, table.list th {
    border-width: 2px;
    border-color: #7ea8f8;
    border-style: solid;
    padding: 5px;
    text-align: left;
  }

  table.list thead {
    background-color: #7ea8f8;
  }

  table.canvas{
    width:480px;
    height:200px;
    background-color: #cccccc;
    border-collapse: collapse;
    border-width: 2px;
    border-color: #7ea8f8;
  }

  .box{
    width:480px;
    height:200px;
    background-color: #cccccc;
  }



  div.blueTable {
    border: 1px solid #000000;
    background-color: #EEEEEE;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  .divTable.blueTable .divTableCell, .divTable.blueTable .divTableHead {
    border: 1px solid #AAAAAA;
    padding: 2px 10px;
  }
  .divTable.blueTable .divTableBody .divTableCell {
    font-size: 15px;
  }
  .divTable.blueTable .divTableRow:nth-child(even) {
    background: #EEEEEE;
  }
  .divTable.blueTable .divTableHeading {
    background: #000000;
    border-bottom: 1px solid #444444;
  }
  .divTable.blueTable .divTableHeading .divTableHead {
    font-size: 15px;
    font-weight: bold;
    color: #eeeeee;
    border-left: 1px solid #AAAAAA;
  }
  .divTable.blueTable .divTableHeading .divTableHead:first-child {
    border-left: none;
  }

  .blueTable .tableFootStyle {
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    background: #AAAAAA;
    background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #AAAAAA 100%);
    background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #AAAAAA 100%);
    background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #AAAAAA 100%);
    border-top: 2px solid #444444;
  }
  .blueTable .tableFootStyle {
    font-size: 15px;
  }
  .blueTable .tableFootStyle .links {
    text-align: right;
  }
  .blueTable .tableFootStyle .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }
  .blueTable.outerTableFooter {
    border-top: none;
  }
  .blueTable.outerTableFooter .tableFootStyle {
    padding: 3px 5px;
  }
  /* DivTable.com */
  .divTable{ display: table; }
  .divTableRow { display: table-row; }
  .divTableHeading { display: table-header-group;}
  .divTableCell, .divTableHead { display: table-cell;}
  .divTableFootCell { display: table-cell;border:0;}

  .divTableCellRight{
    display: table-cell;
    text-align: right;
    padding: 10px;
  }
  .divTableHeading { display: table-header-group;}
  .divTableFoot { display: table-footer-group;}
  .divTableBody { display: table-row-group;}


  .rfqOptionsContainer{
    display:flex;
    flex-wrap:wrap;
    margin-bottom: 32px;
  }
  .rfqOptionsItem{
    flex-basis:50%;
  }
  .rfqOptionsBtn{
    line-height: 0;
    max-height: 30px;
  }

  .rfqOptionsGroup{
    margin: 20px 20px 0 0;
    min-width: 400px;
    min-height: 500px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
  }

  .rfqOptionsPick {
    margin: 20px 20px 0 0;
    min-width: 400px;
  }

  .rfqOptionsGroupHeader{
    padding: 16px;
    background: black;
    color: white;
    font-size: 20px;
  }

  .rfqOptionsGroupBody{
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }
  .rfqOptionsGroupPlus{
    align-items: center;
    flex-grow: 1;
    justify-content: space-around;
    display: flex;
  }

  .rfqOptionsInput {
    width:6em;
  }

  .page-container .rfqFooterTable{width:100%;background: transparent;}
  .page-container .rfqFooterTable tr{background: transparent;}
  .page-container .rfqFooterTable tbody{background: transparent;}
  .page-container .rfqFooterCell{padding: 7px;}
  .rfqFooterCell2{width:calc(6em + 20px);}
  .rfqFooterCell3{width:168px;}
  .page-container .rfqFooterTotalLabel{font-weight:600}

  .page-container .rfqOptionsTitleBox{
    background: black;
    border-color:black;
    color:white;
    font-size: 20px !important;
    box-shadow: unset;
  }

  .optionCol1{width:auto;}
  .optionCol2{width:auto;}
  .optionCol3{width:90px;}
  .optionCol4{width:130px;}
  .optionCol5{width:40px;}

  .rfqRequest-header-text{            font-size: 22px;            color: #060708;            padding-bottom: 28px;        }
  .rfqRequest-flex{            display:flex;            flex-wrap:wrap;            max-width:600px;            font-size: 13px;            line-height: 1.43;            color: #20262a;        }
  .rfqRequest-block{            flex: 1 1 22%;            margin-bottom:6px;        }
  .rfqRequest-label{        }
  .rfqRequest-value{            flex-basis:60%;        }

  .rfqOptions-drag-drop-title {
    font-family: 'adobe-garamond-pro', serif;
    font-size: 22px;
    color: #20262a;
    line-height: 1.64;
    margin-top: 50px;
  }

  .rfqManageOptions .stickyFootMain{
    padding: 32px 0;
  }
  .rfqPlusText{
    margin-bottom: 50px;
    font-size: 18px;
  }
  .rfqPlusIcon{
    text-align: center;
    margin-bottom: 140px;
  }


.rfqRequestApprove-header-text{
  font-size: 22px;
  color: #060708;
  padding-bottom: 28px;
  padding-top: 28px;
}
  .rfqRequest-flex{
    display:flex;
    flex-wrap:wrap;
    max-width:600px;
    font-size: 13px;
    line-height: 1.43;
    color: #20262a;
  }
  .rfqRequest-block{
    flex: 1 1 22%;
    margin-bottom:6px;
  }
  .rfqRequest-label{
  }
  .rfqRequest-value{
    flex-basis:60%;
  }


  .uwRequestsStatus{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 3px;
  }

  .uwRequestsStatusNotQuoted{
    background: #d0021b;
  }

  .uwRequestsStatusAwarded{
    background: #60b602;
  }

    .uwRequestsStatusQuoted{
        background: #4a90e2;
    }

  .uwRequestsStatusQuoted{
    background: #4a90e2;
  }

  .uwRequestsStatusLost{
    background: #b473ed;
  }

  .uwRequestsStatusDeclined{
    background: #908f90;
  }
  .uwRequests td{
    position: relative;
  }


  .rfqrPopupFooter {
    background: #212f38;
    padding: 16px;
    margin: 40px 0 0 0;
    display: flex;
    justify-content: flex-end;
  }
  .rfqrPopupFooter a{
    border: solid 1px #9b9b9b;
    padding: 6px 55px;
    color: white;
  }
  .rfqrPopupEmptyFooter {
    background: #212f38;
    padding: 42px;
    margin: 40px 0 0 0;

  }
  .rfqrPopup .subDetail{
    display: none;
  }
  .rfqrPopup .active + .subDetail {
    display: block;
  }


  .rfqrRespondTitle {
    font-size: 22px;
    padding: 5px;
  }
  .rfqrRespondStart {
    padding: 15px;
  }
  .rfqrRespondData {
    padding: 5px;
  }
  .rfqrRespondAddButton {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 5px 0 20px 0;
  }
  .rfqrRespondSubmitButtons {
    display: flex;
    justify-content: flex-end;
    padding: 7px 0 20px 0;
  }
  .rfqrRespondSeperator {
    padding: 22px 5px 16px 5px;
  }

  .rfqrRespondSubjCell{
    padding: 10px 22px;
  }
  .rfqrRespondSubjContainer{
    display: flex;
    justify-content: space-between;
  }
  .rfqrRespondSubjTitle{
    line-height: 2.45;
  }
  .rfqrRespondSubjTitle{
    line-height: 2.45;
  }
  .rfqrRespondSubjLink{
    display: flex;
    justify-content: space-between;
    border: 1px solid #20262a;
    max-width: 100px;
    padding: 3px 0 2px 10px;
    color: #20262a;
  }
  .rfqrRespondSubjIconContainer{
    width: 10px;
    height: 10px;
    fill: #20262a;
  }
  .rfqrRespondSubjIcon {
    width: 10px;
    fill: #20262a;
  }
  .rfqrRespondSubjRemove {
    padding: 0px 12px 0px 10px;
    line-height: 1.7;
  }

  .stickyFootWrap .rfqrDetailBody .showSubject{
    display: block;
  }
  .rfqrDetailBody .rfqSubject{
    display: none;
  }

}


.option-groups-list .actions .dt-actions-item .rfqHide{
  display:none;
  backround:pink;
}

.option-groups-list .dt-actions .dt-actions-item {
  display: flex;
  justify-content: space-between;
}

.option-groups-list .actions .dt-actions-item .rfqHide{
  display:none;
}

.option-groups-list .btn {
  margin-left: 8px;
}

.option-groups-list .rfqExecSummary {
  padding-top: 32px;
}

.option-groups-list .buttons {
  display: flex;
  justify-content: flex-end;
  padding: 7px 0 20px 0;
}

.available-underwriters-list .rfqRequest-header-text{
  padding-left: 15px;
}

.available-underwriters-list .bounds .column-title{
  padding-left: 15px;
}
.uw-requests-list .dataTables_wrapper{
  margin-bottom: 60px;
}

.uw-requests-list .crfqRetractSelected {
  margin-bottom: 50px;
}


.rfq-list .dt-actions .dt-actions-item {
  display: flex;
  justify-content: space-between;
}

.rfq-list .modal-wrap.active .modal-contentWrap{
  max-width: 40em;
  padding: 0;
}

.rfq-list table tbody .actions {
  padding: 10px;
}

.rfq-list .dataTable .rfqRequestDetail tbody tr td{
  padding-left: 10px;
}

.rfq-subjectivities .rfqSubject .rfqrRespondSubmitButtons a{
   width: 300px;
   margin-top: 18px;
}


 .option-groups-list {
   .jsonForm input[type="search"]  {
     box-sizing:inherit;
   }

   .buttons .btn {
     margin-left: 16px;
   }
   .cmqLeadContainer{
     display: flex;
     justify-content: space-around;
   }
   .cmqLead svg{
     fill: #df4053;
     height: 30px;
   }
   .cmqNotLead svg{
     fill: #a2a1a2;
     height: 30px;
   }
   .jsonForm textarea{
     min-height: 155px;
   }

   .dataTable .rfqRequestDetail tbody tr td{
     padding-left: 10px;
   }

 }

.rfq-proposal  {
  form {
    input[type="submit"] {
      float: left;
    }
    .buttons{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    .rejectContainer{
      display: flex;
    }

    .reject{
      height: 56px;
    }

    .rationale{
      margin: 10px 0 0 20px;
    }
  }
}




.rfqr-list .modal-wrap.active .modal-contentWrap{
  max-width: 40em;
  padding: 0;
}

.rfq-request-list .modal-wrap.active .modal-contentWrap{
  max-width: 40em;
  padding: 0;
}


/* moved the following out of .underwriter as they are also used in pf-admin */

.rfqExecSummary{
	display:flex;
	flex-wrap:wrap;
}
.rfqExecLabel{
	flex-basis:30%;
}
.rfqExecValue{
	flex-basis:70%;
}
.rfqExecValueClient {
	font-weight: 600;
}


.actions .dt-actions-item .rfqHide{
	display:none;
}
.rfqStatusAwarded{
	background: #60b602;
}
.rfqStatusComplete{
	background: #538a17;
}
.rfqStatusInComplete{
	background: #f28921;
}
.rfqStatusCreated{
	background: #bbbbbb;
}
.allQuotesResponded{
	font-weight: 500;
	color: #60b602;
}
.someQuotesResponded{
	font-weight: 500;
	color: #f28921;
}
.rfqStatus{
	position:relative;
	height: 38px;
	width: 38px;
	fill:$white;
}
.rfqStatusSvg{
	position:absolute;
	left:50%;
	top: 50%;
	transform:translate(-50%,-50%);
}



