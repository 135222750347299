.story-facts{
  background-color: $charcoal-grey;
  color: $white;
  text-align: center;
  .flex-parent {
    //min-height: 350px;
    align-items: center;
    > .block {
      flex-basis: 8%;
      @include media("<1100px") {
        flex-basis: 25%;
      }
      min-width: 160px; //Made this wider so that they don't wrap to three lines of text as it interferes with vertical alightment. (MG 070818)
      align-items: center;
      align-content: center;
    }
  }

}






