/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 41px;
    height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $error-color;
    transition: .4s;
}


.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 5px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: $success-color;
}

input.js-waiting:checked + .slider {
    animation: background-loading-success 1.5s infinite;
}

input.js-waiting + .slider {
    animation: background-loading-error 1.5s infinite;
}

input:focus + .slider {
    box-shadow: 0 0 1px $success-color;
}

input:checked + .slider:before {
    transform: translateX(18px);
}

/* Rounded sliders */
.slider {
    border-radius: 24px;
}

.slider:before {
    border-radius: 50%;
}

@keyframes background-loading-success {
    0% {
        background-color: $mid-grey;
    }
    50% {
        background-color:$success-color;
    }
    100% {
        background-color: $mid-grey;
    }
}

@keyframes background-loading-error {
    0% {
        background-color: $mid-grey;
    }
    50% {
        background-color:$error-color;
    }
    100% {
        background-color: $mid-grey;
    }
}