.thirdPartyCheckoutLinkTable{
    display:flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    margin-top:48px;
    margin-bottom:32px;
    .thirdPartyCheckoutLink{
        display: flex;
        flex: 1 1 auto;
        flex-basis: 100%;
        background: #ebebeb;
        margin-bottom: 4px;
        padding: 16px;
    }
    .thirdPartyCheckoutLink-title{
        flex-basis: 100%;
        font-size: 21px;
        line-height: 36px;
        padding-left: 12px;
    }
    .thirdPartyCheckoutLink-button{
        min-width:100px;
        a{
            font-weight:bold;
            padding:10px 16px;
        }
    }
}

.sorryPageLink{
    padding: 8px 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.56;
    svg{
        fill:$accent-primary;
        position:relative;
        padding-left:12px;
        &.icon-arrow-thin-right {
            width: 29px;
            height: 12px;
        }
    }
}

