.location-tabs {
	//border-bottom: 9px solid map-get($locationTabs,sectionBorder);

	//@include media("<tabport") {
	//	border-bottom: 6px solid map-get($locationTabs,sectionBorder);
	//}

	.bounds {
		@include media("<1200px") {
			padding: 0;
		}
	}

	.location-wrap {
		position: relative;
		//margin-top: -44px;
		padding: 0;
		background-color: transparent;
		z-index: 10;

		//@include media("<tabport") {
			//margin-top: -134px;
		//}

		&>section {
			background-color: map-get($locationTabs,sectionBg);
		}

		.tab-content {
			position: relative;
			height: 443px;
			border: none;

			@include media("<tabport") {
				height: auto;
			}

			&::after {
				display: block;
				position: absolute;
				content: "";
				top: 0;
				bottom: 0;
				left: 66.5%;
				width: 3000px;
				background-color: map-get($locationTabs,sectionBg);
				z-index: 5;

				@include media("<1200px") {
					display: none;
				}
			}

			&>div {
				height: 100% !important;
			}

			.office-map {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				height: 100%;
				width: 300%;
				border: none;
				margin-left: -130%;
				z-index: 1;

				@include media(">=1200px") {
					position: absolute !important;
				}

				@include media("<1200px") {
					position: relative;
					margin-left: 0;
					width: 66%;
				}
				@include media("<wide-tab") {
					width: 72%;
				}
				@include media("<tabport") {
					width: 100%;
					height: 282px;
				}
			}
			.office-info-wrap {
				position: relative;
				float: right;
				width: 33.5%;
				height: 100%;
				padding: 55px 46px 50px;
				border: none;
				z-index: 10;
				background-color: map-get($locationTabs,sectionBg);
				box-sizing: border-box;
				color:$side_text;

				@include media("<1200px") {
					padding: 35px 23px 60px;
					width: 34%;
				}
				@include media("<wide-tab") {
					width: 28%;
				}
				@include media("<tabport") {
					width: 100%;
					float: none;
					padding: 35px 16px 20px;
				}

				.office-info {
					@include font("body",14px,$side_text,300,1.57);

					padding-left:20px;

					@include media("<1200px") {
						padding-left: 15px;
					}

					@include media("<tabport") {
						font-size: 15px;
						line-height: 21px;
					}

					@include media("<wide-tab") {
						padding-left: 12px;
					}


					.office {
						text-transform: uppercase;
						margin-bottom: 20px;
					}
					address span {
						display: block;
					}
				}
				.social-icons-wrap {
					position: absolute;
					bottom: 10px;
					left: 46px;

					@include media("<1200px") {
						left: 23px;
						bottom: 20px;
					}
					@include media("<tabport") {
						position: static;
						padding-top: 35px;
					}
				}
			}
		}
	}
}