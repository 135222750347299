    .pf-dashboard {

    /*login page style*/
    .login-wrap-wrap {
        position: absolute;
        top: 0;
        bottom: 0;

        display: table;

        width: 100%;
        height: 100%;

        background: url($login-bg-img) center center no-repeat fixed;
        background-size: cover;
        &:after {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background: $white;
            opacity: 0.5;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
        }
    }
    .login-wrap {
        position: relative;
        z-index: 2;
        display: table-cell;

        vertical-align: middle;
    }
    .password-error{
        font-size: 13px;
        line-height: 1.46;
        color: white;
        ul {
            margin: 16px 0 16px 20px;
            list-style-type: none;
        }
        li::before {
            content: " ";
            position: absolute;
            margin-left: -22px;
            height: 9px;
            width: 9px;
            margin-top: 5px;
            border: 2px solid $aqua-blue;
            border-radius: 50%;
        }
    }
    .login-box {
        position: relative;

        overflow: hidden;

        width: 90%;
        max-width: 615px;
        margin: 0 auto;

        transition: opacity 1s !important;

        opacity: 0;
        box-shadow: 0 0 70px 5px rgba(0, 0, 0, .3);

        background: map_get($login, background);
        background-size: cover;

        padding: 48px 52px 64px;

        @include media("<=phoneland") {
            padding: 48px 24px 8px 24px;
        }

        &.loaded {
            margin-top: 0;

            opacity: 1;
        }
        .jsonForm {
            font-size: 14px;
            font-weight: 300;

            color: $white;

            > section {
                padding: 20px 0;
            }
            > section fieldset {
                width: 100%;
                max-width: 100%;
                margin: 0;
                padding:0;

                border: 0 none;

                @include media("<=phoneland") {
                    padding: 20px 0;
                }
            }
            > section fieldset h1 {
                @include font('title',38px,$white,400,1);

                @include media("<=phoneland") {
                    font-size:37px;
                }
                span{
                    color:$accent-primary;
                }
                margin-top:0;
                margin-bottom: 0.5em;
            }
            .jf-row.jf-input-type-text {
                display: inline-block;

                width: 50%;
                padding-right: 10px;

                @include media("<=phoneland") {
                    width: 100%;
                    padding-right: 0;
                }
            }
            .jf-row.jf-input-type-text + .jf-row.jf-input-type-text {
                padding-right: 0;
                padding-left: 10px;
                @include media("<=phoneland") {
                    width: 100%;
                    padding-left: 0;
                }
            }
            .jf-row.jf-input-type-checkbox {
                display: inline-block;

                width: 50%;

                vertical-align: top;

                margin-top:2em;

                label{
                    line-height: 23px;
                    padding:0;
                    margin-right:.25em;
                    vertical-align:top;
                }

                @include media("<=phoneland") {
                    width: 100%;

                    > label {
                        display: inline-block;
                        width: auto;
                    }
                }
            }
            .jf-row > label.login-wrap {
                width: 100%;
                max-width: 100%;
                text-align:right;
                display:block;
            }
            .jf-row input{
                color:$white;
                &[type="text"],
                &[type="password"],
                &[type="email"],
                &[type="checkbox"]{
                    background:rgba(255,255,255,0.05);
                    border-color:rgba(255,255,255,0.05);
                    display:block;
                    width:100%;
                    padding:8px;
                    margin-left: 0!important;
                    box-shadow: none;
                }
                &[type="checkbox"]{
                    appearance:none;
                    display:inline-block;
                    width:auto;
                }

                &.login-submit {
                    float: right;

                    margin-top: 0;

                    padding:18px 12px;

                    min-width: 168px;
                    max-width:168px;

                    transition: all .3s !important;

                    border-color: map_get($login, btnBorder);
                    background: map_get($login, btnBg);
                    color: map_get($login, btnText);

                    @include media("<=phoneland") {
                        float: none;
                        width: 100%;
                        max-width:none;
                    }
                }
            }
            .focb::before,
            .focb:hover::before{
                background:rgba(255,255,255,0.05);
                border-color:rgba(255,255,255,0.05);
            }
            .focbed.filled {

            }
            .jf-row input{
                &:focus {
                    border-color: $a_accent;
                }
                &.error{
                    border-color: $error-color;
                }
            }
            input[type='submit'] {
                transition: all .3s !important;
            }
        }
        .fpw { //Forgot PassWord
            margin-top:-32px;
            z-index: 100;

            @include media("<=phoneland") {
                margin-top:0;
            }
            label{
                font-size: 12px;
                color:$iron;
            }

            a {
                color: map_get($login, btnReset);
            }
        }
    }

    .login-logo {
        position: relative;

        display: block;

        margin-bottom: 40px;

        @include media("<=phoneland") {
            margin: 0 auto 20px;
        }
    }

}