
body.contactPage {

	#main{ //Keeps page-header spacing as this has no flex-parent beneath it
		padding-top:32px;
		@include media("<=tabport") {
			padding-top:16px;
		}
		@include media("<=phoneland") {
			padding-top:8px;
		}
	}

	.meet-the-team {

		.meet-the-team-header {
			padding: 30px 0 0;

			.header-text {
				float: left;
				margin: 0;

				@include font('title', 48px, false, false, 1);

				@include media("<=phoneland") {
					margin-bottom: 20px;
				}
			}
			select {
				float: right;
				width: 100%;
				max-width: 300px;
				height: 30px;
				padding: 0 30px 0 15px;
				// font-weight: 300;
				font-size: 16px;
				line-height: 28px;
				color: $slate-grey;
				background-color: $white;
				background-image: url('../img/select-arrow.svg');
				background-position: 94% center;
				background-repeat: no-repeat;
				border: 1px solid $mid-grey;
				border-radius: 0;
				box-sizing: border-box;
				-webkit-appearance: none;
				-moz-appearance: none;
				-ms-appearance: none;
				appearance: none !important;

				&::-ms-expand {
				  display: none;
				}

				&:focus {
		            border: 1px solid rgba(0,134,139,0.5);

		            @include box-shadow(inset 0 0 5px rgba(0,134,139,0.3));
		        }

				option {
					font-size: 16px;
					color: $dark-grey;
				}

				@include media("<=phoneland") {
					float: left;
					clear: both;
					margin-bottom: 40px;
				}
			}
		}
        .filtr-wrapper{
            padding:16px 24px;
            @include media("<=tabport"){
                padding:16px 8px;
            }
        }
		.meet-the-team-content {
			@include media("<=phoneland") {
				justify-content: center;
			}
            .filtr-container{
                overflow:hidden;
            }
            .filtr-item{
                a{
                    display:block;
                }
            }
            .filteredOut{
                z-index:-1;
            }
            .resources-block{
                width:33.33%;
				@include media("<=phoneland", ">=400px") {
					//width:50%;
					min-width: 400px;
				}

				@include media("<400px") {
				min-width: 300px;
				}
                .article-meta{

                }
                .article-heading{
					height: 25px;
                    margin-bottom:0;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
                .article-category{
                    border-bottom: solid 1px #d7d5d5;
                }
                .phone{
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 1.6;
                    height:24px;
                    color:$dark;
					@include media("<=phoneland") {
						font-size: 12px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
                }
                .email{
                    font-size: 15px;
					@include media("<=phoneland") {
						font-size: 12px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
                    color:$dark;
                    height:18px;
                }

            }
		}
	}
}