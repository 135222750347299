.account-details {
  .main-section{
    background-color: $alabaster;
    position: relative;
  }
  .main-section:after {
    background-color: $alabaster;
    position: absolute;
    display: block;
    top: 0px;
    left: -2500px;
    right: -2500px;
    content: '';
    height: 100%;
    z-index: -1;
  }

  .details-sidebar{
    padding: 0px;
  }

  .details-widget{
    padding: 16px;
    background-color: $blue-nurse;
    color: $white;
  }


  .jsonForm section.newSec {
        padding: 15px 0;
    }
    .jsonForm > section {
        padding: 0;
    }
    .title-section {
        margin: 0;
    }

  .jsonForm{
    .password-fieldset{
      margin: 0;
    }
    .submit-button{
      margin: 0 0 14px 0;
    }
    .owaspText{
      max-width: 300px;
      margin: auto;
    }
  }

}