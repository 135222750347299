@if($theme == corporate){
.csvio {
    display: inline-block;

    & > span {
        @extend .jf-btn;  // buttons.scss
        display: block;
        height: auto;
        padding-top: 7px;
        padding-bottom: 7px;
        color: $btn2-color;
        background: $btn2-bg;
        border: 1px solid $btn2-border;

        &.population-btn {
            padding: 7px 20px 7px 40px;

            & > svg {
                position: absolute;
                display: block;
                top: 50%;
                left: 10px;
                width: 18px;
                height: 20px;
                margin-top: -10px;
                fill: $btn2-color;
                transition: all .25s ease-out;
            }

            &:hover {
                & > svg {
                    fill: $btn2-color-hover;
                }
            }
        }

        &:hover {
            color: $btn2-color-hover;
            background: $btn2-bg-hover;
            border-color: $btn2-bg-hover;
        }
    }


    // OLD STYLE - I'm not sure whether it's still needed or not //
    /*
    a {
        margin: 10px 0;

        & + a {
            margin: 10px;
        }
    }

    iframe {
        width: 1px;
        height: 1px; 
        opacity: 0;
    }

    form {
        position: relative; 
        display: block;
        margin-top: 8px;

        input[type=submit] {
            float: none !important;
            margin: 0;
        }

        input[type=file] {
            width: 260px;
            height: 32px;
            margin: 0;
            padding: 6px 0 0 0px;
            line-height: 10px;
            vertical-align: bottom;
        }

        .choosefile{
            display: block;
            position: absolute;
            top: 1px;
            left: 1px;
            bottom: 1px;
            width: 80px;
            margin: 0;
            font-family: 'Roboto Condensed', sans-serif;
            line-height: 30px;
            text-align: center;
            color: white;
            background: #1C75BC;
            pointer-events: none;
            z-index: 9;
        }
    }
    */
}
}