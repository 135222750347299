// This is for the doughnut chart in the summary screen
/*doughnut style*/
.chart-wrap {
    background: $dark;
    box-sizing: border-box;
    position: relative;
}

.chart {
    width: 400px;
    height: 400px;
    margin: auto;
    max-width:100%;
}

.doughnutTip {
    position: absolute;
    z-index: 10000;
    min-width: 30px;
    max-width: 300px;
    padding: 5px 15px;
    border-radius: 1px;
    background: rgba(0, 0, 0, .8);
    color: #ddd;
    font-size: 17px;
    text-shadow: 0 1px 0 #000;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.3;
    letter-spacing: .06em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    pointer-events: none;
}

.doughnutTip:after {
    position: absolute;
    left: 50%;
    bottom: -6px;
    content: "";
    height: 0;
    margin: 0 0 0 -6px;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, .7);
    line-height: 0;
}

.doughnutSummary {
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    text-align: center;
    /* text-shadow: 0 -1px 0 #111; */
    cursor: default;
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    vertical-align: middle;
    pointer-events: none;
    margin: 0 !important;
}

.doughnutSummaryTitle {
    /* position: absolute; */
    /* top: 40%; */
    width: 100px;
    /* margin-top: -27%; */
    font-size: 18px;
    /* letter-spacing: .06em; */
    font-weight: 700;
    /* margin: 0 0 0 -30px; */
    text-align: center;
    /* width: 100%; */
    margin: 0 auto;
    vertical-align: middle;
    display: inline-block;
}

.doughnutSummary:before {
    content: "";
    width: 1px;
    height: 100%;
    margin-left: -1px;
    display: inline-block;
    vertical-align: middle;
}

/*.doughnutSummaryNumber {position: absolute; top: 50%; width: 100%; margin-top: -15%; font-size: 55px;}*/
.chart path:hover {
    opacity: 0.75;
}

@media only screen and (max-width: 1024px) {
    .chart {
        width: 200px;
        height: 200px;
    }
    .doughnutSummaryTitle {
        margin: -10px 0 0 -41px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 850px) {
    .chart {
        width: 500px;
        height: 500px;
    }
    .doughnutSummaryTitle {
        font-size: 18px;
    }
}

@media only screen and (max-width: 567px) {
    .chart {
        width: 200px;
        height: 200px;
    }
    .doughnutSummaryTitle {
        font-size: 14px;
    }
}

#icon-pie-chart{
    path:nth-child(3){
        fill:map_get($policyActionStates,done);
    }
    path:nth-child(1){
        fill:map_get($policyActionStates,needsaction);
    }
    path:nth-child(2){
        fill:map_get($policyActionStates,warning);
    }
}
