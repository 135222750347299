.icon-activity {
	width: 32px;
	height: 32px;
}

.icon-adoption-form {
	width: 59px;
	height: 59px;
}

.icon-all-formats {
	width: 24px;
	height: 24px;
}

.icon-arrow-right {
	width: 9px;
	height: 13px;
}

.icon-arrow-thin-right {
	width: 23px;
	height: 13px;
}

.icon-bell {
	width: 24px;
	height: 22px;
}

.icon-bin {
	width: 32px;
	height: 32px;
}

.icon-car {
	width: 48px;
	height: 48px;
}

.icon-car-plus {
	width: 53px;
	height: 53px;
}

.icon-checked-success {
	width: 22px;
	height: 22px;
}

.icon-checklist {
	width: 12px;
	height: 14px;
}

.icon-checklist-indicator-amber {
	width: 18px;
	height: 18px;
}

.icon-checklist-indicator-green {
	width: 18px;
	height: 18px;
}

.icon-checklist-indicator-grey {
	width: 18px;
	height: 18px;
}

.icon-checklist-indicator-red {
	width: 18px;
	height: 18px;
}

.icon-chevron-left {
	width: 16px;
	height: 26px;
}

.icon-chevron-right {
	width: 15px;
	height: 26px;
}

.icon-circle-bullet {
	width: 10px;
	height: 10px;
}

.icon-clipboard {
	width: 21px;
	height: 28px;
}

.icon-close {
	width: 21px;
	height: 22px;
}

.icon-close-button {
	width: 12px;
	height: 12px;
}

.icon-close-button-2 {
	width: 32px;
	height: 32px;
}

.icon-close-icon {
	width: 32px;
	height: 32px;
}

.icon-cloud-computing {
	width: 81px;
	height: 60px;
}

.icon-cloud-upload {
	width: 36px;
	height: 25px;
}

.icon-cog {
	width: 22px;
	height: 22px;
}

.icon-cog_professions {
	width: 25px;
	height: 25px;
}

.icon-compare {
	width: 48px;
	height: 40px;
}

.icon-complete {
	width: 19px;
	height: 13px;
}

.icon-contact-email {
	width: 32px;
	height: 32px;
}

.icon-convertGuest {
	width: 20px;
	height: 17px;
}

.icon-create-policy {
	width: 32px;
	height: 32px;
}

.icon-cross-drawn {
	width: 23px;
	height: 21px;
}

.icon-danger {
	width: 32px;
	height: 28px;
}

.icon-dashboard {
	width: 146px;
	height: 116px;
}

.icon-dashboard-header {
	width: 33px;
	height: 20px;
}

.icon-dashboard-header-inverse {
	width: 33px;
	height: 20px;
}

.icon-disable {
	width: 15px;
	height: 15px;
}

.icon-document {
	width: 24px;
	height: 24px;
}

.icon-down-arrow {
	width: 512px;
	height: 512px;
}

.icon-down-triangle {
	width: 490.68px;
	height: 490.68px;
}

.icon-download {
	width: 16px;
	height: 19px;
}

.icon-download-all-files {
	width: 26px;
	height: 27px;
}

.icon-download_icon {
	width: 34px;
	height: 34px;
}

.icon-edit {
	width: 32px;
	height: 32px;
}

.icon-edit-document {
	width: 56px;
	height: 57px;
}

.icon-email {
	width: 32px;
	height: 26px;
}

.icon-email-dark {
	width: 37px;
	height: 37px;
}

.icon-email-inverse {
	width: 28px;
	height: 21px;
}

.icon-email_professions {
	width: 21px;
	height: 15px;
}

.icon-eye {
	width: 17px;
	height: 11px;
}

.icon-facebook {
	width: 10px;
	height: 18px;
}

.icon-facebook-dark {
	width: 37px;
	height: 37px;
}

.icon-file {
	width: 26px;
	height: 30px;
}

.icon-file-alt {
	width: 26px;
	height: 34px;
}

.icon-filter-close {
	width: 27px;
	height: 25px;
}

.icon-filter-list-close {
	width: 19px;
	height: 13px;
}

.icon-filter-list-open {
	width: 19px;
	height: 13px;
}

.icon-filter-open {
	width: 27px;
	height: 25px;
}

.icon-filter-open-back {
	width: 27px;
	height: 25px;
}

.icon-globe {
	width: 20px;
	height: 20px;
}

.icon-globe_logo {
	width: 112px;
	height: 36px;
}

.icon-google-plus {
	width: 24px;
	height: 15px;
}

.icon-hamburger {
	width: 32px;
	height: 22px;
}

.icon-heart {
	width: 20px;
	height: 18px;
}

.icon-help {
	width: 17px;
	height: 17px;
}

.icon-home {
	width: 48px;
	height: 48px;
}

.icon-home-plus {
	width: 53px;
	height: 53px;
}

.icon-ic-close {
	width: 14px;
	height: 14px;
}

.icon-ic-edit {
	width: 20px;
	height: 20px;
}

.icon-ic-plus {
	width: 16px;
	height: 16px;
}

.icon-ic-warning {
	width: 45px;
	height: 40px;
}

.icon-idle {
	width: 26px;
	height: 18px;
}

.icon-inactive {
	width: 13px;
	height: 9px;
}

.icon-info {
	width: 17px;
	height: 17px;
}

.icon-inprogress {
	width: 20px;
	height: 15px;
}

.icon-insurances {
	width: 32px;
	height: 32px;
}

.icon-invoice {
	width: 26px;
	height: 26px;
}

.icon-jf-add {
	width: 18px;
	height: 18px;
}

.icon-jf-add-edit {
	width: 30px;
	height: 30px;
}

.icon-jf-delete {
	width: 14px;
	height: 18px;
}

.icon-jf-edit {
	width: 19px;
	height: 19px;
}

.icon-jf-remove {
	width: 14px;
	height: 2px;
}

.icon-jf-spreadsheet {
	width: 18px;
	height: 20px;
}

.icon-large-i {
	width: 24px;
	height: 47px;
}

.icon-left-arrow {
	width: 10px;
	height: 17px;
}

.icon-letter {
	width: 367.69px;
	height: 370px;
}

.icon-link-client-console {
	width: 78px;
	height: 79px;
}

.icon-linkedin {
	width: 16px;
	height: 15px;
}

.icon-linkedin-dark {
	width: 37px;
	height: 37px;
}

.icon-list_view_icon {
	width: 22px;
	height: 20px;
}

.icon-lock {
	width: 20px;
	height: 24px;
}

.icon-lockton-logo {
	width: 154px;
	height: 77px;
}

.icon-lockton-logo-header {
	width: 135px;
	height: 63px;
}

.icon-logo {
	width: 112px;
	height: 55px;
}

.icon-logo-scrolling-white {
	width: 140px;
	height: 23px;
}

.icon-logo-white {
	width: 908px;
	height: 417px;
}

.icon-logo_accountants {
	width: 142.5px;
	height: 111.4px;
}

.icon-logo_assetmanagement {
	width: 113px;
	height: 71px;
}

.icon-logo_construction {
	width: 143.1px;
	height: 134.4px;
}

.icon-logo_globalfi {
	width: 142.5px;
	height: 111.4px;
}

.icon-logo_malta {
	width: 135px;
	height: 80px;
}

.icon-logo_mobility {
	width: 136px;
	height: 85px;
}

.icon-logo_performance {
	width: 204.2px;
	height: 124.6px;
}

.icon-logo_recruitment {
	width: 144px;
	height: 115px;
}

.icon-medal {
	width: 17px;
	height: 23px;
}

.icon-menu-business-and-finance {
	width: 14px;
	height: 14px;
}

.icon-menu-delete {
	width: 10px;
	height: 13px;
}

.icon-menu-dots {
	width: 18px;
	height: 4px;
}

.icon-menu-edit {
	width: 14px;
	height: 13px;
}

.icon-menu-eye {
	width: 17px;
	height: 11px;
}

.icon-menu-file-download {
	width: 11px;
	height: 14px;
}

.icon-menu-form {
	width: 10px;
	height: 14px;
}

.icon-menu-grid {
	width: 19px;
	height: 19px;
}

.icon-menu-hide {
	width: 17px;
	height: 14px;
}

.icon-menu-life {
	width: 17px;
	height: 13px;
}

.icon-menu-notebook {
	width: 11px;
	height: 14px;
}

.icon-menu-override-quote {
	width: 16px;
	height: 15px;
}

.icon-menu-paper {
	width: 14px;
	height: 14px;
}

.icon-menu-plus {
	width: 13px;
	height: 13px;
}

.icon-menu-preview {
	width: 11px;
	height: 14px;
}

.icon-menu-remind {
	width: 13px;
	height: 14px;
}

.icon-menu-satisfied {
	width: 16px;
	height: 14px;
}

.icon-menu-select-quote {
	width: 14px;
	height: 15px;
}

.icon-menu-selected {
	width: 19px;
	height: 19px;
}

.icon-menu-upload {
	width: 52px;
	height: 36px;
}

.icon-menu-vertical-dots {
	width: 5px;
	height: 21px;
}

.icon-myLockton {
	width: 24px;
	height: 24px;
}

.icon-myLocktonArrow {
	width: 35px;
	height: 24px;
}

.icon-note {
	width: 12px;
	height: 15px;
}

.icon-notes {
	width: 82.81px;
	height: 109.38px;
}

.icon-open-close {
	width: 24px;
	height: 24px;
}

.icon-open-icon {
	width: 32px;
	height: 32px;
}

.icon-openClose_professions {
	width: 32px;
	height: 32px;
}

.icon-overview-client {
	width: 32px;
	height: 32px;
}

.icon-overview-progress {
	width: 32px;
	height: 32px;
}

.icon-overview-started {
	width: 32px;
	height: 32px;
}

.icon-overview-submitted {
	width: 32px;
	height: 32px;
}

.icon-paper-plane {
	width: 15px;
	height: 15px;
}

.icon-payonline {
	width: 33px;
	height: 28px;
}

.icon-pdf_icon {
	width: 29px;
	height: 34px;
}

.icon-percent {
	width: 45px;
	height: 45px;
}

.icon-pf-download {
	width: 32px;
	height: 32px;
}

.icon-phone {
	width: 35px;
	height: 30px;
}

.icon-phone-call {
	width: 57px;
	height: 57px;
}

.icon-phone-volume {
	width: 27px;
	height: 37px;
}

.icon-pie-chart {
	width: 36px;
	height: 36px;
}

.icon-pin-drop {
	width: 20px;
	height: 28px;
}

.icon-play-button {
	width: 21px;
	height: 21px;
}

.icon-policies {
	width: 32px;
	height: 32px;
}

.icon-pound {
	width: 45px;
	height: 45px;
}

.icon-pound_minus {
	width: 45px;
	height: 45px;
}

.icon-presentation {
	width: 24px;
	height: 20px;
}

.icon-preview {
	width: 23px;
	height: 25px;
}

.icon-preview-modal {
	width: 34px;
	height: 34px;
}

.icon-preview-propform {
	width: 34.1px;
	height: 35px;
}

.icon-print {
	width: 25px;
	height: 23px;
}

.icon-private {
	width: 32px;
	height: 23px;
}

.icon-quote {
	width: 52px;
	height: 41px;
}

.icon-read-only {
	width: 25px;
	height: 27px;
}

.icon-reassign {
	width: 259px;
	height: 226px;
}

.icon-renew {
	width: 24px;
	height: 24px;
}

.icon-requiresAttention {
	width: 17px;
	height: 20px;
}

.icon-reset-icon-01 {
	width: 40px;
	height: 40px;
}

.icon-right-arrow {
	width: 20px;
	height: 20px;
}

.icon-right-arrow-forward {
	width: 34px;
	height: 34px;
}

.icon-right-arrow_thin {
	width: 14px;
	height: 19px;
}

.icon-search {
	width: 18px;
	height: 18px;
}

.icon-search-ew {
	width: 27px;
	height: 25px;
}

.icon-search-ew-back {
	width: 27px;
	height: 25px;
}

.icon-settings {
	width: 22px;
	height: 22px;
}

.icon-smilie-happy {
	width: 40px;
	height: 40px;
}

.icon-smilie-neutral {
	width: 40px;
	height: 40px;
}

.icon-smilie-sad {
	width: 40px;
	height: 40px;
}

.icon-staff {
	width: 36px;
	height: 32px;
}

.icon-started-next {
	width: 34px;
	height: 34px;
}

.icon-switch-accounts-header {
	width: 27px;
	height: 20px;
}

.icon-switch-accounts-menu {
	width: 27px;
	height: 20px;
}

.icon-tail-spin {
	width: 38px;
	height: 38px;
}

.icon-team-member {
	width: 12px;
	height: 16px;
}

.icon-telephone {
	width: 20px;
	height: 20px;
}

.icon-template {
	width: 21px;
	height: 24px;
}

.icon-tick-drawn {
	width: 28px;
	height: 28px;
}

.icon-twitter {
	width: 17px;
	height: 13px;
}

.icon-twitter-dark {
	width: 37px;
	height: 37px;
}

.icon-up-triangle {
	width: 490.66px;
	height: 490.66px;
}

.icon-upload {
	width: 16px;
	height: 17px;
}

.icon-user {
	width: 24px;
	height: 24px;
}

.icon-video {
	width: 24px;
	height: 20px;
}

.icon-view-files {
	width: 403px;
	height: 485px;
}

.icon-vimeo {
	width: 17px;
	height: 17px;
}

.icon-visit-us {
	width: 25px;
	height: 26px;
}

.icon-warning-triangle {
	width: 24px;
	height: 21px;
}

.icon-wizard {
	width: 745px;
	height: 486px;
}

