

    .faq-accordion {
        border: solid 2px $faq-bordercolor;
        .accordion-item {
            &:not(:last-child) {
                border-bottom: solid 2px $faq-bordercolor;
            }
            .faq-icon {
                position: absolute;
                top: 14px;
                left: 20px;

                display: block;

                width: 0;
                height: 8px;

                transition: all .3s;

                opacity: 0;
            }
            .accordion-trigger {
                position: relative;

                display: block;

                background-color: $faq-trigger-bg;

                padding: 16px 64px 16px 32px;//Allow for open-close-icon

                font-size: 22px;
                font-weight: 300;
                line-height: 27px;

                .title-tert {
                    margin: 0;

                    color: $faq-heading-color;

                    font-weight: 300;
                    @include media('<=phoneland') {
                        font-size: 15px;
                    }
                    // DEFAULT
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .accordion-content {
                @if $site == 'mobility' {
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.56em;
                }
                ul {
                    box-sizing: border-box;
                    padding: 20px 50px 15px 30px;

                    //list-style: none;

                    @include media('<=phoneland') {
                        padding: 20px 40px 15px 25px;
                    }
                    li {
                        font-size: 14px;
                        line-height: 18px;

                        color: $body-text;
                    }
                }
                p {
                    padding-right: 16px;
                    padding-left: 16px;
                }
            }
            &.accordion-item-open {
                .accordion-trigger {
                    border-bottom: solid 1px $faq-bordercolor;
                    background-color: $faq-trigger-bg_open;
                    .title-tert {
                        color: $faq-trigger-color_open;
                    }
                    &::after {
                        opacity: 0;
                    }
                }
                .faq-icon {
                    width: 12px;

                    opacity: 1;
                }
                .accordion-content {
                    padding: 16px 0 16px 26px;
                }
            }
        }
        /********************* Rewrited Default Accordion Styles **************************/
        .accordion-content {
            overflow: hidden;

            max-height: 0;

            transition: max-height .7s ease-out, opacity .3s ease-out;

            opacity: 0;
        }
        .accordion-item-open .accordion-content {
            max-height: 800px;

            transition: max-height 1.2s ease-out, opacity .5s ease-out;

            opacity: 1;
        }
    }

