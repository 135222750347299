.select-quote {
    .checkout-table {
        justify-content: space-between;
        max-width:600px!important;// Need to check this for other views e.g. ACCA before setting a better rule.
    }
}

.pf-checkout {
    .header-section {
        font-size: 26px;
        line-height: 1.08em;

        position: relative;

        margin: 0 0 .5em;
        padding: 20px 0;

        background: $side_background;

        &:before {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;

            display: block;

            width: 2500px;
            margin-left: -2500px;

            content: '';

            background: $side_background;
        }
    }

    .header-subsection {
        margin-bottom: 0;

        + .checkout-subtitle {
            display: inline-block;

            margin-bottom: 1em;
        }
    }

    main .block {
        flex-basis: 66%;
        @at-root .ie11 & {
            flex-basis: 60%;
            margin-bottom: 20px;
        }
    }

    .main-block {
        background-color: $alabaster;
        @if $site == 'norway' {
            background-color: $nor-lightgrey;
        }

        .quote-endorsement-message{
            margin:0;
            background-color: transparent;
        }
    }

    .block.sidebar {
        flex-basis: 33%;
        @at-root .ie11 & {
            flex-basis: 30%;
        }
    }

    .content-block {
        .notice-loan-details-request{
            margin: 10px 0;
        }
    }
	
	.content-block:last-child {
		margin-bottom: 58px;
		margin-top: 0;
		.paymentselect-wrap {
			padding-top:0
		}
		.block.paymentselect-option {
			//padding-top:0;
            display:flex;
            flex-direction:column;
		}
	}

    .endorsement-message {
        @include font("body", 15px, $dark, 500, 1.67);
        display: inline-block;
        background-color: $white;
        border: 1px #e8e9e9 solid;
        margin: 32px 32px 16px 0px;
        padding: 16px;
        @include media("<450") {
            font-size: 13px;
            margin: 32px 0px 16px 0px;
        }
    }

    .endorsement-box {
        background-color: $light-orange;
        padding: 20px 0px;
        margin-left: 32px;
        @include media("<450") {
            padding: 18px 28px;
            margin-left: 16px;
        }
    }

    .endorsement-link {
        display: inline-block;
    }

    .completed-title {
        @include font("body", 16px, $dark-81, 500, 1.5);
        padding: 24px 0px 16px 0px;
    }

    .completed-top-border {
        border-top: 1px solid $dark-81;
    }

    .completed-bottom-border {
        border-bottom: 1px solid $dark-81;
    }

    .flex-parent .completed-section {
        margin-bottom: 0;
    }

    .paymentselect-option form {
        display: flex;
        flex-direction: column;
        height: 100%;
    }


    .paymentselect-option .paymentselect-content {
        background: $blue-nurse;
        @if $site == 'norway' {
            background: $nor-darkgrey;
        }
    }

    //.paymentselect-option:nth-child(3n+2) .paymentselect-content{
    //    background: $dark-water-blue;
    //}
    //.paymentselect-option:nth-child(3n+3) .paymentselect-content{
    //    background: $water-blue;
    //}

    .paymentselect-option .paymentselect-header {
        background: $blue-nurse;
        @if $site == 'norway' {
            background: $nor-darkgrey;
        }
    }

    //.paymentselect-option:nth-child(3n+2) .paymentselect-header{
    //    background: $dark-water-blue;
    //}
    //.paymentselect-option:nth-child(3n+3) .paymentselect-header{
    //    background: $water-blue;
    //}

    .invoice-back {
        line-height: 43px;
    }

    .invoice-confirm {
        float: right;
    }

    .checkout-links {
        display: inline-flex;
    }

    .checkout-right-container {
        text-align: right;
        width: 100%;
    }

    .ct-payment-body {
        li {
            padding: 3px 0;
        }
    }

    .survey {
        a {
            border: solid $black 1px;
            display: inline-flex;
            margin-right: 28px;
            background-color: $white;

            .smiley {
                margin: 6px 8px;
            }

            .text {
                padding: 8px 20px 0 4px;;
            }
        }

        a:visited {
            background-color: $black;
            color: $white;

            svg {
                fill: $white;
            }
        }
    }

    .main-block {
        ul {
            list-style-type: none;
            padding-left: 20px;
        }

        li {
            margin-bottom: 14px;
        }

        li:before {
            content: " ";
            position: absolute;
            margin-left: -22px;
            height: 9px;
            width: 9px;
            margin-top: 8px;
            border: 2px solid $accent-primary;
            border-radius: 50%;
        }
    }

    main .block.paymentselect-option {
        //max-width: 50%;
        max-width: 36%;

        flex-basis: 30%;

        padding: 52px 32px 36px 16px;

        /*        @if($site == 'lsos'){
                    display:flex;
                    flex-direction:column;
                    .paymentselect-action,
                    .paymentselect-header{
                        flex:none;
                    }
                    .paymentselect-content{
                        //display:flex;
                        flex: 1 1 auto;
                    }
                }*/
        .paymentselect-action {
            .btn {
                max-width: unset;
            }
        }

        .excessMessage {
            @include font("body", 17px, $dark, 500, 1.47);
            text-align: center;
            font-size: 17px;
            padding: 0 0 24px 0;
            font-weight: 500;
        }

        .paymentselect-amend {
            .btn {
                max-width: unset;
                background-color: $alabaster;
                color: $dark !important;
                border-color: $dark;
                font-weight: 500;
            }
        }

        .amend-quote-re-calculate {
            .btn {
                max-width: unset;
                font-weight: 500;
                line-height: 44px;

            }

            padding-bottom: 10px;
        }

        .amend-quote-cancel {
            .btn {
                max-width: unset;
                background-color: $alabaster;
                color: $dark !important;
                border-color: $dark;
                font-weight: 500;
                line-height: 44px;
            }
        }

        .payment-select {
            .btn {
                max-width: unset;
                font-weight: 500;
                line-height: 44px;
            }
        }

        .payment-content {
            //min-height: 100%;
        }

        @include media("<desktop") {
            flex-basis: 47%;

            padding-bottom: 32px;
        }

        @include media("<=phoneland") {
            flex-basis: 100%;

            padding-bottom: 32px;
            max-width: 100%;
        }

        .paymentselect-header {
            padding: 10px 16px;

            text-align: center;
            vertical-align: middle;

            color: $white;

            .paymentselect-title {
                font-size: 22px;
                font-weight: 400;
                line-height: 1.55em;

                margin: 0;

                @include media("<=1100px") {
                    font-size: 18px;
                }
            }
        }

        .title-block {
            padding-bottom: 48px;
            padding-top: 32px;
            width: 100%;

            > .ct-title {
                @include font('title');
                font-size: 38px;
                line-height: 1.27;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                text-align: center;
            }
        }

        .checkout-amend-table {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        .checkout-table {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            color: $white;
            justify-content: space-between;

            .checkout-list {
                padding-bottom: 64px;
                //padding-left: 24px;
                //padding-right: 24px;
                flex-grow: 0;
            }

            .footnote-block {
                margin: -34px 15px 64px;
            }


            .flex-parent {
                line-height: 1.57;

                //> .ct-label {
                //    flex-basis: 33%;
                //}

                > .ct-data {
                    flex-basis: 35%;
                }

                > .ct-amend-label {
                    flex-basis: 33%;
                    line-height: 48px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                }

                > .ct-amend-data {
                    flex-basis: 35%;

                    .chosen-single {
                        background: rgba(255, 255, 255, 0.2);
                        border: 0;
                        min-height: 48px;
                        min-width: 150px;
                        line-height: 48px;
                        color: $white;
                        padding: 2px 16px;
                    }
                }

                ul {
                    list-style-type: none;
                }

                li:before {
                    content: " ";
                    position: absolute;
                    margin-left: -22px;
                    height: 9px;
                    width: 9px;
                    margin-top: 5px;
                    border: 2px solid $accent-primary;
                    border-radius: 50%;
                }
                //> .ct-payment-body {
                //    flex-basis: 100%;
                //
                //
                //}



                .text-align-center{
                    text-align: center;
                }

            }
            .selected-block {
                padding: 6px 32px 16px 32px;
                position: relative;

                .selected-image {
                    position: absolute;
                    top: 0;
                    left: 32px;
                }

                .selected-text-block {
                    padding: 3px 0 2px 28px;
                    background: rgba(255, 255, 255, 0.12);
                    margin-left: 32px;
                    min-height: 37px;
                    display: flex;
                    align-items: center;

                    .selected-top-text {
                        @include font("body", 12px, $white, 500, 1.5);
                    }

                    .selected-bottom-text {
                        @include font("body", 12px, $white, 300, 1.5);
                    }
                }
            }

            .premium-block {
                padding-top: 24px;
                padding-bottom: 24px;
                flex-grow: 0;
                border-top: 1px solid $slate;
                font-weight: 500;

                > .ct-label span{
                    font-size: 11px;
                    font-weight: 300;
                }
                > .ct-total {
                    flex-basis: 35%;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 0.92;
                }

                > .ct-bolt-on-total {
                    text-align: right;
                    span + span{
                        font-size: 11px;
                        font-weight: 300;
                        line-height: 2em;
                    }
                }
            }

        }


        .icon-payonline {
            width: 33px;
            height: 28px;

            vertical-align: middle;

            fill: $white;
        }

        .icon-finance {
            width: 33px;
            height: 33px;

            vertical-align: middle;

            fill: $white;
        }

        .icon-invoice {
            width: 26px;
            height: 26px;

            vertical-align: middle;

            fill: $white;
        }

        .paymentselect-content {
            display: flex;
            flex-grow: 1;

            .checkout-table .flex-parent {
                padding-top: 0;
                padding-bottom: 0;
                //flex-grow: 1;
                flex-grow: 0;

                .block {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }

            .checkout-table .premium-block {
                padding-top: 24px;
                padding-bottom: 24px;
                flex-grow: 0;
            }
        }

        .paymentselect-list {
            // padding:0;
            margin: 0;

            list-style-type: none;

            .paymentselect-listitem {
                position: relative;

                margin-bottom: 6px;
                padding-left: 25px;
                // font-size: 16px;
                // line-height: 18px;
                .list-icon {
                    position: absolute;
                    z-index: 1;
                    top: 3px;
                    left: 0;

                    display: block;

                    width: 12px;
                    height: 14px;

                    @include media('<=phoneland') {
                        font-size: 20px;
                        line-height: 18px;

                        top: 0;
                    }

                    svg {
                        display: block;

                        width: 100%;
                        height: 100%;

                        fill: $accent-secondary;
                    }
                }
            }
        }

        .btn_a {
            font-size: 16px;
            font-weight: 600;

            width: 100%;
            padding: 10px 16px;

            text-align: center;
            text-transform: uppercase;
            min-height: 64px;
            max-width: unset;
        }

        @if ($site == mobility) {
            .paymentselect-action {
                margin-top: -20px;
                width: 90%;
                margin-left: 5%;
            }
        }
    }

    .contact-info .flex-parent {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29em;

        margin: 0px;
        padding: 8px 16px;

        > .block {
            min-width: 0;
            padding: 8px 16px;
        }

        > .contact-label {
            flex-basis: 36%;
        }

        > .contact-detail {
            font-weight: 600;

            flex-basis: 64%;

            span {
                display: block;
            }

            a {
                overflow: hidden;

                text-overflow: ellipsis;
            }
        }
    }

    address {
        font-style: normal;
    }

    .checkout-table {
        width: 100%;
        max-width: 500px;

        .flex-parent {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.29em;

            margin: 0;
            padding: 8px 26px;

            > .block {
                min-width: 0;
                padding: 8px 16px;

                span {
                    display: block;
                }
            }

            > .ct-label {
                @if ($site == mobility) {
                    flex-basis: 36%;
                    /*               } @elseif($site == lsos) {
                                       flex-basis: 55%;*/
                } @else {
                    flex-basis: 60%;
                }
            }

            > .ct-data {
                font-weight: 600;

                @if ($site == mobility) {
                    flex-basis: 56%;
                } @else {
                    flex-basis: 40%;
                }
                @at-root .ie11 & {
                    /*                    @if($site == lsos){
                                            flex-basis: 30%;
                                        } @else {*/
                    flex-basis: 48%;
                    /*}*/
                }
                span {
                    display: block;
                }

                a {
                    overflow: hidden;

                    text-overflow: ellipsis;
                }
            }
        }

        .completed-parent {
            padding-left: 0px;
        }

        &.ct-totalrow {
            max-width: none;
            padding: 16px 0px;
            border-top: 1px solid $dark;

            .flex-parent {
                justify-content: flex-end;

                .ct-label {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 2.29em;

                    max-width: 220px;

                    text-align: right;

                }

                .ct-data {
                    font-size: 24px;
                    line-height: 1.33em;

                    max-width: 200px;
                }

                .ct-your-quote-label {
                    font-size: 14px;
                    line-height: 2.2;
                    max-width: 200px;
                    font-weight: 500;
                    color: $dark;
                    text-align: right;
                }

                .ct-your-quote-data {
                    font-size: 22px;
                    line-height: 1.45;
                    max-width: 100px;
                    font-weight: 500;
                    color: $dark;
                }
            }
        }
    }

    .completed-table {
        max-width: 600px;
    }

    .completed-contact-parent {
        padding: 48px 32px;

        .completed-contact-title {
            @include font("title", 38px, $white, 300, 1.68);
            padding-bottom: 16px;
        }

        .completed-contact-details {
            @include font("body", 13px, $accent-primary, 500, 1.0);
        }

        .completed-contact-phone {
            @include font("body", 15px, $accent-primary, 300, 1.6);
        }

        .completed-contact-body {
            @include font("body", 14px, $light-grey-nine, 300, 1.57);
            padding-bottom: 24px;
        }
    }

    .cross-sales {
        .cross-sales-title {
            @include font("body", 14px, $accent-primary, 500, 1.0);
        }

        .cross-sales-tit .cross-sales {
            .cross-sales-title {
                @include font("body", 14px, $accent-primary, 500, 1.0);
            }

            .cross-sales-body {
                @include font("body", 15px, $light-grey-nine, 300, 1.57);
                padding-bottom: 24px;
            }

            .cross-sales-list {
                @include font("body", 15px, $light-grey-nine, 500, 1.57);
            }
        }

        le {
            @include font("body", 15px, $light-grey-nine, 300, 1.57);
            padding-bottom: 24px;
        }

        .cross-sales-list {
            @include font("body", 15px, $light-grey-nine, 500, 1.57);
        }
    }

    .checkout-table .flex-parent .ct-completed-label {
        flex-basis: 40%;
        color: $warm-grey-three;
        padding-left: 0px;
    }

    .checkout-table .flex-parent .ct-completed-data {
        flex-basis: 60%;
        color: $dark-81;

        span {
            display: block;
        }

        font-weight: 600;
    }

    .checkout-table .flex-parent .ct-completed-title {
        flex-basis: 60%;
        color: $dark-81;

        span {
            display: block;
        }

        padding: 16px 0;
        font-size: 18px;
        font-weight: 600;
    }


    .your-quote-table {
        max-width: unset;
    }

    .your-quote-title {
        flex-basis: 100%;
        @include font("body", 22px, $dark, 300, 1.45);
        padding-bottom: 48px;
    }

    .your-quote-bolton-title {
        flex-basis: 100%;
        @include font("body", 18px, $almost-black, 500, 1.56);
        padding: 32px 0px;
    }

    .your-quote-additional {
        flex-basis: 100%;
        @include font("body", 22px, $dark, 300, 1.45);
        padding: 32px 0 0 0;
    }

    .your-quote-additional {
        flex-basis: 100%;
        @include font("body", 22px, $dark, 300, 1.45);
        padding: 32px 0 0 0;
    }

    .your-quote-instructions {
        padding-top: 32px;
    }

    .your-quote-instructions-text-left{
        p{
            text-align: left;
        }

    }



    .your-quote-about {
        padding: 48px 32px;

        .about-sub-title {
            @include font("body", 14px, $accent-primary, 500, 1.0);
        }

        .about-title {
            @include font("title", 38px, $white, 300, 1.0);
            padding: 8px 0 16px 0;
        }

        .about-body {
            @include font("body", 14px, $alabaster, 300, 1.57);
            padding: 16px 0 32px 0;

            li {
                margin-left: 16px;
            }
        }
    }

    .checkout-table .flex-parent .ct-your-quote-label {
        flex-basis: 30%;
        @include font("body", 14px, $dark, 500, 1.43);
        padding-left: 0px;
    }

    .checkout-table .flex-parent .ct-your-quote-data {
        flex-basis: 50%;
        @include font("body", 14px, $dark, 500, 1.43);
    }

    .checkout-actions {
        display: flex;

        padding-top: 56px;

        border-top: solid 2px $white-two;

        max-width:600px;

        flex-direction: row-reverse;

        //justify-content: space-between;
        justify-content: flex-start;

        .block {
            //max-width: fit-content;
            max-width: 220px;
        }

        form {
            text-align: right;
        }

        &.no-border-top {
            border-top: none;
        }
    }

    .content-block + .checkout-actions {
        border-top: solid 2px $alto;
    }

    .checkout-actions + .checkout-actions {
        border-top: 0 none;
    }

    .info-widget {
        blockquote {
            font-size: 16px;

            margin: 1em 0 0;
            padding: 0;

            font-weight: 300;

            .quote-attribution {
                font-size: 13px;
                font-weight: 300;
                font-style: normal;
                line-height: 17px;

                margin-top: .5em;
            }
        }
    }

    .map {
        height: 250px;
    }

    .checkout-subtitle {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.57em;
    }

    .extra-list {
        margin: 16px 0;

        list-style: none;

        li {
            // margin-bottom: 20px;
            font-size: 16px;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            line-height: 1.57em;

            position: relative;

            padding-left: 25px;

            .list-icon {
                font-size: 18px;
                line-height: .9;

                position: absolute;
                z-index: 1;
                top: 3px;
                left: 0;

                display: block;

                width: 12px;
                height: 14px;

                color: $accent-primary;

                font-weight: 700;
                @include media('<=phoneland') {
                    font-size: 20px;
                    line-height: 18px;

                    top: 0;
                }

                svg {
                    display: block;

                    width: 100%;
                    height: 100%;

                    fill: $accent-primary;
                }
            }
        }
    }

    .extra-option {
        .jf-input-type-radio {
            display: flex;

            justify-content: space-between;

            > label {
                display: inline-block;

                max-width: 70%;

                flex: 1 1 70%;
                @include media('<=phoneland') {
                    max-width: none;

                    flex-basis: 100%;
                }

                h3 {
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.0em;

                    margin: 0;

                    color: $rouge-two;
                }

                span {
                    text-align: right;
                    vertical-align: top;
                }
            }

            > span {
                font-size: 14px;
                font-weight: 300;
                line-height: 1.57;

                text-align: right;
                vertical-align: top;

                flex: 1 1 30%;
                @include media('<=phoneland') {
                    flex-basis: 100%;
                }
            }
        }
    }

    .extra-detail {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.43em;

        color: $rouge-two;
    }

    .focb:before {
        font-size: 1.1em;
        font-weight: bold;
        line-height: 1.1em;

        display: inline-block;

        width: 1.1em;
        height: 1.1em;
        margin: auto 6px auto 0;

        content: '\00a0'; /*nbsp*/
        text-align: center;

        border: 1px solid rgba($greyish-brown, .3);
        /*position: absolute;
        left: 0;  */
        background-color: #FFFFFF;
        text-shadow: 1px 1px 1px rgba($greyish-brown, .2);
    }

    input[type=radio].focbed {
        position: absolute;

        width: 2em;
        height: 2em;
        /*display: none;  */

        opacity: .001;
    }

    input[type=radio] + .focb:before {
        border-radius: 50%;
    }

    input[type=radio]:checked + .focb:before {
        position: relative;
        /*content: "\2022";  bullet*/

        background-color: $rouge;
        box-shadow: 0 0 0 4px #FFFFFF inset, 0 0 1px 0 $greyish-brown;
    }

    input[type=radio]:required:checked + .focb:before {
        box-shadow: 0 0 0 4px #FFFFFF inset, 0 0 1px 1px $greyish-brown;
    }

    &.loginOrCreate {
        .tabbed {
            border: 0 none;
            background: transparent;

            > ul {
                > li {
                    &:first-child {
                        a {
                            padding-right: 1em;
                            padding-left: 0;

                            border-right: 1px solid rgba($greyish-brown, .5);
                        }
                    }

                    > a {
                        margin: 0;
                        padding: 0;
                        padding-left: 1em;

                        color: $greyish-brown;
                        border: 0 none;
                        border-radius: 0;
                        background: transparent;
                    }
                }
            }

            > section {
                border: 0 none;
                background: transparent;

                > div > div {
                    padding: 0;

                    border-bottom: 0 none;
                }
            }
        }
    }

    .faq-section .faq-accordion {
        border: 0 none;

        .accordion-item {
            border: 0 none;

            .accordion-trigger {
                padding: 4px 0;
                @if $site == 'norway' {
                    background-color: $nor-darkgrey;
                    .title-tert{
                        color: $white;
                    }
                    .icon-open-close {
                        fill: $white;
                    }
                }
                .title-tert {
                    font-weight: 400;
                }

                &:after,
                &:before {
                    display: none;
                }
            }

            &.accordion-item-open {
                .accordion-trigger {
                    border-bottom: 0 none;
                    background: transparent;

                    @if $site == 'norway' {
                        background-color: $nor-darkestgrey;
                    }
                    .title-tert {
                        font-weight: 400;
                    }
                }

                .accordion-content {
                    padding: 8px 0 16px;

                    p {
                        margin-bottom: 0;
                        padding-right: 0;
                        padding-left: 0;
                    }

                    p + p {
                        padding-top: 16px;
                    }
                    @if $site == 'norway' {
                        border-color: $nor-darkgrey;
                    }
                }
            }
        }
    }

    .checkout-error {
        color: $error-color;

        a {
            color: $error-color;
        }
    }

    .trans-success,
    .trans-error {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.78em;

        margin-bottom: 0;

        & + p {
            margin-bottom: 16px;
        }
    }

    .trans-success {
        color: $success-color;
    }

    .trans-error {
        color: $error-color;

        input[type='submit'] {
            font-size: 18px;

            text-decoration: underline;
            // font-weight: 600;

            color: $error-color;
            border: 0 none;
            background: transparent;

            -webkit-appearance: none;
        }
    }

    &.secure-hosting {
        main .block {
            flex-basis: 60%; //IE11 fix as browser sniff doesn't happen on secure hosting template
        }

        .block.sidebar {
            flex-basis: 30%; //IE11 fix as browser sniff doesn't happen on secure hosting template
        }

        .checkout-table {
            .flex-parent {
                > .ct-label {
                    flex-basis: 70%;
                }

                > .ct-data {
                    flex-basis: 30%;
                }
            }
        }

        input[type='submit'].trans-back {
            font-size: 16px;
            // font-weight: 600;

            color: $b_btn-text;
            border: 1px solid $b_btn-border;
            background: $b_btn_background;

            @extend .btn;
            -webkit-appearance: none;

            &:hover {
                color: $b_btn-hover-text;
                border: 1px solid $b_btn-hover-border;
                background: $b_btn-hover_background;
            }
        }

        .error-details {
            padding: 16px;

            background: rgba($black, .05);

            margin-top: 1em;

            border-bottom: 0 none;

            .header-subsection {
                font-size: 18px;
                font-weight: 600;
                line-height: 1.78em;

                margin-top: 0;
                margin-bottom: .5em;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .secure-main {
        background-color: $white;
    }

    .secure-bounds {
        padding: 30px;

        .secure-total-row {
            border-bottom: 0;
        }
    }

    .secure-table {
        max-width: none;
    }

    .secure-btn {
        margin-top: 16px;
        margin-bottom: 32px;
    }

    .secure-total {
        margin-bottom: 32px;
    }

    .secure-form {
        max-width: 600px;
    }

    .your-order-table {
        max-width: 600px;
        width: 100%;
    }

    .your-order-title {
        display: flex;
        padding-top: 45px;
    }

    .your-order-name {
        font-size: 18px;
        line-height: 1.2;
        color: $shark;
        padding: 0 20px 24px 0;
    }

    .your-order-summary {
        display: flex;
        padding-bottom: 20px;
    }

    .your-order-total-text {
        font-size: 14px;
        line-height: 38px;
        font-weight: 500;
        color: $shark;
        padding-right: 40px;
    }

    .your-order-total {
        font-size: 22px;
        font-weight: 500;
        color: $shark;
    }

    .your-order-details {
        color: $shark;
        font-size: 14px;
        font-weight: 500;
        padding: 20px 30px 20px 0;
    }

    .your-order-line {
        display: flex;
        padding: 0 0 15px 0;
    }

    .your-order-line-name {
        padding: 0 60px 0px 0;
    }

    .your-order-line-name {
        padding: 0 60px 0px 0;
        flex-basis: 48%;
    }

    .your-order-line-value {
        flex-basis: 48%;
    }

    .accordion {
        width: 100%;

        .accordion-item .accordion-trigger {
            border-bottom: 0px;
            background-color: $pale-grey-four;
            @if $site == 'norway' {
                background-color: $nor-darkgrey;
                color: $white;
                .icon-open-close {
                    fill: $white;
                    color: $white;
                }
            }
        }

        .accordion-item-open .accordion-trigger {
            background-color: $blue-grey-two;
            @if $site == 'norway' {
                background-color: $nor-darkestgrey;
            }
        }

        .accordion-content {
            border: solid 1px $light-grey-eleven;
            border-top-width: 0;
            @if $site == 'norway' {
                border-color: $nor-darkgrey;
            }
        }
    }

    .icon-ic-close {
        fill: #b1b1b1;
    }

    .your-order-block {
        max-width: 600px;
    }

    .checkout-yourquote {
        .your-order-block {
            .checkout-table {
                max-width: unset;
            }

            .checkout-actions {
                padding: 0;
                border: none;
            }

            .checkout-table.ct-totalrow {
                border-bottom-color: $light-grey-eleven;
            }
        }

        .your-quote-title {
            padding: 0;
        }
    }

    .checkout-table.ct-totalrow .flex-parent .ct-your-quote-data {
        max-width: 125px;
        white-space: nowrap;
    }

    .quoteLink {
        padding-right: 75px;
    }

    .your-order-title {
        display: flex;
    }

    .your-order-name {
        font-size: 18px;
        color: $shark;
        padding: 0 20px 24px 0;
    }

    .your-order-summary {
        display: flex;
        padding-bottom: 20px;
    }

    .your-order-total-text {
        font-size: 14px;
        font-weight: 400;
        color: $shark;
        padding-right: 40px;
    }

    .your-order-total {
        font-size: 22px;
        font-weight: 400;
        color: $shark;
    }

    .your-order-details {
        color: $shark;
        font-size: 14px;
        font-weight: 400;
        padding: 20px 30px 20px 0;
    }

    .your-order-line {
        display: flex;
        padding: 0 0 15px 0;
    }

    .your-order-line-name {
        padding: 0 60px 0px 0;
    }

    .your-order-line-name {
        padding: 0 60px 0px 0;
        flex-basis: 48%;
    }

    .your-order-line-value {
        flex-basis: 48%;
    }

    .accordion {
        width: 500px;

        .accordion-item .accordion-trigger {
            border-bottom: 0px;
            background-color: #e3e4e4;
            @if $site == 'norway' {
                background-color: $nor-darkgrey;
                .title-tert{
                    color: $white;
                }
                .icon-open-close {
                    fill: $white;
                }
            }
        }

        .accordion-item-open .accordion-trigger {
            background-color: #414a51;
            @if $site == 'norway' {
                background-color: $nor-darkestgrey;
            }
        }

        .accordion-content {
            border: solid 1px #d7d5d5;
            border-top-width: 0;
            @if $site == 'norway' {
                border-color: $nor-darkgrey;
            }
        }
    }

    .bounds {
        .notice-rates {
            margin: 36px 0;
        }
    }

    .alert-red{
        color: $nor-red;
    }







    @media print {
        #nav, input, #footer {display:none;}
        .no-print {
            display: none !important;
        }
    }

    button[name="print"] {
        //width: auto;
        text-transform: uppercase;
        color: #01bee5 !important;
        border: solid 1px #01bee5;
        background-color: transparent;
        &:hover {
            background-color: transparent;
            color: #01bee5 !important;
        }
    }








    #modal-checkout {
        .modal-contentWrap {
            position: relative;
            max-width: 920px;
            max-height: 735px;
            width: 100%;
            height: 100%;
            .modal-checkout-content{
                position: absolute;
                left: 0;
                bottom: 101px;
                top: 100px;
                width: 100%;
                overflow-y: scroll;

                .flex-parent {
                    width: 80%;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    margin: 0 auto;

                    .block {
                        width: 100%;
                        text-align: left;
                        ul {
                            padding-left: 16px !important;
                        }
                    }
                }
            }
            .modal-footer {
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #3d3d3d;
                height: 100px;
                width: 100%;
                .flex-parent {
                    padding: 14px;
                    align-content: flex-end;
                    justify-content: flex-end;
                    height: 100%;
                    @include media("<=phoneland"){
                        align-content: center;
                        justify-content: center;
                    }
                    .block {
                        //max-width: fit-content;
                        max-width: 200px;
                        padding: 8px;
                        .modal-agreement-button {
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}


.pf-dashboard.pf-checkout #headband .header-alert {
    display: none;
}

// TEMPORARY FIX FOR MOBILITY @FIXME
.pf-checkout.checkout-yourquote .content-block {
    border-bottom: 0 none;
}

.pf-checkout main .paymentselect-option form {
    @if ($site == mobility) {
        height: 90%;
    }

    .paymentselect-content {
        height: calc(100% - 64px);
    }
}

@if ($site == mobility) {
    .pf-checkout .jsonForm input[type='submit'] {
        border-radius: 28px;
    }
}

.secure-hosting {
    #wrapper-for-cardstartmonth,
    #wrapper-for-cardexpire {
        .collection {
            label {
                display: block;
                padding-left: 0;
            }

            .jf-input-type-select {
                display: inline-block;
                position: relative;

                select {
                    padding-right: 20px;
                }
            }
        }
    }
}

.checkout-paymentmethod.pf-checkout {
    .checkout-actions {
        flex-direction: row;
    }

    .modal-wrap.active {
        .modal-contentWrap {
            max-width: 700px;
        }
    }
}

#complete-print {
    text-decoration: underline;
    cursor: pointer;
    padding-top: 16px;

    &:hover {
        text-decoration: underline;
    }
}

.checkout-paymentcomplete {
    @include media("<desktop") {
        .block,
        .block.sidebar {
            flex-basis: 100%;
        }
    }

    .stickyFootWrap .thank-you-logged-out-content {
        text-align: left;
        padding: 20px 57px;
    }

    .stickyFootWrap .thank-you-logged-out-content-wrap {
        max-width: 60%;
    }
}

//.checkout-pay-finance-refer,
//.checkout-pay-invoice {
//    .block {
//        max-width: 800px;
//    }
//}

.endorsement-header {
    @include font("body", 22px, $dark, 300, 1.45);
    padding: 8px 32px;
}

.endorsement-info {
    @include font("body", 15px, $dark, 500, 1.6);
    padding: 8px 32px;
}

.endorsement-title {
    @include font("body", 15px, $dark, 500, 1.6);
    padding: 8px 32px 0 32px;
}

.endorsement-body {
    @include font("body", 15px, $charcoal-grey, 300, 1.6);
    padding: 0px 32px 8px 32px;
    text-align: center;
}

.loginOrCreate .dashboard-tabs .tabbed-wrap .tabs-control a {
    border-top: 1px $warm-grey solid;
    border-right: 1px $warm-grey solid;
    padding-top: 11px;
}

.loginOrCreate .dashboard-tabs .tabbed-wrap .tabs-control li.active a {
    border-top: 1px $black solid;
    border-right: 1px $black solid;
    padding-top: 11px;
}

.loginOrCreate {
    .info-widget {
        z-index: 10;
    }

    .checkout-actions {
        padding-bottom: 20px;
        justify-content:space-between;
    }

    .btn {
        min-height: 66px;
    }

    .dashboard-tabs .dashboard-wrap .tabs-control {
        margin: auto;
    }

    .owaspText ul {
        padding-left: 20px;
    }
}

//.pf-checkout ul {
//    margin-left: 1em;
//}

.thank-you-logged-out-header {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    color: $accent-primary;
    padding-bottom: 20px;
    padding-top: 30px
}

.thank-you-logged-out-detail {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    color: #414a51;
    border-bottom: 1px solid #c8c9ca;
    padding-bottom: 25px;
}

.thank-you-logged-out-content .logo {
    border-bottom: 1px solid #c8c9ca;
    padding-bottom: 25px;

    img, svg {
        width: 112px;
        height: 54px;
        fill: #4a4a4a;
    }
}

.thank-you-logged-out-buttons {
    padding: 40px 0 10px 0;
}

//.checkout-yourquote {
//    ul {
//        margin-left: 0;
//    }
//}

.checkout-yourquote {
    .widget-title {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.checkout-noquote {
    .column-title {
        @include font("title", 48px, $a-text, 400, 1.21);

        @at-root .detail-page & {
            color: $b_text;
        }

        @at-root .search-results-page & {
            color: $b_text;
        }

        margin-top: 0;
        margin-bottom: 20px;

        display: block;

        @include media('<=phoneland') {
            font-size: 32px;
        }
    }
}


.jsonForm .jf-input-type-text b{
    font-size: 12px;
    font-weight: 400;
    color: tomato;
}

.content-message-checkout-acc{
    padding: 0 32px;
    margin-bottom: 20px;
    font-size: 14px;
    width: 70%;
    @include media("<=tabport"){
        width:100%;
    }
}
table.table-checkout-acc{

    //padding: 32px;

    tbody tr:nth-of-type(odd), .pf-admin table tbody tr:nth-of-type(odd),
    tbody  {
        background: none;
    }

    tbody td{
        padding: 10px 20px 10px 0;
    }

    .td-title{
        border-bottom: 1px solid gray;
        font-weight: bold;
    }

    &:after{
        content: none;
    }
}

.downloads-widget{
    margin-bottom:1px;
    .div-alert-title{
        margin-top:0;
        padding-left:0;
        color:$body-text;
    }
}
.checkout-back{
    white-space:nowrap;
    &:before{
        content:'←';
        display:inline-block;
        margin-right:0.5em;
    }
}

.checkout-skip{
    white-space:nowrap;
    &:after{
        content:'→';
        display:inline-block;
        margin-left:0.5em;
    }
}