.dataTable {
    width: 100%;
    margin: .625rem 0;
}
.dataTable th,
.dataTable td {
    padding: 0.625rem;
}
.dataTable thead {
    border-bottom: 4px solid #999999;
}
.dataTable thead th,
.dataTable thead td {
    cursor: default;
    color: #000000;
    border-color: transparent;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
.dataTable tfoot {
    border-top: 4px solid #999999;
}
.dataTable tfoot th,
.dataTable tfoot td {
    cursor: default;
    color: #000000;
    border-color: transparent;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
.dataTable tbody td {
    padding: 0.625rem 0.85rem;
}
.dataTable .sortable-column {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.dataTable .sortable-column:after {
    position: absolute;
    content: "";
    width: 1rem;
    height: 1rem;
    left: 100%;
    margin-left: -20px;
    top: 50%;
    margin-top: -0.5rem;
    color: inherit;
    font-size: 1rem;
    line-height: 1;
}
.dataTable .sortable-column.sort-asc,
.dataTable .sortable-column.sort-desc {
    background-color: #eeeeee;
}
.dataTable .sortable-column.sort-asc:after,
.dataTable .sortable-column.sort-desc:after {
    color: #1d1d1d;
}
.dataTable .sortable-column.sort-asc:after {
    content: "\2191";
}
.dataTable .sortable-column.sort-desc:after {
    content: "\2193";
}
.dataTable.sortable-markers-on-left .sortable-column {
    padding-left: 30px;
}
.dataTable.sortable-markers-on-left .sortable-column:before,
.dataTable.sortable-markers-on-left .sortable-column:after {
    left: 0;
    margin-left: 10px;
}
.dataTable tr.selected td {
    /*background-color: rgba(28, 183, 236, 0.1);*/
}
.dataTable td.selected {
    /*background-color: rgba(28, 183, 236, 0.3);*/
}
.dataTable.striped tbody tr:nth-child(odd) {
    background: #eeeeee;
}
.dataTable.hovered tbody tr:hover {
    /*background-color: rgba(28, 183, 236, 0.1);*/
}
.dataTable.cell-hovered tbody td:hover {
    /*background-color: rgba(28, 183, 236, 0.3);*/
}
.dataTable.border {
    border: 1px #999999 solid;
}
.dataTable.bordered th,
.dataTable.bordered td {
    border: 1px #999999 solid;
}
.dataTable.bordered thead tr:first-child th,
.dataTable.bordered thead tr:first-child td {
    border-top: none;
}
.dataTable.bordered thead tr:first-child th:first-child,
.dataTable.bordered thead tr:first-child td:first-child {
    border-left: none;
}
.dataTable.bordered thead tr:first-child th:last-child,
.dataTable.bordered thead tr:first-child td:last-child {
    border-right: none;
}
.dataTable.bordered tbody tr:first-child td {
    border-top: none;
}
.dataTable.bordered tbody tr td:first-child {
    border-left: none;
}
.dataTable.bordered tbody tr td:last-child {
    border-right: none;
}
.dataTable.bordered tbody tr:last-child td {
    border-bottom: none;
}
.dataTable .condensed th,
.dataTable .condensed td {
    padding: .3125rem;
}
.dataTable .super-condensed th,
.dataTable .super-condensed td {
    padding: .125rem;
}
.dataTable .sorting {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.dataTable .sorting:after {
    position: absolute;
    content: "";
    width: 1rem;
    height: 1rem;
    left: 100%;
    margin-left: -20px;
    top: 50%;
    margin-top: -0.5rem;
    color: inherit;
    font-size: 1rem;
    line-height: 1;
}
.dataTable .sorting.sort-asc,
.dataTable .sorting.sort-desc {
    background-color: #eeeeee;
}
.dataTable .sorting.sort-asc:after,
.dataTable .sorting.sort-desc:after {
    color: #1d1d1d;
}
.dataTable .sorting.sort-asc:after {
    content: "\2191";
}
.dataTable .sorting.sort-desc:after {
    content: "\2193";
}
.dataTable .sorting_asc,
.dataTable .sorting_desc {
    position: relative;
    cursor: pointer;
}
.dataTable .sorting_asc:after,
.dataTable .sorting_desc:after {
    position: absolute;
    content: "";
    width: 1rem;
    height: 1rem;
    left: 100%;
    margin-left: -20px;
    top: 50%;
    margin-top: -0.5rem;
    color: inherit;
    line-height: 1;
    font-size: 1.1rem;
}
.dataTable .sorting_asc {
    background-color: #eeeeee;
}
.dataTable .sorting_asc:after {
    color: #1d1d1d;
}
.dataTable .sorting_asc:after {
    content: "\2191";
}
.dataTable .sorting_desc {
    background-color: #eeeeee;
}
.dataTable .sorting_desc:after {
    color: #1d1d1d;
}
.dataTable .sorting_desc:after {
    content: "\2193";
}
.dataTables_paginate {
    display: block;
    margin: .625rem 0;
    float: left;
    width: 50%;
    margin: 0;
}
.dataTables_paginate:before,
.dataTables_paginate:after {
    display: table;
    content: "";
}
.dataTables_paginate:after {
    clear: both;
}
.dataTables_paginate .ellipsis {
    display: block;
    float: left;
    margin-left: .0652rem;
    padding: 0.25rem .625rem;
    background-color: #ffffff;
    text-align: center;
    font-size: .875rem;
    color: #6D6E71;
}
.dataTables_paginate > .item {
    display: block;
    float: left;
    margin-left: .0652rem;
    padding: 0.25rem .625rem;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px #eeeeee solid;
    text-align: center;
    font-size: .875rem;
}
.dataTables_paginate > .item:first-child {
    margin-left: 0 !important;
}
.dataTables_paginate > .item.current,
.dataTables_paginate > .item.active {
    background-color: #000;
    border-color: #000;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}
.dataTables_paginate > .item:hover {
    background-color: #75c7ee;
    border-color: #75c7ee;
    color: #ffffff;
}
.dataTables_paginate > .item:disabled,
.dataTables_paginate > .item.disabled {
    cursor: default;
    background-color: #eeeeee;
    border-color: #eeeeee;
    color: #999999;
}
.dataTables_paginate > .item.spaces {
    border: 0;
    cursor: default;
}
.dataTables_paginate > .item.spaces:hover {
    background-color: inherit !important;
    color: inherit !important;
}
.dataTables_paginate.rounded > .item {
    border-radius: .3125rem;
}
.dataTables_paginate.cycle > .item {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: .7rem;
    padding: .4375rem 0;
}
.dataTables_paginate.no-border > .item {
    border: 0;
}
.dataTables_paginate.no-border > .item:hover {
    color: #59cde2;
    background-color: transparent !important;
}
.dataTables_paginate.no-border > .item:disabled,
.dataTables_paginate.no-border > .item.disabled {
    cursor: default;
    background-color: transparent;
    border-color: transparent;
    color: #999999;
}
.dataTables_paginate.no-border > .item.current:hover,
.dataTables_paginate.no-border > .item.active:hover {
    background-color: #75c7ee !important;
    border-color: #75c7ee !important;
    color: #ffffff !important;
}
.dataTables_paginate .paginate_button {
    display: block;
    float: left;
    margin-left: .0652rem;
    padding: 0.25rem .625rem;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px #6D6E71 solid;
    text-align: center;
    font-size: .875rem;
    color: #6D6E71;
}
.dataTables_paginate .paginate_button:first-child {
    margin-left: 0 !important;
}
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.active {
    background-color: #f1f2f2;
    border-color: #6D6E71;
    color: #6D6E71;
    /*text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);*/
}
.dataTables_paginate .paginate_button:hover {
    background-color: #f1f2f2;
    border-color: #6D6E71;
    color: #6D6E71;
}
.dataTables_paginate .paginate_button:disabled,
.dataTables_paginate .paginate_button.disabled {
    cursor: default;
    background-color: #eeeeee;
    border-color: #eeeeee;
    color: #999999;
}
.dataTables_paginate .paginate_button.spaces {
    border: 0;
    cursor: default;
}
.dataTables_paginate .paginate_button.spaces:hover {
    background-color: inherit !important;
    color: inherit !important;
}
.dataTables_info {
    padding: 5px;
    /*background-color: #eeeeee;*/
    background-color: transparent;
    font-size: .875rem;
    float: right;
}
.dataTables_length {
    display: block;
    float: left;
    margin: .625rem 0;
}
.dataTables_length select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin: 0 .125rem;
    padding: .3125rem;
    border: 1px #d9d9d9 solid;
}
.dataTables_length select:focus {
    outline: none;
    border-color: #1d1d1d;
}
.dataTables_filter {
    display: block;
    float: right;
    margin: .625rem 0;
}
.dataTables_filter label > input {
    margin: 0 0 0 .25rem;
}
.dataTables_filter input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: .3125rem;
    border: 1px #d9d9d9 solid;
}
.dataTables_filter input:focus {
    outline: none;
    border-color: #1d1d1d;
}

.dataTable {
    clear: both;
}

/*.dataTables_processing {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
}*/

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.dataTables_processing {
    display: block;
    position: fixed;
    pointer-events: none;
    z-index: 99999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(28, 117, 188, 0.2);
    border-right: 1.1em solid rgba(28, 117, 188, 0.2);
    border-bottom: 1.1em solid rgba(28, 117, 188, 0.2);
    border-left: 1.1em solid rgba(28, 117, 188, 1);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.0s infinite linear;
    animation: load8 1.0s infinite linear;
}

.dataTables_processing,
.dataTables_processing:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
}

.dataTables_wrapper {
    margin-bottom: 100px;
}