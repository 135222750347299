
// collection

.jsonForm {
    .jf-row {
        & > .collection {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin: 10px -28px;
            padding: 0;
            flex-basis:100%;
            & > label {

                &:not(.error) {
                    display: inline-flex;
                    align-items: center;
                    flex: 0 1 auto;
                    min-width: 60px;
                    margin: 0 21px 8px;
                    padding: 0 5px;
                    background-color: transparent;
                    border: none;

                    &:focus,
                    &:active {
                        background-color: transparent;
                    }
                }

                & > input {
                    margin: 0 6px 0 0;
                }
            }

            @include media('<tabport') {
                margin: 10px -15px 5px;

                & > label {
                    &:not(.error) {
                        margin: 0 10px 12px;
                    }
                    &.error.mvalidation {
                        display: none !important;
                    }
                }
            }

            @include media('<=phoneland') {
                flex-wrap: nowrap;
                flex-direction: column;
                align-items: flex-start;
                margin: 10px 0 0;

                & > label:not(.error) {
                    display: flex;
                    margin: 0 0 20px;
                    padding: 0;
                }
            }
        }
    }

    .collection {
        label.focb {
            pointer-events: none;
        }

        &>label {
            &:hover {
                input:not(:checked) {
                    & + .focb {
                        &::before {
                            border-color: $radio-border-hover;
                        }
                        &::after {
                            background: $radio-dot-hover;
                        }
                    }
                }
                @include media('<tabport') {
                    & ~ label.error.mvalidation {
                        display: none !important;
                    }
                }
            }
        }
    }

    .linkedFormWrapper .jf-row > .collection {
        white-space: nowrap;
    }
}

// end collection