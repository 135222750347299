.dataTable {
    position: relative;

    .sorting_asc,
    .sorting_desc{
        background: lighten(map_get($datatables,thBg),10%);

        &::after{
            color: map_get($datatables,thText);
        }
    }
    tr:nth-child(odd){
        background:$pale-grey-91;
    }
    tr:nth-child(even){
        background:rgba($pale-grey-three,0.5);
    }
    select{
        width:100%;
    }


    &:after {
        content: '';
        width: calc(100% - 4px);
        display: block;
        height: 3px;
        background: $charcoal-grey;
        position: absolute;
        bottom: -2px;
    }

    tr[data-is-test-record] td:first-child:after {
        content: "Test record";
        text-transform: uppercase;
        position: absolute;
        font-size: 8px;
        display: block;
        color: #00000054;
    }

}

.dt-actions{
    list-style:none;
    white-space: nowrap;
    padding-left:0;
    .dt-actions-item{
        display:inline-block;
        margin-right:0.5em;
        svg{
            fill: $link-primary;
        }
        a{
            display:inline-block;
        }
        a:hover {
            svg {
                fill: $hover-primary;
            }
        }
		.icon-warning-triangle{
			fill: $warn-color;
			height:28px;
			width:28px;
		}
        .icon-edit{
            height:28px;
            width:28px;
        }
        .icon-notes{
            height:28px;
            width:21px;
        }
        .icon-view-files{
            height:28px;
            width:23px;
        }
        .icon-link-client-console{
            height:29px;
            width:29px;
        }
        .reset-icon{
            height:29px;
            width:29px;
        }
        &:last-child{
            margin-right:0;
        }
        &.dt-action-titem-disabled{
            .icon-view-files {
                fill: $warm-grey-three!important;
            }
        }
    }
}

.dataTables_paginate .paginate_button:disabled,
.dataTables_paginate .paginate_button.disabled {
    display:none;
}

.dataTables_paginate .paginate_button {

    border: 0px;
    color: $almost-black;
}

.dataTables_paginate .paginate_button:hover {
    background-color: $white;
    border-color: $white;
    color: $almost-black;
}

.dataTables_paginate .paginate_button.current{
    cursor:default;
}

/* style for open close for datatable in showassets view in dashboard; */
/*the images are located in dashboard project not in icons.svg due to them being used for background; added by Pradeep */


td.dashboard_pflist_assetsView_childrow_openclose{
    background: url('/img/datatable_plus_open.svg') no-repeat center center;
    background-size: 14px 14px;
    cursor: pointer;
}
tr.dashboard_pflist_assetsView_childrow_shown td.dashboard_pflist_assetsView_childrow_openclose {
    background: url('/img/datatable_minus_close.svg') no-repeat center center;
    background-size: 14px 14px;
    background-color: $white;
}

#policyList-dashboard-assetsView{
    border-spacing: 0px;

    thead th{
        border: 1px solid white !important;
    }

    thead th:last-child{
         border-left: 0px none !important;
     }

    thead th:nth-child(3){
        border-right: 0px none !important;
    }

    td{
        border-top: 2px solid $white;
    }

    .dashboard_pflist_assetsView_childrow_shown{
        td{
            background-color: $white;
        }

        + tr td{
            background-color: $white;
            border-top: 2px solid #f0f0f0;
            border-bottom: 2px solid #f0f0f0;
            @include media('<=phoneland') {
                padding-left: 8px;
            }
        }
    }
}

.dataTable_overflow_wrapper {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    width:100%;
}


table.pflist_dashboard_assetsView{
    border-spacing: 0 !important;

    thead th{
        background: $white;
        //border: 1px solid white !important;
        display: none;
    }


    thead th:last-child{
        border-left: 0 none !important;
    }

    thead th:nth-child(2){
        border-right: 0 none !important;
    }

    td{
        border-top: 2px solid $white;
    }

    &:after {
        content: "";
        width: calc(100% - 4px);
        display: block;
        height: 3px;
        position: absolute;
        bottom: -2px;
        background: none;
    }

    .vehicle-make-model-year {
        text-align: left;
        position: relative;
        left: 30px;
        @media only screen and (max-width: 767px){
            text-align: center;
            position: relative;
            left: 0;
        }
    }

    .registration-number {
        position: relative;
        text-align: left;
        //left: -19px;
        @media only screen and (max-width: 767px){
            text-align: center;
            position: relative;
            left: 0;
        }
    }

    .dashboard_pflist_assetsView_childrow_shown{

        td{
            background-color: $white;
        }

        td:nth-child(3){
            border-right: solid 1px #edeeee;
            border-top: solid 1px #edeeee;
            background-color: $white;
        }

        td:nth-child(2){
            border-top: solid 1px #edeeee;
            background-color: $white;
        }

        td:nth-child(1){
            border-left: solid 1px #edeeee;
            border-top: solid 1px #edeeee;
            background-color: $white;
        }

        @media only screen and (max-width: 767px){
            td:nth-child(3){
                border: solid 1px #edeeee;
                background-color: $white;

                position: relative;
                display: block;
                width: 100%;
                border-bottom: 1px solid #CCCCCC;
            }
            td:nth-child(2){
                border: solid 1px #edeeee;
                background-color: $white;

                position: relative;
                display: block;
                width: 100%;
                border-bottom: 1px solid #CCCCCC;
            }

            td:nth-child(1){
                border: solid 1px #edeeee;
                background-color: $white;

                position: relative;
                display: block;
                width: 100%;
                border-bottom: 1px solid #CCCCCC;
            }
        }

        + tr td{
            background-color: $white;
            border-top: 2px solid #f0f0f0;
            border-bottom: 2px solid #f0f0f0;
            border-right: solid 1px #edeeee;
            border-left: solid 1px #edeeee;
        }

        @media only screen and (max-width: 767px){
            + tr td{
                padding-left: 4% !important;
            }
        }

    }

    .td-img-name {
        padding: 10px !important;
    }

    .logo-car-name {
        padding: 0;
        .insurance-logo {
            height: 61px;
            max-height: 61px;
            max-width: 65px;
            width: 100%;
            position: relative;
            margin-right: 20px;
        }
        .vehicle-make-model-year {
            padding: 19px 0 0 0;
            position: relative;
            left: 0;
        }
    }

    #inner-asset-details{
        border-spacing: unset;
        td{
            border: none;
            padding: 2px 10px;
        }

        &::after{
            content: "";
            width: unset;
            display: none;
            height: unset;
            position: unset;
            bottom: unset;
            background: unset;
        }
    }

    #inner-asset-details-no-headers{
        border-spacing: unset;
        td{
            border: none;
            padding: 2px 10px 2px 0;
        }

        &::after{
            content: "";
            width: unset;
            display: none;
            height: unset;
            position: unset;
            bottom: unset;
            background: unset;
        }
    }

}

