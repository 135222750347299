
/*! Flags */

.service-flag {
	position: absolute;
	display: block;
	top: 0;
	width: 43px;
	height: 59px;
	padding: 20px 6px 8px;
	overflow: hidden;

	img, svg {
		display: block;
		width: 31px;
		height: 31px;
	}
}

// Variants

.resources-block .image-wrap {
	position: relative;

	.service-flag {
		right: 16px;
	}
}

.page-header .flag-wrap {
	position: static !important;
	height: 50px;

	.service-flag {
		left: 16px;
		width: 50px;
		height: 69px;
		padding: 24px 7px 8px;

		img, svg {
			width: 36px;
			height: 36px;
		}
	}
}