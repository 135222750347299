.aside {
	background: $side_background;
	margin-bottom: 2em;
	@include font("body", 14px, $side_text, 300, 1.57);
	
	.flex-parent {
		padding: 24px 16px 64px;
		@include media("<=phoneland") {
			padding: 24px 8px 32px;
		}
		
		.block {
			flex-basis: 100%;
			padding: 8px 16px;
			@include media("<=phoneland") {
				padding: 8px;
			}
		}
	}
	
	a {
		@include font("body", 15px, false, 300, 1.5);
	}
	
	.phoneno {
		@include font("body", 28px, $accent-primary, 500, 1);
		margin-bottom: .45em;
	}
	
	.openinghours {
		a {
			color: $white;
			margin-bottom: 1em;
			display: inline-block;
		}
	}
	
	@include media("<=phoneland") {
		margin: 0 -16px;
		width: 100vw;
	}
	
	.jf-row {
		color: $white;
		
		&.password-reset {
			color: #aaa;
			padding: 10px 0;
			font-size: 12px;
			
			a {
				font-size: 12px;
			}
		}
	}
	
	.show-hide-pass {
		color: $white;
	}
	
	a.show-hide-pass:hover {
		color: $white;
	}
	
	&.aside-without-margin {
		margin: 0;
	}
	
	&.aside-get-a-quote .flex-parent {
		padding-bottom: 26px;
	}
}

.aside-title {
	h3 {
		@include font("body", 14px, $accent-primary, 500, 1.2);
		margin: 0;
	}
	
	h4, h2 {
		@include font("title", 38px, $white, 400, 1.2);
		margin: 0;
		padding-bottom:12px;
		padding-top:8px;
		@include media("<=phoneland") {
			font-size: 32px;
			
			line-height: 1.14em;
			margin-bottom: .5em;
		}
	}
}

.aside-download-list {
	list-style-type: none;
	padding-left: 0;
	
	li {
		padding: 2px 0;
	}
}

// Team members in asides (ie author / speak to specialist
.aside {
	.aside-image-wrap {
		margin-bottom: 40px;
	}
	
	.team-name {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.8;
		color: $accent-primary;
	}
	
	.team-email,
	.team-tel {
		font-size: 16px;
		line-height: 2;
		color: $accent-primary;
		
		a {
			color: $white;
			
			&:hover {
				color: $accent-primary;
			}
		}
	}
	
	.team-email a {
		text-decoration: underline;
	}
}

.aside + .aside {
	margin-top: 32px;
}

.asideQuickQuote {
	background-color: #4a4a4a;
	margin-bottom: 2em;
	@include font("body", 14px, $side_text, 300, 1.57);
	
	.flex-parent {
		padding: 24px 16px 64px;
		@include media("<=phoneland") {
			padding: 24px 8px 32px;
		}
		
		.block {
			flex-basis: 100%;
			padding: 8px 16px;
			@include media("<=phoneland") {
				padding: 8px;
			}
		}
	}
	
	a {
		@include font("body", 15px, false, 300, 1.5);
	}
	
	.jf-row {
		color: $white;
		position: relative;
		display: block;
		width: calc(100% - 6px);
		margin: 0px 6px 30px;
		padding: 0px;
		
		&.password-reset {
			color: #aaa;
			padding: 10px 0;
			font-size: 12px;
			
			a {
				font-size: 12px;
			}
		}
	}
	
	
	&.busy {
		opacity: 0.5;
		pointer-events: none;
	}
	
	
	.get-your-quick-quote-block {
		width: calc(100% - 6px);
		height: 124px;
		opacity: 0.9;
		background-color: #ffffff;
		max-height: 200px;
		overflow: hidden;
		transition: all 500ms;
		display: block;
		margin: 20px 6px 30px;

		&.closed {
			background: inherit;
			max-height: 1px;
		}
		
		&.busy {
			transition: all 0ms;
			background: #d8d8d8;
			background: -moz-linear-gradient(left, #6e6e6e 60%, #e99813 60%, #e99813 100%);
			background: -webkit-linear-gradient(left, #6e6e6e 60%, #e99813 60%, #e99813 100%);
			background: linear-gradient(90deg, #6e6e6e 60%, #e99813 60%, #e99813 100%);
			background-size: 200% 100%;
			/* animate this (wipe already exists) */
			animation: wipe 1s linear infinite;
		}
		
		#error {
			color: #ec5a4b;
			padding: 0 10px;
		}
		
		.get-your-quick-quote-label {
			opacity: 0.9;
			@include font("body", 14px, #20262a, 600, 1.29);
			padding: 10px;
		}
		
		.get-your-quick-quote-value {
			font-size: 42px;
			opacity: 0.9;
			@include font("body", 42px, #20262a, 300, 1.38);
			position: relative;
			left: 37px;
			@media only screen and (max-width: 1198px) {
				@include font("body", 30px, #20262a, 300, 1.38);
			}
			
			.NOK-label {
				font-size: 28px;
				@media only screen and (max-width: 1198px) {
					@include font("body", 16px, #20262a, 300, 1.38);
				}
			}
		}

		#other{
			opacity: 0.9;
			position: relative;
			left: 37px;
			@include font("body", 16px, #20262a, 300, 1.38);

			.NOK-label {
				@include font("body", 14px, #20262a, 300, 1.38);
			}
		}
	}

	.jsonFormInverseQuickQuote{

		li, ul {
			list-style-type: none;
		}

	}
}