.pf-dashboard{
    .client-details {
        margin-bottom: 1px;
        padding: 16px;

        background: $side_background;

        position:relative;        

        a:hover {
            color: $link-primary!important;
        }
        .title h2,
        .title h3 {
            font-size: 24px;
            font-weight: 400;

            margin: 0 0 .4em;
        }
        .details {
            padding: 0;
            .img-wrap {
                display: inline-block;

                width: auto;
                max-width: 100%;
                margin-top: 10px;
                img {
                    width: 100%;
                }
            }
        }
        .address {
            span {
                display: block;
                &.name,
                &.role {
                    display: inline-block;
                }
            }
        }
    }
    .manage-details{
        position: relative;

        display: block;

        padding: 16px 0 8px 32px;

        text-transform: lowercase;

        border-top: 1px solid $white;
        @include media("<=desktop") {
            position: absolute;
            top: 0;
            right: 16px;

            border-top: 0;
        }
        @include media("<=phoneland") {
            position: relative;
            right: auto;

            border-top: 1px solid $white;
        }
        
        .svg-icon {
            position: absolute;
            top: 50%;
            left: 0;

            display: inline-block;

            margin-top: -6px;

            fill: #B6BCB8;
        }
    }
}