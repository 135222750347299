.your-order-table {
    /* max-width: 600px; */
    max-width: unset!important;
}
.your-order-block {
    /* max-width: 600px; */

    .your-order-block-title {
        flex-basis: 100%;
        @include font("body", 22px, $black, 500, 1.45);
        letter-spacing: 0.34px;
        margin:0;
        padding-bottom: 32px;
    }

    .your-order-block-title:empty {
        display: none;
    }

    .offlineInsurances-wrapper {
        padding-top: 26px;
    }

    .checkout-table.ct-totalrow {
        max-width: none;
        padding: 3px 0px;
        /* border-top: 5px double #d7d5d5; */
        margin-top: 30px;
    }

    .content-block {
        border-bottom: none; 
    }
}
.your-order-item {
    padding-block: 0;
    margin-bottom: 16px;
    .accordion {
        padding-bottom: 10px;
    }
}

.your-order-item + .your-order-item {
    margin-top: 30px;
}

.your-order-title {
    display: flex;
    padding-block: 5px;
    align-items: center;
    gap: 16px;
    align-items: center;
}

.your-order-name {
    font-size: 21px;
    line-height: 1.5em;
    color: $shark;
    font-weight: 400; 
}

.your-order-summary {
    display: flex;
    font-weight: 600;
    align-items: baseline;
}

.your-order-total-text {
    font-size: 16px;
    line-height: 38px;
    padding-right: 12px;
}

.your-order-total {
    color: $shark;
}

.your-order-details {
    color: $shark;
    font-size: 14px;
    font-weight: 400;
    padding: 20px 30px 20px 0;
}

.your-order-line {
    display: flex;
    padding: 0 0 15px 0;
}

.your-order-line-value {
    flex-basis: 48%;
}

.your-order-line-name {
    padding: 0 60px 0 0;
    flex-basis: 48%;
}

.your-order-totalrow {
    display: flex;
    padding-block: 14px; 
    align-items: baseline;
    justify-content: end;
    gap: 16px;
    .ct-your-quote-label {
        font-weight:900;
    }
    .ct-your-quote-data {
        font-size: 1.35em;
        font-weight:600;
    }
    
}






/* funky blue bar to the left option? */
.snazzy {
    .your-order-item {
        padding-block: 0;
        border-left: 3px solid $azure;
        padding-left: 16px;
        margin-bottom: 16px;
    }
    .your-order-name {
        font-size: 18px;
    }
    .your-order-title {
        border-bottom: 1px solid #eee; 
        justify-content: space-between; 
    }
    .your-order-summary {
        justify-content: space-between;
        font-weight: 400;
    }
    .your-order-total-text {
        font-size: 14px;
        font-weight: 400;
        color: #b1b1b1;
        padding-right: 40px;
    }
    .your-order-total {
        font-size: 22px;
        font-weight: 400;
    }
    .your-order-block {
        .offlineInsurances-wrapper {
            padding-top: 16px;
        }
    }
    .your-order-table {
        .checkout-table.ct-totalrow {
            border-top: 5px double #d7d5d5;
        }
    }
    .your-order-totalrow {
        justify-content: space-between; 
        .ct-your-quote-label {
            font-weight:300;
        }
    }
}
/* end funky blue bar to the left */