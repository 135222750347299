
.resources-page {
    .page-header {
        &::before {
            display: none;
        }
        .bounds .ajax-form > .flex-parent > .block {
            z-index: 0;

            flex-basis: 100%;

            &:first-child {
                //padding-right: 0;
                background-color: $a_background;
                z-index:2; //This is to keep the select elements above the text search below.
            }

            &:first-child:before,
            &:last-child:before {
                display: none;
            }

            &:last-child:after {
                content: '';
                display: block;
                position: absolute;
                left: -50%;
                width: 200%;
                top: 0;
                height: 100%;
                background-color: $a_background;
            }
            .content-wrap {
                position: relative;
                z-index: 3;
            }
        }
    }

    .back-to-page {
        margin: 30px 0;

        @include media('<1200px') {
            margin: 16px 0 23px;
        }
        @include media('<wide-tab') {
            margin: 26px 0 23px;
        }
        @include media('<tabport') {
            margin: 0 0 23px;
        }
        .back-to-page-link {
            padding-left: 35px;

            @include media('<=phoneland') {
                padding-left: 28px;
            }
            .back-to-page-text {
                font-size: 18px;
                line-height: 22px;

                @include media('<=phoneland') {
                    font-size: 16px;
                }
            }
        }
    }

    img[src=''] {
        background-image: url(/img/onErrorImg/png) center center;
    }

    .imgError {
        overflow: hidden;

        min-height: 110px;
        max-height: 110px;

        background: url(/img/Lockton_white.png) center center;
        background-size: 100% auto;
    }

    .results-count {
        display: none;
    }

    .noResults {
        padding-top: 2em;
    }

}

.resources-grid {
    @extend %grid-thirds;
    padding-top:0;
    //.block {
    //    padding: 0;
    //}
    //margin: 32px;
    //padding: 32px 0;
    //@include media("<=tabport") {
    //    margin: 16px;
    //    padding: 16px 0;
    //}
    //@include media("<=phoneland") {
    //    margin: 8px;
    //    padding: 8px 0;
    //}
}
#main .resources-grid {
    @extend %grid-thirds;
    padding-top:0;
}

svg {
    background-color: transparent;
}

.icon {
    background-color: transparent !important;
    &-video {
        width: 28px;
        height: 28px;
    }
    &-presentation {
        width: 25px;
        height: 25px;
    }
    &-document {
        width: 19px;
        height: 23px;
    }
    &-template {
        width: 21px;
        height: 25px;
    }
    &-all {
        width: 25px;
        height: 25px;
    }
    &-event {
        width: 30px;
        height: 41px;
    }
    &-downloadBox {
        width: 30px;
        height: 32.05px;

        fill: #DDDDDD;
    }
}

.file-download {
    line-height: 32px;

    position: relative;

    margin: 12px 0;
    float: left;
    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
    a span {
        font-size: 20px;
        font-weight: 400;

        display: inline-block;

        padding-left: 40px;

        vertical-align: top;
    }
}

// Resource types:
//$newstypes: news, event, guidance, cpd, proposalform;
//
//@each $type in $newstypes {
//    .newstype_#{$type} {
//        .block-title {
//            background-color: map-get($newstype-colors, #{$type});
//            @if ($site == mobility) {
//                &.bottom {
//                    color: transparent !important;
//                }
//            }
//        }
//        .icon svg {
//            fill: map-get($newstype-colors, #{$type});
//        }
//    }
//}
