
.pagination-container {
	.pagination {
		border-top: 1px solid $alto;

		.pagination-text, li, a, span {
			font-size: 20px;
			line-height: 24px;
			color: $body-text;
		}
		.pagination-text {
		//	float: left;
		}
		ul {
			padding: 22px 16px 22px 25px;
			// float: left;
			list-style: none;
			font-size: 0;
			line-height: 0;
            display: flex;
            text-align: center;
			justify-content: flex-end;

			li {
				margin: 0 3px;
                flex-basis: 50px;

				&.active{
					background: $aqua-blue;
					color: $white;
                    padding: 14px;
				}

				&.pagedResults-prevnext{
					flex-basis: 80px;
				}

				a {
					background: $light-grey-seven;
					display: block;
					white-space: nowrap;
					padding: 14px;
				}

				a:hover {
					background: $light-grey-ten;
				}

				&.next, &.prev {
					margin: 0 1px;

					a {
						padding: 0;
					}
				}
			}
		}
	}
}


