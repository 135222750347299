
.rfqAccept, .rfqDecline {
  max-height: 0px;
  transition: 400ms ease 0s;
  overflow:hidden;
}

.rfqAccept.rfqShow, .rfqDecline.rfqShow {
  max-height: 500px;
}

.rfqRetractBounds {
  padding: 32px 0px 48px 0px;
}

