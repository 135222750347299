.downloads {
	position: relative;
	ul.files {
		list-style: none;
		padding: 0!important;
		li {
			display: block;
			padding:0;
			position: relative;
			margin:0;
			font-size: 16px;
			line-height: 1;
			a {
				min-height: 70px;
				display: flex;
				background-color: $light-grey-seven;
				@if($site = norway){
					background-color: $light-grey-nine;
				}
				color: $body-text;
				
				.fileIcon, .fileTitle, .fileAction {
					align-items: center;
				}
				.fileIcon {
					display: flex;
					align-items: center;
					justify-content: center;
					min-width: 70px;
					svg {
						fill: $accent-primary;
					}
				}
				.fileTitle {
					align-self: center;
					padding: 14px 14px 14px 0;
					flex-grow: 1;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.fileAction {
					background-color: $a_btn-background;
					min-width: 70px;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all 250ms;
					svg {
						fill: $white;
					}
				}
				&:hover {
					color: $link-primary;
					.fileAction {
						background-color: $a_btn-hover-background;
					}
				}
			}
			+ li {
				border-top: 1px solid $white;
			}
			&:after {
				display:none;
			}
			&:before {
				display:none;
			}
		}
	}
	
	
	
	
	/*svg {
		fill: $news-download;
	}*/
}