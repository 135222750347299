/**
* Site specific menu hack #shame
**/

.site-lsos{
     .page-get-a-quote,
     .page-request-a-quote{
        display:none!important;
     }
}

.site-locktonmobility{
     .page-insurance-services,
     .page-about-us{
        display:none!important;
     }
}

.site-medica,
.site-oasis,
.site-healthcare{
    .nav-icons-wrap{
        display:none!important;
    }
}