.pf-dashboard {

    .policies-parent {
        display: flex;
        padding: 0!important;
        justify-content: space-between;
        border-bottom: 0px;
        padding:16px;
        margin:0 -16px;
        @include media("<tabport") {
            flex-wrap: wrap;
        }

        .statusLegend{
            border-top: 2px solid $white;
            width: 100%;
            padding: 32px;
            position: relative;
            margin-top: 64px;
            @include media("<=tabport"){
                margin-top: 32px;
            }
        }

        .statusLegend:after {
            border-top: 2px solid $white;
            position: absolute;
            display: block;
            top: -2px;
            left: -2500px;
            right: -32px;
            height: 100%;
            content: '';
        }
    }
    .applications-my-policies-parent {
        @include media("<wide-tab") {
            flex-wrap: wrap;
        }
    }
    .cover-my-policies-parent {
        @include media("<1000px") {
            flex-wrap: wrap;
        }
    }

    .policies-main{
        width:100%;
    }
    .policies {
        max-width: 1100px;
        flex-basis:70%;
        flex-grow:1;
        .table{
            padding:0 16px;
            span.policy-name {
                display: block;
            }
            span.additional-info {
                display: block;
                font-size: 13px;
                font-weight: 500;
            }
            span.additional-info:before {
                content: "-";
                margin-right: 5px;
            }
        }

    }
    .flex-parent > .policy {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        max-width: 450px;
        &.policy_extended {
            max-width: 814px;
            border: solid 1px #a2a1a2;
            margin-bottom: 67px;
        }
    }

    .policy-sidebar {
        background-color: $blue-nurse;
        min-width: 255px;
        z-index: 1;
        margin-right:32px;
        padding: 0px;
        max-width: 255px;

        @include media("<915px"){
            margin-right: 0;
            max-width: none;
            min-width: unset;
            width: 100%;
        }

        .title {
            @include font("body", 20px, $white, 400, 1.3);
            padding: 50px 30px 0px 30px;
        }
        .progressItem {
            @include font("body", 14px, $white, 400, 1);
            padding: 30px;
            padding-bottom: 0;

            .progressValue {
                @include font("body", 14px, $white, 300, 1);
            }

            .progressRemainValue {
                @include font("body", 13px, $white, 300, 1);
            }

            .progressBorder {
                border-bottom: 1px #777677 solid;
            }

            .progressName {
                padding: 10px 0 10px 0;
            }
            .progressStatus {
                padding: 10px 0 10px 0;
            }
            .progressDoughnut {
                text-align: center;
                padding-bottom: 30px;
            }
            .doughnutChart {
            }
            .progressBar {
                margin: 10px 0 10px 0;
            }
            .progressRemaining {
                padding: 10px 0 10px 0;
                .days {
                    @include font("body", 22px, $white, 400, 1);
                }
                .text {
                }
            }
        }
    }

    .days-text{
        width: 296px;
        height: 16px;
        @include font("body", 14px, $white, 400, 1);
    }

    .wrap-progressBar{
        width: 200px;
        position: relative;
        top: 5px;
        left: 131px;
        &.wrap-progressBar-remaining{
            left: 166px;
        }
        &.wrap-progressBar-active{
            left: 91px;
        }
    }
    //.ie11 .policy,
    //.ie10 .policy {
    //    display: inline-block;
    //
    //    -ms-flex-preferred-size: 40%;
    //    flex-basis: calc(50% - 32px);
    //}
    //.ie9 .policy {
    //    width: calc(50% - 10px);
    //}
    .policy .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;

        position: relative;

        display: block;

        min-height: 40px;
        max-height: 75px;
        margin: 0;
        //    padding: 12px 50px 12px 16px;
        padding: 30px;
        background-color: $tundora;
        @if $site == 'norway'{
            background-color: $nor-darkgrey;
        }
        flex: 1 1 45%;

        color: #FFFFFF;
        &.title_extended{
            width: 764px;
            height: 67px;
            background-color: #212f38;
            padding: 0px;
            flex: 1 1 68%;
        }
    }

    .div-title{
        display: flex;
        flex-wrap: wrap;
    }

    .policy .title .svg-icon {
        position: absolute;
        top: 50%;
        right: 16px;
        display: block;

        width: 28px;
        height: 28px;
        margin: -14px 0 0 0;

        fill: #FFFFFF;
    }

    .performance-title{
        width: 493px;
        height: 19px;
        @include font("body", 16px, $white, 500,  1.43);
        position: relative;
        top: 40%;
        left: 5%;


    }

    .policyIcon {
        flex-basis: 80px;
        position: relative;
		background-color: $warm-grey-three;//default bg colour, gets overwritten later
        svg{
            fill: white;
            position: absolute;
            transform: translate(-50%,-50%);
            top: 50%;
            left: 50%;
            &.icon-complete{
                width: 35.9px;
                height: 27.3px;
            }
            &.icon-inprogress{
                width: 37px;
                height: 27px;
            }
            &.icon-warning-triangle{
                width: 46px;
                height: 40px;
            }
            &.icon-inactive{
                width: 26px;
                height: 18px;
                fill: #6D858D;
            }
        }
    }

    .leadInsurer{
        flex: 1 1 32%;
        background-color: #3c474e;
        text-align: center;
    }

    .lead-insurer-text{
        width: 262px;
        height: 16px;
        @include font("body", 14px, $white, 500,  1.43);
        position: relative;
        top: 40%;
    }

    .lead-insurer-name{
        display: block;
        white-space: nowrap;
        width: 257px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    //.lead-insurer-name:hover{
    //    overflow: visible;
    //    white-space: normal;
    //    height:auto;
    //}

    .text-uppercase{
        text-transform: uppercase;
    }

    .text-lowercase{
        text-transform: lowercase;
    }


    @each $status, $bgcolor in $policyStatuses {
        .policy.#{$status} {
            .policyIcon {
                background-color: $bgcolor;
                &.policyIcon_extended{
                    position: relative;
                    top: 60px;
                }
                &.policyIcon_in_doughnutChart{
                    position: relative;
                    top: -53px;
                }
            }
            .button {
                border-color: $bgcolor;
                background: $bgcolor;
            }
        }
        .status.#{$status} {
            fill: $bgcolor;
        }
        .icon-#{$status} + .status-icon {
            color: $bgcolor;
            background: $bgcolor;
        }

    }

    .policy .content {
        background-color: $dark;
        @if $site == 'norway'{
            background-color: $nor-darkestgrey;
        }
        flex-wrap: wrap;
        display: flex;
        @include font("body", 14px, $white, 400, 1.0);
        max-height: 86%;
        height: 100%;

        @media only screen and (max-width: 880px) {
            max-height: unset;
            height: unset;
        }

        .valueText {
            font-weight: 300;
        }
        .insurer-valueText{
            //width: 126px;
            //height: 19px;

            @include font("body", 14px, $dark, 500,  1.43);
            &.insurer-valueText_extended{
                //width: 126px;
                //height: 19px;
                @include font("body", 14px, $shark, 300,  1.43);
            }

            &.insurer-valueText_extended2{
                max-width: 450px;
                //height: 40px;
                @include font("body", 14px, $shark, 300,  1.43);
                padding: 0px;

                span {
                    margin-left: 30px;
                }
            }

            &.insurer-valueText_extended3{
                max-width: 450px;
                //height: 40px;
                margin-left: 30px;
                text-align: left;
                @include font("body", 14px, $shark, 500,  1.43);
                span {
                    margin-left: 53px;
                }
            }

            &.insurer-valueText_extended4{
                max-width: 450px;
                text-align: right;
                margin-bottom: 10px;
                padding: 0px;
                @include font("body", 14px, $shark, 500,  1.43);
            }

            strong{
                width: 87px;
                //height: 35px;
                @include font("body", 20px, $shark, 700,  1.56);
            }
        }

        .insurer-div-indemnity{
            max-width: 480px;
            margin-left: 6px;

        }

        .insurer-div{
            max-width: 480px;
            margin-left: 14px;

        }

        .insurer-div2{
            max-width: 450px;
            padding: 0px;
            border-bottom: solid 1px #e3e3e3;
            margin-left: 30px;
            margin-top: 5px;
        }

        .insurer .insurer-content1{
            text-align: left;
            border-bottom: solid 0.8px #9b9b9b;
            padding: 20px;
            .flex-column-parent{
                text-align: left;
            }
        }

        .insurer .insurer-content2{
            text-align: left;
            padding: 25px;
        }

        .align-content-right{
            float: right;
        }

        flex: 1 1 100%;
        .insurer {
            flex-basis: 100%;
            padding: 25px;
            &.insurer_extended{
                padding: 0px;
            }

            @if $site == 'globalfi' {
                flex-basis: 50%;
                border-top: #464646 solid 1px;
            }

        }
        .additonal-info{
            flex-basis: 100%;
            padding: 25px;
            &.insurer_extended{
                padding: 0px;
            }

            border-bottom: #464646 solid 1px;
        }

        .split{
            padding:20px 25px;
            max-height:20px;
        }
        .insurer {
            flex-basis: 100%;
            padding: 25px;
            &.insurer_extended{
                padding: 0px;
            }

            &.insurer-special{
                //max-height: 33%;
                max-height: 24%;
                height: 100%;
                //@media only screen and (max-width: 1218px) {
                //    max-height: 24%;
                //}

                @media only screen and (max-width: 880px) {
                    max-height: unset;
                    height: unset;
                }
            }

            @if $site == 'globalfi' {
                flex-basis: 50%;
                border-top: #464646 solid 1px;
            }

        }

        .previous-policy{
            background: #575757;
            flex-basis: 100%;
            padding: 26px 25px 24px 47px;

            .button{
                background: #575757;
                color: white;
            }
        }

        .indemnity {
            flex-basis: 50%;
            padding: 25px;
            max-width: 50%;
            text-align: center;
            border: $tundora solid 1px;
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
            .indemnityTitle {
                padding-bottom: 10px;
            }
            .indemnityValue {
                @include font("body", 24.7px, $white, 500, 1.42);
                padding-top: 10px;
            }
            &.indemnity_extended{
                width: 553px;
                height: 236px;
                background-color: #ffffff;
                flex-basis: 68%;
                padding: 0px;
                max-width: none;
            }
        }
        .contentStatus {
            flex: 1 1 50%;
            max-width: 50%;
            padding: 25px;
            text-align: center;
            border: $tundora solid 1px;
            border-right: 0;
            border-bottom: 0;
            text-align: center;
            .statusTitle {
            }
            &.contentStatus_extended{
                flex: 1 1 32%;
                background-color: #ffffff;
                max-width: none;
            }
        }

        .contentStatus-text{
            width: 108px;
            height: 16px;
            @include font("body", 14px, $dark, 500,  1.43);
        }

        .renewal {
            flex: 1 1 50%;
            max-width: 50%;
            padding: 25px;
            text-align: center;
            background-color: $tundora;
            @if $site == 'norway'{
                background-color: $nor-darkgrey;
            }
            &.renewal_extended{
                flex: 1 1 68%;
                max-width: none;
                background-color: #212f38;
                display: -webkit-flex; /* Safari */
                -webkit-flex-wrap: wrap;
                display: flex;
                flex-wrap: wrap;
            }
        }
        .contentButton {
            flex: 1 1 50%;
            max-width: 50%;
            padding: 18px;
            background-color: $accent-primary;
            text-align: center;
            a {
                background-color: $accent-primary;
                @include font("body", 16px, $white, 500, 1.8);

                &.disabled{
                    background-color:$shark;
                }
            }
			&.disabled {
				background-color: $slate-grey-two;
				a {
					background-color: $slate-grey-two;
                    pointer-events: none;
				}
			}
            &.contentButton_extended{
                flex: 1 1 32%;
                max-width: none;
            }
        }
    }

    .notification-policy {
        position: relative;
        z-index: 1;
        padding: 16px 0 8px 0;
        border-bottom: solid 0.9px $purplish-grey;
        margin-bottom: 20px;
        width: 200px;
        left: 25px;

    }

    .div-alert-total{
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        background-color: $aqua-blue;
        width: 22px;
        height: 22px;
        @include font("body", 13px, $white, 500, 1.69);
        position: relative;
        top: -12px;
    }



    .insurance-type-alert{
        @include font("body", 13px, $white, 500, 1.46);
        .staff-contact{
            @include font("body", 13px, #9e9c9e, 300, 1.46);
        }
        &.alert-message{
            @include font("body", 13px, $white, 300, 1.46);
        }
    }

    .no-alerts{
        @include font("body", 13px, $white, 500, 1.46);
        text-align: center;
    }

    .no-display{
        display: none;
    }

    .close-copy {
        position: absolute;
        top: -6px;
        right: 0;
        display: block;
        padding: 0;
        width: 12px;
        height: 12px;
        @include font("body", 13px, $purplish-grey, 300, 1.46);
        cursor: pointer;
    }

    .time-added{
        width: 198px;
        height: 17px;
        @include font("body", 13px, $purplish-grey, 300, 1.46);
    }

    .display-inline-block{
        display: inline-block;
    }

    .display-inline{
        display: inline;
    }

    .display-block{
        display: block;
    }

    .ie11 .policy .content {
        height: calc(100% - 66px);
    }

    .policy .content > div span .button {
        margin-top: 0;
    }

    .policy-list {
        background-color: $alabaster;
    }

  &.new-dashboard {

      .new-policy-sidebar {
          background-color: $blue-nurse;
          min-width: 255px;
          z-index: 1;
          padding: 0px;
          max-width: 255px;

          .title {
              @include font("body", 20px, $white, 400, 1.3);
              padding: 50px 30px 0px 30px;
          }
          .progressItem {
              @include font("body", 14px, $white, 400, 1);
              padding: 30px;
              padding-bottom: 0;

              .progressValue {
                  @include font("body", 14px, $white, 300, 1);
              }
              .progressRemainValue {
                  @include font("body", 13px, $white, 300, 1);
              }


              .progressBorder {
                  border-bottom: 1px #777677 solid;
              }

              .progressName {
                  padding: 10px 0 10px 0;
              }
              .progressStatus {
                  padding: 10px 0 10px 0;
              }
              .progressDoughnut {
                  text-align: center;
                  padding-bottom: 30px;
              }
              .doughnutChart {
              }
              .progressBar {
                  margin: 10px 0 10px 0;
              }
              .progressRemaining {
                  padding: 10px 0 10px 0;
                  .days {
                      @include font("body", 22px, $white, 400, 1);
                  }
                  .text {
                  }
              }
          }
      }

      .new-policies-parent {
          display: flex;
          padding: 10px 30px;
          justify-content: space-between;
          border-bottom: 0px;
          flex-wrap: wrap;
          @include media("<tabport") {
              padding: 10px 13px;
          }

          @include media("<569px") {
              padding: 10px 4px;
          }

          .new-policies-main{
              width: 65%;
              padding-top: 22px;
              @include media("<915px") {
                  width: 100%;
              }

              .console_viewAll{
                  position: absolute;
                  display:block;
                  min-width:100px;
                  vertical-align: middle;
                  color:$dark-grey;
                  bottom: 8px;
                  z-index: 999999;
                  right: 16px;
                  opacity:1;
                  transition: all .4s ease 0s;


                  .btn_a{
                      display:inline-block;
                      max-width: 26px;
                      padding: 6px;
                      height: 26px;
                      margin-left:.5em;
                      line-height: 13px;
                  }
              }
              .viewAll .console_viewAll{
                  opacity:0;
              }
          }

          .new-policies {
              max-width: 1100px;
              flex-basis:70%;
              flex-grow:1;
              .table{
                  padding:0 16px;
              }

          }

      }

      .new-policy {

          &.new-policy_extended {
              max-width: 814px;
              border: solid 0.8px #9b9b9b;
              margin-bottom: 67px;
              position: relative;
          }

          &.consoleWidget {

              .task-count{
                  background-color:$accent-primary;
                  color:$white;
                  height:19px;
                  width:19px;
                  line-height:19px;
                  font-size: 12px;
                  font-weight: 600;
                  letter-spacing: 0.18px;
                  text-align: center;
                  border-radius:50%;
                  display: inline-block;
                  position: absolute;
                  margin-left: .5em;
              }
          }

          .div-title{
              display: flex;
              flex-wrap: wrap;
          }

          .new-leadInsurer{
              flex: 1 1 32%;
              background-color: #3c474e;
              text-align: center;
              padding: 10px;

              .new-lead-insurer-text{
                  @include font("body", 14px, $white, 300,  1.43);
                  position: relative;
              }

              .new-lead-insurer-name{
                  display: block;
                //white-space: nowrap;
                  white-space: pre-wrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
              }
          }

          .new-title {
              position: relative;
              display: block;
              margin: 0;
              flex: 1 1 45%;

              &.new-title_extended {
                  max-width: 814px;
                  //height: 67px;
                  background-color: #212f38;
                  padding: 0px;
                  flex: 1 1 68%;
              }

              .new-performance-title {
                  @include font("body", 16px, $white, 500, 1.43);
                  position: relative;
                  padding: 23px;
                  span.additional-info {
                      font-weight: 300;
                  }
                  span.additional-info::before {
                      content: " - ";
                  }
                  @include media("<294px"){
                      text-align: center;
                  }
              }
          }

          .new-content {
              flex-wrap: wrap;
              display: flex;
              @include font("body", 14px, $white, 400, 1.0);
              max-width: 814px;





              &.console_wrap{
                  overflow:hidden;
                  position:relative;

                  transition: max-height 0.4s ease 0s;

              }


              .insurer-valueText {


                  @include font("body", 14px, $dark, 500, 1.43);
                  min-width: 0;

                  &.insurer-valueText_extended {
                      @include font("body", 14px, $shark, 300, 1.43);

                  }

                  &.insurer-valueText_extended2 {
                      max-width: 50%;
                      @include font("body", 14px, $shark, 300, 1.43);
                      padding: 0px;

                      span {
                          margin-left: 30px;
                      }
                  }

                  &.insurer-valueText_extended3 {
                      //max-width: 450px;
                      //margin-left: 30px;
                      text-align: left;
                      @include font("body", 14px, $shark, 500, 1.43);

                      span {
                          //margin-left: 53px;
                      }

                      @include media("<244px") {
                          margin-left:  0;
                          padding: 0;
                      }
                  }

                  &.insurer-valueText_extended4 {
                      //max-width: 450px;
                      max-width: 50%;
                      text-align: right;
                      margin-bottom: 10px;
                      padding: 0px;
                      @include font("body", 14px, $shark, 500, 1.43);
                  }

                  strong {
                      width: 87px;
                      //height: 35px;
                      @include font("body", 20px, $shark, 700, 1.56);
                  }

                  &.insurer-valueText_extended5 {
                      border-bottom: solid 1px #e3e3e3;
                      padding: 0px 0px 6px 0px;
                      //max-width: 450px;
                      text-align: left;
                      @include font("body", 14px, $shark, 300, 1.43);
                      .asset-registration-number {
                          float: right;
                          margin-left: 10px;
                      }
                  }

                  &.insurer-valueText_extended6 {
                      max-width: 450px;
                      text-align: left;
                      //padding: 5px 0 0 39px;
                      @include font("body", 14px, $shark, 300, 1.43);
                      @include media("<244px") {
                          padding: 0;
                      }
                  }
              }

              .insurer-div-indemnity {
                  max-width: 480px;
                  width: 100%;

                  .flex-column-parent{
                      display: flex;
                      flex: 1 1 auto;
                      justify-content: space-between;
                      flex-direction: column;

                  }

                  .flex-row-parent{
                      display: flex;
                      flex: 1 1 auto;
                      justify-content: space-between;
                  }


              }

              .insurer-div {
                  //max-width: 480px;
                  //margin-left: 14px;
                  margin: 0 30px 0 30px;
                  padding: 5px 0 0 0;
                  @include media("<244px") {
                      margin:  0 0 0 0;
                  }

              }

              .insurer-div2 {
                  //border-bottom: solid 1px #e3e3e3;
                  //margin: 0 30px 0 30px;
                  padding: 20px 0 0 0;
                  @include media("<244px") {
                      margin:  0 0 0 0;
                  }
              }

              .insurer .insurer-content1 {
                  //text-align: left;
                  display: flex;
                  justify-content: space-between;
                  border-bottom: solid 0.8px #9b9b9b;

                  padding: 15px 0 15px 25px;

                  .flex-column-parent{
                      text-align: left;
                  }

                  //@include media(">1200px") {
                  //    padding: 15px 0 15px 25px;
                  //}
                  @include media("<244px") {
                      padding: 15px 15px 15px 15px;
                  }
              }

              .insurer .insurer-content2 {
                  text-align: left;
                  //padding: 25px;
                  padding: 25px 100px 25px 25px;

                  &.assets-content {
                      padding: 25px 25px 25px 25px;
                  }

                  @include media("<1200px") {
                      padding: 25px;
                  }
                  @include media("<244px") {
                      padding: 15px 15px 15px 15px;
                  }
              }

              .insurer .insurer-content-full {
                  padding: 25px;
              }

              .align-content-right {
                  float: right;
              }

              flex: 1 1 100%;

              .insurer {
                  flex-basis: 100%;
                  padding: 25px;

                  &.insurer_extended {
                      padding: 0px;
                  }

              }

              .new-indemnity {
                  text-align: center;
                  //border: solid 0.8px #9b9b9b;
                  border-left: 0;
                  border-right: 0;
                  border-bottom: 0;

                  .indemnityTitle {
                      padding-bottom: 10px;
                  }

                  .indemnityValue {
                      @include font("body", 24.7px, $white, 500, 1.42);
                      padding-top: 10px;
                  }

                  &.new-indemnity_extended {
                      background-color: #ffffff;
                      padding: 0px;
                      display: flex;
                      flex: 1 1 68%;
                      flex-wrap: wrap;

                      max-height: 258px;
                      position: relative;
                      overflow: hidden;
                      &:after{
                          content:'';
                          position:absolute;
                          display:block;
                          top: 214px;
                          left:1px;
                          right:1px;
                          bottom:1px;
                          background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 100%);
                          opacity:1;
                          transition: max-height 0.4s ease 0s;
                      }


                  }
              }

              &.viewAll .new-indemnity.new-indemnity_extended {
                  max-height: unset !important;
                  &:after{
                      display: none !important;
                  }
              }

              .new-contentStatus {
                  flex: 1 1 50%;
                  max-width: 50%;
                  padding: 25px;
                  text-align: center;
                  border-left: solid 0.8px #9b9b9b;
                  border-right: 0;
                  border-bottom: 0;

                  @include media("<548px") {
                      border-left: 0;
                      border-top: solid 0.8px #9b9b9b;
                  }

                  .new-statusTitle {
                      .new-contentStatus-text {
                          width: 108px;
                          height: 16px;
                          @include font("body", 14px, $dark, 500, 1.43);
                      }

                  }

                  .icon-status-block {
                      .center-svg{
                          position: absolute;
                          -webkit-animation: fadein 2s ; /* Safari, Chrome and Opera > 12.1 */
                          -moz-animation: fadein 2s; /* Firefox < 16 */
                          -ms-animation: fadein 2s; /* Internet Explorer */
                          -o-animation: fadein 2s; /* Opera < 12.1 */
                          animation: fadein 2s;
                          animation-delay: 1s;
                          animation-fill-mode: both;

                      }

                      .started-next{
                          margin-top: 54px;
                          fill: #169ED9;
                      }

                      .ic-warning{
                          margin-top: 46px;
                          fill: #ffffff;
                      }

                      .idle{
                          margin-top:62px;
                          fill: #67868E;
                      }

                      margin-top: 45px;
                      display: flex;
                      justify-content: center;
                      //align-items: center;

                      .new-policyIcon {
                          flex-basis: 80px;
                          position: relative;
                          background-color: $warm-grey-three;//default bg colour, gets overwritten later
                          svg{
                              fill: white;
                              position: absolute;
                              transform: translate(-50%,-50%);
                              top: 50%;
                              left: 50%;
                              &.icon-complete{
                                  max-width: 35.9px;
                                  height: 27.3px;
                                  width: 100%;
                              }
                              &.icon-inprogress{
                                  max-width: 37px;
                                  height: 27px;
                                  width: 100%;
                              }
                              &.icon-warning-triangle{
                                  max-width: 46px;
                                  height: 40px;
                                  width: 100%;
                              }
                              &.icon-inactive{
                                  max-width: 26px;
                                  height: 18px;
                                  width: 100%;
                                  fill: #6D858D;
                              }
                          }

                          &.new-policyIcon_extended{
                              position: relative;
                              top: 60px;
                          }
                          &.new-policyIcon_in_doughnutChart{
                              position: relative;
                              top: -53px;
                          }

                      }

                      .new-size-donutChart{
                          //max-width: 150px;
                          //height: 136px;
                          width: 142px;
                          height: 142px;
                          position: relative;
                      }
                  }

                  .icon-status-block-donutChart {
                      margin-top: 25px;
                  }

                  &.new-contentStatus_extended {
                      flex: 1 1 32%;
                      background-color: #ffffff;
                      max-width: none;
                  }
              }

              .renewal {
                  flex: 1 1 50%;
                  max-width: 50%;
                  padding: 18px;
                  text-align: center;
                  background-color: $tundora;
                  @if $site == 'norway' {
                      background-color: $nor-darkgrey;
                  }

                  &.renewal_extended {
                      flex: 1 1 68%;
                      max-width: none;
                      background-color: #212f38;
                      display: -webkit-flex; /* Safari */
                      -webkit-flex-wrap: wrap;
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: space-between;
                      padding: 26px 19px 10px 19px;

                      @include media(">914px","<1103px") {
                          justify-content: center;
                      }

                      @include media("<704px") {
                          justify-content: center;
                      }
                  }

                  .new-days-text{
                      position: relative;
                      max-width: 296px;
                      width: 100%;
                      height: 16px;
                      @include font("body", 14px, $white, 400, 1);
                  }

                  .new-wrap-progressBar{
                      position: relative;
                      max-width: 200px;
                      width: 100%;
                      padding: 3px 0px 0 0px;
                      &.wrap-progressBar-remaining{
                          padding: 3px 0px 0 0px;
                      }
                      &.wrap-progressBar-active{
                          padding: 3px 0px 0 0px;
                      }

                      .progressValue{
                          padding-right: 10px;
                      }
                      .progressRemainValue {
                          @include font("body", 13px, $white, 300, 1);
                      }


                  }
              }

              .contentButton {
                  flex: 1 1 50%;
                  max-width: 50%;
                  padding: 18px;
                  background-color: $accent-primary;
                  text-align: center;

                  a {
                      background-color: $accent-primary;
                      @include font("body", 16px, $white, 500, 1.8);

                      &.disabled {
                          background-color: $shark;
                      }
                  }

                  &.disabled {
                      background-color: $slate-grey-two;

                      a {
                          background-color: $slate-grey-two;
                          pointer-events: none;
                      }
                  }

                  &.contentButton_extended {
                      flex: 1 1 32%;
                      max-width: none;
                  }
              }

              .policyShape {
                  //max-width: 122px;
                  width: 122px;
                  height: 122px;
                  border-radius: 50%;
                  position: relative;
                  //width: 100%;
                  //top: 27px;
                  //left: 46px;
                  &.policyShape_green{
                      background-color: #90ce51;
                  }
                  &.policyShape_orange{
                      background-color: $sick-amber;
                  }
                  &.policyShape_gray{
                      background-color: #67868e;
                  }
                  &.policyShape_blue{
                      background-color: #169ed9;
                  }
                  &.policyShape_red{
                      background-color: $bright-red;
                  }
              }

          }
      }
  }







    .main-policy-details{
        background-color: $white;

        .pf-dashboard.pf-list.pflist_dashboard_assetsView.block {
            position: relative;
            flex-grow: 3;
            @media only screen and (min-width: 476px){
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    right: 0;
                    top: -37px;
                    bottom: -40px;
                    width: 2px;
                    background-color: #f0f0f0;
                }
            }

            .policy-details{
                max-width: 460px;

                .insurer-policy-name{
                    margin-bottom: 30px;
                }



                .policy-cover-remaining{
                    margin-bottom: 30px;

                }

                .policy-details-documents{

                    .premium-box{
                        margin-bottom: 30px;
                    }

                    .policy-endorsements{
                        margin-bottom: 30px;
                        .endorsement-advice {
                            margin-top: 16px;
                            .strong-view-assets {
                                font-weight: 500;
                            }
                        }
                    }

                    .documents-box{
                        border-bottom: 1px solid #D2D3D4;
                        padding-bottom: 30px;

                        .documents-content{
                            padding-top: 15px;
                            position: relative;

                            svg.policy-file-download {
                                width: 14px;
                                height: 17px;
                                position: relative;
                                top: 4px;
                            }

                        }
                        .document-name{
                            position: relative;
                            left: 10px;
                            @include font("body", 14px, #20262A, 300, 2);
                        }
                    }

                    .asset-claim-buttons-box{
                        padding-top: 44px;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        max-width: 480px;
                        .btn_b{
                            padding: 8px;
                            flex-basis: 30%;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }


        .policy-contact-details{

            .contact-block {
                position: relative;
                left: 41px;
                max-width: 250px;
            }

            @media only screen and (max-width: 476px){
                .contact-block {
                    left: 0px;
                }
            }

            .your-contacts-label {
                text-transform: uppercase;
                padding-bottom: 20px;
                @include font("body", 14px, #20262A, 500, 1);
            }

            .single-policy-contact{
                border-bottom: 1px solid #D2D3D4;
                padding-top: 16px;
                padding-bottom: 12px;
            }

            .policy-contact-company{
                @include font("body", 14px, #20262A, 500, 2);
            }

            .name{
                @include font("body", 14px, #20262A, 300, 2);
            }

            .staff-name {
                @include font("body", 14px, #20262A, 500, 1.4);
            }

            .currentStaff:first-child{
                .single-policy-contact {
                    padding-top:10px;
                }
            }

            .staff-role {
                @include font("body", 14px, #20262A, 300, 2);
            }


            .email{
                @include font("body", 14px, #01BEE5, 300, 2);
            }

            .phone{

                svg.policy-contact-handset-icon {
                    width: 14px;
                    height: 14px;
                    position: relative;
                    top: 2px;
                }

                .phone-number{
                    @include font("body", 14px, #20262A, 300, 2);
                    position: relative;
                    left: 9px;
                }
            }
        }

        .margin-bottom-10{
            margin-bottom: 10px;
        }

        .font-policy-label{
            @include font("body", 14px, #20262A, 500, 1.21);
        }

        .font-policy-content{
            @include font("body", 14px, #20262A, 300, 2);
        }

        div.assets-tooltip-icon{
            //display: inline-block;
            //position: relative;
            //border-radius: 50%;
            //width: 17px;
            //height: 17px;
            //border: 1px solid #01bee5;
            //text-align: center;
            //line-height: 1;
            //
            //span.assets-tooltip-icon{
            //    @include font("body", 14px, #01bee5, 300, 1);
            //    position: relative;
            //    top: 1px;
            //}
            .info-endorsements-icon {
                width: 17px;
                height: 17px;
                position: relative;
                top: 3px;
            }

        }

        .tooltip {
            position: relative;
            display: inline-block;
            border-bottom: 1px dotted #EDEEEE;
        }

        .tooltip .tooltiptext {
            visibility: hidden;
            width: 248px;
            height: 48px;
            background-color: #EDEEEE;
            padding: 10px;
            position: absolute;
            z-index: 1;
            top: -16px;
            left: 231%;
            text-align: left;
            @include font("body", 12px, #20262A, 300, 1);
            box-shadow: 0 0 5px #888888;
        }

        .tooltip .tooltiptext::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -13px;
            border-width: 13px;
            border-style: solid;
            border-color: transparent #EDEEEE transparent transparent;
        }
        .tooltip:hover .tooltiptext {
            visibility: visible;
        }

    }

    .insurance-logo{
        max-height: 40px;
        max-width: 59px;
        position: relative;
        margin-right: 20px;
    }

    .modal-wrap.active .modal-contentWrap {
        background: $white;
        padding: 32px;

        @media only screen and (max-width: 768px) {
            padding: 5px;
        }

        .modal_assets{
            padding: 0 64px;
            width: 86vw;
            max-height: 88vh;
        }

        .assets-locktonpf-logo{
            border-bottom: 1px solid #c8c9ca;
            text-align: left;
            padding: 10px 0px 10px 0px;
            margin-bottom: 10px;

            .logo.logo_performance svg {
                fill: $black;
                height: 68px;
                width: 112px;
                margin-bottom: 10px;
            }
        }

        .insurance-logo-box{
            text-align: end;

            .insurance-logo{
                max-height: 98px;
                max-width: 98px;
                position: relative;
                top: -18px;
            }

            @media only screen and (max-width: 705px) {
                text-align: center;
                .insurance-logo{
                    top: 0px;
                }
            }
        }

        .asset-title-box{
            text-align: left;
            .assets-title{
                margin-bottom: 14px;
                @include font("body", 22px, #20262A, 300, 1.45);

                .assets-title-bold{
                    font-weight: 500;
                }
            }

            .assets-subtitle{
                margin-bottom: 30px;
                @include font("body", 15px, #20262A, 300, 1.6);

                .assets-subtitle-bold{
                    font-weight: 500;
                }
            }
        }

        .main-assets-details{
            text-align: left;

            .assets-left-side.block{
                position: relative;
                flex-grow: 3;
                @media only screen and (min-width: 523px) {
                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0;
                        top: -32px;
                        bottom: -33px;
                        width: 2px;
                        background-color: #f0f0f0;
                    }
                }

                @media only screen and (min-width: 569px) {
                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0;
                        top: -37px;
                        bottom: -40px;
                        width: 2px;
                        background-color: #f0f0f0;
                    }
                }

                .valuation-block{
                    .date-valuation{
                        font-style: italic;
                    }
                }
            }

            @media only screen and (min-width: 546px) {
                .assets-right-side.block {
                    padding-left: 4%;
                }
            }

            .margin-bottom-20{
                margin-bottom: 20px;
            }

            .font-assets-label{
                @include font("body", 14px, #20262A, 500, 2);
            }

            .font-assets-content{
                @include font("body", 14px, #20262A, 300, 1.50);
            }

            div.assets-tooltip-icon{
                display: inline-block;
                position: relative;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                border: 1px solid #01bee5;
                text-align: center;
                line-height: 1;

                span.assets-tooltip-icon{
                    @include font("body", 14px, #01bee5, 300, 1);
                    position: relative;
                    top: 1px;
                }

            }

            .assets-documents-block{
                .assets-documents-row {
                    margin: 5px 0 5px 0;

                    svg.policy-file-download {
                        width: 19px;
                        height: 19px;
                        position: relative;
                        top: 5px;
                    }

                    .document-name{
                        position: relative;
                        left: 10px;
                        @include font("body", 14px, #20262A, 300, 2);
                    }
                }
            }

            .tooltip {
                position: relative;
                display: inline-block;
                border-bottom: 1px dotted #EDEEEE;
            }

            .tooltip .tooltiptext {
                visibility: hidden;
                width: 248px;
                height: 48px;
                background-color: #EDEEEE;
                padding: 10px;
                position: absolute;
                z-index: 1;
                top: -16px;
                left: 231%;
                text-align: left;
                @include font("body", 12px, #20262A, 300, 1);
                box-shadow: 0 0 5px #888888;
            }

            .tooltip .tooltiptext::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 100%;
                margin-top: -13px;
                border-width: 13px;
                border-style: solid;
                border-color: transparent #EDEEEE transparent transparent;
            }
            .tooltip:hover .tooltiptext {
                visibility: visible;
            }

        }
    }











}



@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.div-alert-title{
    height: 32px;
    @include font("body", 20px, $white, 500, 1.6);
    padding: 22px;
    margin-bottom: 30px;
}