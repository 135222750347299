/* close button for ALL panels */
.panelCloseButton {
  background: #FAFAFA;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #ccc;
  border-radius:0;
  text-indent: 0;
  width: auto;
  height: auto;
  padding: 7px 10px;
  color: #888;
  text-transform: uppercase;
  font-family: "Roboto Condensed",sans-serif;
  font-weight: bold;
  line-height: 1em;
  top: 32px!important;
  right: 10px!important;
}
.panelCloseButton:hover {background:#ddd; border-color:#555; color:#333;}
#issues .panelCloseButton {
  top: 0px!important;
  right: 10px!important;
}
/* END close button for ALL panels */