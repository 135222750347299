
#fileListWrap {
//    margin-top: 30px;
//    border: 1px solid #bbb;
    padding: 32px;
    h3 {
        margin: 0;
        padding: 15px;
        line-height: 1em;
        color: $white;
        border-top: 1px solid $purplish-grey;
        padding: 32px 0 32px 0;
        //        border-bottom: 1px solid #bbb;
    }

    ul {
        margin: 0;
        list-style: none;
        padding: 0;
        //padding: 15px 30px;
        //background-color: #fafafa;
        color: $white;
        li {
            //padding: 5px 0px;
            padding-bottom: 24px;
            a {
                color: $white;
                border-top: 1px solid $purplish-grey;
                width: 100%;
                display: block;
                padding-top: 6px;
            }
        }
    }

    @include media("<=tabport") {
        border-left: 1px solid $purplish-grey;
    }
}
