
.jsonForm {
    margin: 0;
    font-size: 100%;
    color: $text-color;

    //this probably isn't needed - coverred by site style
    //*, *::before, *::after {
    //    box-sizing: border-box;
    //}

    section {
        padding: 0 0 10px;
        margin: 0 auto 0 auto;

        h2 {
            padding: 20px 10px;
            margin: 0 auto;

            @include font('title', 30px, false, false, false);

            &::before {
                content: attr(data-page-prefix);
            }
        }
    }

    & > section {
        &:last-of-type {
            padding-bottom: 60px;
        }
        & > header {
            background: $head-bg;
        }
    }

    fieldset {
        position: relative;
        margin: 35px 0 50px;
        padding: 10px;
        background: transparent;
        border: 0;

        .intro {
            display: inline-block;
            margin: 0 0 5px;
            padding: 0;
            //font-size: $intro-fs;
            //line-height: $intro-lh;
            //font-weight: $intro-fw;
            @include font('body', $intro-fs, false, $intro-fw,  $intro-lh);

            dfn {
                margin-right: 4px;
            }
        }

        .intro,
        .help {
            & + .notesDiv.isopen {
                margin-top: 10px;
            }
        }

        @include media('<wide-tab') {
            .intro {
                display: inline;
            }
        }

        @include media('<tabport') {
            margin: 30px 0;
        }
    }

    label {
        display: inline-block;
        padding: 6px 10px 6px 0;
        font-size: $label2-fs;
        line-height: $label-lh;

    }
	
	div#lookupError {
		display: block;
		background: #f7e1e1;
		padding: 10px;
		font-size: 0.9rem;
	}
}

.icon-edit {
    width: 19px;
    height: 19px;
}
.icon-plus {
    width: 18px;
    height: 18px;
}
.watermark {
    display: none;
}
#declaration .jsonForm section h2:before {
    content: "";
}
.dialog-message .jsonForm fieldset {
	margin: 0;
}