.news-detail-page {

    .main-section-container {
        position: relative;
		padding-top: 22px;

        .bounds {
            .main-section {
                p {
                    margin-bottom: 20px;
                }

                .main-article {
                    padding-bottom: 8px;
                    padding-right: 42px;

                    @include media("<tabport") {
                        padding-bottom: 17px;
                    }

                    .date-author-container {
                        display: inline-block;
                        position: relative;
                        margin-bottom: 16px;
                        line-height: 18px;

                        @media only screen and (max-width: 1199px) {
                            margin-bottom: 20px;
                        }
                    }

                    ul {
                        padding-left: 16px !important;
                    }
	
					.article-heading {
						@include font(body, 26px, $dark, 500, 1.44);
					}
					
					.article-date {
						@include font(body, 17px, $warm-grey, false, 1.8);
						position: relative;
						margin-bottom: 15px;
						border-bottom: 1px solid $alto;
		
					}
					.article-category{
						white-space: nowrap;
						text-overflow:ellipsis;
						overflow:hidden;
						height:25px;
						@include font(body, 17px, $dark, 400, 1.44);
					}
					
                }
                .location-section {
                    padding-bottom: 12px;
                    @include media("<=wide-tab") {
                        padding-bottom: 30px;
                    }
                    .map-container {
                        float: left;
                        width: 45%;
                        margin: 0 26px 20px 0;
                        @include media("<=wide-tab") {
                            float: none;
                            width: 100%;
                            margin: 0 0 20px;
                        }
                        .event-map {
                            height: 270px;
                            @include media("<=desktop") {
                                height: 205px;
                            }
                            @include media("<=wide-tab") {
                                height: 250px;
                            }
                        }
                    }
                    .content-container {
                        position: relative;
                        display: inline-block;
                        padding-bottom: 40px;
                        width: 45%;
                        min-height: 270px;
                        @include media("<=desktop") {
                            min-height: 205px;
                        }
                        @include media("<=wide-tab") {
                            min-height: 0;
                            width: 100%;
                            padding-bottom: 47px;
                        }
                        .section-heading {
                            margin: 0 0 16px;
                            font-weight: 300;
                            font-size: 28px;
                            line-height: 34px;
                            @include media("<=phoneland") {
                                margin: 0 0 22px;
                                font-size: 24px;
                                line-height: 29px;
                            }
                        }
                        .event-address {
                            padding-bottom: 10px;
                            span {
                                display: block;
                            }
                        }
                        .sign-up {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            z-index: 2;
                        }
                    }
                }
                .social-icons-wrap {
                    margin-bottom: 35px;

                    @include media("<tabport") {
                        position: absolute;
                        bottom: 84px;
                        left: 32px;
                        margin: 0;
                        z-index: 2;
                    }
                    @include media("<=phoneland") {
                        left: 16px;
                    }
                }
            }
        }
    }
    .news-iframe {
        width: 100%;
        height: 600px;
        @include media("<=phoneland") {
            height: 300px;
        }
    }
    .article-image-wrap {
        position: relative;

        margin-bottom:1.2em;

    }
}

// Resource types:
//$newstypes: news, event, guidance, cpd, proposalform;
//
//@each $type in $newstypes {
//    .news-detail-page .main-section-container .bounds.newstype_#{$type} {
//        .main-section .main-article .article-image-wrap .image-block-text {
//            background-color: map-get($newstype-colors, #{$type});
//            @if ($site == mobility) {
//                color: transparent !important;
//            }
//        }
//    }
//}