// This is a tooltop that shows on some numeric fields with the true number of decimal places
input ~ span.tt {
    display: none;
    position: absolute;
    z-index: 999;
    bottom: 100%;
    margin: -3px 5px;
    right: 0;
    padding: 2px 4px 1px;
    background: #FFFCD9;
    border: 1px solid #ADADAD;
    color: #1D1D1D;
    border-radius: 3px;
    font-size: 0.85em;
    box-shadow: 0px 5px 14px 0px rgba(0,0,0,0.4);
}
span.tt:after {
    content: "";
    position: absolute;
    top: 100%;
    border: 6px solid transparent;
    left: 50%;
    margin-left: -6px;
    border-top-color: #FFFCD9;
}
td:hover input ~ span.tt,
input:hover ~ span.tt {
    display: block;
}