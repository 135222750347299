
/* radio / checkbox custom button style > */

//.jsonForm { // These are used elsewhere, so made styles global.
    .focb {
        cursor: pointer;

        &::before {
            position: relative;
            display: block;
            content: "";  /*nbsp*/
            width: 23px;
            height: 23px;
            margin: 0;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            background-color: $checkbox-bg;
            border: 1px solid $checkbox-border;
        }
        &::before,
        &::after {
            transition: all 0.2s ease-out;

        }
    }

    label.focb {
        color: rgba(50, 50, 50, 0.5);
    }

    input[type=checkbox],
    input[type=radio] {
        &.focbed {
            position: absolute;
            flex: none;
            //width: 0;
            //height: 0;
            //opacity: 0;
            //z-index: -10;
			// These MUST be visible to JS
			opacity: 0.01;
			width: 1px;
			height: 1px;
			left: -5000px;
			z-index: -10;
        }
        & + .focb {
            position: relative;
            flex: none;
            margin-right: 10px;
            padding: 0!important;
            min-width: 28px;
        }
    }

    input[type=checkbox] {
        & + .focb {
            &:hover {
                &::before {
                    background-color: $checkbox-bg-hover;
                    border-color: $checkbox-border-hover;
                }
            }
        }
        &:checked + .focb::before {
            content: "\2714";
            color: $checkbox-color;
            background-color: $checkbox-bg-checked;
            border-color: $checkbox-border-checked;
        }
    }

    input[type=radio] {
        & + .focb {
            &::before {
                width: 28px;
                height: 28px;
                border-radius: 50%;
            }
            &::after {
                position: absolute;
                display: block;
                content: "";
                top: 50%;
                left: 50%;
                width: 14px;
                height: 14px;
                background: transparent;
                border: none;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
            &:hover {
                &::before {
                    border-color: $radio-border-hover;
                }
                &::after {
                    background: $radio-dot-hover;
                }
            }
        }
	
		&:required + .focb::before {
			border-color: $radio-border-req;
		}
        &:checked + .focb {
            &::before {
                border-color: $radio-border-checked;
            }
            &::after {
                background: $radio-dot-checked;
            }
        }
		
		/* special style of radios in a propform table */
		&[data-use-icon] + .focb {
			&::after {
				content: "✔";
				font-size: 18px;
				text-align: center;
				color: $radio-dot-hover;
			}
			&:hover {
				&::after {
					background: none;
					color: $radio-dot-hover;
				}
			}
		}
		&[data-use-icon='tick'] + .focb {
			&::after {
				content: "✔";
			}
		}
		&[data-use-icon='cross'] + .focb {
			&::after {
				content: "✘";
			}
		}
		&[data-use-icon]:checked + .focb {
			&::before {
				border-color: $radio-dot-checked;
			}
			&::after {
				color: #555555;
				background: none;
			}
		}
		&[data-use-icon='tick']:checked + .focb {
			&::after {
			}
		}
		&[data-use-icon='cross']:checked + .focb {
			&::after {
			
			}
		}
    }

    .jf-input-type-radio {
        label.error {
            left: 10px;
        }
    }

    [data-isanswered=true] {
        input {
            &:not(:checked) {
                & + .focb {
                    &::before {
                        border-color: $radio-border-hover;
                    }
                    &::after {
                        background: transparent;
                    }
                }
            }
        }
    }

    .linkedFormWrapper fieldset[data-hasanswers=false] input[type=radio]:required {
        border-color: #cccccc;
    }
	
	
	
	
	
	.jsonForm.jsonFormInverse {
		.focb {
			&::before {
				width: 26px;
				height: 26px;
				line-height: 26px;
				text-align: center;
				background-color: $checkbox-bg-inverse;
				border: none;
				box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
			}
		}
		input[type=checkbox] {
			& + .focb {
				&:hover {
					&::before {
						background-color: $checkbox-bg-hover-inverse;
						border-color: $checkbox-border-hover-inverse;
					}
				}
			}
			&:checked + .focb::before {
				color: $checkbox-color;
				background-color: $checkbox-bg-checked;
				border-color: $checkbox-border-checked;
			}
		}
		input[type=radio] {
			& + .focb {
				&:hover {
					&::before {
						border-color: $radio-border-hover;
					}
					&::after {
						background: $radio-dot-hover;
					}
				}
			}
			&:checked + .focb {
				&::before {
					border-color: $radio-border-checked;
				}
				&::after {
					background: $radio-dot-checked;
				}
			}
		}
		[data-isanswered=true] {
			input {
				&:not(:checked) {
					& + .focb {
						&::before {
							border-color: $radio-border-hover;
						}
						&::after {
							background: transparent;
						}
					}
				}
			}
		}
	}
//}

/* < END radio / checkbox style */