/* ==============
    Sticky Header
   ============== */

.page-container {
	padding-top: 104px;
	@if($theme == professions){
		@at-root .pf-dashboard &{
			padding-top: 148px;
		}
		@at-root .pf-dashboard.pf-agreement &,
		.pf-dashboard.loginOrCreate &{
			padding-top: 85px;
		}
	}
	.account-switcher-enabled & {
		padding-top: 132px;
		@if($theme == professions){
			padding-top: 172px;
		}
	}
	@include media("<desktop"){
		padding-top: 94px;
		@if($theme == professions) {
			padding-top: 88px;
			@if($site==solicitors){
				padding-top: 86px;
			}
			@at-root .pf-dashboard & {
				padding-top: 82px;
			}
			@at-root .site-locktonmalta & {
				padding-top: 81px;
		}
		}
		.account-switcher-enabled & {
			padding-top: 132px;
		}
	}
	@include media("<phoneland"){
		@if($theme == corporate){
			padding-top: 70px;
			.account-switcher-enabled & {
				padding-top: 126px;
			}
		} @else if($theme == professions) {
			.account-switcher-enabled & {
				padding-top: 102px;
			}
		}
	}
	#nav {
		position: fixed;
		top: 0;
		z-index: 99999;
	}

}

.site-lsos .page-container,
.site-locktonlsostest .page-container
{
		@include media("<phoneland"){
			//padding-top: 101px;
			padding-top: 94px;
			.account-switcher-enabled & {
				padding-top: 132px;
			}
		}

		@include media("<415px") {
			padding-top: 83px;
			//padding-top: 94px;
		}

		@include media("<371px") {
			padding-top: 75px;
		}
}

.pf-form .page-container {
	padding-top: 182px;
	.account-switcher-enabled & {
		padding-top: 210px;
	}
}