// Default grid settings
.block.main-content,
.block.main-section {
    flex-basis: 66%;
    @if($site == mobility) {
		// flex-basis:70%;
    }
	@include media("<=wide-tab") {
		flex-basis: 100%;
	}
}
.ie11,
.pf-form_flex{
	.block.main-content,
	.block.main-section{
		flex-basis:67%;	
	}
}
.block.sidebar {
    flex-basis: 33%;

	@include media("<=wide-tab") {
		flex-basis: 100%;
	}
}
.ie11,
.pf-form_flex{
	.block.sidebar {
		flex-basis:27%;
	}
}