
.back-to-page {
	.back-to-page-link {
		position: relative;
		display: block;
		padding-left: 22px;
		margin-bottom:1.5em;
		text-align: right;
		color: $accent-primary;


		.back-to-page-arrow {
			position: relative;
			display: inline-block;
			top: 2px;
			width: 15px;
			height: 15px;
			vertical-align: top;
			margin-right:1em;
		}
		.back-to-page-text {
			display: inline-block;
			font-size: 16px;
			line-height: 20px;
			color: $link-primary;
			transition: all 250ms;
			text-transform:none;
			vertical-align: top;
		}
		.icon-fill {
			fill: $link-primary;
			transition: all 250ms;
		}

		&:hover, &:active {
			.back-to-page-text {
				color: $hover-primary;
			}
			.icon-fill {
				fill: $hover-primary;
			}
		}
	}
}