

.popular-container {
	background-color: $alabaster;
	padding:64px 0;
	a,a:visited{
		color: $dark;
	}
	.article-heading{
		font-size: 18px;
		line-height: 26px;
	}
	.article-meta{
      	border-bottom: 3px solid $aqua-blue;
      	min-height: 200px;
		flex-basis:50%;
		margin:0 32px;
	}
	.carousel-container {
		position: relative;
	}
	.carousel-button-prev {
		position: absolute;
		top: 50%;
		right: 100%;
	}
	.carousel-button-next {
		position: absolute;
		top: 50%;
		left: 100%;
	}
	.article-category{
		padding-top: 6px;
		@include font(body, 14px, $dark, 400, 1.44);
	}
	.column-title{
		//padding-top: 24px;
	}
	a[href^='#carousel-button'] {
		border: 0;
		background-color: $alabaster;
		svg{
			width:100px;
			height:100px;
			fill: $iron-two;
		}
	}
	a:hover {
		cursor: pointer;

		.image-wrap {
			img {
				filter: hue-rotate(-2.16deg) saturate(0.87) brightness(0.87) contrast(0.78);
			}
		}
	}
	.popular-article{
		display:flex;
	}
	.image-wrap{
		flex-basis:50%;
	}
	li{
		width:50%;
	}
}


