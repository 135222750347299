
// Container for the paging control - this should now be fixed all the time
.jf-header-section {
    width: 100%;
    background: #2e373d;
    @if $site == "norway"{
        background:$nor-darkestgrey;
    }
    border: none;
    z-index: 999999;
}

@media only screen and (max-width: 955px) {
    .jf-header-bounds {
        padding-bottom: 39px;
    }
}

@media only screen and (max-width: 487px) {
    .jf-header-bounds {
        padding: 0 10px 40px 10px;
    }
	.jf-page-control li[data-tab] {
		display: none;
	}
}


.jf-header-bounds {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	@extend .bounds;
	@extend .content-padding;
	padding-top: 19px;
	padding-bottom: 19px;
}

//page control itself
ul.jf-page-control {
	list-style: none;
	display: block;
	margin: 0;
	text-align: left;
	z-index: 999999;
	padding: 0;
	line-height: 0;
	margin-left: -3px;
	flex: 1;
}

// Page control li/links
ul.jf-page-control *:focus {outline:none;}
ul.jf-page-control li {display:inline-block;
	margin: 0px 3px;
	padding:3px 0 4px;
	border-top:0px solid transparent;
	border-bottom:3px solid transparent;
	vertical-align:middle;
}
ul.jf-page-control li:first-of-type {padding-left:0;}
ul.jf-page-control li a {
	border: 2px solid $juniper;
	display:block;
	min-width: 30px;
	font-size: 14px;
	line-height:26px;
	overflow:hidden;
	text-align:center;
	padding:0;
	margin:0;
	color:$alabaster;
	text-decoration:none;
}
/*ul.jf-page-control li.current a,*/
ul.jf-page-control li:hover a {background:$juniper;}
ul.jf-page-control li.current {border-bottom-color:$juniper;}
ul.jf-page-control li.current a {/*border-color: #83C5D8; background-color: #ECFDFF;*/ color:$alabaster;}

ul.jf-page-control li a.valid,
ul.jf-page-control li.current.valid {border-color: $sick-green;}
ul.jf-page-control li:hover a.valid {background: rgba($sick-green, 0.5);}
ul.jf-page-control li a.valid {background: $sick-green;}
ul.jf-page-control li a.unfilled,
ul.jf-page-control li.current.unfilled {border-color: $sick-amber;}
ul.jf-page-control li:hover a.unfilled {background: rgba($sick-amber, 0.5);}
ul.jf-page-control li a.invalid,
ul.jf-page-control li.current.invalid {border-color: $sick-red;}
ul.jf-page-control li:hover a.invalid {background: rgba($sick-red, 0.5);}
ul.jf-page-control li a.na,
ul.jf-page-control li.current.na {border-color: $juniper;}
ul.jf-page-control li:hover a.na {background: rgba($juniper, 0.5);}

// This is the text on the right of hte page control bar
ul.jf-page-control-title {
	/*display: block;*/
    display: none;
    position:absolute;
    top: 13px;
    right: 195px;/* 10px; */
    z-index: 9999999;
    list-style-type: none;
    text-indent: inherit;
    margin:0;
    padding:0;
    text-align:right;
}

//this is each item of text in the page control title
ul.jf-page-control-title li {
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0;
    max-height: 0px;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: all .8s;
    -moz-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s;
    transition: all .8s;
    font-weight: 100;
}

ul.jf-page-control-title li.current {
    font-size: 18px;
    max-height: 40px;
    opacity: 1;
    line-height: 25px;
}
ul.jf-page-control-title li a {
    text-decoration:none;
    color: #A1A1A1;
}

// Move across due to no submit button
.readonly ul.jf-page-control-title {
    right: 10px;
}

@media only screen and (max-width: 1340px) {
    ul.jf-page-control-title {
        display: none;
    }
}

@media only screen and (max-width: 955px) {
    ul.jf-page-control-title {
        display: block;
        bottom: 10px;
        top: inherit;
        right: inherit;
        left: 10px;
        text-align: left;
    }
}

@media only screen and (max-width: 680px) {
    ul.jf-page-control-title {
        display: none;
    }
}