ul.first-level-submenu {
    position: absolute;
    top: 100%;
    left: -50%;
    @include media("<=1365px") {
        left: -170px;
    }
    padding: 0px 32px;
    text-align: left;
    background:$almost-black;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
    z-index: 50;
    max-height:0;
    overflow:hidden;
    transition:all 400ms ease;
    display: flex;

    @include media("<=wide-tab") {
        display: none;
        position: static;
        width: 100%;
        min-width:0;
        height: auto;
        top: auto;
        left: auto;
        padding: 0 0 15px 20px;
        background-color: transparent;
        box-shadow: none;
        z-index: 1;
    }

    &>li {
        padding: 32px 32px;
        box-sizing: border-box;
        border-bottom:0 none;
        flex:1 1 300px;

        a{
            &:after{
                display:none;
            }
            &.submenu-sibling{
                @include font('body', 17px, $white, 400,  $lh: 28px);
            }
            @include font('body', 14px, map_get($headerFooter, colour), 400,  $lh: 1.55);
            &:hover{
                color:$white;
            }
        }
        &>a{
            &:hover:after{
                display:none;
            }
        }

        &>ul.second-level-submenu {
            position: static;
            margin: 16px 0 40px 0;

            @include media("<=wide-tab") {
                padding: 5px 0;
                display: none;
                min-width: 0;
                height: auto;
            }

            &>li {
                display: block;
                position: relative;
                padding: 0 0 0 30px;
                border-bottom:0 none;

                &::before,&::after {
                    position: absolute;
                    display: block;
                    top: 1em;
                    left: 1px;
                    content: "";
                    background-color: map_get($headerFooter, colour);
                    z-index: 1;
                    height:10px;
                    width:10px;
                    transform: translateY(-50%) rotate(45deg);
                    transition:all 300ms ease;
                }
                &::after {
                    left: -1px;
                    background-color: $almost-black;
                    @include media("<=wide-tab") {
                        background-color: $dark;
                    }
                    z-index: 2;
                }
                &:hover:before{
                    left:8px;
                    background-color:$link-primary;
                }
                &:hover:after{
                    left:6px;
                }

                &>a {
                    display: block;
                    text-align: left;
                    padding:7px 0;
                    line-height: 22px;

                    @include media("<=wide-tab") {
                        position: relative;
                        padding: 6px 40px 6px 15px;
                        z-index: 5;
                    }
                    &:after{
                        height:1px;
                        top:1em;
                        left:-26px;
                        transform:translateY(2px);
                        width:0;
                        transition: width 300ms ease, opacity 0s;
                    }
                }

                &:hover > a{
//                    color:$link-primary;
                    &:after{
                        width:14px;
                        opacity: 1;
                        z-index:99999;
                    }
                    &:hover:after{
                        top:1em;
                        transform:translateY(2px);
                    }
                }

            }
        }
    }
}

ul.two-column-submenu {
    //left: -230px;
    width: 960px;

    @include media("<=wide-tab") {
        left: 0;
        width: 100%;
    }
}

ul.one-column-submenu {
	width: 480px;
	@include media("<=wide-tab") {
		left: 0;
		width: 100%;
	}
}

/* lockton recruitment specific */
.site-locktonrecruitment {
	ul.one-column-submenu {
		width: auto;
		white-space: nowrap;
		ul.second-level-submenu {
			margin: 16px 0;
		}
		margin-right:-200px;/* hack to mitigate an issue with IE where it truncated the box */
	}
}
/* end lockton recruitment specific */

.has-submenu {
    a.submenu-sibling {
        @include media("<=wide-tab") {
            position: relative;
            padding-right: 45px;

            &::before {
                display: block;
                position: absolute;
                right: 30px;
                top: 50%;
                content: "";
                width: 10px;
                height: 2px;
                margin-top: -1px;
                background: rgba(87, 103, 108, 0.9);
                z-index: 1;
            }

            &::after {
                display: block;
                position: absolute;
                right: 34px;
                top: 50%;
                content: "";
                width: 2px;
                height: 10px;
                margin-top: -5px;
                background: rgba(87, 103, 108, 0.9);
                z-index: 1;
            }
        }
        @include media("<=phoneland") {
            &::before {
                right: 20px;
            }
            &::after {
                right: 24px;
            }
        }
    }
}
ul.first-level-submenu.submenu-opened {
    @include media("<=wide-tab") {
        &::after {
            display: none;
        }
    }
    max-height:600px;
}

.offCanvasMenu-tabbed .offCanvasMenu-navigation li a.first-level-menu {
    &.submenu-sibling {
        display: none;
        justify-content: space-between;
        padding-right: 30px;
        @at-root .pf-admin &{
            padding-right: 50px;
            display: flex;
            cursor: pointer;
        }
        @include media('<=phoneland') {
            padding-right: 50px; 
            display: flex;
        }
    }
}

.offCanvasMenu-tabbed .offCanvasMenu-navigation li{
    a.first-level-menu:not(.submenu-sibling),
    a.menu-item-with-arrow {
        @include media('<=phoneland') {
            //padding-right: 20px;
            &.show-desktop {
                display: none;
            }
        }

        &.show-desktop{
            @at-root .pf-admin & {
                display: none;
            }
        }
    }
}