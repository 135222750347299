
// e.g. Homepage Banner

.l-list,
.banner.split-banner .banner-content ul {
	margin: 35px 0 45px;
	padding: 0 0 0 28px;
	list-style: none;

	li {
		position: relative;
		margin: 0 0 9px;
		padding: 0;
		font-size: 16px;
		line-height: 28px;
		font-weight: 500;
		color: inherit;

		&::before {
			position: absolute;
			display: block;
			content: "";
			top: 50%;
			left: -28px;
			width: 10px;
			height: 10px;
			margin-top: -5px;
			background: transparent;
			border: 2px solid $accent-primary;
			border-radius: 50%;
			box-sizing: border-box;
		}
	}
}

// e.g. Sales Block (Homepage)

.s-list {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0 0 7px;
		padding: 0;

		a {
			position: relative;
			display: inline-block;
			padding: 5px 32px 5px 0;
			font-size: 17px;
			line-height: 26px;
			font-weight: 500;
			color: $link-primary;

			&::before,
			&::after {
				position: absolute;
				display: block;
				content: "";
			}

			&::before {
				left: 0;
				right: 32px;
				bottom: 0;
				height: 2px;
				background: transparent;
				transition: all 250ms;
			}

			&::after {
				top: 50%;
				right: 0;
				width: 20px;
				height: 12px;
				margin-top: -6px;
				//background: url('../img/static-icons/arrow-thin-right.svg') no-repeat center;
				background-size: cover;
			}

			&:hover {
				&::before {
					background: $accent-primary;
				}
			}
		}
	}
}