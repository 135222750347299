.offCanvasMenu-tabbed {
    background: transparent;
    padding: 0;

    & > section {
        border: 0 none;
        background: transparent;
        display:block;
        opacity:0;
        z-index:0;
        transition:opacity 200ms ease,z-index 0s linear 200ms;
        &.open{
            opacity:1;
            z-index:1;
            transition:opacity 200ms ease,z-index 0s linear 200ms;
        }
        & > div > div {
            padding: 0;
            border-bottom: 0 none;
        }
    }
    & > ul.offCanvasMenu-tabtops {

        & > li.hasNotifications:after{
            display: block;
            background: $accent-primary;
            position: absolute;
            top: 17px;
            right: 28px;
            width: 9px;
            height: 9px;
            border-radius: 100%;
            content:'';
        }

        & > li.hasNotifications2:after{
            display: block;
            background: $accent-primary;
            position: absolute;
            top: 0px;
            right: 16px;
            width: 22px;
            height: 22px;
            border-radius: 100%;
            content: attr(data-notificationsCount);
            @include font("body", 13px, $white, 500, 1.69);
            text-align: center;
        }

        padding-left: 20%;
        & > li,
        & > li.active {
            padding:6px 15px 6px 0;
            & > a {
                background: transparent;
                border: 0 none;
                margin-left:0;
                padding-left:0;
                transition: opacity 300ms ease;
                svg {
                    fill: map_get($offCanvasNav,iconsOn);
                }
                &:hover{
                    opacity:1;
                }
            }
        }
    }
    .offCanvasMenu-navigation {
        li {
            padding: 0;
            a {
                padding: 12px 0 12px 20%;
                border-right: 5px solid transparent;
            }
        }
        li.on > a,
        li > a:hover {
            border-right-color: map_get($offCanvasNav,accent);
            background: map_get($offCanvasNav,hover);
        }

    }
}

.off-canvas-nav .offCanvasMenu-tabbed .offCanvasMenu-navigation {
    li > ul li>a {
        border-right-color: $dark;
        background: $dark;
    }
}

.menu-title{
    @include font(title, 34px, map_get($offCanvasNav,header), false,  1.88);
    padding-left:20%;
}

.h2{
    margin: 0.83em 0;
}

.close-copy {
    position: absolute;
    top: -6px;
    right: 0;
    display: block;
    padding: 0;
    width: 12px;
    height: 12px;
    @include font("body", 13px, $purplish-grey, 300, 1.46);
    cursor: pointer;
}

.insurance-type-alert-menu{
    @include font("body", 13px, $white, 500, 1.46);
    .staff-contact{
        @include font("body", 13px, #9e9c9e, 300, 1.46);
    }
    &.alert-message{
        @include font("body", 13px, $white, 300, 1.46);
    }
}

.notification-policy-menu {
    position: relative;
    z-index: 1;
    padding: 16px 0 8px 0;
    border-bottom: solid 0.9px $purplish-grey;
    margin-bottom: 20px;
    width: 200px;
    left: 76px;
    &.notification-policy-menu_extended{
        border-bottom: none;
    }

}

.time-added{
    width: 198px;
    height: 17px;
    @include font("body", 13px, $purplish-grey, 300, 1.46);
}

.display-inline-block{
    display: inline-block;
}

.display-inline{
    display: inline;
}

.display-block{
    display: block;
}
