//Form when pleaced inside a tabbed off canvas menu element
.multiple-ocm-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
}
.ocm-form{
    padding:0 19% 0 20%;

    .menu-title{
        padding-left:0;
    }

    .menu-subtitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: #ffffff;
        margin-bottom: 40px;
    }


    .form-field{
        margin-bottom:.75em;
    }
    input[type="text"],
    input[type="password"],
    input[type="search"]{
        border:1px solid map_get($offCanvasNav,border);
        background:transparent;
        appearance:none;
        font-size:16px;
        line-height:20px;
        color:$white;
        padding:10px 6px;
        width:100%;
        transition:all 300ms ease;
        &:focus{
            border-color:$accent-primary;
        }
    }
    input[type="search"]{
        border: 0 none;
        border-bottom:1px solid map_get($offCanvasNav,iconsOn);
        margin-bottom: 40px;
    }
    input[type="password"]{
        margin-bottom: 10px;
    }
    label{
        display:block;
        @include font(body, 13px,map_get($offCanvasNav,header), false,  15px);
        margin-bottom:.5em;
    }

    .btn_a{
        margin:1em 0 0.75em;
    }

    .form-links{
        @include font(body, 12px,map_get($offCanvasNav,text), false,  18px);
        text-align:right;
        a{
            color:$iron;
            &:hover{
                color: map_get($offCanvasNav,accent);
            }
        }
    }
}

.ocm-acc-switcher{

    margin-bottom: 40px;
}

.ocm-permissions {
    .menu-title {
    font-size: 1.25em;
    color: #bbb;
    border-bottom: 1px solid #666;
    }
    form .check-wrap .focb {
        color: #bbb;
    }
    form .check-wrap .focb:hover,
    form .check-wrap input[type='checkbox'].focbed:checked+.focb {
        color: white;
    }
    form .check-wrap input[type='checkbox'].focbed+.focb:before {
        background: rgba(255, 255, 255, 0.175);
        scale: 0.9;
    }
    form .check-wrap input[type='checkbox'].focbed+.focb:hover:before {
        border-color: white;
    }
    form .check-wrap input[type=checkbox]:checked+.focb::before {
        background-color: #90ce51;
    }
}