/**
* Headband style
**/

// This is deprecated, may not be required at all from corp skin onwards

.pf-dashboard, .pf-form_flex, .pf-admin{
    .icon-logout {
        font-size: 10px;

        display: inline-block;

        transition: all .3s !important;
        
        text-transform: uppercase;
    }
    .icon-logout .svg-icon {
        fill: map_get($headband,text);
    }
    .icon-logout .svg-icon use {
        transition: all .3s !important;

        fill: map_get($headband,text);
    }
    .icon-logout:hover,
    .icon-logout:hover .svg-icon use {
        color: map_get($headband,hover);

        fill: map_get($headband,hover);
    }
    #headband {
        position: absolute;
        z-index: 99999550;
        top: 0;
        right: 0;
        left: 0;

        width: 100%;
        height: 54px;
        
        text-align:left;
        
        transition: border-color .5s;

        color: map_get($headband,text);;
        background: map_get($headband,background);
        .header-alert .svg-icon{
            fill: map_get($headband,text);;
        }
        .flex-parent,
        .block{
            padding-top:0;
            padding-bottom:0;
            @include media("<=phoneland") {
                min-width:0;
                flex-basis:10%;
                flex-grow:1;
            }
        }
        .block + .block{
            flex-grow:3;
        }
    }
    #headband #headerbuttons {
        text-align:right;
        display:block;
    }
    #mainheader + #headband #headerbuttons .headerbuttons-item,
    .headericon-exit,
    .headericon-logout {
        font-size: 12px;

        position: relative;
        top: inherit;
        right: inherit;

        display: inline-block;
        float: none;

        margin-left: 10px;

        text-transform: uppercase;
    }
    .headericon-logout {
        margin-left: 0;
    }
    .headericon-user:before {
        font-size: 16px;
        line-height: 16px;
    }
    #headband #headerbuttons .headerbuttons-item:hover,
    .headericon-logout:hover,
    #headband #headerbuttons .headerbuttons-item.active {
        color: map_get($headband,hover);
    }
    .logout-button {
        font-family: inherit;
        font-weight: normal;
        line-height: 22px;

        margin-top: 8px;

        text-align: center;
        white-space: nowrap;

        color: map_get($headband,text);
        border: 0;
        background: transparent;
    }
    .logout-button:hover {
        border: 0;
        background: transparent;
    }
}


.pf-dashboard, .pf-form_flex{

    #mainheader.shallow + #headband {
        border-bottom-color: transparent;
    }
    
    #headband .lockton {
        position: relative;
        top: 17px;
        // bottom: 0;
        left: 0;

        display: block;

        height: 24px;
        margin: auto;

        transition: opacity .5s;

        opacity: 0;
    }
    #mainheader.shallow + #headband .lockton {
        opacity: 1;
    }
    #headband .lockton img {
        display: inline-block;

        height: 24px;
    }
    #mainheader #logomask {
        position: fixed;
        z-index: 99999555;
        top: 0;
        left: 0;

        display: block;

        width: 300px;
        height: 13px;

        background: $white;
    }
    #headband #accManBasic,
    #headband #headerbuttons {
        position: relative;

        // display: table-cell;

        padding-left: 10px;

        vertical-align: middle;
    }
    #headband .header-alert {
        font-size: 14px;
        font-weight: 300;
        display: table-cell;
        vertical-align: middle;
        line-height: 54px;
        transform: translateZ(0);
    }

    .header-alert .alert-num {
        font-size: 12px;
        line-height: 20px;

        position: relative;

        display: inline-block;

        box-sizing: border-box;
        width: 20px;
        height: 20px;

        text-align: center;

        color: #FFFFFF;
        border-radius: 50%;
        background: $a_accent;
    }
    #headband .header-alert span {
        display: inline-block;

        padding-left: 10px;

        vertical-align: middle;
    }

    #headband #accManBasic {
        overflow: hidden;

        max-width: 1px;
        padding-right: 0;

        transition: max-width 1.25s;
        white-space: nowrap;

        opacity: 0;
    }
    #mainheader.shallow + #headband #accManBasic {
        max-width: 400px;
        padding-right: 12px;

        opacity: 1;
        color: #CCCCCC;

        font-size:14px!important;
    }
    #headband #accManBasic:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        display: block;

        width: 1px;
        height: 20px;
        margin: auto;

        content: '';

        border-right: 1px solid #DDDDDD;
    }
    #headband #accManBasic .acc-contact {
        display: inline-block;

        color: #1C75BC;
    }
    #headband #accManBasic .acc-contact .phone {
        color: #AFAFAF;
    }

    #mainheader + #headband a.do-maximise-header {
        font-size: 0;

        display: inline-block;

        width: 0;
        height: 0;
        margin: 0 5px;

        vertical-align: middle;

        border: 5px solid transparent;
        border-top-width: 7px;
        border-top-color: #CCCCCC;
    }

    .lsoslogo {
        display: none!important;

        margin-left: 10px;
    }
    .lsos .lsoslogo {
        display: inline-block!important;
    }
    .lsos #headband .lsoslogo {
        height: 40px;
        margin: -7px 0 -8px 10px;
    }
    #mainheader {
        position: relative;
        height: 150px;
        margin-top: 54px;
        z-index: 10;
        padding: 0;

        transition: opacity .5s;

        opacity: 1;
    }
    
    #mainheader.shallow {
        opacity: 0;
    }

    #mainheader .logo .svg-logo {
        position: relative;

        display: inline-block;

        width: 112px;
        height: 110px;

        vertical-align: middle;

        fill: $white;
    }



    #mainheader > .bounds {
        padding-top: 18px;
        padding-bottom: 22px;

        text-align: left;
    }
    #mainheader .bounds ul.main-nav {
        padding-right:310px; // To make room for the acc-contact-details block
    }


    #mainheader .bounds > ul li.create {
        float: right;
    }

    #mainheader .bounds > ul li.create a {
        font-size: 10px;

        position: relative;

        padding: 14px 32px;

        transition: color .3s !important;
    }

    #mainheader .bounds > ul li.create a span {
        position: relative;

        display: block;

        width: 85px;
        margin-top: 8px;

        text-align: center;
    }

    #mainheader .bounds ul.main-nav li .svg-icon {
        fill: #BDC6CC;
    }

}