.banner {

    @if ($site == recruitment) {
        min-height: 580px;
    } @else {
        min-height: 663px;
    }

    @include media('<=phoneland') {
        min-height: 0 !important;
        height: calc(100vh - 70px);
    }


    .banner-slide {
        background: center center url($banner-bg-img);
        background-size: cover;

        text-align: left;

        @include media('<wide-tab') {
            padding: 52px 0 48px;
        }

        @include media('<=phoneland') {
            padding: 32px 0 28px;
        }
    }

    .banner-contentwrap {
        display: flex;

        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .banner-content {

        @include media('<=phoneland') {
            flex-basis: 100%;
        }

        .title_site {
            @include font("title", 52px, $banner-text, 400, 1.23);

            margin: 0 0 .25em;
            max-width:800px;

            @include media('<=phoneland') {
                font-size: 36px;
            }

            @if ($site == norway) {
                font-size: 46px;
            }
        }
        .strapline {
            @include font("title", 52px, $banner-strapline, 400, 1.23);

            margin: 0 0 .5em;
            max-width: 44vw;
            @include media("<=wide-tab") {
                max-width: 44vw;
                margin: .5em 0 .5em;
            }

            @include media('<=phoneland') {
                font-size: 36px;
                max-width: none;
            }
        }
        p {
            @include font("body", 16px, $banner-text, 300, 1.56);
            max-width: 44vw;
            @include media("<=wide-tab") {
                max-width: 44vw;
            }
            margin-bottom: 1.5em;
            @include media("<phoneland") {
                max-width: none;
                font-size: 16px;
                line-height: 1.75;
            }
        }
        .banner-cta {
            @include font("body", 18px, $banner-text, 300, 1.22);
            width: 100%;
            text-align: center;

            @if ($site == lsos) {
                @include media('<=tabport') {
                    max-width: 75%;
                }
            }
        }

        &.banner_inverse {
            .title_site, p, .banner-cta {
                color: $white;
            }

            @if ($site == norway) {
                .title_site{
                    font-size: 46px;
                }
            }
        }

    }

    .icon-down-arrow {
        position: absolute;
        top: 90%;
        left: 50%;

        width: 40px;
        height: 40px;

        transform: translate(-50%, -50%);

        @include media("<=865px") {
            left: 77%;
        }

        @include media("<=tabport") {
            width: 30px;
            height: 30px;
            left: 77%;
            @if ($site == lsos) {
                top: inherit;
                bottom: 40px;
                left: inherit;
                right: 30px;
            }
        }

        @include media("<=phoneland") {
            width: 20px;
            height: 20px;
            top: 80%;
            left: 50%;
        }

        @media screen and (max-height: 480px){
            top: 75%;
            left: 50%;
        }

        svg {
            width: 40px;
            height: 40px;

            fill: $white;

            @include media("<=tabport") {
                width: 30px;
                height: 30px;
            }

            @include media("<=phoneland") {
                width: 20px;
                height: 20px;
            }
        }
    }
    #scrollDown:before {
        position: absolute;
        top: -54%;
        left: -50%;

        width: 200%;
        height: 200%;

        content: '';
        transition: background .4s ease 0.1s, border-color .4s ease 0s;

        border: 3px solid rgba($white, .02);
        border-radius: 50%;
        background: rgba($white, .05);

        @include media("<=tabport") {
            background: rgba($white, .2);
            border-color: rgba($white, .6);
        }

    }
    #scrollDown:hover:before {
        background: rgba($white, .2);
        border-color: rgba($white, .6);
        transition: background .4s ease 0s, border-color .4s ease 0.1s;
    }
    .flexSlider-dots,
    .flexSlider-nextButton,
    .flexSlider-prevButton,
    .flexSlider-indexContainer {
        display: none;
    }
    .banner_stream {
        padding-right: 400px;
    }
    .banner-widget-wrap {
        position: relative;
    }
    .banner-widget {
        width: 370px;
        position: absolute;
        right: -50vw;
        top: 2em;
		@if ($site == performance) {
			top: 3em;
		}
        z-index: 999;
        animation: slide-from-the-right 1s 2s 1 forwards;
        .tabbed {
            background: transparent;
            padding: 0;
			.logo.logo_globalfi{
				padding:0;
				margin-bottom: -21px;
				margin-top: 7px;
				svg{
					height: 55px !important;
					width: 90px !important;
				}
			}
        }
        .tabbed > section {
            border: 0 none;
            background: transparent;
        }
        .tabbed > ul {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            padding: 0;
        }
        .tabbed > ul > li {
            display: block;
            flex: 1 1 auto;
        }
        .tabbed > ul > li > a {
            width: 100%;
            color: $white;
            border-radius: 0;
            border: 0 none;
            margin: 0;
            padding: 16px 0 16px 32px;
            background: $charcoal-grey;
        }
        .tabbed > ul > li.active > a {
            border: 0 none;
            background: transparent;
        }
        .tabbed > section > div > div {
            padding: 0;
        }
        .flex-parent {
            padding-bottom: 32px;
        }

        @include media("<=wide-tab") {
            position: relative;
            width: auto;
            top: auto;
            right: auto;
            margin: 0 -32px;
            z-index: 3;
            animation: none;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);

            .jsonForm .flex-wrap-row {
                max-width: 706px;
            }
        }
        @include media("<=phoneland") {
            margin: 0 -16px;
            margin-bottom: 0 !important;

            .aside {
                width: auto;
                margin: 0;

                .inlineCheckbox label ~ .focb {
                    margin-left: 15px !important;
                }
                .jsonForm.jsonFormInverse .flex-wrap-row {
                    display: block;

                    .one-half.left-block {
                        margin-right: 0;
                    }
                }
                .aside-title {
                    display: none;
                }
            }
            .tabbed > ul > li > a {
                padding: 16px;
            }
        }
    }

    // Split Banner

    &.split-banner {
        .banner-content {
            max-width: 420px;
            //padding: 170px 0 115px;
            padding: 150px 0 115px;
            color: $dark;

            .banner-cta {
                display: inline-block;
                max-width: 100%;
                width: auto;
                min-width: 220px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: inherit;

                @if $site != 'norway' {
                    border: 1px solid $dark;

                    &:hover {
                        color: $white;
                        background: $dark;
                        border-color: $dark;
                    }
                }

            }

            ul {
                //@extend .l-list;
            }

            h1, p, ul {
                color: inherit;
            }
        }

        .banner-slide {
            .banner-content.banner_inverse {
                color: $white;

                @if $site != 'norway' {
                    .banner-cta {
                        color: inherit !important;
                        border-color: $alto;

                        &:hover {
                            color: $dark !important;
                            background: $white;
                            border-color: $white;
                        }
                    }
                }
            }
        }

        @include media("<wide-tab") {
            .banner-slide {
                padding: 0;
            }
            .banner-content {
                padding: 110px 0;
            }
        }

        @include media("<tabport") {
            .banner-content {
                padding: 40px 0 50px;
            }
        }

        @include media("<phoneland") {
            .banner-content {
                max-width: 100%;
                .banner-cta {
                    display: block;
                    width: 100%;
                    min-width: 0;
                }
            }
        }
    }

    // Stream Banner

    &.banner_stream,
    &.banner_withWidget {
        .banner-content {
            max-width: 720px;

            @include media("<desktop-basic") {
                max-width: 520px;
            }
        }

        @include media("<=wide-tab") {
            min-height: 0 !important;

            .flexSlider {
                position: relative;
                display: flex;
                top: auto;
                left: auto;
                right: auto;
                bottom: auto;
                min-height: 580px;
                height: auto;

                .ie10 &,
                .ie11 & {
                    height: 600px;
                }

                & > ul {
                    display: flex;
                    flex: 0 0 10000%;
                    height: auto;

                    & > li {
                        display: flex;
                        flex: 0 0 1%;
                        margin: 0;
                    }
                }
                .banner-contentwrap {
                    display: flex;
                    flex: 1 1 100%;
                    align-items: center;
                }

                .banner-slide {
                    height: auto !important;
                }
            }
            .banner-content {
                padding: 0;
            }
        }

        @include media("<tabport") {
            .flexSlider {
                min-height: 440px;
            }
        }
    }
}
