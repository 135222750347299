
.jsonForm {
    .onoffswitch {
        position: relative; 
        width: 102px !important;
        margin-top: 5px;
        user-select: none;

        .ison,
        .isoff {
            display: block; 
            float: left; 
            width: 50%; 
            height: 36px; 
            padding: 0; 
            font-size: $switch-fs; 
            line-height: 34px;
            font-weight: $switch-fw;
            text-align: center;
            font-style: normal;
            text-transform: uppercase;
            color: $switch-color; 
            border: 1px solid $switch-border;
            border-radius: 2px;
            box-sizing: border-box;
        }

        .ison {
            padding-right: 20%;   
            background-color: $switch-on-bg;  
            box-shadow: inset 0 2px 3px 0 $green-leaf;
        }

        .isoff  {
            padding-left: 20%;
            background-color: $switch-off-bg;
            box-shadow: inset 0 2px 3px 0 $tundora;
        }
    }

    .onoffswitch-checkbox {
        position: absolute;
        height: 0;
        width: 0;
        overflow: hidden;
        opacity: 0;
        //clip: rect(0 0 0 0);

        &:checked + .onoffswitch-label {
            .onoffswitch-inner {
                margin-left: 0;
            }
            .onoffswitch-switch {
                right: 0px;
                margin-right: 0;
            }
        }
    }

    .onoffswitch-label {
        display: block; 
        margin: 0;
        padding: 0 !important;
        overflow: hidden;
        cursor: pointer;
    }

    .onoffswitch-inner {
        display: block; 
        width: 200%; 
        margin-left: -100%;
        transition: margin 0.1s ease-in 0s;
    }

    .onoffswitch-switch {
        position: absolute; 
        display: block; 
        top: 0; 
        bottom: 0;
        right: 100%;
        width: 40%; 
        margin: 0px;
        margin-right: -40%;
        background: $switch-sw-bg;
        border: 1px solid $switch-border; 
        border-radius: 2px;
        transition: all 0.1s ease-in 0s;

        &::before,
        &::after {
            position: absolute;
            display: block;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 18px;
            width: 12px;
            margin: auto;
            border-left: 1px solid $switch-sw-line;
            border-right: 1px solid $switch-sw-line;
        }

        &::before {
            width: 20px;
        }
    }

    .onoffswitch-placeholder {
        display: block;
        padding: 0;
        line-height: 1.8em;
        vertical-align: top;
        color: rgba(0,0,0,0.4); 
    }
}
