
.insurance-service-detail-page {

    &.site-locktonperformance .page-header .block:first-child {
        padding-top:0;
        padding-bottom:107px;
        h1{
            margin-bottom:0;
        }
        @include media("<=phoneland"){
            padding-top:2em;
            padding-bottom:0;
            h1{
                margin-bottom:20px;
            }
            .content-wrap.withClubBadges .clubBadge{
                margin-bottom:0;
            }
        }
    }

    .section-heading {
        @include font('title', 48px, $dark, 400, 58px);
        margin-top: 0;
        margin-bottom: .5em
    }

    #testimonials-carousel.carousel {
        ul {
            border-top: 0;
            padding: 0;
            margin: 0;
        }
        li {
            width: 100%;
            padding-top: 0;
        }
        .carousel-button-wrapper {
            display: none;
        }
        blockquote {
            margin: 0;
            line-height:1.6;
            .column-quote {

            }
            .column-additional {
                cite {
                    font-style: normal;
                }
            }
        }
    }
    .carousel-button-prev,
    .carousel-button-next{
        display:none;
    }

    .main-section{

        .main-article {
            p {
                margin-bottom: 20px;
            }

            ul {
                margin-bottom: 24px;
            }

            .covered-section {
                padding: 0;

                p {
                    margin-bottom: 28px;
                }

                .covered-block {
                    -webkit-flex: 1 1 50%;
                    padding: 0 16px 16px 0;
                    min-width: 300px;

                    .article-readmore{
                        opacity:1;
                        padding-top:1em;
                        color:$link-primary;
                        &:hover{
                            color:$hover-primary;
                        }
                    }

                    .covered-content {
                        height: 362px;
                        overflow: hidden;
                        transition: height 1s ease-out;
                    }
                }
            }
        }
        article,
        & > section{
            margin-bottom:64px;
            padding-right: 42px;
            padding-top: 26px;

            @if ($site == privateclient) {
                h3 {
                    font-size: 21px;
                }
            }

            @include media("<tabport"){
                margin-bottom:48px;
            }
            @include media("<phoneland"){
                margin-bottom:32px;
            }

            &.section_quickQuote{
                padding-top: 0;
            }
        }

        .additional-section {
            h3 {
                @include font('body', 22px, $dark, 700, 1.3);
                margin-top: 0;
            }
        }
    }

    .service-header {
        display: none;
    }
}

.phonebar {
    .page-container {
        .header-with-service-contact{
            #nav {
                top: 28px;
            }

            .service-header {
                display: block;

                .service-header-contact{
                    height: 28px;
                    width: 100%;
                    background-color: #01bee5;
                    position: fixed;
                    top: 0;
                    z-index: 9999999;
                }

                .service-header-content {
                    margin: auto;
                    max-width: 170px;
                    width: fit-content;
                    font-family: 'Gotham A', Helvetica, Arial, sans-serif;
                    font-size: 13px;
                    font-weight: bold;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #ffffff;
                    padding: 6px;

                    @include media("<phoneland"){
                        font-size: 15px;
                        max-width: 195px;
                    }
                }

                .service-header-telephone {
                    color: #ffffff;
                }
            }
        }
    }
}