.client-console {
  .stickyFootMain {
    background: transparent;
  }
  .block.sidebar {
    flex-basis: 25%;
    min-width: 250px;
  }
  .jsonForm fieldset {
    max-width: 100%;
    fieldset {
      padding: 0; // For repeatable areas - padding on fieldsets is incorrectly set from jsonform-overrides.scss (which needs to go at some point)
    }
  }
  #modalDialog .jsonForm fieldset {
    margin: 0;
  }
  .console-pane-actions {
    position: absolute;
    top: 32px;
    right: 32px;
    //float:right;
    .console-pane-action {
    }
  }
  main {
    .tabbed {
      display: block;
      position: relative;
      background: none;
      padding: 4px 0 0 0;
    }

    .tabbed > section {
      border: none;
      border-top: 2px solid transparent;
    }

    .tabbed > section > div {
      display: block;
    }

    .no-js .tabbed > section.open > div {
      max-height: inherit; /*eurgh*/
    }

    .tabbed .tabbed > section {
      border-top: 1px solid transparent;
    }

        .tabbed > section > div > div {
            margin: 0;
            padding: 0px;
            border: none;
        }
        .tabbed .tabwrap .content-padding {
            padding: 30px 0px;
        }
        .tabbed .tabbed .tabwrap .content-padding {
            padding: 30px 30px 30px 0px;}

    .tabbed>section>div>div {
        margin:0;
        padding: 0px;
        border: none;
    }

    .tabbed .tabwrap .content-padding {
      padding: 30px 0px;
    }

    .tabbed .tabbed .tabwrap .content-padding {
      padding: 30px 30px 30px 0px;
    }

    .tabbed .overflowTabs {
      right: 0;
      left: inherit;
    }

    /* tab loading animation */
    section.active.waiting:after {
      content: "";
      top: 0;
      right: 0;
      left: 0;
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: #d8d8d8;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 60%, #e99813 60%, #e99813 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 60%, #e99813 60%, #e99813 100%);
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 60%, #e99813 60%, #e99813 100%);
      background-size: 200% 100%;
      /* animate this */
      -webkit-animation: wipe 1s linear infinite;
      -moz-animation: wipe 1s linear infinite;
      animation: wipe 1s linear infinite;
      /* but don't go on about it */
      -webkit-animation-iteration-count: 10;
      animation-iteration-count: 10;
    }

    @-webkit-keyframes wipe {
      0% {
        background-position: 200% 0
      }
      100% {
        background-position: 0 0
      }
    }
    @-moz-keyframes wipe {
      0% {
        background-position: 200% 0
      }
      100% {
        background-position: 0 0
      }
    }
    @keyframes wipe {
      0% {
        background-position: 200% 0
      }
      100% {
        background-position: 0 0
      }
    }

    /* summary stats */
    .summary-stats {
      flex-grow: 0;
      flex: inherit;
      margin: 0 -32px;
    }

    .summary-stats .summary-stat {
      flex: inherit;
      min-width: 0;
      display: flex;
      padding: 16px;
    }

    .summary-stats .summary-meta, .summary-stats .summary-icon {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-size: 1.5em;
      font-weight: 100;
      margin-right: 10px;
    }

    /* jsonform summary stats v2 - this is wack */
    .jsonForm fieldset.stats {
      flex-grow: 0;
      flex: inherit;
      margin: -32px 0 -32px -16px;
      width: auto;
      min-width: auto;
    }

    .jsonForm fieldset.stats .jf-row {
      flex: inherit;
      min-width: 0;
      display: inline-flex;
      padding: 16px;
      width: auto;
      max-width: 33%;
      flex-grow: 0;
    }

    .jsonForm fieldset.stats .jf-row label.stat-icon,
    .jsonForm fieldset.stats .jf-row *[data-stat-icon] {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 1.5em;
      font-weight: 100;
      margin-right: 0;
      border: 0;
      background: transparent;
      opacity: 1;
      flex-shrink: 0;
      min-width: 0;
    }

    .jsonForm fieldset.stats .jf-row label.stat-icon {
      width: auto;
      max-width: none;
      min-width: 45px;
    }

    .jsonForm fieldset.stats .jf-row *[data-stat-icon] {
      width: auto;
      max-width: none;
    }

    .jsonForm fieldset.stats .stat-icon[for=totalLoss] svg {
      fill: #bb0000;
    }

    .client-console {
      background: white;
    }
  }
  #client-console-summary{
    .contact-block{
      max-width:300px;
      color:$body-text;
      a{
        color:$body-text;
        &:hover{
          text-decoration:underline;
        }

      }
      .chosen-single{
        border-top:0;
        border-left:0;
        border-right:0;
        background:none;
        box-shadow:none;
      }
    }
    .person{
      padding-left: 12px;
      padding-top: 6px;
      &.primary-only{
        padding-left:0;
      }
    }
  }
  //.clearFilters{
  //  display:none;
  //}
}