#jf_jfdec {
    //padding-top: 203px;
}

body.readonlyMode #jf_jfdec {
    padding-top: 0px;
}

#jf_jfdec section h2:before {
    display: none;
}

form#jf_jf1.inIframe {
    //padding-top: 52px;
    padding-top: 78px;
	
}

form#jf_jf1.inIframe .jf-header-section,
form#jf_jf1.inIframe .jf-header-section[style] {
    top: 0 !important;
	position:fixed;
}

.pf-form.readonlyIframe .page-container {
	padding-top: 0px;
}


#modalDialog.propformpreview .dialog-box {width: 80vw;}
#modalDialog.propformpreview .dialog-message {padding:0;}
#modalDialog.propformpreview iframe {
	width: 100%;
	height: calc(100vh - 300px);
	border: none;
}