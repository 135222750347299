/* Hacky fix for primary-secondary radio button field */
.jsonForm [id*="anotherPractice"].jf-input-type-radio{
  min-width: 190px;
  width: 2%;
}

.jsonForm #f8a th,
.jsonForm #f8a td,
.jsonForm #f8aa th,
.jsonForm #f8aa td,
.jsonForm #f9a th,
.jsonForm #f9a td{
  //width: 33%;
	width: 25%;
}

#hidden-for-renewal-f1-1b{
  display: none;
}

.jsonForm #wrapper-for-i1-4-2 > label.inline-append {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.jsonForm #wrapper-for-i1-4-2 .collection {margin-top:-10px;}



/* hacky fix for the inline SOF pseudo validation */
.jsonForm {
	
	/* style the SOF warning */
	fieldset#elpl_sof_oops,
	fieldset#dando_sof_oops,
	fieldset#cyber_sof_oops {
		margin-top: -40px;
		/*border: 1px solid $aqua-blue!important;*/
		color: $aqua-blue!important;
		background: lighten($aqua-blue,95%);
	}
	
	/* fully hide the SOF questions when not required (rather than show the 'not applicable' box) */
	fieldset#elpl_sof.setasrequired,
	fieldset#dando_sof.setasrequired,
	fieldset#cyber_sof.setasrequired {
		display:none;
	}
	
	/* hide the SOF warning when there are no answers */
	fieldset#elpl_sof[data-hasanswers='false'] + fieldset#elpl_sof_oops,
	fieldset#dando_sof[data-hasanswers='false'] + fieldset#dando_sof_oops,
	fieldset#cyber_sof[data-hasanswers='false'] + fieldset#cyber_sof_oops {
		display:none;
	}
	/* hide the SOF warning when there are answers but the section is hidden */
	fieldset#elpl_sof.setasrequired + fieldset#elpl_sof_oops,
	fieldset#dando_sof.setasrequired + fieldset#dando_sof_oops,
	fieldset#cyber_sof.setasrequired + fieldset#cyber_sof_oops {
		display:none!important;
	}
	
	/* reduce the top margin of the SOF fields so they look less disconnected */
	fieldset#elpl_sof,
	fieldset#dando_sof,
	fieldset#cyber_sof {
		margin-top:-40px;
	}
	
	/* make the sof intro look like the legends */
	fieldset#elpl_sof .intro,
	fieldset#dando_sof .intro,
	fieldset#cyber_sof .intro {
		@include font('title', 18px,false, 400,  2.26);
		letter-spacing: .2px;
	}
}
/* END hacky fix for the inline SOF pseudo validation */


/*
hacky fix for norway_offWorkAccident form
where we're having a conditional field to ensure that the sum of two other fields is >0.
This conditional field is visibility hidden but will still be validated so if its required by those
two fields then it will be validated (and will fail)
*/
.jsonForm {
	/* visually hide the num_coverages_oops fieldset */
	#num_coverages_oops {
		position: absolute;
		opacity: 0.01;
		visibility: hidden;
		right: 100%;
	}
	/* recolour the required fields when num_coverages_oops is required */
	#num_coverages_oops:not(.setasrequired) ~ fieldset #num_coverages_standard,
	#num_coverages_oops:not(.setasrequired) ~ fieldset #num_coverages_expanded {
		box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $buttercup !important;
	}
}
/* end hacky fix for norway_offWorkAccident form */


/*
hacky fix for norway_workersCompensation form
where we're having a conditional field to ensure that the sum of two other fields is >0.
This conditional field is visibility hidden but will still be validated so if its required by those
two fields then it will be validated (and will fail)
*/
.jsonForm {
	/* visually hide the num_coverages_oops fieldset */
	#num_employees_oops {
		position: absolute;
		opacity: 0.01;
		visibility: hidden;
		right: 100%;
	}
	/* recolour the required fields when num_employees_oops is required */
	#num_employees_oops:not(.setasrequired) ~ fieldset #num_employees_selfEmployed,
	#num_employees_oops:not(.setasrequired) ~ fieldset #num_employees {
		box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 #f5a623 !important;
	}
}
/* end hacky fix for norway_workersCompensation form  */


/*
hacky fix for norway_travelInsuranceBusiness form
where we're having a conditional field to ensure that the sum of two other fields is >0.
This conditional field is visibility hidden but will still be validated so if its required by those
two fields then it will be validated (and will fail)
*/
.jsonForm {
	/* visually hide the num_coverages_oops fieldset */
	#num_coverages_oops {
		position: absolute;
		opacity: 0.01;
		visibility: hidden;
		right: 100%;
	}
	/* recolour the required fields when num_coverages_oops is required */
	#num_coverages_oops:not(.setasrequired) ~ fieldset #num_businessCoverages,
	#num_coverages_oops:not(.setasrequired) ~ fieldset #num_privateCoverages {
		box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 #f5a623 !important;
	}
}
/* end horror */
