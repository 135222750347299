
/* Chosen() style overwrites */

.jsonForm {
	select {
		&:required {
			~ .chosen-container .chosen-single,
			~ .chosen-container .chosen-choices {
				border-color: $border-color-req;
				box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-req;
			}
			&:valid {
				~ .chosen-container .chosen-single,
				~ .chosen-container .chosen-choices {
					border-color: $border-color-req;
					box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-val;
				}
			}
			&:invalid {
				&[data-answered="true"] { //and answered
					~ .chosen-container .chosen-single,
					~ .chosen-container .chosen-choices {
						border-color: $border-color-err;
						box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-err;
					}
				}
			}
		}
	}
	
	.chosen-container[style]{
		width: 100%!important;
		//margin-left: 6px!important;
	}
	
    .chosen-container {
        position: relative;
        flex: 1 1 auto;
        width: auto;
        margin-left: 6px;
        font-size: $input-fs;

        .chosen-single,
        .chosen-choices {
            min-height: 36px;
            height: auto;
            padding: 7px 0 7px 14px;
            font-size: $input-fs;
            line-height: 20px;
            color: $text-color;
			border-radius: 0;
            /*background: $el-bg-req;
            border: 1px solid $border-color-req;
            box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-req;*/
			background: $el-bg;
			border: 1px solid $border-color;
			box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color;
	
			
        }

        .chosen-single {
            span {
                //margin-right: 65px;
                margin-right: 32px;
                font-size: inherit;
                line-height: inherit;
                text-transform: none;
            }

            div {
                //right: 48px;
                right: 12px;
                width: auto;

                b {
                    display: block;
                    width: 10px;
                    height: 100%;
                    //background: url("/img/source/arrow-down.svg") no-repeat center center;
                    //background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229%22%20height%3D%225%22%20viewBox%3D%220%200%209%205%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23333%22%20fill-rule%3D%22evenodd%22%20d%3D%22M8.454%200L9%20.54%204.5%205%200%20.54.543%200%204.5%203.92z%22%2F%3E%0A%3C%2Fsvg%3E') no-repeat center center;
                }
            }
        }

        .chosen-drop {
            border: 1px solid $border-color-req;
            border-radius: 0;
            box-shadow: none;
            z-index: 999999999;
        }

        .chosen-results {
            margin: 0;
            padding: 0 4px;
            font-size: $input-fs;
            font-weight: normal;
            color: $text-color;

            li {
                padding: 8px 10px;
                line-height: 20px;
				&.group-option {
					padding-left:15px;
				}
            }
        }

        &.chosen-with-drop {
            .chosen-drop {
                right: 0;
                left: 0;
            }
        }
    }

    select[data-type="contact"] ~ .chosen-container .chosen-single {
        span {
            margin-right: 65px;
        }
        div {
            right: 48px;
        }
    }

    .chosen-container-active {
        border: none;

        &.chosen-with-drop {
            .chosen-single {
                background: $el-bg-req;
                border: 1px solid $border-color-req;
                border-radius: 0;
                box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-req;

                div {
                    border: none;

                    b {
                        background-position: center;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .chosen-container-multi {
        max-width: 100%;
        width: calc(100% - 6px) !important;

        .chosen-drop {
            width: 100% !important;
        }

        .chosen-choices {
            padding: 5px 30px 0 5px;
            //background: url("/img/source/arrow-down.svg") no-repeat 95% center;
            background-position: calc(100% - 12px) center;

            li {
                &.search-field {
                    margin: 0 0 5px 9px;

                    input[type="text"] {
                        min-height: 0;
                        height: auto;
                        margin: 0;
                        padding: 2px 0;
                        font-size: inherit;
                        line-height: inherit;
                        color: $placeholder-color;
                    }
                }
                &.search-choice {
                    margin: 0 6px 5px 0;
                    padding: 4px 28px 4px 11px;
                    line-height: 14px;
                    font-weight: normal;
                    color: inherit;
                    background: $black-haze;
                    border: 1px solid $iron2;
                    border-radius: 0;
                    box-shadow: none;

                    .search-choice-close {
                        position: absolute;
                        display: block;
                        top: 50%;
                        right: 10px;
                        width: 14px;
                        height: 14px;
                        margin-top: -7px;
                        padding: 2px;
                        background: url("/img/source/ic-close.svg") no-repeat center;
                        background-position: center !important;
                        background-size: 10px 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
	
	@include media('<=desktop') {
        /* I think this is redundant (in fact wrong) - there was a point when we needed a border rather than shadow but we have since enforced the shadow elsewhere)
        .chosen-container {
            margin-left: 0;
            
            .chosen-single,
            .chosen-choices {
                border-left: 4px solid $line-color-req;
                box-shadow: none;
            }
        }

        .chosen-container-active {
            &.chosen-with-drop {
                .chosen-single {
                    border-left: 4px solid $line-color-req;
                    box-shadow: none;
                }
            }
        }*/
    }

    @include media('<tabport') {
        .chosen-container-multi {
            width: 100% !important;
        }
    }
}

#my_select_chzn .chzn-drop .chosen-search {
  width: 99% !important;
}
#web_template_exploit_name_chzn .chosen-drop .chosen-search input {
  width: 98% !important;
}


.jsonForm.readonly select[data-type='asset'] + .chosen-container span.assetViewOnly {
	cursor: pointer;
}
.jsonForm.readonly select[data-type='asset'] + .chosen-container span.assetViewOnly:after {
	content: "↗";
	color: #0094ff;
	position: absolute;
	font-weight: 900;
	margin-left: 10px;
	vertical-align: middle;
}