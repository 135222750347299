// A Table design using flex-bos for display of basic two column tabular data.

.flex-table{
    font-size: 17px;
    line-height: 1.53;
    letter-spacing: 0.26px;
    margin-bottom:2em;
    max-width:600px;
    .flex-parent {
        padding:0;
        > .ft-label {
            flex-basis: 33%;
            padding:0;
            font-weight: bold;
        }
        > .ft-data {
            flex-basis: 66%;
            padding:0;
            
        }
    }

}