
.homepage main{
    & > section{
        padding: 48px 0;
        @if $site == "norway"{
            padding: 64px 0 48px;
        }
        @include media("<=tabport"){
            padding: 32px 0;
        }
        @include media("<=phoneland"){
            padding: 16px 0;
        }
    }
    .banner-contentwrap {
        .banner-cta {
            font-size: 16px;
            font-weight: 500;
            line-height: 2.25;
            text-transform: none;
        }
    }
}
@include media("<=phoneland"){
    .homepage .aside{
        margin-bottom:-32px;
    }
}
.latest-resources-section{
    font-size:18px;
    font-weight: 300;
    line-height: 1.56;
    padding-top: 48px;
    padding-bottom: 32px;
    background-color: $alabaster;
    .block-heading{
        @include font("title",48px,$dark,400,1.21);
        margin:32px 0;
        @include media("<=tabport"){
            margin:16px 0;
        }
        @include media("<=phoneland"){
            margin:8px 0;
            font-size: 28px;

            line-height: 1.14em;
        }
    }
    .article-meta{
        background-color: $alabaster;
    }
}

#modalDialog-start-home{

    .dialog-box{
        width: 920px;
        height: 734px;
        max-width: 80%;
        max-height: 80%;
        min-width: 0px;
        padding: 0px;
        position: relative;

        .dialog-message{
            background: url(../img/norway/norway-commercial-1440-x-663-1.jpg) no-repeat;
            //height: 638px;
            padding: 0;
            position: absolute;
            overflow-y: scroll;
            height: 90%;
            width: 100%;

            .modal-start-content{
                padding: 0;
            }

            .modal-side-a{
                min-width: 523px;
                height: 638px;
                opacity: 0.86;
                background-color: #ffffff;
                padding: 0;
                flex-grow: 2;
                overflow: auto;

                .modal-lockton-logo{
                    max-width: 158px;
                    max-height: 95px;
                }

                .modal-advokatforeningen-logo{
                    max-width: 184px;
                    max-height: 79px;
                }

                .modal-content-box{
                    flex-basis: 100%;
                    .modal-content-text{
                        @include font("body",20px,#20262a,300,1.5);
                    }
                }

            }

            @media only screen and (max-width: 653px) {
                .modal-side-a {
                    min-width: 100%;
                }
            }

            .modal-side-b{
                padding: 0;
            }
        }

        .dialog-footer{
            max-width: 921px;
            width: 100%;
            max-height: 96px;
            height: 100%;
            background-color: $nor-darkestgrey;
            position: absolute;
            bottom: 0;

            .modal-footer-content{
                padding: 0;
                justify-content: flex-end;
                position: relative;
                top: 12px;
                .btn-continue{
                    flex: none;
                    .btn-modal-continue{
                        width: 157px;
                        min-width: 80%;
                        height: 40px;
                        min-height: 80%;
                        background-color: $nor-orange;
                        @include font("body", 14px, $white, 300, 1.4);
                        text-transform: uppercase;
                        border-color: $nor-orange;
                    }
                }

                .btn-not-show{
                    flex: none;
                    .btn-modal-not-show{
                        width: 199px;
                        min-width: 80%;
                        height: 40px;
                        min-height: 80%;
                        border: solid 1px $nor-orange;
                        @include font("body", 14px, $nor-orange, 300, 1.4);
                        background-color: transparent;
                        text-transform: uppercase;
                    }
                }

            }

        }
    }

    @media only screen and (max-width: 519px) {
        .dialog-box{
            .dialog-message{
                .modal-side-a{
                    .modal-box{
                        display: inline-block;
                    }
                }
            }

            .dialog-footer{
                max-height: 124px;
                height: 100%;
                .modal-footer-content {
                    justify-content: center;
                }
            }
        }
    }

}
