.readonlyModeNotice {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  /* width: 60%; */
  margin: 0 auto;
  text-align: center;
  background: #f37e00;
  padding: 5px;
  /*pointer-events:none;*/
  line-height: 16px;
	color:#ffffff;
}
.readonlyModeNotice a {
  pointer-events: all;
  text-transform: uppercase;
  padding: 1px 4px 0px;
  font-weight: 600;
  border: 1px solid #1c75bc;
}
.readonlyModeNotice a:hover {
  background: rgba(255,255,255,.5);
  color: #009444;
  border-color: #009444;
}
#forceSubmissionBlock {
  //display:none;
}
.ready ~ #forceSubmissionBlock {
  display:block;
}

.forceSubmissionMessage{
    padding: 20px;
    background: #DDEEF7;
    max-width:calc(100% - 300px);
    width:100%;
	@include media("<=tabport"){
		max-width:100%;
	}
	button{
		float:right;
	}
}
.forceSubmissionMessage:after {
  content: "";
  display: table;
  clear: both;
}
//#forceSubmissionBlock p {
//  margin: 7px 0;
//  float:left;
//}
//#forceSubmissionBlock button {
//  font-size: 100%; /* 1 */
//  margin: 0; /* 2 */
//  vertical-align: baseline; /* 3 */
//  *vertical-align: middle; /* 3 */
//  font-family:"proxima-nova",sans-serif;
//  background-color:white;
//  border-radius:0;
//  border: 1px solid rgba(0, 0, 0, 0.3);
//  background-color: #1C75BC;
//  text-indent: 0;
//  border: 1px solid #1C75BC;
//  -webkit-border-radius: 0;
//  -moz-border-radius: 0;
//  border-radius: 0;
//  display: inline-block;
//  color: white;
//  font-family: 'Roboto Condensed', sans-serif;
//  font-size: 17px;
//  font-weight: 700;
//  font-style: normal;
//  padding: 0 15px;
//  line-height: 30px;
//  width: auto;
//  text-decoration: none;
//  text-align: center;
//  float: right;
//  text-transform: uppercase;
//  -webkit-transition: all .5s;
//  -moz-transition: all .5s;
//  -ms-transition: all .5s;
//  -o-transition: all .5s;
//  transition: all .5s;
//}#forceSubmissionBlock button {
     //  font-size: 100%; /* 1 */
     //  margin: 0; /* 2 */
     //  vertical-align: baseline; /* 3 */
     //  *vertical-align: middle; /* 3 */
     //  font-family:"proxima-nova",sans-serif;
     //  background-color:white;
     //  border-radius:0;
     //  border: 1px solid rgba(0, 0, 0, 0.3);
     //  background-color: #1C75BC;
     //  text-indent: 0;
     //  border: 1px solid #1C75BC;
     //  -webkit-border-radius: 0;
     //  -moz-border-radius: 0;
     //  border-radius: 0;
     //  display: inline-block;
     //  color: white;
     //  font-family: 'Roboto Condensed', sans-serif;
     //  font-size: 17px;
     //  font-weight: 700;
     //  font-style: normal;
     //  padding: 0 15px;
     //  line-height: 30px;
     //  width: auto;
     //  text-decoration: none;
     //  text-align: center;
     //  float: right;
     //  text-transform: uppercase;
     //  -webkit-transition: all .5s;
     //  -moz-transition: all .5s;
     //  -ms-transition: all .5s;
     //  -o-transition: all .5s;
     //  transition: all .5s;
//}

//#forceSubmissionBlock button:hover {
//  background-color: #009444;
//  border: 1px solid #009444;
//}
#forceSubmissionBlock #invalidMessage {
  display:none;
}
.invalid #forceSubmissionBlock #invalidMessage {
  display:inline;
  color:#BE1E2D;
}
.invalid #forceSubmissionBlock button {
  background-color: #999;
  border: 1px solid #999;
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  -ms-opacity: 0.6;
  -o-opacity: 0.6;
  opacity: 0.6;
  cursor:default;
  pointer-events:none;
}

/* NEW STUFF */
.readonlyModeNotice {position:fixed; top:0; height:25px; z-index:99999550; vertical-align:middle; white-space:nowrap; overflow:hidden;}
.readonlyModeNotice ~ #mainheader,
.readonlyModeNotice ~ #headband,
.readonlyModeNotice ~ form .jf-header-section {margin-top:25px;}

.readonlyNotice {color:red;}



/* Improved read only notice (now more modal-like) */
.readonlyModeModal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	z-index: 99999550;
	display: flex;
	background: rgba(0,0,0,0.4);
	align-items: center;
	justify-content: center;
	flex-direction: column;
	white-space: normal;
	line-height: 1.5em;
	color: #ffffff;
	text-shadow: none;
}
.readonlyModeModal .lockedmsg {
	display: block;
	width: 90%;
	max-width: 600px;
	text-align: center;
}
.readonlyModeModal .lockedmsg a {
	display:inline-block;
	margin:20px
}
.readonlyModeModalCollapsed {
	height: 140px;
	pointer-events: none;
}
.readonlyModeModalCollapsed {
	height: 105px;
	pointer-events: none;
	margin: 0 auto;
	background: none;
	width: calc(100% - 350px);
	color: orange;
}
.readonlyModeModalCollapsed .lockedmsg {
	display:none;
}
.readonlyModeModalCollapsed:before {
	content: attr(readonly-text);
	font-size: 14px;
}
.readonlyModeModal:after {
	content: " ";
	display: block;
	position:fixed;
	top:0;
	right:0;
	left:0;
	height:4px;
	background:orange;
}
body.readonlyMode .readonlyModeModal + #page-container {
	filter:blur(5px);
}
body.readonlyMode .readonlyModeModal.readonlyModeModalCollapsed + #page-container {
	filter:none;
}