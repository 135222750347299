
.jsonForm {
    table:not(.dataTable) {
        width: 100%;
        font-size: $td-fs;
        line-height: 16px;
        border-spacing: 0;
		caption {
			text-align: left;
		}
        thead {
            th {
                padding: 4px 7px;
                font-size: $th-fs;
                font-weight: $th-fw;
                text-align: left;
                text-transform: uppercase;
            }
			tr.preheader th[colspan] {
				max-width: 50px;
			}
        }

        tbody {
            th {
                &:first-child {
                    padding-right: 5px;
                    font-weight: $th2-fw;
                    text-align: left;
                }
            }
        }

        tr {
            &[data-is-total-row="true"] {
                th {
                    padding-left: 15px;
                    padding-right: 15px;
                    font-size: $th-fs;
                    font-weight: $th-fw;
                    text-align: right;
                    text-transform: uppercase;
                    background: $humming-bird;
                    border: 1px solid $botticelli;
                }
                td {
                    padding-top: 0;
                    padding-bottom: 0;
                }
                input[type=number] {
                    box-shadow: none;
                }
            }
        }

        th {
            & > span {
                display: inline-block;
                text-overflow: ellipsis;
                transition: max-width .5s;
            }

            a {
                font-size: 0.8em;
                text-decoration: none;
                color: #888888;

                &:hover {
                    color: #32809D;
                }
            }

            dfn {
                margin-right: 2px;
            }

            &[data-collapsed='true'] {
                cursor: e-resize;
            }
            &[data-collapsed='false'] {
                cursor: w-resize;
            }
        }

        td {
            position: relative;
            padding: 4px 5px;
            text-align: right;

            input {
                width: 100%;
                height: 100%;
                text-align: left;
                border-radius: 0;
            }

            &.collapsed {
                input {
                    width: 100%;
                    margin: 0;
                    font-size: 0;
                    box-shadow: none;
                }
            }
        }

        th,
        td {
            transition: max-width .5s;

            &[data-collapsed='true'] {
                max-width: 40px !important;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                & > span {
                    max-width: 40px;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            &[data-collapsed='false'] {
                //max-width: 200px;
            }

            & + th,
            & + td {
                max-width: 200px;
            }
	
			&[data-child-is='radio'] {
				width: 50px;
			}
        }

        label.error {
            left: 5px;
            bottom: calc(100% + 7px);
        }

        // Differences (data-tab-direction="vertical")

        &[data-tab-direction="vertical"] {

            th,
            td {
                &:first-child {
                    min-width: 240px;
                    padding: 0px;
                }
            }

            tbody {
                tr {
                    &:last-child {
                        td {
                            padding-bottom: 12px;
                        }
                    }
                }
                th {
                    &:first-child {
                        font-weight: $th3-fw;
                    }
                    dfn svg[class*=icon] {
                        margin-left: 6px;
                    }
                }
            }
            
            &.limited {
                tr {
                    th, td {
                        &:last-child {
                            width: 90px;
                            padding-left: 3px;

                            input {
                                width: 100%;
                                margin-left: 0;
                            }
                        }
                        &:nth-last-child(2) {
                            padding-right: 15px;
                        }
                    }
                }

                label.error {
                    left: -3px;
                }
            }
        }
    }


    #claimsFieldsetInEnduranceForm {
        th:first-child {
            min-width: unset;
            width: 10%;
        }

        td {
            &[data-child-is='radio'] {
                width: 15%;
            }

            &[data-child-is='text'] {
                width: 70%;

                input {
                    max-width: 97%;
                }
            }
        }
    }

    #areasPractiseFieldsEnduranceForm {
        td {
            width: 25%;
        }
    }

    // OLD STYLE

    /*
    tr:first-child th, 
    td, 
    th > span {
        max-width: 8em;
        transition: max-width .5s;
    }

    th[data-show-linkage~="lastCompletedYear"],
    td #lastCompletedYear {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='6px' height='6px' viewBox='0 0 6 6' overflow='inherit' xml:space='preserve'><polygon fill='#83C5D8' points='0,0 6,0 0,6 ' /></svg>");
        background-position: 0 0;
        background-repeat: no-repeat;
    }
    */
}
/* Override the td text alignment is data-text-align='left' is set on the table. Needed for when we have y/n radios in the table. */
.jsonForm table[data-text-align='left']:not(.dataTable) {
	th, td {
		text-align: left;
		label.focb {
			vertical-align: top;
		}
	}
}

/*specific styling for #claims-table */
.jsonForm table#claims-table {
	width:auto;
}
@media (min-width: 767px){
	.jsonForm table#claims-table {
		min-width:600px;
	}
}
.jsonForm table#claims-table td[data-child-is='text'] {
	padding-right:15px;
}
.jsonForm table#claims-table thead th {
	vertical-align:bottom; font-weight:bold;
}
.jsonForm table#claims-table th:first-child,
.jsonForm table#claims-table td:first-child{
	min-width:8em;
	max-width:8em;
	width:8em
}