/**
* Off-Canvas Nav
**/

/* General styles for all menus */

.off-canvas-nav {
    position: fixed;
    width: 384px;
    height: 100%;
    top: 0;
    right: -384px;
    transition: all 0.3s ease;
    padding: 80px 0 20px 0;
    overflow-y: auto;
    background: map_get($pushNav,background);
    background-size:cover;
    z-index:9999999;

    @include media("<=phoneland") {
        width: 100%;
        right: -100%;
        padding: 20px 0 20px 16px;
    }
}
.is-offcanvas-open .off-canvas-nav{
    right: 0;
}

.off-canvas-nav ul{
    list-style-type: none;
}
.off-canvas-nav li{
    padding: 6px 0 6px 15px;
    &.edit-profile{
        margin-top:3em;
        //position:relative;
        &:after{
            height:1px;
            background:$purplish-grey;
            width:80%;
            position:absolute;
            top:-2em;
            right:0;
            display:block;
            content:'';
        }
    }
}

.off-canvas-nav li a { // Over specificed due to lame code in _pf-admin.scss
    display: block;

    vertical-align: middle;
    font-size: 14px;
    line-height: 20px;
    color: map_get($pushNav,text);

    @include media("<=wide-tab") {
        height: auto;

        font-size: 14px;
        line-height: 20px;
        color: map_get($pushNav,text);

    }

    &:hover, &:active {

//        color: $link-primary!important;
        color: $white!important;

        background-color: $cinder;

        text-decoration: none;
        @include media("<=wide-tab") {
            background-color: transparent;
        }
    }
}

.offCanvasMenu-navigation{
    svg{
        fill:map_get($pushNav,text);
        width:20px;
        height:20px;
        transition:fill 300ms ease;
        position:absolute;
        right:20%;
        top:50%;
        transform: translateY(-50%);
    }
    a:hover svg{
        fill:map_get($offCanvasNav,accent);
    }
}

.off-canvas-nav li.on a,
.off-canvas-nav li.current a{

    color: map_get($offCanvasNav,accent)!important;




    background-color: $cinder;

    text-decoration: none;

    @include media("<=wide-tab") {
        background-color: transparent;
    }
}

/* Vertical menu that slides from the left or right */

.cbp-spmenu-right.cbp-spmenu-open {
    right: 0px;
}

.page-container {
    overflow-x: hidden;
    position: relative;
    left: 0;
    transition: all 0.3s ease;
}
.is-offcanvas-open .page-container {
    left: -384px;
}

//-------------------------//

.mobile-nav-close {
    position: absolute;
    right: 33px;
    top: 32px;
    width: 14px;
    height: 14px;
    padding: 0;
    //background: url("../img/close-icon.svg") no-repeat center center;
    //background-size: cover;
    z-index: 100;

    &:hover {
        cursor: pointer;
    }
    @include media("<=phoneland") {
        right: 20px;
    }
    svg{
        fill:map_get($headerFooter, colour);
        width:20px;
        height:20px;
        transition:fill 300ms ease;
        &:hover{
            fill:$white;
        }
    }
}


.main-nav-icons{
    position:relative;
}
.off-canvas-nav{
    .nav-icons-wrap{
        padding:0;
        border-bottom:0 none;
    }

}
