.homeSection{
    .heading-block{
        flex-basis:100%;
        h3{
            margin-bottom:0;
            font-weight: 500;
        }
    }
    .article-heading{
        @include font("title",48px,$dark,400,1.21);
        margin-top:0;
        @include media("<=675"){
            font-size: 32px;

            line-height: 1.14em;
        }
    }
    &.theme_negative{
        background-color: $cod-grey;
        .article-heading {
            color:$white;
        }
        color:$white;
    }
    &.theme_image{
        background:url($sales-bg-img);
        background-size:cover;
        background-position-x: right;
    }
    &.theme_video{
        background-size:cover;
        background-position-x: right;
        background-color: $cod-grey;
        .article-heading {
            color:$white;
        }
        color:$white;
        .flex-parent{
            justify-content: flex-end;
            align-content: center;
            min-height:600px;
            @include media("<=tabport"){
                min-height:0;
                padding-top:192px;
            }
            .block{
                max-width:40%;
                @include media("<=900px"){
                    max-width:70%;
                }
                @include media("<=tabport"){
                    max-width:none;
                }
            }
        }
        .portalVideo-play{
            position:absolute;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            svg{
                height: 120px;
                width: 120px;
                fill:$light-grey;
                opacity: 0.9;
                transition: all 0.4s ease 0s;
            }
            &:hover svg{
                fill:$white;
                opacity: 1;
                transition: all 0.4s ease 0s;
            }
            @include media("<=900px"){
                left: calc(15% + 16px);
            }
            @include media("<=tabport"){
                top:64px;
                left:50%;
                transform:translate(-50%,0);
            }
        }
        .modal-wrap.active .modal-contentWrap{
            padding:0;
            line-height: 0;
        }
    }
    .l-list {
        margin-top:0;
        li{
            font-weight:400;
        }
    }
}