
.flexSlider[data-transitionMode='fade'] {
	&.two-sides-in-viewport {
		& > ul {
			& > li {
				display: flex;
				width: 50%;
				overflow: visible;

				&.current {
					z-index: 2;
				}

				&:nth-child(odd) {
					left: 0;

					.banner-slide-inner {
						margin-left: -150px;
						padding-left: 150px;
						overflow: hidden;
					}
					.banner-slide-container {
						justify-content: flex-end;
						padding-left: 150px;

						/* BG Overlay
						&::before {
							background: rgba(255,255,255,0.78);
						}
						*/
					}
				}
				&:nth-child(even) {
					left: 50%;

					.banner-slide-inner {
						margin-right: -150px;
						overflow: hidden;
					}
					.banner-slide-container {
						justify-content: flex-start;
						padding-left: 240px;
						padding-right: 300px;

						/* BG Overlay
						&::before {
							background: rgba(62,117,131,0.84);
						}
						*/
					}
				}
			}
		}

		.banner-slide-inner {
			position: relative;
			display: flex;
			flex: 1 1 auto;
			min-height: 100%;
			transform: skew(20deg);
		}
	
		.banner-slide-container {
			position: relative;
			display: flex;
			flex: 1 1 auto;
			min-height: 100%;
			margin: 0 -150px;
			//padding: 0 220px;
			padding: 0 150px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			transform: skew(-20deg);

			/* BG Overlay
			&::before {
				position: absolute;
				display: block;
				content: "";
				flex: none;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			*/

			& > div {
				position: relative;
			}
		}

		@include media('<desktop-basic') {
			& > ul {
				& > li {
					&:nth-child(odd) {
						.banner-slide-inner {
							margin-left: 0;
							padding-left: 0;
							overflow: visible;
						}
						.banner-slide-container {
							justify-content: flex-start;
							padding-left: 0;
						}
					}
					&:nth-child(even) {
						.banner-slide-inner {
							margin-right: 0;
							overflow: visible;
						}
						.banner-slide-container {
							padding-left: 20px;
							padding-right: 0;
						}
					}
				}
			}

			.banner-slide-inner {
				transform: none;
			}
		
			.banner-slide-container {
				margin: 0;
				padding: 0;
				transform: none;
			}
		}

		@include media("<tabport") {
			& > ul {
				position: relative;
				display: flex;
				flex-direction: column;
				& > li {
					position: relative;
					flex: 1 1 auto;
					top: auto;
					min-height: 0;
					width: 100%;
					height: auto !important;
					opacity: 1 !important;

					&:nth-child(even) {
						left: 0;

						.banner-slide-container {
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}

.flexSlider[data-transitionMode='fade'] {
	&.three-sides-in-viewport {
		& > ul {
			& > li {
				display: flex;
				width: 33.3%;
				overflow: visible;

				&.current {
					z-index: 2;
				}

				&:nth-child(1) {
					left: 0;

					.banner-slide-inner {
						margin-left: 0px;
						padding-left: 0px;
						overflow: hidden;
					}
					.banner-slide-container {
						justify-content: center;
						//justify-content: flex-end;
						//padding-left: 150px;

						/* BG Overlay
						&::before {
							background: rgba(255,255,255,0.78);
						}
						*/
					}
				}
				&:nth-child(2) {
					left: 33.3%;

					.banner-slide-inner {
						//margin-right: -150px;
						overflow: hidden;
					}
					.banner-slide-container {
						justify-content: center;
						//justify-content: flex-start;
						//padding-left: 240px;
						//padding-right: 300px;

						/* BG Overlay
						&::before {
							background: rgba(62,117,131,0.84);
						}
						*/
					}
				}

				&:nth-child(3) {
					left: 66.6%;
					width: 33.4%;

					.banner-slide-inner {
						//margin-right: -150px;
						overflow: hidden;
					}
					.banner-slide-container {
						justify-content: center;
						//justify-content: flex-start;
						//padding-left: 240px;
						//padding-right: 300px;

						/* BG Overlay
						&::before {
							background: rgba(62,117,131,0.84);
						}
						*/
					}
				}
			}
		}

		.banner-slide-inner {
			position: relative;
			display: flex;
			flex: 1 1 auto;
			min-height: 100%;
			//transform: skew(20deg);
		}



		.banner-slide-container {
			position: relative;
			display: flex;
			flex: 1 1 auto;
			min-height: 100%;
			margin: 0 -150px;
			//padding: 0 220px;
			padding: 0 150px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			//transform: skew(-20deg);

			h1{
				font-size: 3em;
				line-height:1.2;
			}



			& > div {
				position: relative;
			}

			.banner-contentwrap {
				justify-content: center;



				.banner-content {
					text-align: center;
					p{
						max-width: 100%;
						font-size: 14px;
					}

					h1 {
						font-family: $font-title;
					}

					.sub-title {
						margin: 0;
						font-weight: 500;
					}

					.banner-cta {
						margin-top: 45px;
						border: none;
					}

				}
			}
		}

		@include media('<desktop-basic') {
			& > ul {
				& > li {
					&:nth-child(odd) {
						.banner-slide-inner {
							margin-left: 0;
							padding-left: 0;
							overflow: visible;
						}
						.banner-slide-container {
							justify-content: flex-start;
							padding-left: 0;
						}
					}
					&:nth-child(even) {
						.banner-slide-inner {
							margin-right: 0;
							overflow: visible;
						}
						.banner-slide-container {
							padding-left: 0px;
							padding-right: 0;
						}
					}
				}
			}

			.banner-slide-inner {
				transform: none;
			}

			.banner-slide-container {
				margin: 0;
				padding: 0;
				transform: none;
			}

			.half-bounds{
				max-width: 100%;
			}
		}

		@include media("<tabport") {
			& > ul {
				position: relative;
				display: flex;
				flex-direction: column;
				& > li {
					position: relative;
					flex: 1 1 auto;
					top: auto;
					min-height: 0;
					width: 100%;
					height: auto !important;
					opacity: 1 !important;

					&:nth-child(2), &:nth-child(3) {
						left: 0;
						width: 100%;
					}
				}
			}
		}
	}
}