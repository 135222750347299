
// textarea alignment gubbins

.jsonForm {
	.jf-input-type-textarea {
		& > label,
		& > textarea {
			vertical-align: top;
		}
	}
}


#wrapper-for-PrimaryContact{
	label{
		font-size: 10px;
	}
}


#input-submit-wrap{
	display: flex;
	justify-content: flex-end;
	//input{
	//	width: 25%;
	//}
}