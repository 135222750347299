.getAQuoteOption {
    border-right: solid 1px $iron;



    .getAQuoteOption-heading {
        @include font("title", 30px, $almost-black, 300, 1.5);
        text-align: center;
    }

    .getAQuoteOption-desc {
        text-align: center;
        @include font("body", 15px, $body-text, 300, 1.5);
    }

    .getAQuoteOption-btn {
        text-align: center;

        a {
            display: inline-block;
        }
    }
    &:last-child{
        border-right:0 none;
    }
}

.getAQuoteOption-icon {
    height: 68px;
    position: relative;
    margin-top: 20px;

    svg {
        fill: $white;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }

    .svg-wrap {
        display: block;
        content: '';
        position: absolute;
        background: $b_background;
        border-radius: 50% 50%;
        width: 68px;
        height: 68px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 14px;
    }
}



.flex-parent .block-getAQuoteOption {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 200px;
  background-clip: content-box;
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  -webkit-flex: 1 1 200px;
}

// Get a quote dialog boxes - this shouldn't live here! - now includes separately in common.css
//.alertDialog {
//    font-family: sans-serif;
//    display: none;
//    position: fixed;
//    top: 0;
//    right: 0;
//    bottom: 0;
//    left: 0;
//    background: #222222;
//    background-color: rgba(0, 0, 0, .75);
//    text-align: center;
//    z-index: 99999999;
//}
//
//.alertDialog .dialog-box {
//    background-color: #ffffff;
//    width: 40%;
//    min-width: 400px;
//    max-width: 80%;
//    margin: auto;
//    display: inline-block;
//    vertical-align: middle;
//    max-height: 100%;
//    overflow: auto;
//}
//
//.alertDialog:before {
//    content: '';
//    display: inline-block;
//    height: 100%;
//    vertical-align: middle;
//    margin-right: -0.25em;
//}
//
//.alertDialog .dialog-title {
//    background: #edeeee;
//    padding: 20px 40px;
//    font-family: AGaramondPro;
//    font-size: 30px;
//    text-align: left;
//    color: #060708;
//    line-height:68px;
//    vertical-align: middle;
//    .getAQuoteOption-icon {
//        width:68px;
//        margin-right:1em;
//        display:inline-block;
//        vertical-align: middle;
//    }
//}
//
//.alertDialog .dialog-message {
//    margin: 0;
//    padding: 30px;
//    text-align: center;
//}
//
//.alertDialog .dialog-button-row {
//    background: none repeat scroll 0 0 rgba(100, 100, 100, 0.1);
//    background-color: #212f38;
//    display: flex;
//    flex-direction: row-reverse;
//    padding-right: 40px;
//}
//
//.alertDialog .dialog-button-row {
//    a, input {
//        background: #01bee5;
//        color: rgb(255, 255, 255);
//        padding: 10px;
//        display: inline-block;
//        margin: 15px 0 15px 15px;
//        text-decoration: none;
//        min-width: 150px;
//        font-size: 14px;
//        text-transform: uppercase;
//        appearance:none;
//        border:0 none;
//    }
//}
//
//.alertDialog .dialog-button-row a + a {
//    background: transparent;
//    box-shadow: 0 0 0 1px #9b9b9b;
//}
//
//.alertDialog .dialog-button-row a:hover {
//    background: #666;
//    box-shadow: none;
//}
//
//.alertDialog form {
//    text-align: left;
//    opacity: 1 !important;
//}
//
//.alertDialog form.submitted input:invalid + label.mvalidation {
//    display: block;
//}
//
//.alertDialog form .jf-row > label.mvalidation {
//    padding: 0px 10px 0px 10px !important;
//    display: inline-block;
//    min-width: 30%;
//    max-width: 100%;
//    width: auto;
//    text-align: center;
//}

.complete-proposal-message{
    text-align: left;
    margin-bottom: 30px;
    font-family: "Gotham A","Gotham B",sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #060708;

    .proposal-message{
        margin-bottom: 30px;
    }

    &.complete-proposal-link{
        font-size: 17px;
        font-weight: 500;
        line-height: 2.12;
        color: #01bee5;
    }

    &::after{
        content: '';
        position: absolute;
        border: solid 1px #d7d5d5;
        min-width: 35%;
        z-index: 9;
    }
    @media only screen and (max-width: 866px) {
        &::after{
            content: '';
            position: absolute;
            border: solid 1px #d7d5d5;
            min-width: 322px;
            z-index: 9;
        }
    }

    .ion-ios-arrow-thin-right---Ionicons {
        width: 25px;
        height: 13px;
        position: relative;
        left: 13px;
    }
}





@media only screen and (max-width: 1024px) {
    .get-a-quote-page .dropzone {
        width: 100%!important;
    }


    .get-a-quote-page a.drop-button {
        //  pointer-events: none;
        bottom: 0px!important;
        position: relative!important;
        //display: block!important;
        left: 0!important;
        margin: 10px auto !important;
    }

    .alertDialog .dialog-button-row {
        padding-right: 0!important;
    }


    .alertDialog .dialog-button-row a {
        margin: 15px auto!important;;
    }

    .get-a-quote-page .dropzone-icon{
        margin-top: 40px;
    }

    .alertDialog .dialog-title {
        font-size: 21px !important;
    }

    .alertDialog .dialog-title .getAQuoteOption-icon {
        margin-top: 10px!important;
        margin-bottom: 10px!important;
    }

    .alertDialog .dialog-title .getAQuoteOption-icon {
        margin-right: 10px!important;
    }


}

@media only screen and (max-width: 402px) {
    .alertDialog .dialog-box {

        min-width: 100%!important;
        margin: auto -5px!important;

    }

    .alertDialog .dialog-title {
        font-size: 16px !important;
    }

    .alertDialog{
        position: relative!important;

    }

    .modal-wrap.active .modal-contentWrap {
        max-width: 100%!important;
        max-height: 100%!important;
        margin: auto -5px!important;
        overflow: auto !important;
        vertical-align: auto;
        padding: 0;


    }

    .modal-overlay:before{
        display: inline-block;
        height: 100%;
        margin-right: 0;
        vertical-align: middle;
    }

    .get-a-quote-page .dropzone {
        width: 95%!important;
    }


}