.dashboard-tabs {
	//border-bottom: 9px solid map-get($locationTabs,sectionBorder);

	//@include media("<tabport") {
	//	border-bottom: 6px solid map-get($locationTabs,sectionBorder);
	//}
    //margin-top: 40px;

	.dashboard-wrap {
		position: relative;
		//margin-top: -44px;
		padding: 0;
		background-color: transparent;
		z-index: 10;

		//@include media("<tabport") {
			//margin-top: -134px;
		//}

		&>section {
			//			background-color: map-get($locationTabs,sectionBg);
			background-color: $alabaster;
			&>div>div{
                position:relative;
				//padding:24px 0 48px;
                border-bottom:0 none;
			}
		}

		&>section:after {
			//			background-color: map-get($locationTabs,sectionBg);
			background-color: $alabaster;
			position: absolute;
			display: block;
			top: 0px;
			left: -2500px;
			right: -2500px;
			height: 100%;
			content: '';
			z-index: -1;
		}
		.tabs-control{
			padding:0 32px;
			@include media("<=tabport"){
				padding:0 16px;
			}
			@include media("<=phoneland"){
				padding:0 8px;
			}
		}
		.tab-content {
			position: relative;
			border: none;

			@include media("<tabport") {
				height: auto;
			}

//			&::after {
//				display: block;
//				position: absolute;
//				content: "";
//				top: 0;
//				bottom: 0;
//				left: 66.5%;
//				width: 3000px;
////				background-color: map-get($locationTabs,sectionBg);
//				background-color: $light-grey-seven;
//				z-index: 5;
//
//				@include media("<1200px") {
//					display: none;
//				}
//			}

			&>div {
				height: 100% !important;
			}

            .tab-info-wrap{
              background-color: $light-grey-seven;
            }
            fieldset{
                margin-top:0;
            }
		}
	}
}