.pf-form_flex #mainheader,
.pf-dashboard #mainheader,
.pf-dashboard #nav{ // Added mainheader to ensure override over default jsonform styles
    overflow:visible;
    .acc-contact-details-wrapper {
        position: absolute;
        z-index: 2;
        top: 16px;
        right: 32px;
        @include media("<=1070px") {
            top: 26px;
        }
        @include media("<desktop") {
            top: 18px;
        }
        @include media("<wide-tab") {
            display:none;
        }
    }
    .acc-contact-details {
        position: relative;

        display: inline-block;

        text-align: left;
        vertical-align: top;
    }
    .acc-contact-details .img-wrap {
        line-height: 0;

        display: inline-block;

        margin: 0 10px 0 0;

        vertical-align: bottom;
    }
    .acc-contact-details-wrapper img {
        width: 93px;
        height: 110px;

        border: 1px solid #808285;
        @include media("<=1070px") {
            width: 81px;
            height: 96px;
        }
    }
    .acc-contact-details .text-wrap {
        display: inline-block;

        min-width: 200px;
        padding-top: 25px;
        @include media("<=1070px") {
            min-width: 170px;
        }
    }
    .acc-contact-details .name {
        display: block;
    }
    .acc-contact-details .phone {
        display: block;
    }
    .acc-contact-details .email {
        display: block;

        transition: color .3s !important;
    }
    .acc-contact-details .email:hover {
        color: $link-primary;
    }
    .acc-contact-details .contact-select {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        padding-left: 104px;
        @include media("<=1070px") {
            padding-left: 94px;
        }
    }
    .acc-contact-details .contact-select .select-wrap:after {
        display: none!important;
    }
    .acc-contact-details .contact-select select {
        padding: 0;

        text-align: left;

        border: 0;
    }
    .acc-contact-details .contact-select .chosen-container {
        font-size: 14px!important;
    }
    .acc-contact-details .contact-select .chosen-container .chosen-drop {
        border: 0;
        box-shadow: none;
    }
    .acc-contact-details .contact-select .chosen-container-single .chosen-single {
        padding: 0 0 0 4px;

        border: 0;
        border-radius: 0;
        background: $white;
        box-shadow: none;
    }
    .acc-contact-details .contact-select .chosen-container-single .chosen-single span {
        font-size: 16px;
    }
    .acc-contact-details .contact-select .chosen-container .chosen-results {
        margin-right: 0;
        padding-left: 10px;
    }
    .acc-contact-details .contact-select .chosen-container .chosen-results li {
        width: 100%;
        padding: 5px 4px;
    }
    .acc-contact-details .contact-select .chosen-container.chosen-with-drop .chosen-results {
        border-right: 1px solid #808285;
    }
    .acc-contact-details .contact-select .chosen-container .chosen-results li.highlighted {
        background: #F2F2F2;
    }
    .acc-contact-details .contact-select .chosen-container-active .chosen-single {
        border: 0;
        box-shadow: none;
    }
    .acc-contact-details .contact-select .chosen-container-active.chosen-with-drop .chosen-single {
        border: 0;
        border-right: 1px solid #808285;
        background: $white;
        box-shadow: none;
    }
    .manage-details {
        position: relative;

        display: block;

        padding: 16px 0 8px 32px;

        text-transform: lowercase;

        border-top: 1px solid $white;
    }
    .manage-details .svg-icon {
        position: absolute;
        top: 50%;
        left: 0;

        display: inline-block;

        margin-top: -6px;

        fill: #B6BCB8;
    }
}

.pf-dashboard #nav{
    .acc-contact_li{
        min-width:310px;
        padding:0;
        .acc-contact-details-wrapper {
            position: relative;
            top: 0;
            right: 0;
        }
        .text-wrap{
            line-height:20px;
        }
    }
}