.accordionnice {
    //ul.jf-page-control li a {
    //    border: 1px solid #4a4a4a;
    //    display: block;
    //    min-width: 30px;
    //    font-size: 14px;
    //    line-height: 26px;
    //    overflow: hidden;
    //    text-align: center;
    //    padding: 0;
    //    margin: 0;
    //    color: #4a4a4a;
    //    text-decoration: none;
    //}
    //
    //ul.jf-page-control {
    //    z-index: 9;
    //    li.current a {
    //        color: #4a4a4a;
    //        border: 1px solid #f5a623;
    //    }
    //}
    //
    //.jf-header-section {
    //    z-index: 9;
    //    width: 100%;
    //    background: white;
    //    border: none;
    //}
    //
    //ul.jf-page-control li.current {
    //    border-bottom-color: white;
    //}

    .accordion-item-open .accordion-content,
	.accordion-item header ~ p.intro {
        -webkit-transition: max-height 0.6s ease-in 0s, opacity 0.4s ease 0.3s;
        -o-transition: max-height 0.6s ease-in 0s, opacity 0.4s ease 0.3s;
        transition: max-height 0.6s ease-in 0s, opacity 0.4s ease 0.3s;
    }

    .accordion-item legend {
        max-height: 0px;
        overflow: hidden;
    }

    .accordion-item-open legend {
        max-height: 400px;
        overflow: visible;
    }
	
	.accordion-item-open fieldset:first-of-type > legend:first-of-type {
		padding-top: 25px;
		display: block;
	}
	.accordion-item header ~ p.intro:not(.datapagelastintro) {
		max-height: 0px;
		margin: 0;
		overflow:hidden;
	}
	.accordion-item-open header ~ p.intro {
		margin-top: 30px;
		max-height: 1000px;
		overflow: visible;
	}


    &.jsonForm > section > .not-applicable {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        border: none;
        text-align: center;
        padding: 3px;
        text-transform: uppercase;
        color: white;
        background: rgb(103, 134, 142);
        width: 13em;
        margin: auto;
        display: inline-block;
    }

    &.pf-form .page-container {
        //padding-top: 182px;
    }


    &.jsonForm section.accordion-item {
        padding: 0;
        margin-bottom: 2px;
        border: 1px solid #eee;
        border-width: 0 1px 0 1px;
        min-height: 80px;
    }

    //&.jsonForm:not(.checklistForm) section.accordion-item:last-of-type,
	//&.jsonForm.checklistForm section.accordion-item.last {
	//&.jsonForm section.accordion-item:last-of-type {
	&.jsonForm:not(.checklistForm) section.accordion-item:last-of-type {
        border-width: 0 1px 1px 1px;

        .accordion-content {
            max-height: 1000px;
            opacity: 1;
            margin: 10px 20px;
            padding: 20px 30px;

            legend, .intro {
                max-height: 1000px;
            }
        }
    }
	
	&.jsonForm.checklistForm section.accordion-item:last-of-type {
		border-width: 0 1px 1px 1px;
		
		.accordion-content {
			max-height: 1000px;
			opacity: 1;
			margin: 10px 20px;
			padding: 20px 10px;
			
			legend, .intro {
				max-height: 1000px;
			}
		}
	}
	
	&.jsonForm section.accordion-item.last .accordion-content ~ .accordion-content:last-of-type {
		display:none;
	}

    &.jsonForm section.accordion-item-open {
    }
	
	&.jsonForm section.fo-section-hide-me {
		height: 1px;
		overflow: hidden;
		opacity: 0;
    }

    &.jsonForm section h2 {
        position: relative;
    }

    &.jsonForm section.accordion-item h2 {
        margin: 0;
        color: #4a4a4a;
        font-weight: 300;
        font-size: 24px;
        line-height: 1.62em;
        width: 100%;
        text-indent: 20px;
    }

    &.jsonForm section.accordion-item-open h2 {
        color: #fff;
        background-color: #4a4a4a;
    }


    &.jsonForm section fieldset {
        padding: 30px;
    }

    &.jsonForm section.accordion-item fieldset {
        width: auto;
        margin: 0;
        padding: 0 30px;
		//&:not([data-layout-style]) {
		//	display: -webkit-box;
		//}
    }

    &.jsonForm section.accordion-item-open fieldset {
        padding: 20px 30px;
        margin: 10px 20px;
        overflow: visible;
        animation: 2s delay-overflow;
        max-height: 600000px;
    }

    &.jsonForm section.accordion-item-open fieldset fieldset {
        padding: 0px;
        margin: 0px;
    }

    @keyframes delay-overflow {
        from {
            overflow: hidden;
        }
    }

    &.jsonForm section.accordion-item-open {
        border: 1px solid #eee;
        border-top-width: 0;
    }

    &.jsonForm section.current {
        background: #fff;
    }

    &.jsonForm .nextbuttonwrapper {
        text-align: right;
        max-width: 100%;
        //padding-right: 20px;
        padding-bottom: 30px;
        padding-top: 20px;
        border-top: 1px solid #4a4a4a;
    }
	
	&.jsonForm.readonly .nextbuttonwrapper {
		display:none;
	}


    /* header anchor band */
    &.jsonForm .jf-header-section {
        display: block;
        position: relative;
        top: inherit !important;
        padding-top: 10px;
        padding-bottom: 10px;
        background: white;
        border-top: 1px solid #eee;
    }

    &.jsonForm .jf-header-section .jf-header-bounds {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    &.jsonForm .jf-header-section button#main_submit_float {
        right: 0px;
    }

    &.jsonForm .jf-header-section button#main_summary {
        right: 123px;
    }

    &.jsonForm .jf-header-section ul.jf-page-control li:first-of-type {
        //display:none;
    }

    //#issues, #summaryScreen {margin-top: 50px!important;}

    #p4 .intro {
        margin: 20px 10px 0 20px;
        font-size: 16px;
        font-weight: 400;
    }

    #p4.accordion-item .intro {
        margin-left: -10px;
    }

    #p4.accordion-item-open .intro {
        margin-left: 20px;
    }

    /*#p4.accordion-item-open .intro {
        margin-left: 20px;
        position: absolute!important;
        bottom: 30px;
    }*/
    #p4.accordion-item-open .intro {
        margin-left: 20px;
        margin-top: -60px;
        margin-bottom: 30px;
        max-width: calc(100% - 160px);
    }

    @media only screen and (max-width: 600px) {
        #p4.accordion-item-open .intro {
            margin-top: -20px;
            max-width: inherit;
        }
    }

    .invalid #p4 .intro {
        color: #BE1E2D; /*red*/
        position: relative;
    }

    .invalid #p4.accordion-item .intro:before {
        content: "\26A0"; /*triangle*/
        vertical-align: middle;
        margin-right: 0.5em;
        text-decoration: none !important;
        display: inline-block;
    }

    .invalid #p4 .datapagelastintro {
        cursor: pointer;
        text-decoration: underline;
    }

    .invalid #p4 .datapagelastintro:hover {
        color: #439fbd !important;
    }

    .invalid #p4.accordion-item .datapagelastintro em {
        border-bottom: 1px solid #BE1E2D;
    }

    .incomplete #p4 .intro {
        color: #F7941E; /*orange*/
        position: relative;
    }

    .incomplete #p4.accordion-item .intro:before {
        content: "\2718"; /*cross*/
        vertical-align: middle;
        margin-right: 0.5em;
        text-decoration: none !important;
        display: inline-block;
    }

    .incomplete #p4 .datapagelastintro {
        cursor: pointer;
        text-decoration: underline;
    }

    .incomplete #p4 .datapagelastintro:hover {
        color: #439fbd !important;
    }

    .incomplete #p4.accordion-item .datapagelastintro em {
        border-bottom: 1px solid #F7941E;
    }

    .incomplete #p4.accordion-item .datapagelastintro em {
        border-bottom: 1px solid #F7941E;
    }


    .complete #p4 .intro {
        color: #74bd43; /*green*/
        position: relative;
    }

    .complete #p4.accordion-item .intro:before {
        content: "\2714"; /*tick*/
        vertical-align: middle;
        margin-right: 0.5em;
        text-decoration: none !important;
        display: inline-block;
    }

    &.jsonForm ul.jf-page-control-title li a {
        font-weight: 100;
    }

    &.jsonForm #wrapper-for-i1-1-7 {
        margin-bottom: 10px;
    }

    &.jsonForm label[for=theftAndMaliciousDamageExcludedOvernight],
    &.jsonForm label[for=buyonlinenotavailable],
    &.jsonForm label[for=buyonlinenotavailableduetovehicle],
    &.jsonForm label[for=valueOver7000] {
        background: rgba(247, 148, 30, 0.1);
        padding: 10px 13px;
        border-radius: 0px;
    }

    &.jsonForm label[for=theftAndMaliciousDamageExcludedOvernight] strong,
    &.jsonForm label[for=buyonlinenotavailable] strong,
    &.jsonForm label[for=buyonlinenotavailableduetovehicle] strong,
    &.jsonForm label[for=valueOver7000] {
        font-weight: 600;
    }

    &.jsonForm .jf-row > label {
        max-width: 100%;
    }

    .readonlyCustomNotice {
        position: relative;
        vertical-align: middle;
        height: inherit;
        padding: 20px;
        background-color: aliceblue;
    }

    .readonlyCustomNotice a + a {
        border: none;
        padding: 0;
    }

    @media only screen and (max-width: 768px) {
        .jf-page-control {
            visibility: hidden;
        }

        &.jsonForm section.accordion-item h2 {
            text-indent: 8px;
        }
        &.jsonForm section.accordion-item h2 .icon-open-close {
            right: 16px;
        }
	
		.page-container .block.empty-right-column {
			display: none;
		}
    }
	
	@media only screen and (max-width: 1030px) {
		.page-container .block.empty-right-column {
			display: none;
		}
	}
}