.underwriter{
  div.notify-block{
    .notification{
      .content{
        flex-wrap: nowrap;
        justify-content: space-between;
        .main-notification{
          padding: 12px 0 12px 17%;
          max-width: 294px;
          .notification-title{
            font-family: 'Gotham A',"Gotham B", Helvetica, Arial, sans-serif;
            font-size: 13px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.46;
            letter-spacing: normal;
            color: $white;
          }

          .notification-content{
            font-family: 'Gotham A',"Gotham B", Helvetica, Arial, sans-serif;
            font-size: 13px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.46;
            letter-spacing: normal;
            color: $white;
          }
        }

        .delete-notification{
          .delete{
            width: 12px;
            height: 12px;
            object-fit: contain;
            color: #9e9c9e;
          }
        }
      }

      &::after{
        height: 1px;
        background: #777677;
        position: absolute;
        left: 19%;
        display: block;
        content: '';
        width: 77%;
      }

    }

    .notification:last-child{
      &::after{
        display: none;
      }
    }
  }
}