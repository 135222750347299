/* dialog-polyfill */
dialog {
	position: absolute;
	left: 0; right: 0;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: -webkit-fit-content;
	height: fit-content;
	margin: auto;
	border: solid;
	padding: 1em;
	background: white;
	color: black;
	display: block;
}

dialog:not([open]) {
	display: none;
}

dialog + .backdrop {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background: rgba(0,0,0,0.1);
}

._dialog_overlay {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
}

dialog.fixed {
	position: fixed;
	top: 50%;
	transform: translate(0, -50%);
}





/* native dialog overrides */
body dialog {
	border: none;
	border-radius: 0;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	position: fixed;
/*	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);*/
	max-width:90%;
	min-width:50%;
	/**/
	top:0;
	bottom:0;
	margin:auto;
	padding: 0;
	overflow: initial;
}

//body.pf-admin.insurances-edit  #wizardModal{
//	min-width: fit-content;
//}

body dialog main {
	padding: 20px;
	max-height: 90vh;
	overflow-y: auto;
	min-height: 33vh;
}
body dialog button {
	max-width: none;
	display: block;
}
body dialog button.close {
	max-width: none;
	display: block;
	position: absolute;
	bottom: 100%;
	right: 0;
	width: 36px;
	height: 36px;
	margin: 0 -18px -18px 0;
	padding: 10px;
	overflow: hidden;
	background: red;
	border: none;
	text-overflow: ellipsis;
	border-radius: 50%;
	font-size: 16px;
	line-height: 16px;
	font-weight: bold;
	text-align: center;
}
body dialog button.close:hover {
	background-color: #00a9cc;
}
/* native */
body dialog::backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
}
/* polyfilled */
body dialog + .backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
}

body dialog main {
	padding: 40px;
	font-size:0.9em;
	font-weight:200;
}

body dialog main h1 {
	margin-top: 0;
	border-bottom: 1px solid #333;
}
body dialog main h1, body dialog main h2 {
	font-weight:200;
}
body dialog main h3, body dialog main h4 {
	font-weight:400;
}