#icon-logo_mobility {
    .one{
        fill: #87756A;
    }
    .two{
        fill: #8A786D;
    }
    .three{
        fill: #09357A;
    }
    .four{
        fill: #92C020;
    }
}

.icon-logo_mobility svg{
    width: 136px!important;
    height: 85px!important;

    @include media("<=phoneland") {
        width: 90px!important;
        height: 56px!important;        
    }
}

.logo.logo_performance{
    line-height: 1px;
    svg{
        fill: $white;
        height: 63px/*!important*/;
        width: 83px/*!important*/;
        stroke: none;
    }
}

.icon-logo_accountants{
	 fill: $white;
	 stroke: $white;
 }

.site-medica{

    .logo {
        max-width:none!important;
        flex-basis:200px;
    }
}

.logo.logo_medica{
    //width: 136px;
    //height: 85px;
    padding:16px 0;

    a img{
        width: 184px!important;
        height: 43px!important;
    }
}

.logo.logo_oasis{
    padding:16px 0;

    a img{
        width: 112px!important;
        height: 63px!important;
    }
}

.logo.logo_recruitment{
    padding:16px 0;

    a img{
        width: 83px!important;
        height: 63px!important;
    }
	svg{
		fill: $white;
		height: 63px!important;
		width: 83px!important;
	}
	
    @include media("<phoneland") {
        padding: 0;
    }
}

.logo.logo_globalfi{
	padding:10px 0;

	a img{
		height: 72px !important;
		width: 115px !important;
	}
	svg{
		fill: $white;
		height: 72px !important;
		width: 115px !important;
	}
	
	@include media("<phoneland") {
		padding: 0;
	}
}

.logo.logo_construction{
    padding:10px 0;

    a img{
        height: 72px !important;
        width: 115px !important;
    }
    svg{
        fill: $white;
        height: 72px !important;
        width: 115px !important;
    }

    @include media("<phoneland") {
        padding: 0;
    }
}

.logo.logo_privateclients{
    padding:10px 0;
    a {
        display: flex;
    }
    a img{
        height: 72px !important;
        width: 115px !important;
    }
    svg{
        fill: $white;
        height: 56px !important;
        width: 98px !important;
    }

    @include media("<phoneland") {
        padding: 0;
    }
}


.header .logo {
    position: relative;
    max-width: 215px;
    padding-right: 41px;
    background-color: map_get($header, logoBg);
    flex: 1 1 auto;
    z-index: 1;

    text-align: left;

    @if ($site == lsos) {
        max-width: 355px;
        min-width: 350px;
    }

    //@if ($site == norway) {
    //    flex: 0 0 auto;
    //
    //    @include media(">=1200px") {/* was >=1280px */
    //        max-width: 350px;
    //        min-width: 300px;
    //    }
    //}

    @if ($site == recruitment) {
        flex: 0 0 auto;

        @include media(">=1280px") {
            max-width: 350px;
            min-width: 300px;
        }
    }

    //&.logo_norway {
    //    display: flex;
    //    align-items: center;
    //    max-width: none;
    //
    //    &::after {
    //        display: none;
    //    }
    //}


    @include media("<1200px") {
        max-width: 138px;
        @if ($site == lsos) {
            max-width: 300px;
            min-width: 298px;
        }
        @if ($site == norway) {
            max-width: 275px;
        }

        @if ($site == mobility) {
            padding: 0;
        }
    }

    @include media("<=desktop") {
        @if ($site == lsos) {
            padding: 20px 32px 26px 0;
            max-width: 300px;
        }

        @if ($site == mobility) {
            padding: 0;
        }
    }

    //@include media("<=wide-tab") {
    //    -ms-flex: none;
    //    -webkit-flex: none;
    //    flex: none;
    //    display: block;
    //}

    @include media("<tabport") {
        &.logo_norway {
            padding-right: 0;
        }
    }

    @include media("<=phoneland") {
        //padding: 8px 52px 9px 0;
        padding-right:22px;
        @include media('<=350px') {
            padding-right:0px;
        }

        @if ($site == lsos) {
            max-width: 240px;
            min-width: 230px;
            //@include media("<=450px") {
            //    min-width: 0px;
            //}
        }
    }

    a {
        display: inline-block;

        img, svg {
            width: 112px;
            height: 54px;

            @if($site == privateclient){
                width: 97px;
                height: 74px;
            }

            @if($site == accountants){
                width: 97px;
                height: 74px;
            }

            @include media("<1200px") {
                width: 97px;
                height: 48px;

                @if($site == accountants){
                    width: 97px;
                    height: 74px;
                }

            }
            @include media("<=phoneland") {
                width: 78px;
                height: 38px;

                @if($site == accountants){
                    width: 63px;
                    height: 48px;
                }
            }
        }
    }

    &::after {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2500px;
        margin-left: -2500px;
        content: "";
        background-color: map_get($header, logoBg);
        z-index: 1;
    }
}

.site-lsos .logo{
    max-width: 345px;
}

.logo_lsos { // @FIXME needs to be secondary logo
    display: inline-block;
    margin-left: 35px;
    vertical-align: top;

    @include media('<=desktop') {
        margin-left: 20px;
    }

    @include media('<=phoneland') {
        margin-left: 16px;
    }
    @include media('<=350px') {
        margin-left: 8px;
    }
    img {
        min-width: 97px;
        max-width: 154px;
        height: auto;
		max-height: 62px;
        @include media('<=1200px') {
            width: 120px;
			max-height: 49px;
        }
        @include media("<=desktop") {
            width: 120px;
			max-height: 49px;
        }
        @include media('<=phoneland') {
            width: 97px;
			max-height: 39px;
        }
    }
}

.logo_norwaybar { // @FIXME needs to be secondary logo
    display: inline-block;
    //margin-left: 35px;
    vertical-align: top;

    //@include media('<=desktop') {
    //    margin-left: 20px;
    //}
    //@include media('<tabport') {
    //    margin-left: 16px;
    //}
    //@include media('<=420px') {
    //    display: none;
    //    //margin-left: 8px;
    //}
    img {
        max-width: 154px;
        height: auto;
        vertical-align: middle;
        @include media('>1200px') {
            min-width: 128px;
        }
        @include media('<=1200px') {
            width: 120px;
        }
        @include media('<tabport') {
            width: 105px;
        }
        @include media('<=phoneland') {
            width: 95px;
        }
    }
}

.pf-dashboard, .pf-form_flex {

    //.header-alert .alert-num {
    //    font-size: 12px;
    //    line-height: 20px;
    //
    //    position: relative;
    //
    //    display: inline-block;
    //
    //    box-sizing: border-box;
    //    width: 20px;
    //    height: 20px;
    //
    //    text-align: center;
    //
    //    color: #FFFFFF;
    //    border-radius: 50%;
    //    background: $a_accent;
    //}
    .lsoslogo {
        display: none !important;

        margin-left: 10px;
    }
    .lsos .lsoslogo {
        display: inline-block !important;
    }
}

.header .bounds .logoRecruitment a img {
    width: 83px!important;
    height: 63px!important;
}

