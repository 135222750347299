.dashboard-contact{
    .main-section{
        background-color: $alabaster;
        position: relative;
    }
    .main-section:after {
        background-color: $alabaster;
        position: absolute;
        display: block;
        top: 0px;
        left: -2500px;
        right: -2500px;
        content: '';
        height: 100%;
        z-index: -1;
    }

    .contact-sidebar{
        padding: 0px;
    }

    .stickyFootMain .contact-widget{
        padding: 32px;
        background-color: $blue-nurse;

        height: 100%;
        .office-info{
            margin-bottom:4em;
        }
        .address{
            @include font("body", 15px, $alabaster, 300, 1.57);
            padding: 12px 0 0 0;
        }
        .phone-no{
            @include font("body", 15px, $aqua-blue, 500, 1.6);
            a{
                color: $accent-primary;
            }
        }
    }
    .chosen-container {
        max-width: 100% !important;
    }
    .jsonForm > section {
        padding: 0;
    }
    .jsonForm > section:last-of-type {
        padding: 0;
    }
    .title-section {
        margin: 0;
    }
    .jsonForm textarea {
        height: 12em;
    }
}