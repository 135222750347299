.onpage-mp-contents {
    .mp-section.mp-section_index {
        background-color: transparent!important;
        .mp-content-wrap .block .inner-wrap {
            min-height: 96px;
            .text {
                font-size: 15px;
                font-weight: 300;
                line-height: 1.24;

                color: $greyish-brown;
                margin-top:0;
            }
            .icon {
                margin-right: 10px;
                &::before {
                    font-size: 13.5px;
                    line-height: 13.5px;

                    top: 6px;
                }
            }
            svg {
                width: 25px;
                height: 25px;
            }
        }
    }
}

// .js .master-policy {
//     .faq-section{
//         display:none;
//     }
//     .modal-content{
//         .faq-section{
//             display:block;
//         }
//     }
// }
