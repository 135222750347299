
// Required stytes

.jsonForm {
    *:required,
    .linkedFormWrapper fieldset *:required {
        border: 1px solid $border-color-req;
        box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-req;
    }
    [data-answered=true]  {
        &:required:valid,
        &.valid:required  {
            box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-val;
        }
        /*&:required:invalid, (highlight regardless of whether required) */
        &:invalid,
        &.error:required {
            color: $el-color-err;
            border-color: $border-color-err;
            box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-err;
        }
    }
    .linkedFormWrapper {
        fieldset {
            *:required {
                border: 1px solid $border-color-req;
            }
            &[data-hasanswers=true] {
                *:required {
                    box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-req;
                }
                [data-answered=true]:required:valid,
                [data-answered=true].valid:required  {
                    box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-val;
                }
                /*[data-answered=true]:required:invalid, (highlight regardless of whether required) */
                [data-answered=true]:invalid,
                [data-answered=true].error:required {
                    color: $el-color-err;
                    border-color: $border-color-err;
                    box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-err;
                }
            }
        }
    }

    //ERROR styles

    label.error {
        position: absolute;
        display: block;
        flex: none;
        left: 0px;
        right: auto;
        bottom: 47px;
        //bottom: calc(100% + 10px);
        min-width: 150px;
        max-height: 0%;
        min-height: 0;
        margin: 0;
        padding: 0;
        font-size: 0px;
        line-height: $tooltip-lh;
        font-weight: $tooltip-fw;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        opacity: 0;
        color: $tooltip-color-err;
        background: $tooltip-bg-err url("/img/source/ic-warning.svg") no-repeat 11px 12px;
        border-radius: 0;
        box-shadow: $tooltip-shadow;
        transition: opacity .25s ease-out;
        cursor: help;
        z-index: 99999;
		border: none;

        // Error tooltip

        &::before {
            position: absolute;
            display: block;
            content: "";
            top: 100%;
            left: 20px;
            width: 0px;
            height: 0px;
            border: 12px solid transparent;
            border-top: 10px solid $tooltip-bg-err;
            border-bottom: none;
        }

        /*
        &::after {
            position: absolute;
            display: block;
            content: "";
            top: 12px;
            left: 11px;
            width: 22px;
            height: 19px;
            //background: url("/img/source/ic-warning.svg") no-repeat center;  
            background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2223%22%20height%3D%2220%22%20viewBox%3D%220%200%2023%2020%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M11.22.043L0%2019.97h22.44L11.22.043zm0%203.644l8.228%2014.612H2.993L11.22%203.687z%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M10.325%208.119h1.792v5.515h-1.792zM10.325%2015.056h1.792v1.851h-1.792z%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E') no-repeat center;  
            background-size: cover;      
        }
        */
    }


    *:focus + label.error,
    .collection:hover ~ label.error,
    .collection label:hover ~ label.error,
	.collection label:focus-within ~ label.error,
    * + label.error:hover,
    .focusfade + label.error,
    .onoffswitch:hover ~ label.error {
        display: block !important;
        max-width: 100%;
        //max-height: 100%;
        max-height: none;
        min-height: 43px;
        padding: 13px 13px 16px 45px!important;//Horrible I know, but it's being override and looks awful
        font-size: $tooltip-fs;
        overflow: visible;
        opacity: 1;
        white-space: normal;
    }

    /* overwrite js calc'ed width if its in a collection as these are full side anyway) */
    .collection ~ label.error[style],
    .collection label.error[style] {
        width: auto !important;
        //padding: 0 10px !important;
    }

    .collection ~ label.error,
    .collection label.error,
    td label.error {
        margin-bottom: 0 !important;
    }

    .valid + label.error,
    :valid + label.error {
        display: none !important; /* this wont work because jquery.validate sets display:block inline and doesnt set.valid until on blur */
        visibility: hidden !important; /* use this instead */
        opacity: 0 !important;
    }
    // End error tooltip


    @include media('<=desktop') {
        *:required,
        .linkedFormWrapper fieldset *:required {
            //border-left: 4px solid $line-color-req;
            //box-shadow: none;
        }
        [data-answered=true]  {
            &:required:valid,
            &.valid:required  {
                //border-left: 4px solid $line-color-val;
                //box-shadow: none;
            }
            &:required:invalid,
            &.error:required {
                //border-left: 4px solid $line-color-err;
                //box-shadow: none;
            }
        }
        .linkedFormWrapper {
            fieldset {
                &[data-hasanswers=true] {
                    *:required {
                        //border-left: 4px solid $line-color-req;
                        //box-shadow: none;
                    }
                    [data-answered=true]:required:valid,
                    [data-answered=true].valid:required  {
                        //border-left: 4px solid $line-color-val;
                        //box-shadow: none;
                    }
                    [data-answered=true]:required:invalid,
                    [data-answered=true].error:required {
                        //border-left: 4px solid $line-color-err;
                        //box-shadow: none;
                    }
                }
            }
        }
    }
}
