// Table flow
.pf-admin, .pf-dashboard{
/*-------------------------------------------------------*/
/*- 767 Below Tab Portrait                              -*/
/*-------------------------------------------------------*/
    @media only screen and (max-width: 767px) {
        /*Table Flow*/

        .table-flow thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        .dashboard .table-flow > tbody > tr > td,
        .table-flow > tbody > tr > td {
            position: relative;

            display: block;

            width: 100%;
            padding-left: 30%!important;

            border: none;
            border-bottom: 1px solid #CCCCCC;

            text-align:right!important;

        }

        .table-flow > tbody > tr > td:before {
            position: absolute;
            left: 6px;

            width: 30%;
            padding-right: 10px;

            content: attr(data-label);
            text-align: left;
            white-space: nowrap;
        }

        .table-flow > tfoot > tr {
            font-size: 0;
        }
        .table-flow > tfoot > tr > td {
            font-size: 15px;

            display: inline-block;

            width: 50%;
        }

        .table-flow .full-width + td {
            width: 0;
            padding: 0;
        }

        .table-flow > tfoot > tr > td.full-width {
            width: 100%;
        }

        .table-flow > tfoot > tr > td:last-of-type,
        .table-flow > tfoot > tr > td.space {
            width: 0;
            padding: 0;
        }
    }
	
}

.pf-dashboard{
   html:not(.ie9) .doc-table .table-flow a.downloads .svg-icon {
		@include media("<=tabport") {
			left: 0;

			margin-left: 0;
	   	
		}
	}
}