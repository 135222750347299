// This is to make jsonform see through until it's ready
.jsonForm.jsonFormOutermostInstance{
    position: relative;
    margin: 0;
    opacity: 0.3;
    transition: opacity .25s;
    
    // Up opacity when ready
    &.ready {
        &, .jsonForm {
            opacity: 1;
        }
    }
}

.jsonForm.ready .jf-header-section,
body > form.jsonForm.ready > section > fieldset {
    max-height: inherit; 
    overflow: visible;
}
// end ready

// This is just bounds - this should be replaced by stunnPlate bounds/flex-parent etc
.jsonForm .jf-header-bounds,
.jsonForm > section > header > h2,
.jsonForm > section > .intro,
.jsonForm > section > fieldset,
.jsonForm > section > .not-applicable {
    max-width: $stunnPlate-bounds;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $stunnPlate-h-padding;
    padding-right: $stunnPlate-h-padding;

    @include media('<=phoneland') {
        padding-left: $stunnPlate-h-padding/2;
        padding-right: $stunnPlate-h-padding/2;
    }
}

.jsonForm>section>.not-applicable{
    display:block;
    margin-top:.5em;
    margin-bottom:2em;
    max-width: 1268px;
}

// THESE STYLES ARE FOR THE FIRST SECTION - this should be done in a better way if possible (ie using container instead of the first <section>
// Padding top is to account for the fixed header at the top
//#jf_jf1 {
//    padding-top: 203px;
//}
//
//.readonlyMode form#jf_jf1 {
//    padding-top: 227px;
//}

.readonlyMode form#jf_jf1 input,
.readonlyMode form#jf_jf1 label {
    pointer-events: none;
}

//@media only screen and (max-width: 850px) {
//    #jf_jf1 {
//        padding-top: 240px;
//    }
//}

/* a very specific fix needed for the endorsement add json form when shown in a modal and where the only visible fields are chosen.js elems */
@media only screen and (min-width: 850px) {
    #modal-ajax .jsonForm {
        min-width: 438px;
    }
}

/* fix propform whitespace after footer */
body.pf-form>form[target^="uploadFrame"], body.pf-form>iframe {
	position: absolute;
}