section.notification-bar {
    padding-left: 20%;

    .align-left, .align-right {
        line-height: 21px;
        color: $white;

        .notification-icon {
            display: none;
        }
        .notification-counter-rounded {
            display: none;
        }

        .notification-message {
            display: inline-block;
            font-size: 14px;
            //padding-left:20%;
            @include media("<=wide-tab") {
                font-size: 13px;
                line-height: 1.46;
            }
        }
        .go-to-my-lockton {
            display: block;
            font-size: 10px;
            line-height: 22px;
            //text-transform: uppercase;
            color: $white;

            span {
                padding: 0 2px 0 5px;
            }

            &:hover, &:active {
                color: $link-primary;
            }
        }

    }
}