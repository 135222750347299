//.insurances-edit .jsonForm.stickyFootMain{z-index: 999999999;}



.member-detail-page{
  .latest-resources-section{
    background-color: $alabaster;
  }
}


//TO BE REMOVE ONCE CORP SKIN IS FINISHED
.svg-icon{
  width: 30px;
  height: 30px;
  fill: white;
  margin: 22px 0px 0px 24px;
}

// This is a hacky fix for E&W main form question 6.1
#f6aa{
  margin-bottom:-40px;
}


#home .menuitem_home {//home menu item hidden if on home page
	display:none
}


.site-locktonperformance .banner-widget,
.site-locktonrecruitment{
	/*top: 3em;*/
	.request-a-quote-form-wrap .jf-row.compressed {
		margin: 2em 0 0 0;
	}
}
.toolkittopic-page .page-header .ajax-form > .flex-parent > .block{
    flex-basis:100%;

}

.toolkittopic-page #text-search-container:after{
    width:200vh;
}

.toolkittopic-page .search-results{
    margin-top: 32px;
}

.pf-admin .jsonForm fieldset:first-of-type{
    margin-top:0;
}

.indicator-yes,
.indicator-no{
    position:relative;
    &:after{
        position:absolute;
        opacity:1;
        content:'';
        margin-top:-6px;
        //top:50%;
        //left:50%;
        //margin-left:-50%;
        width:12px;
        height:12px;
        background: $mid-grey;
        border-radius:50%
    }
}
.indicator-yes:after{
    background: $success-color;
}

.chosen-style + .chosen-container-single .chosen-single div b{
    background:none!important;
}

body .chosen-container-single .chosen-single div b {
    /* we're using our own 'icon' (see :before and :after below) */
    background-image: none!important;
    width: 7px!important;
    transform-origin: center;
}
body .chosen-container-single .chosen-single .chosen-with-drop div b {
    transform:rotate(180deg);
}
.chosen-container-single .chosen-single div b:before,
.chosen-container-single .chosen-single div b:after{
    content: '';
    display: block;
    background: $brown-grey;
    width: 3px;
    height: 8px;
    position: absolute;
    top: 50%;
    margin-top: -3.6px;
    /*margin-left: -8px;*/
}

.chosen-container .chosen-single div b {

}

.chosen-container-single .chosen-single div b:before{
    transform:rotate(-55deg);
    transform-origin: center;
}

.chosen-container-single .chosen-single div b:after{
    transform:rotate(55deg);
    left:4px;
}

#modal-claims.active .modal-contentWrap{
	height:90%;
	width:90%;
}

/* fix to policy create > staff row (not a problem in professions theme) */
.pf-create .jsonForm fieldset[data-layout-style=rows] {
	margin-block-start: 8px !important;
	
	.jf-row {
		flex-basis: 33%;
		
		label:not(.error) {
			text-transform: none;
			font-size: 12px;
			padding-bottom: 5px;
		}
	}
	
	.jf-row > .collection > label:not(.error) {
		margin-left: 0;
		margin-bottom: 0;
	}
	
	#wrapper-for-PrimaryContact label {
		font-size: 12px;
	}
}

#recent-accessed-clients tr td:last-of-type {
    text-align: right;
}

.icon-wizard{
    height: 30px;
    width: 30px;
}