/*initial layout*/

.pf-admin {

    // Webshim datepicker fixes (above overrides them)
    .ws-popover button,
    button.ws-popover-opener {
        font-weight: 600;

        display: block;

        margin-top: 0;

        color: #222222;
        border: 0 none;
        background: transparent;
    }
    button.ws-popover-opener {
        width: 19px;
        padding: 0;
    }
    &.pf-create .ws-date {
        width: 100%;
    }
    
    header.pageHead {
        margin-bottom: 20px;
        padding: 10px 0;

        border: 0;
        background: #EEEEEE;
    }
    header.pageHead h2 {
        margin: 0;
    }

    /* Media Queries */

    /*-------------------------------------------------------*/
    /*- 1024 Tab Landscape                                  -*/
    /*-------------------------------------------------------*/
    @media only screen and (max-width: 1024px) {
        .chart {
            width: 200px;
            height: 200px;
        }
        .doughnutSummaryTitle {
            font-size: 14px;

            margin: -10px 0 0 -41px;
        }
    }
    @media only screen and (max-width: 850px) {
        .chart {
            width: 500px;
            height: 500px;
        }
        .doughnutSummaryTitle {
            font-size: 18px;

            margin: 0 0 0 -20px;
        }
    }

    .icon-date-time{
        width: 16px;
        height: 16px;
    }

    .date-time-box{
        padding: 0;
        flex-wrap: nowrap;
    }

    .red-lights{
        background-color: #f01731;
    }

    .green-lights{
        background-color: #86c347;
    }

    .grey-lights{
        background-color: #868686;
    }

    .rounded-img{
        border-radius: 59px;
        width: 59px;
        height: 59px;
    }

    .pound-square{
        width: 26px;
        height: 26px;
        background-color: $white;
        position: relative;
        top: -2px;
    }

    .pound-square-disable{
        width: 26px;
        height: 26px;
        background-color: $light-grey-eight;
        position: relative;
        top: -2px;
    }

    .pound-square-green{
        width: 26px;
        height: 26px;
        background-color: #86c347;
        position: relative;
        top: -2px;
    }

    .icon-payment{
        fill: #c8c9ca !important;
        padding: 4px;
        position: relative;
        left: 2px;
        width:26px;
        height:26px;
        cursor: pointer;
    }

    .icon-payment-done{
        padding: 6px;
        position: relative;
        left: 1px;
        width:26px;
        height:26px;
        top: 2px;
    }

    .edit-policy-shell{
        position: relative;
        top: -3px;
    }

    .icon-edit-shell{
        fill: $aqua-blue !important;
        height: 26px;
        width: 26px;
    }

    .pic-align{
        text-align: center;
    }

    .tabbed>section>div>div {
        padding: 0;
    }

    .payment-border-green{
        border-bottom: 4px solid #86c347;
    }

    .payment-border-red{
        border-bottom: 4px solid #f01731;
    }

    .payment-border-orange{
        border-bottom: 4px solid #f28619;
    }

    .payment-border-grey{
        border-bottom: 4px solid #868686;
    }
  
}

#datatable-policies-workflow{
    //th:nth-child(6),
    //th:nth-child(7),
    //th:nth-child(8),
    //th:nth-child(9),
    //th:nth-child(10),
    //th:nth-child(11),
    //th:nth-child(12),
    //th:nth-child(13),
    //th:nth-child(14),
    //td:nth-child(6),
    //td:nth-child(7),
    //td:nth-child(8),
    //td:nth-child(9),
    //td:nth-child(10),
    //td:nth-child(11),
    //td:nth-child(12),
    //td:nth-child(13),
    //td:nth-child(14) {
    //    min-width: 50px;
    //    max-width: 50px;
    //    padding-left: 4px;
    //    padding-right: 4px;
    //    text-align: center;
    //}

    &.dataTable tbody td{
        padding: 4px 22px;
    }



    .tooltip {
        position: relative;
        display: inline-block;
        //border-bottom: 1px dotted black;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        color: black;
        text-align: center;
        border-radius: 6px;
        padding: 10px 0;
        position: absolute;
        z-index: 1;
        top: 31px;
        left: 50%;
        margin-left: -25px;

        width: 165px;
        //height: 133px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;

        span {
            position: relative;
            top: 10px;
        }

        .done {
            width: 142px;
            height: 32px;
            background-color: #86c347;
            margin-top: 20px;
            text-align: center;
            color: $white;
            @include font('body', 13px, $white, 500,  1.14);
            position: relative;
            left: 11px;
            padding: 9px;
            cursor: pointer;

        }

        .ntu {
            width: 142px;
            height: 32px;
            background-color: #868686;
            margin-top: 10px;
            text-align: center;
            color: $white;
            @include font('body', 13px, $white, 500,  1.14);
            position: relative;
            left: 11px;
            padding: 9px;
            cursor: pointer;
        }
    }

    .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left:  -67px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #ffffff transparent;
    }

    .tooltip-head {
        position: relative;
        display: inline-block;
        //border-bottom: 1px dotted black;
    }

    .tooltip-head .tooltiptext-head {
        visibility: hidden;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: 37px;
        left: 58px;
        margin-left: -103px;

        width: 165px;
        height: 42px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        text-transform: none;
        @include font('body', 13px, $black, 300,  1.14);

        span {
            position: relative;
            top: 10px;
        }
    }

    .tooltip-head .tooltiptext-head::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -63px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #ffffff transparent;
    }

    th:hover .tooltiptext-head{
        visibility: visible;
    }

    /*the container must be positioned relative:*/
    .custom-select {
        position: relative;
        @include font('body', 13px, $black, 300,  1.14);
        margin: 0px -22px;
    }

    .custom-select select {
        display: none; /*hide original SELECT element:*/
    }

    .select-selected {
        background-color: transparent;
        position: relative;
        top: -3px;
    }

    /*style the arrow inside the select element:*/
    .select-selected:after {
        position: absolute;
        content: "";
        top: 8px;
        right: 10px;
        width: 10px;
        height: 10px;
        border: 1px solid transparent;
        border-color: black transparent transparent black;
        transform: rotate(225deg);
    }

    /*point the arrow upwards when the select box is open (active):*/
    .select-selected.select-arrow-active:after {
        border-color: transparent transparent black black;
        top: 22px;
        transform: rotate(137deg);
    }

    /*style the items (options), including the selected item:*/
    .select-items div,.select-selected {
        @include font('body', 13px, $black, 300,  1.14);
        padding: 8px 16px;
        cursor: pointer;
        user-select: none;

    }

    .select-selected.select-arrow-active{
        background-color: $white;
        border-bottom: solid 1px #e3e3e4;
        padding: 17px 16px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);

    }

    /*style items (options):*/
    .select-items {
        position: absolute;
        background-color: $white;
        top: 97%;
        left: 0;
        right: 0;
        z-index: 99;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
        .same-as-selected{
            color: #9b9b9b;
            pointer-events: none;
        }

    }

    /*hide the items when the select box is closed:*/
    .select-hide {
        display: none;
    }

    .select-items div:hover, .same-as-selected {
        background-color: rgba(0, 0, 0, 0.1);
    }

    #nested-table{
        padding: 0;
        margin: 0 -22px;
        table {
            border-collapse: collapse;
            tbody, tr, td {
                border: none;
                border-bottom: 1px solid #edeeee;
                @include font('body', 14px, #20262a, 300,  1.21);
            }

            tr{
                height: 100px;
            }

            svg{
                fill: #01bee5;
            }
        }

        table:after{
            display: none;
        }
    }
}

#modal-payment {
    .modal-content{
        .alert-dialog{
            font-family: sans-serif;
            position: fixed;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #222222;
            background-color: rgba(0,0,0,.75);
            text-align: center;
            z-index: 99999999;
            form {
                text-align: left;
                opacity: 1!important;
            }
            .dialog-box{
                background-color: #ffffff;
                width: 40%;
                min-width: 400px;
                max-width: 80%;
                margin: auto;
                display: inline-block;
                vertical-align: middle;
                max-height: 100%;
                overflow: auto;

                .dialog-title{
                    background: #edeeee;
                    padding: 20px 40px;
                    font-family: AGaramondPro;
                    font-size: 30px;
                    text-align: left;
                    color: #060708;
                    line-height: 68px;
                    vertical-align: middle;
                }

                .dialog-message {
                    margin: 0;
                    padding: 30px;
                    text-align: center;

                    .intro {
                        display: inline-block;
                        margin: 0 0 5px;
                        padding: 0;
                        font-family: 'Gotham A',"Gotham B", Helvetica, Arial, sans-serif;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                    }
                }

                .dialog-button-row {
                    background: none repeat scroll 0 0 rgba(100,100,100,0.1);
                    background-color: #212f38;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: row-reverse;
                    flex-direction: row-reverse;
                    padding-right: 40px;

                    a {
                        background: #01bee5;
                        color: rgb(255, 255, 255);
                        padding: 10px;
                        display: inline-block;
                        margin: 15px 0 15px 15px;
                        text-decoration: none;
                        min-width: 150px;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .alert-dialog:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -0.25em;
        }

    }

    .subStatusField{
        padding-bottom: 10px;
    }
}

td.deal-stage {
    text-transform: capitalize;
    position: relative;
    white-space: nowrap;

    min-width:500px;

    font-size: 13px;
    line-height: 1.23;
    letter-spacing: 0.2px;
    color: $black;
    text-align:center;

    @at-root .broker-dashboard &{
        min-width:0;
    }

    .substatusIndicator {
        display: flex;
        gap: 12px;
        align-items: flex-end;
        position: absolute;
        left:22px;
        right:22px;
        bottom:8px;



        @at-root .broker-dashboard & {
            bottom: 0;
            left: 0;
            right: 0;
            gap: 8px;
        }

        .substatus {
            flex: 1 1 10px;
            height: 5px;
            background: $success-color;
            position:relative;
            cursor: pointer;



            @at-root .pf-list &{
                height: 12px;

                &:after, &:before{
                    content:'';
                    display:block;
                    position:absolute;
                    top:0;
                    left:-3px;
                    right:-3px;
                    bottom: 50%;
                    background-color: inherit;
                    transform: skew(50deg);
                }
                &:after{
                    top:50%;
                    bottom:0;
                    transform: skew(-50deg);
                }
            }

            &.current ~ div, &.none, &.none ~ div {
                background: $azure;
            }

        }
    }
}
.dt-actions-item [class^="pound-square"] {
    margin-left: 1.5em;
}