/* QUICK CREATE WIZARD */
#quickCreateWizard {
	max-width: 600px;
	margin: 30px 0 40px 20px;
	counter-reset: card;
	
	.wizardCard:before {
		content: counter(card);
		display: block;
		position: absolute;
		right: 100%;
		top: 0;
		font-size: 20px;
		line-height: 1em;
		margin-right: 10px;
		color: #979797;
		transition: all 0.3s ease;
	}
	
	.wizardCard.selectorOpen::before, .wizardCard:last-of-type::before {
		font-size: 24px;
	}
	
	.wizardCard {
		position: relative;
		counter-increment: card;
		border-left: 4px solid #bbbbbb;
		margin: 0 0 15px 0;
		padding: 0 0 0 15px;
		transition: all 0.3s ease;
	}
	
	.title {
		text-transform: uppercase;
		font-size: 11px;
		line-height: 13px;
		color: #bbbbbb;
		transition: all 0.3s ease;
	}
	
	.wizardCard.selectorOpen .title, .wizardCard:last-of-type .title {
		font-size: 13px;
	}
	
	.heading {
		font-size: 1.8rem;
		line-height: 3rem;
		transition: all 1s ease-out;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 100%;
	}
	
	.heading:hover {
		cursor: pointer;
	}

	.wizardCard .form {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-top: .5em;
	}
	
	.wizardCard .form .selection {
		flex-grow: 1;
		margin-right: 10px;
	}
	
	.wizardCard .form .selection .chosen-container {
		min-width: 100%;
	}
	
	.wizardCard .form .selection .chosen-container[style] {
		/* something (chosen.js) is hardcoding the width as an inline style */
		width: 100%!important;
	}
	
	.wizardCard a[class|="wizardCard__cta"] {
		background: #01bee5;
		color: white;
		padding: 0.68rem;
		line-height: 1em;
		text-transform: uppercase;
		font-size: 0.8rem;
		font-weight: bold;
		/*margin: 6px 3px 6px 0;*/
		margin: 0;
		display: inline-block;
		vertical-align: middle;
	}
	
	.wizardCard a[class|="wizardCard__cta-text"] {
		background: none;
		color: #01bee5;
		padding-inline: 0;
	}
	
	.wizardCard a[class|="wizardCard__cta"]:hover {
		background: #888888;
	}
	
	.wizardCard a[class|="wizardCard__cta-text"]:hover {
		background: none;
		color:#333333;
	}
	
	.xxxwizardCard[data-complete="true"]:after {
		display: inline-block;
		transform: rotate(45deg);
		height: 34px;
		width: 20px;
		border: 1px solid #93be30;
		border-width: 0 8px 8px 0;
		content: " ";
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto 0 auto 20px;
		left: 100%;
	}
	
	.xxxxwizardCard[data-complete="true"]:after {
		display: inline-block;
		height: 30px;
		width: 30px;
		border: 2px solid #93be30;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto 0 auto 20px;
		left: 100%;
		content: "✓";
		content: "✔";
		content: "☑";
		font-size: 22px;
		line-height: 30px;
		text-align: center;
		color: #93be30;
		font-weight: 100;
		border-radius: 50%;
	}
	
	.wizardCard[data-complete="true"] .title:after {
		display: inline-block;
		color: #93be30;
		content: "✔";
		margin-left: .5em;
	}
	
	.wizardCard[data-required="true"] {
		border-color: #f5a623;
	}
	
	.wizardCard[data-required="true"] .title {
		color: #f5a623;
	}
	
	.wizardCard[data-complete="true"] {
		border-color: #93be30;
		opacity: .5;
	}
	
	.wizardCard[data-complete="true"] .title {
		color: #bbbbbb;
	}
	
	.wizardCard.selectorOpen,
	.wizardCard:hover {
		opacity: 1 !important; /* sorry, important required for IE11 - also, i know its duped below but IE is breaking */
		transition: all 0.3s ease;
	}
	
	.wizardCard.selectorOpen,
	.wizardCard:hover,
	.wizardCard:focus-within,
	.wizardCard:last-of-type {
		opacity: 1;
		transition: all 0.3s ease;
	}
	
	/*.wizardCard:hover .heading {
		 background-color: rgba(0,0,0,0.1);
		 transform: scale(1.5);
		font-size: 2em;
		line-height: 1.0em;
		transition: all 0.3s ease;
	}*/
	/*.wizardCard.focussed, .wizardCard:hover, .wizardCard:focus-within {
		opacity:1;
		transition: all 0.3s ease;
	}
	
	.wizardCard.focussed a[class|="wizardCard__cta"], article.wizardCard:hover a[class|="wizardCard__cta"] {
		background: #01bee5;
	}*/
	
	
	.wizardCard {
		--animation-speed: 0.3s; /* gah! css variables are not IE11 compatable */
		--i: 100;
	}
	
	.wizardCard .heading { /*reveal the heading. bounce at end */
		position: absolute;
		transform-origin: left center;
		transform: scale(1);
		opacity: 1;
		transition: all 0.3s cubic-bezier(0.35, 0, 0.75, 1.35);
	}
	
	.wizardCard.selectorOpen .heading { /*collapse the heading */
		/*font-size: 1px;
		line-height: 10px;
		opacity: 0;*/
		transform: scale(.1);
		opacity: 0;
		transition: all 0.4s cubic-bezier(0.35, 0, 0.75, 1.35);
	}
	
	.wizardCard[data-complete="true"] .form { /* collapse the form */
		transform-origin: left center;
		transform: scale(.1);
		opacity: 0;
		transition: all 0.3s cubic-bezier(0.35, 0, 0.75, 1.35);
		pointer-events: none;
	}
	
	.wizardCard[data-complete="true"].selectorOpen .form { /* reveal the form */
		transform: scale(1);
		--i: calc(var(--i) - 1);
		z-index: 2; /* needed to fix problem with chosen */
		opacity: 1;
		pointer-events: auto;
		transition: all 0.4s cubic-bezier(0.35, 0, 0.75, 1.35);
	}
	
	
	.grid-wrapper {
		display: grid;
		grid-template-columns: 100px 1fr;
		grid-gap: 0;
		background: #f4f4f4;
		padding: 10px 14px;
		font-size: 0.9rem;
		margin: 0 0 5px;
		/* margin: 0 0 5px -15px; */
	}
	
	.grid-wrapper div.foot {
		border-top: 1px solid #CCCCCC;
		padding-top: 5px;
		margin-top: 5px;
		color: #cccccc;
	}
	
	&.busy .wizardCard {
		pointer-events: none;
		cursor: wait;
	}
	
	&.busy .wizardCard.selectorOpen {
		opacity: 0.3;
	}
	
	&.busy::after {
		content: "Please wait...";
		display: block;
		margin: auto;
		position: fixed;
		font-size: 5vw;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
	}
}


/* Things inside a dialog
body dialog main == #wizardModalContent */


body dialog main .jsonForm section .intro {
	margin-top: 1em;
}

body dialog main #nope.intro a[href^="#modal:"] {
	vertical-align: middle;
	fill: #01bee5;
	/* background: #01bee50d; */
	padding: 5px 10px 5px 5px;
	border-radius: 1em;
	/* box-shadow: 0px 1px 2px #00000017 inset; */
	/* font-style: italic; */
	font-family: initial;
}

body dialog main .intro a[href^="#modal:"] svg {
	vertical-align: text-bottom;
	fill: #01bee5;
	margin-right: 4px;
}


body dialog main .jsonForm fieldset {
	margin: 0;
	padding: 0;
	max-width: inherit !important;
}

body dialog main .jsonForm .jf-input-type-submit {
	margin-top: 26px;
	display: flex;
}

body dialog main .jsonForm .jf-input-type-submit input[type="submit"] {
	transition: none;
	margin: 0 0 0 auto;
	float: none;
	min-width: 230px;
}

body dialog main .jsonForm .jf-input-type-submit input[type="submit"]:focus {
	background-image: url(/img/loadingdots.svg);
	background-repeat: no-repeat;
	background-position: center right;
	background-size: auto;
}

body dialog main .jsonForm .invisibleButSubmittable {
	height:1px;
	overflow:hidden;
	opacity:0.1;
	legend {
		display: none;
	}
}

/* weird glowing border thing */
/*
body dialog main {
    background: #ffffff;
}
body dialog main:before, body dialog main:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094,
    #0000ff, #00ff00,#ffff00, #ff0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 20s linear infinite;
}
@keyframes steam {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

body dialog main:before, body dialog main:after {
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    background: radial-gradient(#000000, #000000, #01bee5, #01bee5, #01bee5, #000000, #000000, #000000, #000000, #000000);
    background-size: 400%;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    z-index: -1;
    animation: steam 10s linear infinite;
}
body dialog main:after {
    filter: blur(30px);
    opacity: 0.5;
}
*/
/*
:root {
    --f: 1%;
}

body dialog main:before, body dialog main:after {
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    background: conic-gradient(#01bee5 var(--f, 1%), #000000 0);
    background-size: 100%;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    z-index: -1;
    transition: all 20s linear;
}
body dialog:hover main:before, body dialog:hover main:after {
    --f: 100%;
}

body dialog main:after {
    filter: blur(30px);
    opacity: 0.5;
}
*/