$invert-contacts: false;

.page-header {
    position: relative;
    z-index: 11;

    color: $a_text;

    //This block is for meta data for the page, e.g. client, id number. Should have a better name (working with what we have)
    @at-root .pf-admin .block-content {
        h2 {
            margin-top: 0;
            margin-bottom:12px;
            letter-spacing: 0.34px;
            font-size:22px;
            font-weight:bold;
            line-height: 1.45;
        }
        p{
            font-size: 14px;
            line-height: 1.14;
            letter-spacing: 0.21px;
        }
    }

    @at-root .detail-page &{
        color: $b_text;
    }
    @at-root .search-results-page &{
        color: $b_text;
    }

    //@if ($site == lsos) {
    //    padding-top: 30px;
    //}
    @if(($site == 'lsos') or ($site == 'globalfi') or ($site == 'construction') or ($site == 'privateclient')){
        padding-top: $site;
    }

    .club-badge-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .bounds > .flex-parent {
        padding-top: 0;
        padding-bottom: 0;

        .notification-block {
            flex-basis: 73%;
        }

        .contact-block {
            flex-basis: 25%;
            max-width: 300px;
            @media only screen and (max-width: 767px) {
                flex-basis: 100%;
            }
            .chosen-single,
            .contact-select {
                border: 0px solid #CCCCCC;
                padding: 0 0 0 0px;
                line-height: 34px;
                box-shadow: none;
                background:transparent;
                font-weight:400;
                &:hover {
                    line-height: 34px;
                }
            }
            a {
                @include font("body", 15px, $almost-black, 300, 1.5);

                &:hover {
                    @include font("body", 15px, $almost-black, 300, 1.5);
                }
            }
            .name {
                @include font("body", 18px, $almost-black, 500, 1.56);
            }
            .email {
                @include font("body", 15px, $a_accent, 300, 1.5);
                text-decoration: underline;
                &:hover {
                    @include font("body", 15px, $cool-blue, 300, 1.5);
                    text-decoration: underline;
                }
            }
        }

        .acc-contact-details .text-wrap {
            &.name,
            &.email,
            &.phone {
                display:block;
            }
        }

        @include media('<=phoneland') {
            display: block;

            width: 100%;
            padding-right: 13px;
            padding-left: 13px;

            flex: none;
        }
        .block {
            position: relative;

            padding-top: 48px;
            padding-bottom: 48px;

            @at-root .get-a-quote-page:not(.get-a-quote-page_withUpload) &{
                padding-bottom:inherit;
            }

            &.news-detail-image {
                @include media('<=568px') {
                    display: none;
                }
            }

            &.block-person-info {
                min-width: 450px;
            }

            &.block_image {
                min-height: 455px;
                //min-width: 450px;
                @include media("<930px") {
                    padding-bottom: 63%;
                    min-height: unset;
                }
                @include media("<=phoneland") {
                    display:none;
                }
            }

            .content-image {
                position: absolute;
                width: 100vw;
                left: 50%;
                transform: translateX(-50vw);
                top: 0;
                bottom: 0;
                background-size: cover;
                background-position: center;
            }

            @include media('<=1200px') {
                padding-top: 32px;
            }

            @include media('<=wide-tab') {
                padding-top: 20px;
                padding-bottom: 20px;
            }
            @include media('<=phoneland') {
                display: block;
                width: 100%;
                padding-bottom: 27px;
                -ms-flex: none;
                -webkit-flex: none;
                flex: none;

                &.block-resources-grid{
                    padding-left: 0;
                    padding-right: 0;
                }
                &.resources-grid{
                    & > .block{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }


            }
            .content-wrap {
                font-size: 16px;
                line-height: 20px;
                max-width: 600px;
                @include media('<=phoneland') {
                    max-width: 100%;
                }
                @at-root .pf-admin & {
                    max-width: none;
                }
                @at-root .pf-agreement &{
                    max-width: none;
                }
                &.withClubBadges {
                    @include media('<=phoneland') {
                        padding-bottom: 75px;
                    }
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: static;
                    .clubBadge {
                        display:flex;
                        align-items:center;
                        position: absolute;
                        bottom: 0;
                        //left: 200px;
                        padding: 0 130px 0 16px; /* vertical padding to hold this open is in the children */
                        background: linear-gradient(-120deg, transparent 0%, transparent 70px, #30363C 70px);
                        font-size: 12px;
                        z-index: 1;
                        //@include media('<=wide-tab') {
                        //	padding: 0 116px 0 0;
                        //}
                        //@include media('<=phoneland') {
                        //	position: relative;
                        //	width:fit-content;
                        //	margin-bottom: -27px;
                        //}
                        &::before {
                            content: '';
                            display: block;
                            background-color: #30363C;
                            top: 0;
                            height: 100%;
                            width: 2500px;
                            right: 100%;
                            position: absolute;
                        }
                        img {
                            width: 75px;
                            height: 75px;
                            margin:16px 20px 16px 0;
                            @include media('<=phoneland') {
                                width: 55px;
                                height: 55px;
                                margin: 10px 20px 10px 0;
                            }
                        }
                        span {
                            padding:0;
                        }
                    }
                }
            }
            .additional-content {
                position: relative;

                margin-top: -3px;
                padding-left: 23px;

                @include media('<=1200px') {
                    padding-right: 15px;
                }
                @include media('<wide-tab') {
                    margin-top: 0;
                    padding-right: 0;
                    padding-left: 15px;
                }
            }
            &:first-child {
                padding-right: 32px;

                @include media('<wide-tab') {
                    //padding-left: 0;
                }
                @include media('<=phoneland') {
                    padding-right: 7px;
                }

                @at-root {
                    .pf-admin &,
                    .pf-dashboard & {
                        text-align: left;
                    }
                }

                &::before {
                    position: absolute;
                    z-index: -1;
                    top: 0;

                    bottom: 0;

                    display: block;

                    //@if $site == 'norway' {
                    //    width: 200vh;
                    //    left:-50%;
                    //
                    //} @else {
                    right: 0;
                    width: 2500px;
                    //}

                    content: '';

                    background-color: $a_background;

                    @at-root .detail-page & {
                        background-color: $b_background;
                    }

                    @at-root .search-results-page &{
                        background-color: $b_background;
                    }

                    @include media('<=phoneland') {
                        right: -32px;
                    }
                }
                //@if $site == 'norway' {
                //    @at-root .detail-page &::after {
                //        content: "";
                //        display: block;
                //        position: absolute;
                //        top: calc(100% - 200px);
                //        left: calc(50% - 158px);
                //        width: 316px;
                //        height: 316px;
                //        background: $nor-lightgrey;
                //        border-radius: 0% 100% 0% 100%;
                //        clip-path: polygon(0% 130%, 130% 0%, 100% 100%, 100% 100%);
                //        transform: scale(1, 0.56) rotate(45deg);
                //    }
                //} @else{
                &::after {
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    bottom: 0;
                    left: 100%;

                    display: block;

                    width: 2500px;

                    content: '';

                    background-color: $a_background;

                    @at-root .detail-page & {
                        background-color: $b_background;
                    }

                    @at-root .search-results-page &{
                        background-color: $b_background;
                    }
                }

                //}

            }
            &:last-child {
                &.actions-block {
                    padding-left: 16px;
                    &::before {
                        display: none;
                    }
                    &::after {
                        background-color: $a_background;

                        @at-root .detail-page & {
                            background-color: $b_background;
                        }
                        @at-root .search-results-page &{
                            background-color: $b_background;
                        }
                    }
                }
            }
            & + .block {
                .content-image {
                    width: 50vw;
                    left: 0;
                    @include media("<1198px") {
                        //width: 70vw;
                        //left: -120px;
                    }

                    @include media("<932px") {
                        //width: 102vw;
                        //left: -16px;
                        //right: 0;
                        //top: 0;
                        //bottom: 0;
                    }

                    transform: none;
                }
                @include media('<1200px') {
                    padding-left: 23px;
                }
                //@include media('<tabport') {
                //    display: none;
                //}
                @include media('<=phoneland') {
                    padding-left: 8px;
                    /*padding-right: 4px;
                    padding-left: 0;*/
                    //margin:0 -13px;
                }
                &::after { // right bg
                    background-color: $a_background;

                    @at-root .detail-page & {
                        background-color: $b_background;
                    }
                    @at-root .search-results-page &{
                        background-color: $b_background;
                    }

                    left: 0;

                    @include media('<=phoneland') {
                        left: -32px;
                    }
                }

            }
        }
        .actions-block {
            text-align: right;
        }
        & > div > div {
            position: relative;
            z-index: 5;
        }
        .column-title {
            @include font("title", 48px, $a-text, 400, 1.21);

            @at-root .detail-page & {
                color: $b_text;
            }

            @at-root .search-results-page &{
                color: $b_text;
            }

            margin-top: 0;
            margin-bottom: 20px;

            display: block;

            @include media('<=phoneland') {
                font-size: 32px;
            }
        }
        .column-content {
            line-height: 1.75;
        }

        .column-content-request{
            padding-bottom: 20px;
        }
    }

}
