
.form-container {
  @if($site == performance){
    .quote-form {
      display: block;
      padding: 15px;
      position: relative;
      background-color: $corp-silver;

      fieldset {
        margin:0;
        padding: 5px;
      }
      fieldset>legend {
        font-size: 28px;
        margin: 0 0 0 15px;
        padding-top: 15px;
      }

      .form-fields-row {

        @include media(">=phoneland") {
          display: flex;
          justify-content: space-between;
        }

        .form-field {
          position: relative;
          flex-grow: 1;
          margin: 10px 15px;
          font-size: 12px;

          @include media(">=phoneland") {
            max-width: calc(50% - 30px);
            &.full-width {
              max-width: calc(100% - 30px);
            }
            &.stumpy {
              max-width: calc(25% - 30px);
            }
          }

          align-self: flex-end;
        }
      }

    }
  } @else {

    .quote-form {
      display: block;
      padding: 22px 32px 30px;
      background-color: $corp-silver;
      overflow: hidden;
      position: relative;

      @include media("<wide-tab") {
        padding: 22px 24px 38px;
      }
      @include media("<tabport") {
        padding: 16px 16px 35px;
      }

      input[type="text"], input[type="tel"], input[type="email"], select, textarea, input[type="date"] {
        width: 100%;
        padding: 0 10px;
        font-size: 16px;
        color: $greyish-brown;
        background-color: $white;
        border: solid 1px #dedddd;
        border-radius: 0;

        &:focus {
          border: 1px solid rgba(0,134,139,0.5);

          box-shadow:inset 0 0 5px rgba(0,134,139,0.3);
        }
      }
      input[type="text"], input[type="tel"], input[type="email"], select, input[type="date"] {
        height: 29px;
        line-height: 27px;
        transition: all 300ms;
      }
      textarea {
        padding: 5px 10px 10px;
        height: 169px;
        line-height: 20px;
        resize: none;

        @include media("<tabport") {
          height: 248px;
        }
      }
      label {
        display: block;
        padding: 0 30px 4px 0;
        font-size: 18px;
        line-height: 1.3;
        color: #212121;

        @include media("<=phoneland") {
          font-size: 15px;
          line-height: 24px;
        }
      }
      .form-field {
        position: relative;
        width: 48%;
        margin-bottom: 18px;

        &:nth-child(odd) {
          float: left;
          clear: left;

          @include media("<=phoneland") {
            width: 100%;
            float: none;
          }
        }
        &:nth-child(even) {
          float: right;
          clear: right;

          @include media("<=phoneland") {
            width: 100%;
            float: none;
          }
        }
        .ff-validation {
          &::after {
            display: none;
            position: absolute;
            content: '';
            top: 6px;
            right: 10px;
            width: 16px;
            height: 17px;
            font-size: 19px;
            text-align: center;
            color: $nasty-green;
            z-index: 3;
          }
        }
        input, select {
          &:required:invalid, &:required.error, &.error {
            border: solid 1px #ec1a3b;
            box-shadow:0 0 20px rgba(236,26,59,0.35);

            & ~ .ff-validation {
              &::after {
                background: url("../img/invalid-icon.svg") no-repeat center top;
              }
              &::before {
                display: block;
                content: attr(data-err);
                font-size: 16px;
                line-height: 24px;
                padding: 3px 0 0;
                color: #ec1a3b;
              }
            }
          }
          &:required:valid, &.no-error {
            & ~ .ff-validation {
              &::after {
                content: '\2713';
              }
            }
          }
        }
        input[value=""]:required:invalid, select:required.no-error, input:required.no-error, .no-error {
          border: solid 1px #dedddd;
          box-shadow:none;

          &:focus {
            border: 1px solid rgba(0,134,139,0.5);

            box-shadow:inset 0 0 5px rgba(0,134,139,0.3);
          }

          & ~ .ff-validation {
            &::before {
              display: none;
            }
            &::after {
              background: none;
            }
          }
        }
        select:required.no-error.not-selected, .no-error.not-selected {
          & ~ .ff-validation {
            &::after {
              content: '';
            }
          }
        }

        select {
          border-radius: 0;
          padding-right: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          appearance: none !important;

          &::-ms-expand {
            display: none;
          }

          background-image: url('../img/select-arrow.svg');
          background-position: 96% center;
          background-repeat: no-repeat;
        }
      }
      .form-textarea {
        clear: both;
        margin-bottom: 26px;

        @include media("<wide-tab") {
          margin-bottom: 18px;
        }
      }
      .form-submit {
        text-align: right;
      }
    }
  }

}

.get-quote-block {

  &.flex-parent {
    //padding: 0;
    justify-content: space-between;
	  margin-bottom:20px;
  }

  .block {
    background-color: #212f38;
    max-width: 640px;
    //padding: 0;
  }

  .content {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    height: 100%;

    .text {
      color: #ffffff;
      font-size: 24px;
      line-height: 1.12;
      font-family: "adobe-garamond-pro", serif;
      font-weight: normal;
      @include media("<=tabport"){
        font-size:18px;
      }
    }

    ul li {
      color: #f7f7f7;
      font-size: 14px;
      line-height: 1.71;
    }


    .line {
      width: 100%;
      height: 2px;
      opacity: 0.4;
      border: solid 1px #ffffff;
      margin-top: 25px;
      margin-bottom: 21px;
    }


    .quote-header {
      display: flex;
      align-items: center;
      margin-bottom: 5px;


      h2 {
        margin: 0;
        font-size: 34px;
        color: #ffffff;
        font-family: "adobe-garamond-pro", serif;
        padding-top: 0;
        padding-bottom: 0;

        @include media("<=tabport"){
          font-size:28px;
        }
      }
		
		svg + h2 {
			margin: 0 26px;
		}

       .icon-car,  .icon-car-plus {
        fill: #D4AF37;
      }

       .icon-home, .icon-home-plus {
        fill: #49ADAD;
      }
    }
	
	.quote-header + .line {
		  margin-top: 6px;
	  }



    .btn_a {
      margin-top: 24px;
      font-size: 14px;
      padding: 14px 20px;
    }

    .getAQuoteOption-btn {
      width: 228px;
      height: 44px;
    }

  }

}
