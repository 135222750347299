#summaryScreen {
    position: fixed;
    top: -100%;
    bottom: 100%;
    left: 0;
    right: 0;
    margin-top: 0;
    overflow: auto;
    z-index: 9999;
    background-color: #ffffff;
    overflow: auto;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    padding: 0 !important;
    .mainBlock{
        background:#f7f7f7;
        //margin-top:80px;
    }
    .sidebar{
        background-color: $dark;
        @include media("<=tabport") {
            display: flex;
        }
    }
    .block{
        @include media("<=tabport") {
            flex-basis: 100%;
        }
    }
    .flex-parent{
        @include media("<=tabport") {
            flex-direction: column-reverse;
        }
    }
}



.summary-table .na .button {
    background-color: #bbb;
    position: relative;
}

.summary-table .na .button:after {
    content: "N/A";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: 5px 10px;
    background: #bbb;
}

.jf-page-summaryscreen{
    border:0 none!important;
    a{
        border:0 none!important;
        .icon-pie-chart{
            display: block;
            margin-right: .75em;
        }
    }
}


// This is the legend for the dougnut chart in the summary screen
.doughnutChartKey {
    font-size: 0.8em;
    color: #666;
    text-align: center;
}

.doughnutChartKey span {
    color: #6D6E71;
    font-size: 2em;
    line-height: 0.5em;
    vertical-align: text-top;
}

#summaryScreen .jf-progressTable {
    flex-basis:60%;
    @include font('body', 16px, false, 400,  1.2);

    table {
        width: 100%;
        border-spacing: 1px;
        border-collapse: separate;
        tr{
            &:nth-child(odd){
                td:nth-child(2n){
                    background:#edeeee;
                }
            }
            &:nth-child(even){
                td:nth-child(2n){
                    background:#e3e3e4;
                }
            }
            @include media("<phoneland") {
                display: flex;
                flex-wrap: wrap;
            }
            td {
                padding: 20px;
                &:first-of-type {
                    background-color:$charcoal-grey;
                    //font-size: 20px;
                    font-weight: 500;
                    width: 70px;
                    padding-right: 10px;
                    color:$white;
                }
                &:nth-of-type(2n) {
                    background-color:$dark;
                }
                &:nth-of-type(3n) {
                    background-color:$dark;
                    width: 250px;
                    position: relative;
                    color:$white;
                }
                &:last-of-type {
                    width: 60px;
                    color:$white;
                    padding:0;
                }
                svg{
                    fill: $white;
                }
            }

            @include media("<phoneland") {
                td:nth-child(1) {
                    flex-basis: 20%;
                }
                td:nth-child(2) {
                    flex-basis: 80%;
                }
                td:nth-child(3) {
                    flex-basis: 80%;
                    border-top: 1px solid $white;
                    border-right: 1px solid $white;
                }
                td:nth-child(4) {
                    flex-basis: 20%;
                    border-top: 1px solid $white;
                }

                &:nth-child(odd){
                    td:nth-child(1){
                        background:#edeeee;
                        color: $warm-grey;
                    }
                }
                &:nth-child(even){
                    td:nth-child(1){
                        background:#e3e3e4;
                        color: $warm-grey;
                    }
                }
            }
        }
    }
    .button {
        @extend .btn_a;
        width:100%;
        height:100%;
    }
}