// This is from the layout.css included in the dashboard <head>
//
.pf-dashboard {
    .table {
        display: table;
        width: 100%;
    }
    .table .two-third,
    .table .one-third {
        position: relative;

        display: table-cell;

        box-sizing: border-box;

        vertical-align: top;
    }
    .table .one-third {
        width: 33.33%;
    }
    .table.summary .one-third {
        width: 425px;
    }

    //h1 {
    //    font-size: inherit;
    //    /* text-transform: uppercase; */
    //    font-size: 24px;
    //    font-weight: 400;
    //}
    /*page style*/
    body header {
        font-size: 14px;

        display: block;

        width: 100%;

        border-bottom: 1px solid $white-two;
    }

    /*table style*/
    //table {
    //    font-size: 16px;
    //}
    //table thead {
    //    font-size: 17px;
    //    line-height: 1.24;
    //    font-weight: 500;
    //}
    //table thead th {
    //    font-weight: 500;
    //}
    //table tfoot:before {
    //    display: block;
    //
    //    height: 5px;
    //
    //    content: '';
    //}
    //table tfoot tr {
    //    border: 1px solid #939598;
    //}
    //table tfoot tr td {
    //    padding: 5px 5px 5px 0;
    //}
    //table tbody {
    //    border: 0;
    //    border-bottom: 2px solid #57676C;
    //    background: $light-grey-eight;
    //}
    //table tbody tr {
    //    padding: 5px;
    //
    //    border-bottom: 1px solid $white;
    //}
    //table tbody tr td {
    //    box-sizing: border-box;
    //    padding: 5px 5px 5px 0;
    //}
    //table tbody tr:nth-of-type(odd) {
    //    background: $light-grey-seven;
    //}

    .policyDoc a {
        display: block;
    }
    .policyDoc a .svg-icon {
        margin: 0;

        fill: #0086BF;
    }
    .policyDoc.disabled a .svg-icon {
        margin: 0;

        fill: #C7C7C7;
    }
    .policyDoc.disabled a,
    .policyDoc.disabled a:hover {
        cursor: default;
    }
    .client-details {
        margin-bottom: 1px;
        padding: 16px;

        background: #EBECE6;
    }
    .client-details a {
        transition: color .3s !important;
    }
    .client-details a:hover {
        color: #049444 !important;
    }
    .client-details .title h2,
    .client-details .title h3 {
        font-size: 24px;
        font-weight: 400;

        margin: 0;
    }
    .client-details .details {
        padding: 0;
    }
    .client-details .details .img-wrap {
        display: inline-block;

        width: auto;
        max-width: 100%;
        margin-top: 10px;
    }
    .client-details .details .img-wrap img {
        width: 100%;
    }
    .client-details .details .address span {
        display: block;
    }
    .client-details .details .address span.name,
    .client-details .details .address span.role {
        display: inline-block;
    }
    .manage-details {
        position: relative;

        display: block;

        padding: 16px 0 8px 32px;

        text-transform: lowercase;

        border-top: 1px solid #FFFFFF;
    }
    .manage-details .svg-icon {
        position: absolute;
        top: 50%;
        left: 0;

        display: inline-block;

        margin-top: -6px;

        fill: #B6BCB8;
    }
    a.downloads {
        position: relative;

        display: block;

        padding: 16px 0 8px 32px;

        text-transform: lowercase;


    }
    a.downloads .svg-icon {
        position: absolute;
        top: 50%;
        left: 0;

        display: inline-block;

        height: 30px;
        margin-top: -12px;

        fill: #922545;
    }
    .doc-table a.downloads .svg-icon {
        left: 50%;

        margin-left: -12px;

        fill: #0086BF;
    }
	.doc-table a.downloads .icon-download {
		left: 50%;
		transform:translateX(-50%);
		fill: $accent-primary;
	}
	a.downloads .dlText {
        display: none;
    }
    /*Dashboard nav*/
    .dash-nav {
        border-bottom: 1px solid #E6E7E8;
    }
    .dash-nav ul {
        margin: 0;
    }
    .dash-nav ul li {
        display: inline-block;

        margin: 0 10px;
        padding: 10px 0;

        -webkit-transition: border-color .3s ease !important;
        -moz-transition: border-color .3s ease !important;
        -ms-transition: border-color .3s ease !important;
        -o-transition: border-color .3s ease !important;
        transition: border-color .3s ease !important;

        border-top: transparent 3px solid;
        border-bottom: transparent 3px solid;
    }
    .dash-nav ul li:first-of-type {
        margin-left: 0;
    }
    .dash-nav ul li.current,
    .dash-nav ul li:hover {
        border-bottom: #939598 solid 3px;
    }
    .dash-nav ul li a {
        padding: 10px 5px;

        text-transform: uppercase;

        color: #595A5C;
    }
    .title-section {
        display: none;

        margin-bottom: 30px;
        padding: 15px 0;

        color: #595A5C;
        background: #E6E7E8;
    }
    .title-section h1 {
        font-size: 18px;
        font-weight: 400;

        margin: 0;

        text-transform: none;
    }
    .title-section h1 span {
        font-weight: 600;

        text-transform: uppercase;
    }
    .pf-dashboard section {
        padding-top: 20px;

        background: #F1F2F2;
    }
    .pf-dashboard section + section {
        padding-top: 20px !important;
        padding-bottom: 100px;

        border-top: 1px solid white;
    }
    .pf-dashboard footer {
        margin-top: 0;
    }
    .pf-dashboard section h1,
    .pf-dashboard section h2 {
        font-size: 22px;
        font-weight: 400;

        margin: 20px 0;

        text-transform: uppercase;

        color: #717171;
    }
    .two-column-left {
        display: inline-block;

        width: 100%;
        max-width: calc(65% - 32px);
        margin-right: 32px;

        vertical-align: top;
    }
    .one-column-right {
        display: inline-block;

        width: 100%;
        max-width: 35%;
    }
    .dataTables_length select,
    select.chosen-style,
    select.filter-select {
        padding-right: 25px;
    }
    .documents-page .inline-form {
        margin-bottom: 20px;
    }
    /* All below amended by matty */
    button {
        //font-size: 14px;
        //font-weight: 400;
        //font-style: normal;
        //line-height: 30px;
        //
        //display: inline-block;
        //
        //width: auto;
        //margin-top: 10px;
        //padding: 0 15px;
        //
        //text-align: center;
        //text-decoration: none;
        //text-indent: 0;
        //text-transform: uppercase;
        //
        //color: white;
        //border: 1px solid #0086BF;
        //-webkit-border-radius: 0;
        //-moz-border-radius: 0;
        //border-radius: 0;
        //background-color: #0086BF;
    }
    //button:hover {
    //    cursor: pointer;
    //
    //    border: 1px solid #009444;
    //    background-color: #009444;
    //}
    .button.docFilter {
        float: none;

        margin: 0;
    }
    .logout-button {
        font-family: inherit;
        font-weight: normal;
        line-height: 22px;

        margin-top: 0;
        margin-top: 8px;

        text-align: center;
        white-space: nowrap;

        color: #FFFFFF;
        border: 0;
        background: transparent;
    }
    .logout-button:hover {
        border: 0;
        background: transparent;
    }

    select {
        padding: 5px 15px 5px 5px;

        background: #FFFFFF url(/img/arrow.png) right center no-repeat;
    }
    .ie9 select,
    .ie10 select,
    .ie11 select {
        padding-right: 5px;

        background-image: none;
    }
    b {
        font-weight: bold;
    }
    ul {
        padding: 0;
    }




    header.pageHead {
        margin-bottom: 20px;

        border: 0;
        background: #EEEEEE;
    }
    header.pageHead > h2 {
        width: 90%;
        max-width: 1300px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;
    }
    header.pageHead > h2 {
        margin: 0 auto;
        padding: 10px;
    }
    .dataTable tbody tr.shown + tr {
        padding: 0; /* background:white; */
    }
    .dataTable tbody tr.shown + tr td {
        padding: 8px 16px 32px; /* background:white; *//* border:1px solid #E6E7E8; */
    }
    /* .dataTable tbody tr.shown + tr td > div {padding:0 0.85rem;} */
    .dataTable tbody tr.shown + tr td table {
        /* background:white; */
    }
    .dataTable tbody tr.shown + tr td table tr td {
        /* border-top:1px solid #E6E7E8; */
        padding: .625rem .85rem; /* background:white; */
    }
    .dataTable tbody tr.shown + tr td table tr {
        background: #FEFEFE;
    }
    .dataTable tbody tr.shown + tr td table tr:nth-of-type(odd) {
        background: #E8EBEB;
    }
    .create-note {
        display: block;

        padding-bottom: 14px;

        text-align: right;
    }
    .create-note button {
        margin-top: 0;
    }
    .dataTable tbody tr.shown + tr tr.noFiles {
        display: table;

        width: 100%;
    }
    .dataTable tbody tr.shown + tr tr.noFiles td {
        font-weight: 600;

        width: 100%;
        padding: 5px 10px;
    }

    @media only screen and (max-width: 1070px) {
        .acc-contact-details-wrapper {
            top: 26px;
        }

        .acc-contact-details-wrapper img {
            width: 81px;
            height: 96px;
        }

        .acc-contact-details .contact-select {
            padding-left: 94px;
        }

        .acc-contact-details .text-wrap {
            min-width: 170px;
        }
    }
    @media only screen and (max-width: 1024px) {
        .table.summary .one-third {
            width: 225px;
        }
        .chart {
            width: 200px;
            height: 200px;
        }
        .doughnutSummaryTitle {
            font-size: 14px;

            margin: -10px 0 0 -41px;
        }
    }

    @media only screen and (max-width: 1023px) {
        .acc-contact-details-wrapper {
            top: 18px;
        }
    }
    @media only screen and (max-width: 850px) {
        .summary-table {
            padding-right: 0;
        }
        .table .one-third,
        .table .two-third {
            display: block;

            width: 100%;
        }
        .table .one-third {
            margin-top: 30px;
        }
        .chart {
            width: 500px;
            height: 500px;
        }
        .doughnutSummaryTitle {
            font-size: 18px;

            margin: 0 0 0 -20px;
        }
    }
    @media only screen and (max-width: 773px) {
        .bounds {
            width: 100%;
        }
        .acc-contact-details-wrapper {
            top: 0px;
            right: 0px;
        }
        .two-column-left,
        .one-column-right {
            max-width: 100%;
            margin-right: 0;
        }
        /*Table Flow*/

        html:not(.ie9) .table-flow thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        html:not(.ie9) .table-flow > tbody > tr > td {
            position: relative;

            display: block;

            width: 100%;
            padding-left: 40%;

            border: none;
            border-bottom: 1px solid #CCCCCC;
        }

        html:not(.ie9) .table-flow > tbody > tr > td:before {
            position: absolute;
            /* top: 6px; */
            left: 6px;

            box-sizing: border-box;
            width: 30%;
            padding-right: 10px;

            content: attr(data-label);
            text-align: left;
            white-space: nowrap;
        }

        html:not(.ie9) .table-flow > tfoot > tr {
            font-size: 0;
        }
        html:not(.ie9) .table-flow > tfoot > tr > td {
            font-size: 15px;

            display: inline-block;

            width: 50%;
        }

        html:not(.ie9) .table-flow .full-width + td {
            width: 0;
            padding: 0;
        }

        html:not(.ie9) .table-flow > tfoot > tr > td.full-width {
            width: 100%;
        }

        html:not(.ie9) .table-flow > tfoot > tr > td:last-of-type,
        html:not(.ie9) .table-flow > tfoot > tr > td.space {
            width: 0;
            padding: 0;
        }

        html:not(.ie9) .doc-table .table-flow a.downloads .svg-icon {
            left: 0;

            margin-left: 0;
        }
    }

    .tonedBackground{
        background-color: #f7f7f7;
    }

    //@media only screen and (max-width: 568px) {
        //.acc-contact-details-wrapper {
        //    display: none;
        //}
    //}

}

// New and needed styles:
a.status-icon{
    fill:$accent-primary;
    vertical-align:middle;
    display:inline-block;
    margin-left:.5em;
}

.dashboard-homepage{
    .content-wrap{
        max-width: none !important;
    }
}
.pf-dashboard.documents-page  .doc-table a.downloads .svg-icon {
    @include media("<=phoneland") {
        left: auto;
        right: 0;
    }
}