.footer-rsa {
    background: $light-grey-91;
    .flex-parent > .block {
        min-width: 0;
    }
    .rsa-image {
        flex-basis: 10%;
        @include media('<=phoneland') {
        	flex-basis:50%;

        	img{
        		width:86%;
        		position:relative;
        		top:-6px;
        	}
        }
    }
    .rsa-underwrite {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.33em;

        text-transform: uppercase;

        flex-basis: 30%;
        @include media('<=phoneland') {
            font-size: 12px;
            line-height: 1.17em;
            flex-basis:50%;
        }
    }
    .rsa-text {
        font-size: 14px;
        line-height: 1.14em;

        flex-basis: 60%;
        @include media('<=phoneland') {
            font-size: 12px;
            line-height: 1.17em;

            flex-basis: 100%;
        }
    }
}
