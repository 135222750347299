/*!
*  Stunnplate Flex layout.css
*  Version: 1
*
*/
/* 
*  Last Updated: 06/11/15
*  By: Adam
*  
*  Notes:
*  Default flexbox style layput
*
* 
*/

/* ================================
Default Layout Elements
================================ */


/* ===============
Flex sticky footer
=============== */

/*add to body*/
.stickyFootWrap {
    display: -ms-flexbox;
    display: -webkit-flex;
    display:         flex;
            flex-direction: column;

    min-height: 100vh;

        -ms-flex-direction: column;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
}

/*add to the 'main element/section that you want the footer to clear'*/
.stickyFootMain {
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    -ms-flex-positive: 1;
    -ms-flex-negative: 0;
    -ms-flex-preferred-size: auto;
    ms-flex: 1;
}

/*add to all of the other section/elements on the page (including the footer) apart from the one the footer needs to clear*/
.stickyFootOther {
    display: block;

    width: 100%;

        -ms-flex: none;
    -webkit-flex: none;
            flex: none;
}


/* ===============
Flex grid
=============== */

.flex-parent {
    display: -ms-flexbox;
    display: -webkit-flex;
    display:         flex;
            flex-direction: row;

    padding: 16px;

    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-line-pack: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-direction: row;
    -webkit-flex-direction: row;
        -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
            align-content: flex-start;
}





.ie9 .flex-parent {
    font-size: 0;

    vertical-align: middle;
}


.flex-parent .block {
    /*border:32px  solid transparent;
  border-right:0;
  border-bottom:0;*/
    display: inline-block;

    box-sizing: border-box;
    min-width: 200px;     /* Firefox 3.6 */  /* Safari 4? Chrome 6? */
    padding: 16px;

    background-clip: content-box;      /* Firefox 4, Safari 5, Opera 10, IE 9 */

        -ms-flex: 1 1 25%;
            flex: 1 1 25%;
    -webkit-flex: 1 1 200px;
	
	&.grow3 {
		flex-grow:3;
	}
}

.ie11 .flex-parent > .block-heading,
.flex-parent > .block-heading{
    flex-basis:100%;
    min-width:100%;
    h1{
        margin-bottom:0;
    }
}

.ie11 .flex-parent .block {
    -ms-flex-preferred-size: 22%;
    -webkit-flex-basis: 22%;
            flex-basis: 22%;
}

.ie10 .flex-parent .block {
    -ms-flex-positive: 1;
    -ms-flex-negative: 1;
    -ms-flex-preferred-size: 22%;
}


.ie9 .flex-parent .block {
    font-size: 16px;

    width: 25%;

    vertical-align: top;
}


.flex-parent.right {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
            justify-content: flex-end;
}


.flex-parent.right .block {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}


.block-resources-grid{
    padding-top: 0!important;
}


.bounds, .jf-header-bounds {
    width: 100%;
    /*max-width: 1234px;*/
    max-width: 1364px;
    margin: 0 auto;
}

/*replaces our table border-spacing where we're not using flex*/
.content-padding {
    padding: 0 32px;
}

.block-justify-content{
    justify-content: center;
    -ms-flex-pack:center;
}



/* ===============
Column widths
=============== */
/*.block.one {
  width: 100%;
}
.block.one-half {
  width: 50%;
}
.block.one-third {
  width: 33.33%;
}
.block.two-third {
  width: 66.66%;
}
.block.one-fourth {
  width: 25%;

  empty-cells: show;
}
.block.three-fourth {
  width: 75%;
}


.block.aside {
  width: 370px;
  }*/


/* ===============
ALL: IE Fixes
=============== */



/* ==========================================================================
Media Queries
========================================================================== */

@media only screen and (min-width: 1030px) {
    .flex-parent .block {
        -webkit-flex: 1 1 25%;
    }
}


/* ===============
Maximal Width (above 1170px) - See Default
=============== */


@media only screen and (max-width: 1170px) {
}

@media only screen and (max-width: 830px) {
}


@media only screen and (max-width: 768px) {
    #main section .narrow {
        width: 100%;
    }
}



@media only screen and (max-width: 720px) {
}


@media only screen and (max-width: 630px) {
}


@media only screen and (max-width: 568px) {
    .flex-parent {
        padding: 8px;
    }
    .flex-parent .block {
        padding: 8px;
    }

    .content-padding {
        padding: 0 16px;
    }
}


@media only screen and (max-width: 320px) {
}
