#helpPanel {
    position:fixed;
    top:-100%;
    bottom:100%;
    left:0; right:0;
    margin-top:0;
    overflow:auto;
    z-index:99999500;
    overflow:auto;
    transition: all 0.5s;
    padding:0!important;
    background-color: #FBFBFB;
    border-top: 1px solid #ddd;
}

#helpPanel .bounds {padding-top:20px;}
#helpPanel .bounds p {margin:2em 0;}
#helpPanel .browser-logos {display:table; margin:1em 0;}
#helpPanel .browser-logos a {display:table-cell;}
#helpPanel .browser-logos a + a {padding-left: 20px;}
#helpPanel .browser-logos a img {width:100%; max-width:96px;}
#helpPanel .icon-help {color:#1C75BC; display:inline-block; vertical-align:middle;}

// this is part fo the help pannel
kbd {
    -moz-border-radius:3px;
    -moz-box-shadow:0 1px 0 rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    -webkit-border-radius:3px;
    -webkit-box-shadow:0 1px 0 rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    background-color:#EFEFEF;
    border:1px solid #C7C7C7;
    border-radius:3px;
    box-shadow:0 1px 1px rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    color:#333;
    display:inline-block;
    font-family: 'proxima nova',sans-serif;
    font-size:1.1em;
    line-height:1.4em;
    margin:0 .1em;
    padding:.1em .6em;
    text-shadow:0 1px 0 #fff;
    font-family: monospace;
}