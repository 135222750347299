.summary-of-changes{
    .text-changed-info{
        color: mediumblue;
    }

    .box-forms{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;

        .summary-sja{
            margin: 0 5px;
            width: 49%;
        }
    }

}

// This is the style for the summary of changes form modal that is present in the policy list (pf-admin)

#datatable-policies{
    .trigger_popup_fricc{
        cursor: pointer;
    }
}

.wrapper-compare{
    display: none;

    .background {
        background: rgba(0, 0, 0, 0.73);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 99999;
    }
    .foreground {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 30%;
        max-height: 30%;
        margin: 30%;
        box-sizing: border-box;
        padding: 30px;

        @media only screen and (max-width: 1200px) {
            margin: 25%;
        }

        @media only screen and (max-width: 1080px) {
            margin: 20%;
        }

        @media only screen and (max-width: 900px) {
            margin: 14%;
        }

        @media only screen and (max-width: 750px) {
            margin: 8%;
        }

        @media only screen and (max-width: 670px) {
            min-width: 65%;
            margin: auto;
        }

        @media only screen and (max-width: 1355px) {
            .chosen-container{
                width: 340px !important;
            }

            #compare-forms{
                max-width: 160px;
            }
            max-width: 73%;
        }

        @media only screen and (max-width: 550px) {
            .chosen-container{
                width: 280px !important;
            }

        }

        @media only screen and (max-width: 455px) {
            .chosen-container{
                width: 140px !important;
            }

            #compare-forms{
                max-width: 100px;
            }
        }

        @media only screen and (max-width: 320px) {
            .chosen-container{
                width: 140px !important;
            }

        }

        .form-compare-content{
            background-color: white;
            position: fixed;
            top: 40%;
            width: 100%;
            height: 100%;
            z-index: 99999;
            max-width: 30%;
            min-width: 570px;
            max-height: 30%;
            box-sizing: border-box;
            //padding: 45px;

            @media only screen and (max-width: 670px) {
                max-width: 65%;
                min-width: unset;
            }

            @media only screen and (max-height: 1024px) {
                top: 30%;
            }

            @media only screen and (max-height: 823px) {
                top: 30%;
                max-height: 36%;
            }

            @media only screen and (max-height: 667px) {
                top: 30%;
                max-height: 40%;
            }

            @media only screen and (max-height: 641px) {
                max-height: 47%;
                top: 28%;
            }

            @media only screen and (max-height: 480px) {
                max-height: 53%;
                top: 25%;
            }

            .stickyFootMain{
                height: 100%;
                .form-content{
                    height: 100%;
                    .column-title{
                        font-size: 18px;
                        height: 100%;
                        max-height: 22%;
                        text-align: center;
                        padding: 31px 15px 15px 15px;
                        @media only screen and (max-width: 455px) {
                            max-height: 37%;
                        }
                    }
                    .input-content{
                        padding: 20px 60px;
                        @media only screen and (max-width: 670px) {
                            padding: 0 30px;
                        }

                        .select-compare{
                            margin: 20px 0;
                            text-align: right;
                        }

                        .chosen-container{
                            text-align: left;
                        }
                    }

                }
            }

            .button-compare{
                margin: 20px 0;
                text-align: right;
            }
        }
    }
}