.pf-admin, .pf-dashboard{
	.table {
        display: table;
        width: 100%;
    }
    .table .two-third,
    .table .one-third {
        position: relative;

        display: table-cell;

        box-sizing: border-box;

        vertical-align: top;

		@include media("<=850px") {
            display: block;

            width: 100%;
        }
    }
    .table .one-third {
        width: 33.33%;
        @include media("<=850px") {
        	margin-top: 30px;
        }
    }
    .table.summary .one-third {
        width: 425px;
        @include media("<=desktop") {
        	width: 225px;
        }
    }

    // Table Styles
    table tfoot:before {
        display: block;

        height: 5px;

        content: '';
    }
    table tfoot tr {
        border: 1px solid #939598;
    }
}

 /*table style*/

.pf-admin .rfqOptionsContainer .rfqFooterTable{
    &:after {
        content: "";
        width: calc(100% - 4px);
        display: block;
        height: 1px;
        position: absolute;
        bottom: 34px;
        background: $charcoal-grey;
    }
}

.pf-dashboard table:not(.flat-plain-table),
.pf-admin table:not(.flat-plain-table) {
    font-size: 16px;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: $white;
    position: relative;

    &:after {
        content: "";
        width: calc(100% - 4px);
        display: block;
        height: 3px;
        position: absolute;
        bottom: -2px;
        background: $charcoal-grey;
    }
    thead tr th:not(:empty) {
        border: 1px solid #939598;
    }
    tbody {
        border: 1px solid #939598;
        border-bottom: 0;
        background: $light-grey-eight;
        color: $dark;
    }
    tbody tr {
        padding: 5px;

        border-bottom: 1px solid #939598;
    }
    tbody tr:nth-of-type(odd) {
        background: $light-grey-seven;
    }
	&.detail-table-dark {
		border-spacing: 0px;
		tbody {
			background: $charcoal-grey;
			color: $white!important;
		}
		tbody tr:nth-of-type(odd) {
			background: $charcoal-grey;
		}
		tbody tr.lighten {
			background: rgba(255,255,255,0.2);
		}
		tbody tr {
			border:none
		}
	}
}
table thead:after {
    display: block;
}

.pf-dashboard table:not(.flat-plain-table){
	@include media('<=phoneland') {
		width: 100%;
	}

}




.dataTable,
.pf-dashboard table:not(.flat-plain-table),
.pf-admin table:not(.flat-plain-table){
    thead th{
        border: 0 none!important;
        background: map_get($datatables,thBg);
        padding: 22px;
        text-transform: uppercase;
        @include font('body', 14px, map_get($datatables,thText), 500,  1.14);
    }
    tbody {
        td{
            padding: 22px;
            @include font('body', 14px, $dark, 400,  1.21);
        }
		tr.requiresAttention {
			td {color:$warn-color;}
			
			.icon-warning-triangle {
				fill: $warn-color;
			}
		}
		tr.featuredrow {
			td {
				text-transform: uppercase;
			}
			td:last-child{
				font-size:17px;
				font-weight:600;
			}
		}
        tr.shown + tr {

            padding: 0;

            background: white;
            td {
                padding: 0;

                border: 1px solid #E6E7E8;
                background: white;
                > div {
                    padding: 0 .85rem;
                }
                table {
                    background: white;
                    tr td {
                        padding: .625rem .85rem;

                        border-top: 1px solid #E6E7E8;
                        background: white;
                    }
                }
            }
        }
    }
	
	&.detail-table-dark {
		border-spacing: 0px;
		tbody {
			th{
				text-align:left;
				padding: 22px 23px;
				@include font('title', 22px, $white, 200,  1.21);
			}
			th:nth-of-type(odd){
				@include font('title', 34px, $white, 200,  1.21);
			}
			td{
				padding: 22px 23px;
				border-bottom: 1px solid $charcoal-grey;
				color: $white;
			}
		}
		&:after {
			width: 100%;
			bottom: -5px;
		}
	}
	
	&.detail-table-dark-light {
		tbody {
			td:nth-of-type(odd){
				background: $charcoal-grey;
				color: $white;
			}
		}
	}
	
	&.two-equal-columns {
		tbody {
			td{
				width:50%;
				@include font('body', 14px, $dark, 200,  1.21);
			}
			td:nth-of-type(odd){
				font-weight:600;
			}
		}
	}
}

.pf-admin table.no-bottom-border:after {
	display: none!important;
}

.traffic-red {
  background-color: red;
}
.traffic-pink {
  background-color: deeppink;
}
.traffic-orange {
  background-color: darkorange;
}
.traffic-yellow {
  background-color: yellow;
}
.traffic-green {
  background-color: green;
}


.flat-plain-table {
	/* UNSET ALL? Nah.
			table.flat-plain-table,
			table.flat-plain-table thead,
			table.flat-plain-table tbody,
			table.flat-plain-table tfoot,
			table.flat-plain-table th,
			table.flat-plain-table td,
			table.flat-plain-table tr {
				all: unset;
			} */
	width:100%;
	margin-bottom:1rem;
	font-size: 0.95rem;
	thead, tbody {
		border: none;
		background: none;
	}
	thead {
		border: none;
		border-bottom: 1px solid #555555;
	}
	th, td {
		padding:3px 16px;
		text-align: left;
		line-height: 1em;
	}
	th {
		padding-bottom:10px;
	}
	tr td:first-child, tr th:first-child {
		padding-left:0;
	}
	tr td:last-child, tr th:last-child {
		padding-left:0;
	}
	tbody:before {line-height:10px; content:"\200C"; display:block;}
	td a svg {
		height: 1.2em;
		width: 1.2em;
		vertical-align: middle;
		fill: $a_btn-background;
		fill: currentColor;
	}
	td:last-child {white-space:nowrap; padding-right:0; text-align:right;}
	/*td a:hover svg {
		fill: $a_btn-hover-background;
	}*/
	tr.future, tr.voided {
		position: relative;
	}
	tr.voided {
		text-decoration: line-through;
		background: linear-gradient(transparent 48%, rgba(0,0,0,0.1), transparent 52%, transparent); //line
	}
	tr.future:hover:after, tr.voided:hover:after {
		content: attr(data-tip);
		position: absolute;
		left: 0;
		margin-top: -1.2em;
		width: fit-content;
		background: #ffffff;
		border: 1px solid #cccccc;
		color: #888888;
		padding: 2px 6px;
		font-size: 0.8em;
		box-shadow: 0 5px 10px -7px black;
		pointer-events: none; /* we dont want this tooltip to get in the way */
	}
	/*tr.voided:hover:after {
		background: lighten($mp_blood-red, 80%);
	}*/
	tr.future td, tr.voided td {
		opacity: 0.3;
		filter: grayscale(1);
		a {
			pointer-events: none;
		}
	}
	tr.latest td {
		a.delete {
			pointer-events: none;
			color: #cccccc;
		}
	}
	tr.active {
		background-color: #DDEBEF;
		position:relative;
		a[href="#"] {
			opacity: 0.3;
			filter: grayscale(1);
		}
	}
	tr.active td:first-child:before {
		content: "\21E8"; /*\21FE  \2192 \21C0 279E 279F 27A7*/
		position: absolute;
		left: -0.25em;
		color:#b8d6de;
	}
	
}