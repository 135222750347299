blockquote{
    border-top: 2px solid $iron;
    padding-top:2em;
    font-style:italic;
    line-height: 1.75;
    letter-spacing: normal;
    color: $charcoal-grey;
    cite{
        font-weight: 500;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: $dark;
    }
}