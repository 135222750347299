
body.member-detail-page {

	.main-content {
		position: relative;
		padding: 56px 0 75px;

		@include media("<wide-tab") {
			padding: 33px 0 40px;
		}
		@include media("<tabport") {
			padding: 66px 0 40px;
		}
	}
	.article-content{
		padding-right: 42px;
	}
	.location-single-section {
		border: 0;
		overflow:hidden;

		.location-wrap {
			margin-top: 0;

			.tab-content .office-info-wrap {
				@media only screen and (min-width: 768px) and (max-width: 991px) {
					width: 34%;
				}

			}
			.tab-content .office-map {
				@media only screen and (min-width: 768px) and (max-width: 991px) {
					width: 66%;
				}
				@include media("<tabport") {
					height: 280px !important;
				}
			}
		}
	}
	.flex-parent .block .column-title{
		margin-top: 40px;
	}
}
