.alert {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 12px;
	border: 1px solid $light-grey-four;
	&.withbutton {
		display: flex;
		align-items: center;
		justify-content: space-between;
		button {
			padding: 0.5rem 1rem;
			width: auto;
			text-transform: uppercase;
		}
	}
}

// @deprecated
.alert.green {
	color: $success-color;
}

.alert.success {
	color: $success-color;
}

.alert.red, .alert.error {
	color: $warn-color;
}

.alert.warn {
	color: $warn-color;
	
	.icon-warning-triangle {
		fill: $warn-color;
		float: left;
		margin-right: 10px;
		vertical-align: top;
	}
}

.login-box .alert {
	margin: 0;
	padding: 5px;

	font-size:14px;
	font-weight: 300;

	text-align: center;
}
.login-box .alert.success {
	color: $white;
	background:$warn-color;
	border-color: $warn-color;
}
.login-box .alert.error {
	color: $white;
	background:$error-color;
	border-color: $error-color;
}

.alert_readonly{
	margin-bottom: 2em;
	border: 1px solid $light-grey-four;
	display:flex;
	max-width:640px;
	.alert-icon{
		background:$light-orange;
		flex-basis:56px;
		flex-grow:0;
		svg{
			fill:$white;
			position:relative;
			top:50%;
			left:50%;
			transform:translate(-50%, -50%);
		}
	}
	.alert-message{
		font-size:17px;
		line-height: 1.29;
		flex-basis: auto;
		padding: 12px 16px;
		line-height: 1.29;
  		letter-spacing: 0.26px;
	}
	.alert-title{
		font-weight:bold;
	}
}