.resources-block {
    padding: 39px 12px 47px;
    overflow:hidden;
    margin-bottom:68px;

    .flex-parent{
        min-width: 340px;
    }
    @include media('<1200px') {
        width: 50%;
        max-width: 50%;
        padding: 30px 12px 37px;
    }

    @include media('<wide-tab') {
        // padding: 31px 8px;
        padding: 36px 8px 31px;
    }

    @include media('<tabport') {
        width: 100%;
        max-width: none;
        padding: 27px 8px 25px;
        margin-bottom:0;
    }

    @at-root .resources-page &:nth-child(2){
        flex-basis:100%!important;
        max-width:none!important;
        overflow:visible;
        padding-top:0!important;
        .image-wrap {

            img {
                //position:relative;
                //width:100vw;
                //left:50%;
                //margin-left:-50vw;
                //max-width:none;

                position: relative;
                left: 50%;
                //max-width: none;
                transform: translateX(-50%);
                @include media("<=wide-tab"){
                    height:260px;
                }
                @include media("<=phoneland"){
                    height:178px;
                }
            }
        }
        .article-meta:after,
        .article-meta:before{
            display:block;
            content:'';
            background:$white;
            position:absolute;
            left:-8px;
            top:0;
            bottom:0;
            width:8px;
            @include media("<=wide-tab"){
                display:none;
            }
        }
        .article-meta:before{
            left:100%;
        }
    }

    @at-root .insuranceService-grid &{
        .article-heading{
            height:auto;
            border-bottom: solid 1px #d7d5d5;
            margin-bottom:0;
            padding-bottom: 4px;
        }
        .article-summary{
            display: block;
            color: $dark;
        }
    }

    .article-meta{
        padding:16px 0;

        transition: all 300ms ease 0s;

        background:$white;

        @include media(">=wide-tab"){
            margin-top:-64px;
            transform:translateY(64px);
            @at-root .resources-block:hover .article-meta{
                transform:translateY(0);
            }
        }

        @at-root .get-a-quote-grid &{
            @include media(">=wide-tab"){
                margin-top:-32px;
                transform:translateY(32px);
                @at-root .resources-block:hover .article-meta{
                    transform:translateY(0);
                }
            }
        }
    }

    .article-meta-gray{
        background:#f7f7f7;
    }


    .article-heading {
        @include font(body, 18px, $dark, 700, 1.44);
        @include media("<=phoneland") {
            @include font(body, 15px, $dark, 700, 1.44);
        }
        //height:78px;
        margin-bottom:10px;

        @at-root .get-a-quote-grid &{
            height:52px;
            margin-bottom:0;
        }
    }

    .article-date {
        @include font(body, 14px, $warm-grey, false, 1.8);

        position: relative;

        margin-bottom: 15px;

        border-bottom: 1px solid $alto;

    }

    .article-category{
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        //height:25px;
        @include font(body, 14px, $dark, 400, 1.44);
        @include media("<=phoneland") {
            @include font(body, 12px, $dark, 400, 1.44);
        }
    }

    .article-summary{
        //display:none;
        @include font(body, 15px, $body-text, false, 1.6);
        margin-bottom:10px;
    }

    .article-readmore {
        @include font(body, 15px, $warm-grey, false, 1.6);
        svg{
            fill:$warm-grey;
            vertical-align: middle;
			height: 12px;
			width: 30px;
        }
        opacity:0;
        &:hover{
            color: $accent-primary;
            svg{
                fill:$accent-primary;
            }
        }
        @at-root .get-a-quote-grid & {
            font-weight: 500;
            color: $accent-primary;
            svg{
                fill:$accent-primary;
            }
        }
    }

    .image-wrap img{
        transition: all 300ms;
        min-width:100%;
    }

    &:hover {
        cursor: pointer;

        .image-wrap {
            img {
                filter: hue-rotate(-2.16deg) saturate(0.87) brightness(0.87) contrast(0.78);
            }
        }
        .article-readmore{
            opacity:1;
        }
    }

}

.resources-title{
    padding: 32px 0 0 32px;
}

.section-gray{
    background-color: #f7f7f7;
}