.footer-section {
    background: map_get($footer, primaryBg);


    @if ($theme == corporate) {
        .company-meta {
            //background: map_get($footer,secondaryBg);
            padding-top: 80px;
            position: relative;
            // Below percentages are magic numbers
            background: linear-gradient(119deg, map_get($footer, primaryBg) 0%, map_get($footer, primaryBg) 16%, map_get($footer, secondaryBg) 16%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            @include font(body, 14px, map_get($footer, text), false, 1.57);

            @include media("<=phoneland"){
                padding-top: 40px;
                padding-left: 0;
                background: linear-gradient(-119deg, map_get($footer, primaryBg) 0%, map_get($footer, primaryBg) 12%, map_get($footer, secondaryBg) 12%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
	
			@include media("<=desktop"){
				background: linear-gradient(-119deg, map_get($footer, primaryBg) 0%, map_get($footer, primaryBg) 12%, map_get($footer, secondaryBg) 12%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			}

            &:before {
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b8e1fc+0,90bff0+50,6ba8e5+51 */
                background: linear-gradient(135deg, map_get($footer, primaryBg) 0%, map_get($footer, primaryBg) 50%, map_get($footer, secondaryBg) 51%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 20%;
                //content:'';
            }
            .flex-parent {
				max-width: 83%;
				margin: 0 0 0 auto;
				justify-content: space-between;
				padding: 0 16px;
				@include media("<=desktop") {
					margin: 0 auto 0 0;
				}
				@include media("<=640px") {
					max-width: 100%;
					margin: 0 auto 0 0;
					padding: 0 8px;
				}
				@include media("<=phoneland") {
					flex-direction: column;
				}
            }
            .block{
				max-width: 25%;
				min-width: 145px;
				@include media("<=desktop") {
					min-width: 45%;
					max-width: 45%;
				}
				@include media("<=640px") {
					min-width: 50%;
					max-width: 50%;
				}
                @include media("<=phoneland") {
                    //padding-left: 36%;
					max-width: 80%;
                }
				flex: none;
            }
            @at-root .pf-admin &{
                padding-top:0;
                padding-left:0;
                background:none;
                .flex-parent{
                    justify-content:space-between;
                    padding-top:0;
					max-width: 100%;
                }
                .company-meta_social{
                    flex-basis:100%;
                    max-width:400px;
                    background: linear-gradient(119deg, map_get($footer, primaryBg) 0%, map_get($footer, primaryBg) 20%, map_get($footer, secondaryBg) 20%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    padding-top:0;
                    position:relative;
                    ul.social-icons{
                        float: right;
                        margin-top:48px;
                    }
                    &:after{
                        content:'';
                        display:block;
                        position:absolute;
                        width:25vw;
                        background-color:map_get($footer, secondaryBg);
                        top:0;
                        bottom:0;
                        left:100%;
                    }
                }
            }
            .footer-logo{
                max-width:300px;
            }
            .logo{
                padding:0;
                max-width:112px;
            }
			.icon-phone {
				width: 30px;
				height: 26px;
			}
			.icon-email {
				width: 31px;
				height: 25px;
			}
			.icon-email-inverse {
				width: 28px;
				height: 21px;
			}
			.icon-twitter {
				width: 24px;
				height: 18px;
			}
			.icon-linkedin {
				width: 21px;
				height: 20px;
			}
        }
        h3 {
            @include font(title, 48px, map_get($footer, title), false, 1.21);
            @include media("<=phoneland"){
                font-size:34px;
                line-height:1.41;
            }
        }
        h4 {
            @include font(body, 18px, map_get($footer, title), 400, 1.43);
			color:#ffffff;
            margin-bottom:.7em;
            @include media("<=phoneland"){
                margin-top:.5em;
            }
        }
        h5 {
            @include font(body, 14px, map_get($footer, title), 700, 1.43);
            margin-bottom:0;
            @include media("<=phoneland"){
                line-height: 1.46;
                font-size: 13px;
            }
        }
        .copyright {
            @include font(body, 12px, map_get($footer, body), 400, 1.67);
            .copyright-meta {
                padding: 1em 0;
                color:$warm-grey;
                .bounds{
					@include media("<=1357px") {
						padding:0 32px;
					}
                    @include media("<=desktop") {
                        padding:0 32px;
                    }
                    @include media("<=phoneland") {
                        padding:0 16px;
                    }
                }
                &:first-child {
                    border-bottom: 1px solid map_get($footer, body);
                }
            }
            @at-root .pf-admin &{
                background:transparent;
                position:relative;
                margin-top:-48px;
            }
        }
    }
    .company-meta_offices {
        text-align: right;
        padding-bottom: 0;
        order:3;
        &.block{
            @include media("<=phoneland") {
                position:relative;
                right:-16px;
                padding-left:1%;
            }
        }
    }
    .company-meta_phone {
        .phone-number {
            @include font(body, 31px, map_get($footer, accent), 500, 1);
			@include media("<1400px") {
				font-size:calc(26px + 10 * ((100vw - 720px) / 1400));//variable font size from 26 to 36
			}
			@include media("<1100px") {
				font-size:calc(20px + 10 * ((100vw - 720px) / 1400));//variable font size from 20 to 30
			}
			@include media("<=desktop") {
				font-size:26px;
			}
			white-space:nowrap;
        }
        @include media("<=phoneland") {
            order:1;
            //margin-left:-22%;
        }
    }
    .company-meta_social {
        @include media("<=phoneland") {
            order: 2;
        }
		ul.social-icons {
			display: block;
			margin-left:0;
			li {
				display: inline-block;
				vertical-align: middle;
				border-right: 1px solid #f7f7f7;
				padding: 0 12px 0 0;
			}
			li + li {
				padding-left: 12px;
			}
			li:last-child {
				border-right: 0 none;
			}
		}
    }
    .footer-offices {
        list-style-type: none;
        margin: 0;
        padding: 0 0 0 50px;
        background: linear-gradient(119deg, transparent 0%, transparent 43px, map_get($footer, accent) 43px); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        display: inline-block;
        li {
            display: inline-block;
			//display: none; /* we need to hide all but the first 2, so hide all... */
			//&:nth-child(-n+2){
			//	display: inline-block;/* ...and show first 2 */
			//}
			background: map_get($footer, accent);
            transition:background 300ms ease;
			
            a {
                padding: 26px 30px;
                @include font(body, 18px, $white, 700, 1.44);
                display: inline-block;
            }
            &:hover{
                background: $a_btn-hover-background;
            }
            border-left: 1px solid $light-blue-grey;
            &:first-child {
                border-left: 0 none;
            }
            @include media("<=wide-tab"){
                display:none;
            }
            &:last-child{
                display:none;
                @include media("<=wide-tab"){
                    border-left: 0 none;
                    display:block;
                }
            }
            right: 0px;
            position: relative;
        }
    }
    .company-bounds{
		padding-left: 0;
    }

    .footer-icon {
        height: 34px;
        fill: $white;
        a{
            display: inline-block;
            &:visited{
                fill:$white;
            }
            &:hover{
                fill:$accent-primary;
            }
        }
        svg, ul, a {
            vertical-align: middle;
        }
    }
	
	.footer-nav-wrap {
		border-top: 1px solid $warm-grey;
        position:relative;
		@include font(body, 12px, map_get($footer, body), 400, 1.67);
		.bounds{
			@include media("<=1357px") {
				padding:0 32px;
			}
			@include media("<=desktop") {
				padding:0 32px;
			}
			@include media("<=phoneland") {
				padding:0 16px;
			}
		}
	}
	ul.footer-nav.flex-ul {
		justify-content: flex-start;
		list-style-type: none;
		@include media('<=phoneland') {
			display: block;
		}
		padding:0;
		margin:0;
	}
	ul.footer-nav li {
		padding: 1em 2em 1em 0;
		display: block;
		margin: 0;
		& + li {
			@include media('<=phoneland') {
				padding-top: 0;
			}
		}
		a {
			color: $warm-grey;
			&:hover {
				color: $link-primary;
			}
		}
	}
	
	
	#cookieNotice {
		background-color: #292e32;
		color: #ffffff;
		position: -webkit-sticky;
		position: sticky;
		bottom: 0px;
		@include font(body, 14px, map_get($footer, text), false, 1.57);
		.bounds{
			display:flex;
			justify-content: space-between;
			align-items: center;
			max-height: 300px;
			overflow: hidden;
			transition: all 250ms;
			padding: 14px 0;
			p {margin:0;}
			label {height: 28px; margin-left:10px;}
			a {white-space: nowrap;}
			svg.icon-close {/* With added round border */
				fill: $link-primary;
				border: 2px solid $link-primary;
				padding: 4px;
				border-radius: 50%;
				width: 28px;
				height: 28px;
				&:hover {
					text-decoration: none;
					cursor: pointer;
					fill: $warm-grey;
					border-color: $warm-grey;
				}
			}
			@include media("<=1357px") {
				padding:14px 32px;
			}
			@include media("<=desktop") {
				padding:14px 32px;
			}
			@include media("<=phoneland") {
				padding:14px 16px;
			}
		}
		input {
			position: absolute;
			opacity: 0.01;
			left: -100%;
		}
		input:checked ~ div {
			max-height: 0px;
			padding: 0;
		}
	}
	
}
