
.speak-to-specialist-widget {
	padding: 20px;
	margin-bottom: 25px;
	border: solid 1px $silver;
	// border-bottom: 6px solid $slate-grey-96;
	
	@include media("<wide-tab") {
		padding: 15px 15px 12px;
	}
	@include media("<tabport") {
		margin-bottom: 17px;
	}

	.widget-image-wrap {
		float: left;
		width: 110px;
		margin: 0 18px 10px 0;
		border: solid 1px $warm-grey;

	    @media only screen and (max-width: 1040px) {
			margin-bottom: 15px;
		}
		@include media("<wide-tab") {
			margin-right: 12px;
		}
		@media only screen and (min-width: 768px) and (max-width: 991px) {
			width: 45%;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
	.widget-title {
		margin: 0 0 25px;
		font-weight:300;
		font-size: 23px;
		line-height: 29px;
		color: $greyish-brown;

		@include media("<wide-tab") {
			font-size: 22px;
			line-height: 29px;
		}
		@include media("<=phoneland") {
			font-size: 20px;
		}
	}
	.contacts {
		@media only screen and (min-width: 768px) and (max-width: 1040px) {
			clear: both;
		}
		@include media("<=phoneland") {
			clear: both;
		}
		span {
			display: block;
			line-height: 24px;
			margin-bottom: 5px;
		}
		.name {
			font-weight:400;
			font-size: 19px;
			color: $greyish-brown;
		}
	}
}