.assets{
	
	.coverStatus {
		margin:0;
		* {vertical-align: middle;}
		strong {
			display: inline-block;
			padding: 3px;
			vertical-align: middle;
			padding-right: 0.8em;
			clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
			svg {
				max-height: 16px;
				max-width: 20px;
				vertical-align: text-top;
			}
		}
	}
	.onCover strong {
		background-color: rgba($green-leaf, .1);
		color:$green-leaf;
		svg {
			fill: $green-leaf;
			&.icon-tick-drawn {
				stroke: $green-leaf;
				stroke-width: 2px;
			}
		}
	}
	.offCover strong {
		background: rgba($dull-red, .1);
		color:$dull-red;
		svg {
			fill:$dull-red;
		}
	}
	.sold {
		display: inline-block;
		font-weight: 100;
		text-transform: uppercase;
		border: 1px solid;
		color: $mp_blood-red;
		line-height: 1em;
		padding: 0.3rem 0.3rem 0.1rem 0.3rem;
	}
	.alert.deleted {
		color: $mp_blood-red;
		//border: 1px solid $mp_blood-red;
		font-weight: normal;
		.icon-warning-triangle {
			fill:$mp_blood-red;
		}
	}
	
	section.block.assets-block {
		
		@media only screen and (max-width: 1030px) {
			min-width: 100%;
		}
	}
	
	.flex-parent .vertical-divider {
		width: 2px;
		max-width: 2px;
		min-width: 2px;
		box-sizing: content-box;
		background-color: #e2e2e2;
		@media only screen and (max-width: 1030px) {
			display: none;
		}
	}
	.child-asset-listing {margin-bottom:40px;}
	.asset-edit-jsonform-wrapper .jsonForm fieldset {
		padding: 0;
	}
	
	.jsonForm input[type=checkbox]+.focb::before {
		content: "\2573";
		color: #ffffff;
	}
	.jsonForm input[type=checkbox]:checked+.focb::before {
		content: "\2714";
		color: #ffffff;
		background-color: #90ce51;
		border-color: #90ce51;
	}
	.jsonForm.disabled{
		opacity:0.4;
		pointer-events: none;
	}
	.jsonForm .chosen-container[style], .jsonForm .chosen-container-single[style] {
		width: calc(100% - 6px)!important;
	}
	
	.no-bottom-padding {
		padding-bottom:0;
	}
	.file-drop-area {
		position: relative;
		display: flex;
		align-items: center;
		min-width: 300px;
		max-width: 100%;
		padding: 25px;
		border: 1px dashed rgba(0, 0, 0, 0.4);
		transition: 0.2s;
		&.is-dragover {
			background-color: rgba(1, 1, 1, 0.1);
		}
	}
	
	.fake-btn {
		flex-shrink: 0;
		background-color: #00bee5;
		color: #ffffff;
		border: 1px solid rgba(0, 0, 0, 0.3);
		padding: 8px 15px;
		margin-right: 10px;
		font-size: 12px;
		text-transform: uppercase;
	}
	
	.file-msg {
		font-size: small;
		font-weight: 300;
		line-height: 1.4;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.file-input {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		cursor: pointer;
		opacity: 0;
		&:focus {
			outline: none;
		}
	}
	
	
	.asset-document-list {
		a {
			vertical-align: middle;
			svg {
				height: 1em;
				width: 1em;
				vertical-align: middle;
				fill: currentColor;
			}
		}
	}
	
}