
/* 20201105 replaces _contacts.scss. Combined contacts and assets in to this single file since they are essentially the same */
/* Contact system buttons (the contact form style is in the modal (see styledAlert.js) */

.locktonContactActionsWrapper, .locktonAssetActionsWrapper {
	position: absolute;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	bottom: 0;
	right: 0;
	padding: 3px;
	line-height: 0;
	cursor: pointer;
	z-index: 2;
	
	span.icon-jf-add-edit-wrapper {
		display:block;
		background-color: $cerulean2;
		transition: all 0.25s ease-out;
	}
	
	svg {
		display: block;
		fill: white;
		
		&.icon-jf-add-edit {
			width: 30px;
			height: 30px;
			transition: all 0.25s ease-out;
		}
	}
	
	&:hover {
		cursor: pointer;
		
		span.icon-jf-add-edit-wrapper {
			background-color: $cerulean3;
		}
		.locktonContactActions, .locktonAssetActions {
			max-height: none;
			overflow: visible;
			opacity: 1;
		}
	}
	
	@include media('<=600px') {
		top: auto;
		right: 0;
		bottom: 0;
		transform: none;
	}
}

.locktonContactActions, .locktonAssetActions {
	position: absolute;
	display: flex;
	left: -15px;
	bottom: calc(100% + 10px);
	max-width: 310px;
	min-width: 80px;
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	background: $tooltip3-bg;
	box-shadow: $tooltip-shadow;
	transition: opacity 0.25s ease-out;
	z-index: 999;
	
	&:hover {
		max-height: none;
		overflow: visible;
		opacity: 1;
	}
	
	&::before,
	&::after {
		position: absolute;
		display: block;
		content: "";
		flex: none;
		top: 100%;
	}
	
	&::before {
		left: 0;
		right: 0;
		height: 10px;
		opacity: 0;
		cursor: default;
	}
	
	&::after {
		left: 20px;
		border: 12px solid transparent;
		border-top: 10px solid $tooltip3-bg;
		border-bottom: none;
	}
	
	&>a,
	&>label {
		position: relative;
		display: flex;
		align-items: center;
		flex: 0 1 auto;
		min-height: 60px;
		padding: 15px;
		font-size: $tooltip-fs;
		line-height: $tooltip-lh;
		font-weight: normal;
		color: $tooltip-color;
		outline: none;
		cursor: pointer;
		
		svg {
			flex: none;
			
			&[class*=icon] {
				margin-right: 14px;
			}
		}
		
		&:not(:first-child) {
			&::before {
				position: absolute;
				display: block;
				content: "";
				top: 13px;
				left: 0;
				bottom: 13px;
				width: 1px;
				background: $salt-box;
			}
		}
		
		&:hover {
			svg {
				fill: $cerulean3;
			}
		}
	}
	
	a[class*=Button] {
		color: $tooltip-color;
	}
	
	.locktonContactEditButton, .locktonAssetEditButton {
		min-width: 83px;
		display:none;
		svg[class*=icon] {
			margin-right: 14px;
		}
		&::after {
			display: inline-block;
			content: "Edit";
		}
	}
	.locktonAssetEditButton::after {
		content: attr(data-button-text);
	}
	
	select:invalid .locktonContactEditButton, select:invalid .locktonAssetEditButton {
		display: inherit;
	}
	
	.locktonContactAddButton, .locktonAssetAddButton {
		min-width: 135px;
		
		svg[class*=icon] {
			margin-right: 12px;
		}
		&::after {
			display: inline-block;
			content: "Add Contact";
		}
	}
	
	.locktonAssetAddButton::after {
		content: attr(data-button-text);
	}
	
	@include media('<wide-tab') {
		left: auto;
		right: 0;
		bottom: 100%;
		bottom: calc(100% + 1px);
		
		&::before,
		&::after {
			display: none;
		}
	}
}


select[data-type='contact'] ~ .locktonContactActionsWrapper .locktonContactEditButton,
select[data-type='asset'] ~ .locktonAssetActionsWrapper .locktonContactEditButton {
	display: none;
}
select[data-type='contact'][data-answered='true'] ~ .locktonContactActionsWrapper .locktonContactEditButton,
select[data-type='asset'][data-answered='true'] ~ .locktonAssetActionsWrapper .locktonAssetEditButton {
	display: flex;
}


// OLD STYLE

.jsonForm {
	
	&.readonly .locktonContactActionsWrapper, &.readonly .locktonAssetActionsWrapper {
		display: none !important;
	}
	
}
