// Data table Listing Page Filter
.dtListFilter{
	.dtListFilter-flex{
		display:flex;
		flex-wrap:wrap;
		max-width:600px;
	}
	.dtListFilter-block{
		flex: 1 1 22%;
		margin-bottom:6px;

		@include media("<phoneland") {
			flex-basis:100%;
		}
	}
	.dtListFilter-label{
		line-height:34px;
	}
	.dtListFilter-select{
		flex-basis:60%;	 	
	}
	input[type="number"]{
		width:100%;
		background-color: $white;
		border: 1px solid #CCCCCC;
		line-height:34px;
		padding-left:4px;
		font-size:14px;
	}

	.clearFilters{
		font-size:0.8em;
	}
}