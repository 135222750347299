
/**************************/
/* 	   COLOUR PALLETTE    */
/**************************/

// Colours marked NTC were created on http://chir.ag/projects/name-that-color/ as there was no matching variable that existed (probably from Solicitors)

// COLOUR MAPPINGS
// 9b9b9b
// Accounttants
// $rouge - $rouge-three
// $rouge-two - $rouge-four
// $white -$white-five
// $silver-- $silver-two
// $white-two - $white-six
// $white-three - $white
// $warm-grey - $warm-grey-two
// 
// Mobility
// $light-grey - $light-grey-three
// $light-grey-two - $light-grey-four
// $warm-grey - $warm-grey-two
// 
// LSOS
// $white-two - $white-six
// $slate-grey - $slate-grey-two
// $nasty-green - $nasty-green-two
// $warm-grey - $warm-grey-two
// 
// Solicitors
// $white-two - $white-six
// $white2 - $white-five
// $light-grey-three - $light-grey
// $light-grey - $light-grey-five
// $light-grey-two - $light-grey-siz
//


/***************** CORPORATE THEME COLORS *******************/
$almost-black: #060708;
$aqua-blue:#01bee5;
$big-stone: #121836;
$dark: #20262a;
$outer-space: #2b3134;
$cinder: #131618;
$corp-silver: #eeeeee;
$charcoal-grey: #414a51;
$corp-white: #f7f8f8;
$corp-apple: #71B544;
$venetian-red: #D0021B;
$iron: #c8caca;
$iron-two:#d2d3d4;
$turquoise-blue:#0fa8c7;
$purplish-grey:#777677;
$cod-grey:#1d1d1d; //NTC
$alabaster: #f7f7f7; // NTC
$light-blue-grey:#b1d5e5;
$alto: #d7d5d5; //NTC
$warm-grey: #9b9b9b;
$blue-nurse: #212f38;
$water-blue: #5f7f8d;
$dark-water-blue: #2e4655;
$slate: #7b8d9c;
$light-orange: #faa24a;
$burnt-orange: #D05F04;
$dark-81: #2d363d;
$cross-blue: #43aad6;
$bright-red: #d0021b;
$dull-red: #991524;
$cool-blue: #0fa7c7;
$shark: #20262a;

/***************** NORWAY THEME COLORS *******************/
$nor-teal:#3e7692;
$nor-turqoise:#38eaca;
$nor-orange:#ec5a4c;
$nor-red:#a40d22;
$nor-darkgrey:#4a4a4a;
$nor-darkestgrey:#3d3d3d;
$nor-lightgrey:#ebebeb;

// See hacky colour fix further down...

/***************** OLD THEMES COLORS *******************/

$apple: #369338; // NTC
$black: #000000;
$black-two: #212121;
$azure:#029ee3;

$blue-grey: #5c767e;
$blue-grey-two: #414a51;
$blue-grey-96: rgba(91, 118, 126, 0.96);
$brown-grey: #979797;

$bluish-grey: #768c93;
$cerulean: #0086bf;
//$charcoal-grey: #313132;
$cool-grey: #b5b6b7;

$dark-grey-blue: #2c585e;
$dark-grey-blue-94: rgba(44, 88, 94, 0.94);

$dark-slate-blue: #183565;
$dark-slate-blue-two: #1c3a6e;

$dark-grey-three: #060708;
$dark-80: rgba(23, 32, 51, 0.8);
$dark-sky-blue: #55acee;
$denim-blue: #3b5998;
$dirty-purple-53: rgba(74, 73, 74, 0.53);
$dull-orange: #D17B2F;
$greyish-brown: #4a4a4a;
$grey-nurse: #e3e5e3; // NTC
$gunmetal: #505c64;
$light-burgundy: #922545;

$light-grey: #f8f9f7;
$light-grey-two: #dededd;
$light-grey-three: #efefef;
$light-grey-four: #eaebe8;
$light-grey-five: #d0d2c5;
$light-grey-six: #efefec;
$light-grey-seven: #edeeee;
$light-grey-eight: #e3e3e4;
$light-grey-nine: #f6f7f7;
$light-grey-ten: #dadcdc;
$light-grey-eleven: #d7d5d5;

$light-grey-91: rgba(238, 239, 236, 0.91);
$light-grey-32: rgba(227, 228, 221, 0.3);

$light-navy-blue: #2d5581;
$mirage : #172133; // NTC
$nasty-green: #74bd43;
$nasty-green-two: #7ea43e;

$nice-blue: #0976b4;

$outer-space:#2D373D;

$pale-grey: #ebedf0;
$pale-grey-two: #ecf1f0; //#f1f9f8;
$pale-grey-three: #E9EBEF;
$pale-grey-four: #e3e4e4;

$pale-grey-91: rgba(232, 235, 238, 0.91);
$pale-grey-95: rgba(232, 235, 238, 0.95);
$pale-grey-94: rgba(240, 248, 247, 0.94);



$pale-red: #dd4b39;
$peacock-blue: #005e9c;

$petrol: #00606d;
$petrol-45: rgba(0, 96, 109, 0.45);
$petrol-60: rgba(0, 96, 109, 0.6);


$quill-grey: #e0e1df; // NTC
$rolling-stone: #7a878b; // NTC

$rouge: #be1446;
$rouge-two: #be1547;
$rouge-three: #b11b29; // Accountants $rouge;
$rouge-four: #b01b29;

$sick-green: #93be30;
$sick-amber: #ff9933;
$sick-red: #BE1E2D;

$silver: #bdc6cc;
$silver-two: #b6bcb8;
$silver-three: #bcc6cb;


$slate-grey: #57676c;
$slate-grey-two: #55565a;
$slate-grey-96: rgba(87, 103, 108, 0.96);

$sunflower-yellow: #ffd602;
$squash: #e99813;
$topaz: #18b7d8;
$tundora : #464646; //NTC

//$warm-grey: #979797; // @FIXME
$warm-grey: #9b9b9b; // Solicitors - Map others
$warm-grey-two: #979797; // @FIXME
$warm-grey-three: #767677;

$white: #ffffff;
$white-two: #d8d8d8; // @FIXME
$white-three: #d8d8d8; // @FIXME
$white-four: #dfdddd;
$white-five: #faf7f7; //Accountants $white;
$white-six: #dedddd;
$white-seven: #fffffe;


/***************** NORWAY QUICKFIX COLORS *******************/
@if $site == 'norway'{
  $blue-nurse: $nor-darkestgrey;
}

/***************** MASTER POLICY COLORS *******************/

$mp_peacock-blue: #005e9c;
$mp_white: #dedddd;
$mp_light-grey: #dededd;
$mp_light-grey-two: #f8f9f7;
$mp_blue-grey: #5c767e;
$mp_white-two: #ffffff;
$mp_bluish-grey: #7d97a0;
$mp_greyish-brown: #4a4a4a;
$mp_dark-slate-blue: #1c3a6e;
$mp_blue-grey-96: rgba(91, 118, 126, 0.96);
$mp_sunflower-yellow: #fedb00;
$mp_windows-blue: #339ccc;
$mp_peacock-blue-two: #005697;
$mp_cerulean: #0086bf;
$mp_red: #d70302;
$mp_blood-red: #96000b;
$mp_off-white: #fffffe;
$mp_orangish: #fa7a45;
$mp_sun-yellow: #fcd43e;
$mp_pale-grey: #d9e1e8;
$mp_nice-blue: #1967a2;
$mp_cerulean-two: #0083bf;
$mp_marine-blue: #003478;
$mp_golden: #faca00;
$mp_sunshine-yellow: #ffee33;
$mp_grey-blue: #6b82a1;
$mp_bluey-grey: #a3b0c5;
$mp_medium-blue: #2e78cc;
$mp_dark-grey-blue: #354a66;
$mp_dusky-blue: #45618a;
$mp_white-three: #fefefe;
$mp_sienna: #ba4a19;
$mp_reddish-orange: #fa6121;
$mp_mango: #fa992e;
$mp_butterscotch: #ffb838;
$mp_wheat: #ffd487;
$mp_egg-shell: #ffebc2;
$mp_dark: #111923;
$mp_dark-two: #233045;
$mp_dodger-blue: #3897ff;
$mp_carolina-blue: #8cbfff;
$mp_slime-green: #82cf06;
$mp_light-blue-grey: #cce7f3;
$mp_duck-egg-blue: #e6f3f9;
$mp_scarlet: #bf000c;
$mp_light-blue-grey-two: #c7d7eb;
$mp_light-grey-three: #eef3ee;
$mp_faded-blue: #828fca;
$mp_azure: #0aa3eb;
$mp_wheat-two: #f8cc99;
$mp_pale: #fdeedc;
$mp_dark-blue-grey: #1e3547;
$mp_very-light-brown: #d1b087;
$mp_greyish-brown-two: #4f4f4f;
$mp_greyblue: #68a1b0;
$mp_light-teal: #a6d9e8;
$mp_dark-indigo: #0a2842;
$mp_gunmetal: #3f4c57;
$mp_white-four: #e6e6e6;
$mp_white-five: #f5f5f5;
$mp_white-six: #e3e3e3;
$mp_black: #000000;
$mp_greyblue-two: #667587;
$mp_dark-three: #0c0c0f;
$mp_cloudy-blue: #bfc7d7;
$mp_beige: #eee3cf;
$mp_red-two: #d70202;
$mp_white-seven: #f9f9f8;
$mp_slime-green-two: #91cc05;
$mp_dark-beige: #a68a66;
$mp_dark-lime: #78ab02;
$mp_white-eight: #ededed;
$mp_dark-grey-blue-two: #364a66;

/********************************************/


