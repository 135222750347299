.notification-set{
    padding:12px 0 24px;
    &:first-child{
        padding-top:0;
    }
    .notification-title{
        @include font("title", 15px, $white, 500, 1.46);
        padding:12px 0 12px 20%;
        margin:0;
    }
    .notification-content{
        position: relative;
        display: flex;
        width: 60%;
        margin-inline: auto;
        justify-content: space-between;
        gap: 10px;
        .notification-message{
            position: relative;
            z-index: 1;
            /*padding:12px 0 12px 20%;
            width:70%;*/
            @include font('body', 13px, $light-grey, false,  1.46);
            span{
                display: block;
                /*padding-top: 10px;
                max-width: 205px;*/
                a{
                    color: $iron;
                    line-height:1em;
                    &:hover{
                        color: $link-primary;
                    }
                }

            }
        }
        a.delete{
            /*padding-left: 10px;*/

            line-height:1em;
            color: $iron;
            &:hover{
                color: $link-primary;
            }
        }
        .notification-delete{
            position: absolute;
            top: 8px;
            right: 46px;

            display: block;

            padding: 0;
            color:$purplish-grey;
        }
    }
    .notification-content + .notification-content {
        margin-top:30px;
    }
}

// Used on Perf homepage sidebar and BOS
.notification-policy {
    position: relative;
    z-index: 1;
    padding: 16px 0 8px 0;
    border-bottom: solid 0.9px $purplish-grey;
    margin-bottom: 20px;
    width: 200px;
    left: 25px;

}

// Used on Perf homepage sidebar and BOS
.insurance-type-alert{
    @include font("body", 13px, $white, 500, 1.46);
    .staff-contact{
        @include font("body", 13px, #9e9c9e, 300, 1.46);
    }
    &.alert-message{
        @include font("body", 13px, $white, 300, 1.46);
    }
}

// Used on Perf homepage sidebar and BOS
.close-copy {
    position: absolute;
    top: -6px;
    right: 0;
    display: block;
    padding: 0;
    width: 12px;
    height: 12px;
    @include font("body", 13px, $purplish-grey, 300, 1.46);
    cursor: pointer;
}