
.jsonForm {

    // Row container
    .jf-row {
        position: relative;
        display: block;
        max-width: $wrapper-limit;
        width: 100%;
        margin: 7px 0;
        padding: 0px;

        &.jf-contact-row {
            //display: flex;

            align-items: center;
            &:first-child{
                flex-grow:0;
            }

            & > label:not(.error) {
                flex: none;
                max-width: 180px;
                width: 100%;
                font-weight: bold;
            }
        }

        &.jf-input-type-cash {
            & > label:not(.error) {
                font-size: $label2-fs;
            }
        }

        &.jf-input-type-submit{
            max-width:none;
        }

        &.jf-input-type-checkbox {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: flex-start;
            margin: 18px 0 14px;

            label {
                padding: 5px 0 0;

            }
            &.jf-input-type-onoffswitch{
                label{
                    flex-basis: 100%;
                }
                .onoffswitch{
                    margin-top:.8em;
                }
            }
			&.inlineCheckbox {
				display: flex;
				flex-direction: row;
				flex-basis: auto;
				align-items: flex-end;
				justify-content: space-between;
				label {
					display: block;
					width: calc(100% - 40px);
					@at-root .banner &.compressed {
						font-size: 8px;
						line-height: 1.2em;
					}
				}
				.focb {
					display: block;
					margin: 0;
					vertical-align: baseline;
					width: 30px;
				}
			}
        }
	
		

        &.jf-input-type-repeatable {
            max-width: none;
            
            dfn svg[class*=icon] {
                margin-left: 5px;
            }

            & > label {
                dfn {
                    margin-right: 2px;
                }
            }
        }

        &.jf-input-type-multifilerepeatable {
            max-width: none;

            dfn svg[class*=icon] {
                margin-left: 5px;
            }

            & > label {
                dfn {
                    margin-right: 2px;
                }
            }
        }

        &.jf-input-type-datatable {
            max-width: none;

            table.limited {
                max-width: $wrapper-limit + 5px;
            }
        }

        &.jf-input-type-radio {
            max-width: none;

            & > label {
                @include font('body', $intro-fs, false, $intro-fw,  $intro-lh);
            }
        }

        //&.jf-input-type-textarea {
        //    max-width: none;
        //}

        &.notesDiv {
            max-width: $wrapper-limit;
            margin: 0;

            & + .jf-row {
                margin-top: 0;
            }
            & + .jf-input-type-datatable {
                margin-top: 7px;
            }
        }

        & > label {
            // something to do with repeatable regions
            &.inline-append {
                visibility: hidden;
            }
        }

        & > .chosen-container {
            .dialog-box & {
                //width: 100% !important;
				width: calc(100% - 6px)!important;
            }
        }
	
		//@at-root .banner_stream &.compressed {
		//	font-size: 8px;
		//	line-height: 1.2em;
		//	a {
		//		font-size: 8px;
		//		line-height: 1.2em;
		//	}
		//}

        // CK Editor styles - we can probably leave this
        & > div.cke {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            padding: 0;
            margin: 0;
            border: none;
            border-radius: 3px;
            box-shadow: none;
            overflow: hidden;
            box-sizing: border-box;

            &.cke_chrome {
                border: 1px solid rgba(0, 0, 0, 0.3);
            }
        }

        @include media('<desktop') {
            &.jf-input-type-datatable {
                max-width: 700px;
                width: 100%;
                overflow: auto;
            }
        }

        @include media('<tabport') {
            &.jf-input-type-checkbox {
                flex-wrap: nowrap;
                min-height: 23px;
            }
            &.jf-input-type-datatable {
                max-width: 500px;
            }
        }

        @include media('<=600px') {
            &.jf-contact-row {
                display: block;

                & > label:not(.error) {
                    max-width: none;
                }
                .chosen-container {
                    max-width: 100%;
                }
            }
            &.jf-input-type-checkbox {
                label:not(.onoffswitch-label) {
                    display: inline-block;
                    width: auto;
                }
            }
        }

        @include media('<phoneland')  {
            &.jf-input-type-datatable {
                max-width: 300px;

                table {
                    min-width: 500px;
                }
            }
        }
    }
}

// READ ONLY MODE - grey everything out
body.readonlyMode .rowbuttonwrapper.jf-row {
    opacity: 0.3;
    pointer-events: none;
    filter: grayscale(1);
}

/*
@media only screen and (max-width: 600px) {
    .jsonForm {
        .jf-row {
            padding: 3px;

            & > input,
            & > textarea,
            & > select,
            & > div.cke {
                display: block;
                width: 100% !important;
            }

            & > label {
                display: block;
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
*/