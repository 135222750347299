.client-tabs{
    .icon-dashboard{
        height:17px;
        width:22px;
    }

    .input-type-title-select{
        position:relative;
        label{
            display:block;
            font-size: 14px;
            line-height: 17px;
            opacity: 0.68;
        }
        select,
        .chosen-container{
            display: block;
            position:relative;
            border: 0;
            border-bottom: 1px solid $blue-grey;
            width: 80%;
            color: #666666;
            //text-indent: 30px;
            font-size: 1.25em;
            font-weight: 100;
            line-height: 2em;
            //margin-bottom: 30px;
            .chosen-single {
                height:auto;
                padding: 6px 0;
                span{
                    //text-indent: 36px;
                    font-size:24px;
                    line-height:28px;
                    font-weight:300;
                }
                div b{
                    background: url(chosen-sprite.png) no-repeat 0 12px;
                }
            }
        }
        .chosen-single{
            background:none;
            border:0;
            box-shadow:none;
        }
        //svg{
        //    position:absolute;
        //    fill: $sick-green;
        //    left: 8px;
        //    bottom: 10px;
        //}
    }
	.tabwrap form {margin-bottom:30px;}
}