/* in columns */
.layout-style-columns  {
  display:table;
  padding-left: 130px!important;
}
.layout-style-columns .linkedFormWrapper + .linkedFormAddNew {
  padding-left: 20px;
}
.layout-style-columns .linkedFormWrapper {
  display:table-cell;
}
.layout-style-columns .linkedFormWrapper .jsonForm .jf-row {
  display:block;
  padding:4px;
  width:auto;
  vertical-align:bottom;
  text-align:right;
  margin:0px 2px 0px 0px;
}
.layout-style-columns .linkedFormWrapper .jsonForm .jf-row {

}
.layout-style-columns .linkedFormWrapper + .linkedFormWrapper .jsonForm .jf-row {
  padding-left:4px; min-width:inherit;
}
.layout-style-columns .linkedFormWrapper .jsonForm .jf-row > label {
  position: absolute;
  margin: 0;
  width: 125px;
  top: 0;
  bottom: 1px;
  white-space: nowrap;
  right: 100%;
  text-align: left;
  border-radius: 2px;
  background: rgba(0,0,0,0.05);
}
.layout-style-columns .linkedFormWrapper + .linkedFormWrapper .jsonForm .jf-row > label {
  display:none;
}