.modal_pdf{

    left: 0;

    .row{
        margin-bottom:8px;
    }
    .icon{
        display:inline-block;
        vertical-align: middle;
        height:20px;
    }
    svg{
        height:12px;
        width:12px;
    }

    &.active{
        .modal-overlay{
            &:before{
                height: unset;
            }

            .pdf-control-panel{
                position: fixed;
                top: 0;
                margin: auto;
                width: 100%;
                background-color: #333333;
                box-sizing: border-box;
                box-shadow: 0 5px 5px rgba(39, 39, 39, 0);
                display: flex;
                flex-direction: row;
                padding: 16px;
                -ms-flex-align: start;
                -webkit-align-items: flex-start;
                -ms-flex-line-pack: stretch;
                -ms-flex-align: stretch;
                align-items: stretch;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -ms-flex-direction: row;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -ms-flex-line-pack: start;
                align-content: flex-start;

                .control-panel-block{
                    -webkit-flex: 1 1 25%;
                    -ms-flex: 1 1 25%;
                    flex: 1 1 25%;

                    .doc-name{
                        color: $white;
                    }

                    .page-num-summary{
                        color: #868686;
                        font-size: 14px;
                        font-weight: normal;
                        margin: 0 10px;
                        &:hover{
                            color: #ffffff;
                        }

                        span[id^='prevPage-']{
                            margin-right: 20px;
                            cursor: pointer;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }

                        span[id^='nextPage-']{
                            margin-left: 20px;
                            cursor: pointer;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }
                    }

                    input[id^='page_input-']{
                        max-width: 32px;
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid;
                        color: #868686;
                        text-align: center;
                        &:focus{
                            color: #ffffff;
                        }
                    }

                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                        -moz-appearance: textfield;
                    }

                    .pdf-preview-icons-block{
                        .pdf-preview-icons {
                            display: inline-block;
                            width: fit-content;
                            padding-right: 25px;
                        }
                    }


                    .modal-close{
                        position: relative;
                        width: 40px;
                        right: unset;
                        top: unset;
                        z-index: unset;
                        color: $white;
                        font-weight: bold;
                    }
                }
            }

            .modal-contentWrap {
                width: unset;
                height: unset;
                padding: 0;
                margin-top: 5%;

                .modal-content{
                    .pdf-preview{
                        canvas[id^='canvas-'] {
                            border: 1px solid black;
                            direction: ltr;
                        }
                    }
                }
            }

            .icon-download {
                left: 50%;
                transform:translateX(-50%);
                fill: $white;
            }

            .icon-pdf-preview-close{
                left: 50%;
                transform:translateX(-50%);
                fill: $white;
            }

        }
    }
}