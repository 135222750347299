
.jsonForm {
    .fileuploader {
        position: relative;
        min-width: 300px;
        width: calc(100% - 6px);
        margin: 0 0 0 6px;
        //font-family: "proxima-nova", sans-serif;
        font-size: $input-fs;
        vertical-align: middle;
        color: $shady-lady;
        background-color: $white;
        border: 1px solid $border-color-req;
        box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $juniper;

        &.required {
            box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-req;
        }

        .thumb {
            width: auto;
            height: 34px;
            margin: 1px 1px 1px 3px;
            vertical-align: middle;
        }

        button {
            display: inline-block;
            flex: none;
            width: auto;
            padding: 0 14px;
            //font-family: "Roboto Condensed", sans-serif;
            font-size: $button-fs;
            line-height: 34px;
            font-weight: $button-fw;
            font-style: normal;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            text-indent: 0;
            vertical-align: middle;
            color: $btn-color;
            background-color: $btn-bg;
            border: 1px solid $btn-bg;
            transition: all .3s ease-out;

            &.deleteFileButton {
                background: #5B89B2;
            }

            &:hover {
                background-color: $btn-bg-hover;
                border: 1px solid $btn-bg-hover;
            }
            &:focus {
                outline: none;
            }
        }

        .hover {
            background-color: $btn-bg-hover;
            border: 1px solid $btn-bg-hover;
        }

        .chooseFile {
            position: relative;
            display: flex;
            min-height: 36px;
            margin: -1px;
            margin-right: 0;
            line-height: 36px;
            vertical-align: middle;
            text-overflow: ellipsis;
            white-space: nowrap;

            span {
                display: inline-block;
                float: none;
                //max-width: 150px;
                padding: 0 14px;
                vertical-align: middle;
                text-overflow: ellipsis;
                overflow: hidden;

                &.warning {
                    display: block; 
                    margin: 0px; 
                    padding: 0px 0px 0px 24px; 
                    color: #666;
                    background: rgb(255, 245, 192) url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="23px" height="16px" viewBox="0 0 23 16" enable-background="new 0 0 23 16" xml:space="preserve"><path fill="#FF9900" d="M8,2.48L2.612,14h10.777L8,2.48z M8,0L8,0c0.345,0,0.689,0.233,0.951,0.698l6.828,13.61 C16.303,15.239,15.857,16,14.791,16H1.208c-1.067,0-1.512-0.761-0.989-1.691l6.829-13.61C7.31,0.233,7.654,0,8,0L8,0z M7,12 c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S7,12.552,7,12z M8,6c0.552,0,1,0.448,1,1l-0.312,3H7.312L6.999,7 C6.999,6.448,7.447,6,8,6L8,6z"/></svg>') 4px center no-repeat;
                }
            }
        }

        .cropDisplay {
            display: block;
            margin: 0px 2px 2px;
            padding: 0px;
            background: rgb(240, 240, 240);
            border: 1px solid #ccc;
            border-radius: 2px;

            .cropWarning {
                min-height: 16px;
                margin: 4px; 
                padding: 4px 4px 4px 24px; 
                color: #666; 
                background: rgb(255, 245, 192) url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="23px" height="16px" viewBox="0 0 23 16" enable-background="new 0 0 23 16" xml:space="preserve"><path fill="#FF9900" d="M8,2.48L2.612,14h10.777L8,2.48z M8,0L8,0c0.345,0,0.689,0.233,0.951,0.698l6.828,13.61 C16.303,15.239,15.857,16,14.791,16H1.208c-1.067,0-1.512-0.761-0.989-1.691l6.829-13.61C7.31,0.233,7.654,0,8,0L8,0z M7,12 c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S7,12.552,7,12z M8,6c0.552,0,1,0.448,1,1l-0.312,3H7.312L6.999,7 C6.999,6.448,7.447,6,8,6L8,6z"/></svg>') 4px center no-repeat;
            }

            .imageInput,
            .imageOutput {
                display: inline-block;
                min-height: 60px;
                width: 50%;
                width: calc(50% - 6px);
                margin: 4px 0px 4px 4px;
                vertical-align: top;
                background-image:
                                -moz-linear-gradient(45deg, #ddd 25%, transparent 25%),
                                -moz-linear-gradient(-45deg, #ddd 25%, transparent 25%),
                                -moz-linear-gradient(45deg, transparent 75%, #ddd 75%),
                                -moz-linear-gradient(-45deg, transparent 75%, #ddd 75%);
                background-image:
                                -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, #ddd), color-stop(.25, transparent)),
                                -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, #ddd), color-stop(.25, transparent)),
                                -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.75, transparent), color-stop(.75, #ddd)),
                                -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.75, transparent), color-stop(.75, #ddd));
                background-size: 20px 20px;
                background-position: 0 0, 10px 0, 10px -10px, 0px 10px;
            }
        }

        .progressBar {
            position: absolute;
            flex: none;
            left: 0;
            bottom: -8px;
            width: 100%;
            overflow: hidden;
            background-color: #edeeee;

            bottom: -6px;
            background-color: inherit;
            height: 3px;

            b {
                display: block;
                width: 0%;
                height: 6px;
                background-color: $cerulean2;
                //box-shadow: 0 0 1px 0px rgba(0,0,0,0.6) inset;
                transition: all .25s;
            }
        }

        .otherButtons {
            position: absolute;
            flex: none;
            top: 0%;
            right: 0px;
            bottom: 0px;
            margin: 0;
            font-size: 0;

            button {
                height: 100%;
                margin: 0;

                &.uploadFileButtonNOTSUREIFWEWANTTHIS {
                    &::after {
                        position: absolute;
                        left: 100%;
                        content: "← click to upload file";
                        padding-left: 6px;
                        color: red;
                    }
                }

                &.deleteFileButtonNOTSUREIFWEWANTTHIS {
                    width: 36px;
                    font-size: 0;
                    text-indent: -1000px;
                    overflow: hidden;
                    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="4 4 32 32" enable-background="new 4 4 32 32" xml:space="preserve"><polygon fill="#aa0000" points="24.95,12.222 20.001,17.171 15.051,12.222 12.222,15.05 17.172,20 12.222,24.95 15.051,27.777 20.001,22.829 24.95,27.777 27.777,24.95 22.829,20 27.777,15.05 "/></svg>');
                    background-position: center center;
                    background-repeat: no-repeat;
                    border: 1px solid $border-color-req;
                }

                &.uploadFileButton {
                    position: relative;
                    margin-right: -1px;
                }

                &.cancelFileButton,
                &.replaceFileButton,
                &.deleteFileButton {
                    width: 36px;
                    margin: 0;
                    padding: 0;
                    font-size: 0;
                    text-indent: -1000px;
                    overflow: hidden;
                    background-color: $white;
                    background-position: center center;
                    background-repeat: no-repeat;
                    border: 1px solid $border-color-req;
                    border-right: none;

                    &:hover,
                    &.hover {
                        background-color: $dusty-gray2;
                        cursor: pointer;

                        &::after {
                            position: absolute;
                            display: inline-block;
                            left: 0%;
                            bottom: 100%;
                            height: auto;
                            margin: 0 0 6px 14px;
                            padding: 4px 7px;
                            font-size: 12px;
                            line-height: 1em;
                            text-indent: 0;
                            color: $tooltip-color;
                            background: $tooltip-bg;
                            border: none;
                            z-index: 88;
                        }
                        &::before {
                            position: absolute;
                            display: block;
                            content: "";
                            left: 0%;
                            bottom: 100%;
                            width: 0px;
                            height: 0px;
                            margin: 0 0 -2px 14px;
                            padding: 0;
                            overflow: hidden;
                            border: 4px solid transparent;
                            border-top-color: $tooltip-bg;
                            z-index: 88;
                        }
                    }
                }

                &.cancelFileButton {
                    //background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/cancelFile.svg');
                    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23979797%22%20fill-rule%3D%22evenodd%22%20d%3D%22M14%201.41L12.59%200%207%205.59%201.41%200%200%201.41%205.59%207%200%2012.59%201.41%2014%207%208.41%2012.59%2014%2014%2012.59%208.41%207z%22%2F%3E%0A%3C%2Fsvg%3E');

                    &:hover,
                    &.hover {
                        //background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/cancelFile.hover.svg');
                        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%20d%3D%22M14%201.41L12.59%200%207%205.59%201.41%200%200%201.41%205.59%207%200%2012.59%201.41%2014%207%208.41%2012.59%2014%2014%2012.59%208.41%207z%22%2F%3E%0A%3C%2Fsvg%3E');

                        &::after {
                            content: "Cancel";
                        }
                    }
                }

                &.replaceFileButton {
                    //background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/replaceFile.svg');
                    background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cpath%20fill%3D%22%23979797%22%20d%3D%22M11.032%2028.448c-4.524-4.866-4.444-12.49%200.254-17.222%201.924-1.936%204.328-3.092%206.828-3.472l-0.138-4.154c-3.508%200.426-6.904%201.992-9.594%204.7-6.298%206.344-6.372%2016.58-0.246%2023.064l-3.482%203.506%2011.020%200.602-0.030-11.668-4.612%204.644zM24.324%204.53l0.030%2011.668%204.614-4.646c4.524%204.866%204.446%2012.49-0.254%2017.222-1.924%201.938-4.328%203.092-6.83%203.472l0.138%204.152c3.51-0.426%206.906-1.992%209.596-4.702%206.296-6.342%206.374-16.58%200.244-23.062l3.482-3.506-11.020-0.598z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');

                    &:hover,
                    &.hover {
                        //background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/replaceFile.hover.svg');
                        background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M11.032%2028.448c-4.524-4.866-4.444-12.49%200.254-17.222%201.924-1.936%204.328-3.092%206.828-3.472l-0.138-4.154c-3.508%200.426-6.904%201.992-9.594%204.7-6.298%206.344-6.372%2016.58-0.246%2023.064l-3.482%203.506%2011.020%200.602-0.030-11.668-4.612%204.644zM24.324%204.53l0.030%2011.668%204.614-4.646c4.524%204.866%204.446%2012.49-0.254%2017.222-1.924%201.938-4.328%203.092-6.83%203.472l0.138%204.152c3.51-0.426%206.906-1.992%209.596-4.702%206.296-6.342%206.374-16.58%200.244-23.062l3.482-3.506-11.020-0.598z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');

                        &::after {
                            content: "Replace File";
                        }
                    }
                }

                &.deleteFileButton {
                    //background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/deleteFile.svg');
                    background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cpath%20fill%3D%22%23979797%22%20d%3D%22M6.778%2015.824l2.204%2019.418c0.118%200.922%204.57%203.954%2011.018%203.958%206.448-0.004%2010.902-3.036%2011.020-3.958l2.204-19.418c-3.368%201.884-8.402%202.776-13.224%202.776s-9.856-0.892-13.222-2.776zM26.334%203.824l-1.718-1.906c-0.662-0.942-1.382-1.118-2.784-1.118h-3.664c-1.4%200-2.12%200.176-2.784%201.118l-1.716%201.906c-5.14%200.898-8.868%203.28-8.868%205.036v0.342c0%203.092%206.804%205.6%2015.2%205.6s15.2-2.508%2015.2-5.6v-0.342c0-1.756-3.726-4.138-8.866-5.036zM24.138%208.278l-2.504-2.678h-3.268l-2.504%202.678h-3.4c0%200%203.724-4.44%204.222-5.042%200.38-0.46%200.77-0.636%201.274-0.636h4.086c0.504%200%200.892%200.176%201.274%200.636%200.496%200.602%204.222%205.042%204.222%205.042h-3.402z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');

                    &:hover,
                    &.hover {
                        //background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/deleteFile.hover.svg');
                        background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M6.778%2015.824l2.204%2019.418c0.118%200.922%204.57%203.954%2011.018%203.958%206.448-0.004%2010.902-3.036%2011.020-3.958l2.204-19.418c-3.368%201.884-8.402%202.776-13.224%202.776s-9.856-0.892-13.222-2.776zM26.334%203.824l-1.718-1.906c-0.662-0.942-1.382-1.118-2.784-1.118h-3.664c-1.4%200-2.12%200.176-2.784%201.118l-1.716%201.906c-5.14%200.898-8.868%203.28-8.868%205.036v0.342c0%203.092%206.804%205.6%2015.2%205.6s15.2-2.508%2015.2-5.6v-0.342c0-1.756-3.726-4.138-8.866-5.036zM24.138%208.278l-2.504-2.678h-3.268l-2.504%202.678h-3.4c0%200%203.724-4.44%204.222-5.042%200.38-0.46%200.77-0.636%201.274-0.636h4.086c0.504%200%200.892%200.176%201.274%200.636%200.496%200.602%204.222%205.042%204.222%205.042h-3.402z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');

                        &::after {
                            content: "Delete File";
                            left: 100%;
                            margin-left: -20px;
                        }
                        &::before {
                            left: 100%;
                            margin: 0 0 -2px -20px;
                        }
                    }
                }
            }
        }

        .foEl {
            position: absolute;
            top: -5000px;
            left: -5000px;

            &.error, 
            &.valid {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                min-height: 100%; /* new jan height fix */
                width: 0;
                margin: 0;
                padding: 0;
                overflow: hidden;
                opacity: 0;
                background: none;
                border: none;
                box-shadow: none;
                pointer-events: none;
            }
        }
    }

    @include media('<=desktop') {
        .fileuploader {
            width: 100%;
            margin-left: 0;
            border-left: 4px solid $line-color-req;
            box-shadow: none;
        }
    }
}

::-webkit-file-upload-button {
    cursor: pointer;
}