// THe social icons around the site have been removed in the corporate and interim skins. Corporate brings them back
// in the footer as "go to social" rather than "share" functionality
.social-icons-wrap {
    @if ($theme == corporate OR $theme== interim OR $theme == professions) {
        display: none;
    }
    ul.social-icons {
        text-align: left;
        list-style-type: none;
        @if ($theme == corporate OR $theme == interim  OR $theme == professions) {
            display: none;
        }
    }
}

ul.social-icons {
    text-align: left;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 32px;

    //Below is to align this block with the "Stay Connected" title below
    margin-left: -12px;
    width: 168px;

    @include media("<=phoneland"){
        margin-bottom: 0;
    }

    li {
        display: block;
        vertical-align: middle;
        border-right: 1px solid map_get($footer, title);

        flex: 1 1 24%;

        &:last-child {
            border-right: 0 none;
        }

        a {
            display: block;
            text-align: center;
            line-height: 25px;
            svg {
                vertical-align: middle;
                transition: fill 250ms;
                fill: map_get($footer, text);
            }

            &:hover, &:active {
                svg {
                    &.icon-twitter {
                        fill: #55ACEE;
                    }
                    &.icon-facebook {
                        fill: #3B5998;
                    }
                    &.icon-linkedin {
                        fill: #0976B4;
                    }
                    &.icon-google-plus {
                        fill: #DD4B39;
                    }
                }
            }
        }
    }
}
