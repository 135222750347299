.documents-page{
	.documents-block {
		display: inline-block;
	}
	.documents-year-block {
		display: inline-block;
		min-width: 100px;
	}
	.documents-year-block .chosen-single {
		min-width: 100px;
	}
	.documents-year-block .chosen-drop {
		min-width: 100px;
	}
	button {
		padding: 15px;
		width: 62px;
	}
	.main-content{
		background-color: $alabaster;
		position: relative;
	}
	.main-content:after {
		background-color: $alabaster;
		position: absolute;
		display: block;
		top: 0px;
		left: -2500px;
		right: -2500px;
		content: '';
		height: 100%;
		z-index: -1;
	}

	.sidebar{
		padding: 0 16px;
		margin-top: 0;
	}

	.downloads-widget{
		//height: 100%;
		margin-top: 0;
	}
	.widget-title{
		padding: 32px 0 12px 0;
	}

	span.insurance-type-name {
		display: block;
	}
	span.additional-info {
		display: block;
		font-size: 13px;
		font-weight: 500;
	}
	.multiDownloadCheckbox{
		display: flex;
		justify-content: center;
		align-items: center;
		label{
			margin-right:0;
		}
	}
}

.modal_documents {
	.row{
		margin-bottom:8px;
	}
	.icon{
		display:inline-block;
		vertical-align: middle;
		height:20px;
	}
	svg{
		height:20px;
		width:20px;
	}
}