.three-options {
    .three-options-section {
        padding-top:0;
        .flex-parent {
            justify-content: space-between;
            align-content: space-between;
            .block-getAQuoteOption {
                max-width: 424px;
                min-width: 310px;
                width: 100%;
                //height: 366px;
                height: 400px;
                background-color: #212f38;
                /* margin-top: 20px; */
                border:none;
                @media screen and (max-width: 388px){
                    min-width: 310px;
                }
                &:nth-child(2), &:nth-child(3){
                    margin-left: 5px;
                    @media screen and (max-width: 812px){
                        margin-left: unset;
                    }
                }

                .three-options-heading {
                    max-width: 80%;
                    width: 100%;
                    margin: auto;
                    margin-top: 7%;
                    .getAQuoteOption-heading {
                        color: #ffffff;
                        font-size: 38px;
                        font-family: AGaramondPro,serif;
                        margin: unset;
                        line-height: 1.15em;
                    }
                }

                .getAQuoteOption-icon {
                    .svg-wrap {
                        background: none;
                        border-radius: unset;
                        .icon-file-alt {
                            width: 59px;
                            height: 59px;
                        }

                        .icon-cloud-upload {
                            width: 89px;
                            height: 59px;
                        }

                        .icon-phone-volume{
                            width: 57px;
                            height: 57px;
                        }
                    }
                }

                a.btn_a {
                    width: 100%;
                    max-width: initial;
                    height: 66px;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: 'Gotham A',"Gotham B", Helvetica, Arial, sans-serif;
                    /*looks lame - text is at top. center it: */
                    display: flex;
                    align-items: center;
                    justify-content: center;      
                }
            }
        }
    }

    #modal-callback, #modal-upload, #modal-complete-proposal {
        .getAQuoteOption-icon {
            width: 68px;
            margin-right: 1em;
            display: inline-block;
            vertical-align: middle;
        }
        #uploadFormInfo{
            max-width: unset;
            margin: 35px 0 50px;
        }

        .dialog-title {
            font-family: AGaramondPro,serif;
        }

        .focb::before {
            position: relative;
            display: block;
            content: "";
            width: 23px;
            height: 23px;
            margin: 0;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            background-color: #fff;
            border: 1px solid #f5a623;
        }

        .jsonForm input[type=radio]:checked+.focb::before {
            border-color: #212f38;
        }

        .jsonForm input[type=radio]+.focb::before {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }

        .jsonForm input[type=checkbox]:checked+.focb::before {
            content: "\2714";
            color: #fff;
            background-color: #90ce51;
            border-color: #90ce51;
        }

        .jsonForm label {
            font-size: 13px;
        }
    }
}