/*Issues Summary*/
#issues {
  position:fixed;
  top:-100%; bottom:100%;
  left:0; right:0;
  margin-top:0;
  overflow:auto;
  z-index:9999;
  background-color:#ffffff;
  overflow:auto;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  padding:0!important;
}



#issues .issues,
#issues .todo,
#issues .complete {height:0; overflow:hidden; padding:0;}

#issues .title {
  @include font('title', 30px, $almost-black, 300,  1);
  background-color: $light-grey-seven;
  padding: 32px;
}
#issues ul {list-style-type:none; padding-left:30px;}
#issues ul li {position:relative; margin-bottom:20px;}
#issues ul li a {
  @include font('body', 17px, $almost-black, 300,  2.12);
}
#issues ul li a span {
  @include font('body', 15px, $bright-red, 300,  1 );
  width:100%;
  display:none;
}

//custom style for to do list (differs from issues list)
#issues .todo ul li {
	padding-bottom: 20px;
	border-bottom: 1px solid $alto;
	a {
		color: $burnt-orange;
		margin-left: 30px;
		display: inline-block;
		&:hover {
			color: $hover-primary;
		}
	}
}
	
#issues ul li a span + span {background:$sick-red; color:white; text-transform:uppercase; font-size:14px; width:auto; padding:2px 18px 2px 8px; position:relative;}

.invalid #issues .issues {height:100%; overflow:auto; background:$white; /*background:rgba(247,233,227,0.5);*/ padding:20px 0 50px 0;}
.invalid #issues .issues ul li a span {display:inline-block;}
.invalid #issues .issues ul li:before {color:$white;}

.incomplete #issues .todo {height:100%; overflow:auto; background:$white; /*background:rgba(251,176,64,0.15);*/ padding:20px 0 50px 0;}
.incomplete #issues .todo ul li:before {color:$white;}

.complete #issues .complete {height:100%; overflow:auto; background:$white; /*background:rgba(149,189,62,0.3);*/ padding:20px 0 50px 0;}


