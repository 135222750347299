.childRow{
    width:100%;
}

.pf-admin .dataTable .dt-docVisibility{
    border-collapse: separate;
    border-spacing: 0 2px;
    td{
        border: 1px solid $dark-grey;
        line-height:24px;
        &:first-child{
            width:40%;
        }
        &:nth-child(2){
            background:$corp-silver;
            max-width:200px;
            white-space: nowrap;
        }
        .slider-label{
            display: inline-block;
            margin-right:4em;
        }
        &:last-child{
            text-align:right;
        }
        .switch{
            margin:0 .4em;
        }
    }
}