.pf-view{
    body, html {
        height: 100%;
        min-height: 100%;
        margin: 0;
        overflow: hidden;
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}