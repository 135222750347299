
.resource-filter-select,
.filter-select,
.resource-filter-image{
    width:100%;
    appearance: none;
    option{
        padding: 13px 24px;
    }
    & + .chosen-container .chosen-single,
    & + .chosen-container .chosen-drop li{
        padding: 13px 20px;
        height:auto;
        @include font(body, 15px, false, false, 2.16);

    }
    & + .chosen-container .chosen-drop li:hover{
        background-color:$corp-white;
        color:$almost-black;
    }
    & + .chosen-container .chosen-drop li.result-selected {
        background-color:transparent;
        color:$iron-two;
    }
}

.flex-parent > .filter-button-wrapper{
    flex-basis:92px;
    max-width:92px;
    min-width:0;
    align-self:flex-end;
    svg{
        fill:$white;
    }
    .btn{
        padding:18px 20px;
    }
}


.page-header .resources-grid.flex-parent,
.page-header .resources-grid.flex-parent > .block{
    padding-top:0;
}

.resources-filters .title{
    @include font(body, false, false, $dark, 1.75);
    margin-bottom:1.5em;
    @include media("<=phoneland"){
        margin-bottom:.9em;
    }
}

.resources-grid.flex-parent > .block:first-child:before{
    display:none;
}

#text-search-container {
    // This is for the resource center search
    position: relative;

    padding-top: 10px;
    padding-bottom: 10px;

    .btn{
        padding: 14px 20px;
    }

    .flex-parent{
      margin: 0 0 0 0;
      padding: 4px 0px 4px 0px;
    }

    margin-top:2em;

    @include media('<tabport') {
        padding-top: 22px;
        padding-bottom: 22px;
        
        display:block;
    }

    @include media('<phoneland') {
        padding-top: 28px;
        padding-bottom: 32px;
    }

    &:after{
        left:-50%!important;
        content:'';
        position:absolute;
        background: $charcoal-grey;
        top:0;
        bottom:0;
        height:100%;
    }

    //.resource-centre-filters-container{
    //    width:250px;
    //}
    .single-block {
        position: relative;
        z-index: 3;
        //width:250px;

        .block-title {
            @include font(body, 17px, $white, 300, 76px);

            padding: 0 0;

            max-width: 250px;

            @if ($theme == interim) {
                color:$dark;
            } @else{
                color: $white;
            }


            @include media('<tabport') {
                line-height: 20px;

                margin-bottom: 15px;
                padding-left:0px;
                
            }
            @include media('<=phoneland') {
                font-size: 18px;
                line-height: 22px;
                padding-left:0px;
            }
        }
        .search-form-wrap {
            //float: left;
            padding:12px 0;

            display:flex;
            flex-wrap:nowrap;


            @include media('<tabport') {
                padding:0;
            }
            .search-field-wrap {
                /*flex: 1 1 80%;*/
                @include media('<tabport') {
                    margin-top:-28px;
                }
                @include media('<phoneland') {
                    margin-top:-6px;
                    width: 100%;
                }

                .search-field {
                    font-size: 16px;
                    line-height: 30px;

                    box-sizing: border-box;
                    width: 539px;
                    height: 52px;
                    padding: 0 10px;

                    color: $greyish-brown;
                    border: solid 1px $greyish-brown;
                    background-color: $white;


                    @include media('<wide-tab') {
                        width: 273px;
                    }
                    @include media('<tabport') {
                        width: 100%;
                    }
                    &:focus {
                        border: 1px solid rgba(0,134,139,.5);
                        box-shadow: inset 0 0 5px rgba(0,134,139,.3);
                    }
                }
            }
            .submit-btn-wrap {
                margin-left: 15px;
                flex: 1 0 58px;
                max-width:58px;

                @include media('<tabport') {
                    //float: right;
                    margin-top:-35px;
                }
                @include media('<phoneland') {
                    //float: right;
                    margin-top:-6px;
                }
                .submit-btn {
                    height: 30px;
                }
                svg{
                    fill:$white;
                }
            }
        }
    }
}

//This has to be over specific due to page-header styles being specific
.resources-page .resources-filters > .flex-parent > .block{
    flex-basis:100%;
    padding:0;
}

.resources-filters .flex-parent .flex-parent{
    margin:0 -32px -20px -32px;
    @include media("<=wide-tab"){
        margin:0 -16px 16px -16px;
    }
    @include media("<=phoneland"){
        margin:0 -8px 8px -8px;
    }
}


//Lose padding on resources pagese
.resources-filters + main{
    .search-results .resources-grid{
        padding-top:0;
        margin-top:0;
    }
}

.resources-filters .ajax-form > .flex-parent{
  padding-bottom:0;
}