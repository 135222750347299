.widget-console{
    position:relative;
    svg{
        fill:$body-text;
        margin-right:.25em;
    }
    .title{
        @include font('body', 18px, $body-text, 500,  1.56);
    }
    address{
        @include font('body', 14px, $body-text, 300,  1.56);
        margin-bottom:.5em;
    }
    ul{
        list-style-type: none;
    }
    .edit-client{
        position:absolute;
        top:0;
        right:0;
        font-size: 14px;
        font-weight: 300;
        color:$link-primary;
        svg{
            vertical-align:middle;
            margin-right:0;
            margin-left:.5em;
            fill:$link-primary;
        }
    }
	a{font-size:14px!important;}
}