.partners #main{
    .block{
        &:nth-child(odd){ // This is the body text
            flex-basis:60%;
            @include media("<=phoneland"){
                order:2;
            }
        }
        &:nth-child(even){ // This is the partner image
            flex-basis:30%;
            @include media("<=phoneland"){
                order:1;
            }
        }
    }
}
.partner-title{
    @include font("body",20px,$dark,500,1.4);
    margin-top:0;
}