
/**************** GENERAL ******************/

$stunnPlate-bounds: 1332px;
$stunnPlate-h-padding: 32px;
$wrapper-limit: 706px;

/***************** FONTS *******************/

// Legend
$legend-fs: 20px;
$legend-lh: 26px;
$legend-fw: 400;

// Labels
$label-fs: 13px;
$label2-fs: 12px;
$label-lh: 16px;

// Inputs
$input-fs: 13px;

// Tooltips and Boxes
$tooltip-fs: 12px;
$tooltip-lh: 15px;
$tooltip-fw: 500;

// Buttons
$button-fs: 12px;
$button-fw: 500;

// Datatable & Labels (Repeatable regions)
$th-fs: 11px;
$th-fw: 500;

// Datatable
$td-fs: 13px;
$th2-fw: 700;
$th3-fw: 400;

// Switch On|Off
$switch-fs: 13px;
$switch-fw: 700;

// Intro & Radio Label
$intro-fs: 13px;
$intro-lh: 16px;
$intro-fw: 400;

/***************** COLORS *******************/

$white: #ffffff;
$buttercup: #f5a623;
$woodsmoke: #060708;
$alabaster: #f7f7f7;
$dusty-gray: #9b9b9b;
$dusty-gray2: #979797;
$juniper: #67868e;
$off-yellow: #fefcdf;
$monza: #d0021b;
$atlantis: #93be30;
$atlantis2: #90ce51;
$cerulean: #00a9cc;
$cerulean2: #01BEE5;
$cerulean3: #00A9CC;
$botticelli: #cfe5e9;
$humming-bird: #e2f7fb;
$tundora: #4a4a4a;
$ebony-clay: #212f38;
$salt-box: #676668;
$silver-sand: #c8c9ca;
$silver-sand2: #bbbdbd;
$lima: #7ed321;
$green-leaf: #417505;
$submarine: #b7bfc4;
$shady-lady: #b2b1b2;
$iron: #dfe0e1;
$iron2: #e3e3e4;
$black-haze: #edeeee;
$alto: #d4d4d4;
$shark: #20262a;
$athens-gray: #eeeeef;

// Global
$text-color: $woodsmoke;
$placeholder-color: $shady-lady;
$el-color-err: $monza;

// Backgrounds (Elements)
$el-bg: $white;
$el-bg-req: $white;
$el-bg-disabled: $alabaster;
$el-bg-focus: $off-yellow;
$el-bg-inverse: $outer-space;

// Borders
$border-color: $juniper;
$border-color-req: $dusty-gray;
$border-color-err: $monza;
$line-color: $juniper;
$line-color-req: $buttercup;
$line-color-err: $monza;
$line-color-val: $atlantis;

// Checkbox & Radio
$checkbox-color: $white;
$checkbox-bg: $el-bg-req;
$checkbox-bg-inverse: $el-bg-inverse;
$checkbox-bg-hover: $el-bg-focus;
$checkbox-bg-hover-inverse: $charcoal-grey;
$checkbox-border: $dusty-gray;
$checkbox-border-hover: $atlantis;
$checkbox-border-hover-inverse: $charcoal-grey;
$checkbox-bg-checked: $atlantis2;
$checkbox-border-checked: $atlantis2;
$checkbox-border-req: $line-color-req;
$radio-border-hover: $dusty-gray;
$radio-dot-hover: $silver-sand;
$radio-border-checked: $ebony-clay;
$radio-border-req: $line-color-req;
$radio-dot-checked: $atlantis;

// Switch On|Off
$switch-color: $checkbox-color;
$switch-border: $dusty-gray;
$switch-off-bg: $silver-sand2;
$switch-on-bg: $lima;
$switch-sw-bg: $white;
$switch-sw-line: $submarine;

// Buttons
$btn-color: $white;
$btn-bg: $cerulean2;
$btn-bg-hover: $cerulean;

$btn2-color: $text-color;
$btn2-bg: $white;
$btn2-border: $dusty-gray;
$btn2-color-hover: $white;
$btn2-bg-hover: $shark;

// Tooltips
$tooltip-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
// ---
$tooltip-bg: $cerulean;
$tooltip-color: $white;
// ---
$tooltip2-bg: $humming-bird;
$tooltip2-color: $tundora;
$tooltip2-border: $botticelli;
// Contact Actions
$tooltip3-bg: $ebony-clay;

// Tooltip (Error)
$tooltip-bg-err: $monza;
$tooltip-color-err: $white;

// Not applicable box
$na-box-bg: $white;
//$na-box-color: $dusty-gray;
$na-box-color: $dusty-gray;
$na-box-border: $iron;
//$na-box-border: $iron;

// Repeatable Footer Area
$rfa-bg: $alabaster;
$rfa-border: $alto;

// Headings
$head-bg: $alabaster;


/***************** MEDIA QUERIES **************/

$breakpoints: (
  'phoneport'	    : 320px,
  'phone'	        : 480px,
  'phoneland'	    : 568px,
  'tabport'		    : 768px,
  'wide-tab'	    : 992px,
  'desktop'		    : 1024px,
);