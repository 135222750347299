.contact-tabs {
    padding: 0;

    background-color: transparent;

    @include media("<=tabport") {
        margin-left: -32px;
        margin-right: -32px;
    }
    @include media("<=phoneland") {
        margin-left: -16px;
        margin-right: -16px;
    }
    .tab-tops {
        display: flex;

        margin: 0 0 8px;
    }
    .tab-title {
        font-size: 28px;
        line-height: 1.39em;

        position: relative;

        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        border-radius: 0;

        flex-basis: 30%;
        flex-grow: 1;


        &:first-child {
            border-left: 0;
        }
        &:last-child {
            border-right: 0;
        }
        a {
            font-weight: 300;

            width: 100%;
            margin: 0;
            padding: 18px 64px 16px 32px; //Allow for open-close-icon

            opacity: 1;
            color: $tab-heading-color;
            border: 0 none;
            background-color: $tab-heading-bg;
            
            @include media("<=1100px") {
                padding-left:24px;    
            }

        }
        &.active {
            a {
                color: $tab-heading-color_active;
                background-color: $tab-heading-bg_active;
                &:hover {
                    color: $tab-heading-color_active;
                }
            }
        }
    }
    .contact-tab {
        font-weight: 300;

        color: $slate-grey;
        border: 1px solid $light-grey-four;
        border-bottom-width: 6px;
        > div > div {
            padding: 16px 0 32px 0;

            border-bottom: 0 none;
        }
    }
    .icon-phone,
    .icon-contact-email,
    .icon-letter {
        position: relative;
        top: -4px;

        width: 20px;
        height: 20px;
        margin-right: 6px;

        vertical-align: middle;

        fill: $dark-slate-blue-two;
    }
    &[max-width~='950px'] {
        padding: 0;
        // border:1px solid black;
        // border-width:0px 1px;
    }
    &[max-width~='950px'] > ul {
        display: none;
    }
    &[max-width~='950px'] > section {
        display: block;
        // background:white;

        border: 0;
    }
    &[max-width~='950px'] > section > a {
        font-size: 21px;
        font-weight: 300;
        line-height: 1.34em;
        // border:1px solid black;
        // border-width:1px 0;

        position: relative;

        display: block;

        padding: 18px 64px 16px 32px; //Allow for open-close-icon

        text-decoration: none;

        color: $tab-heading-color;
        background: $tab-heading-bg;

        @include media("<=phoneland") {
            padding-left:16px;
        }
    }
    &[max-width~='960px'] > section.active > a {
        color: $tab-heading-color_active;
        border: 0 none;
        background-color: $tab-heading-bg_active;
        &:hover {
            color: $tab-heading-color_active;
        }
    }
    .tabtrigger .icon-open-close{
        @include media("<=tabport") {
            right:32px;
        }
        
        @include media("<=phoneland") {
            right:16px;
        }
    }
}

@if $site == 'mobility' {
    #t-customerServices .block {
        font-weight: 300;

        padding-top: 0;
        padding-bottom: 0;

        border-right: 1px solid $slate-grey;
        border-left: 1px solid $slate-grey;

        flex-basis: 30%;
        flex-grow: 1;
        @include media('<=960px') {
            border-right: 0 none;
            border-bottom: 1px solid $slate-grey;
            border-left: 0 none;

            flex-basis: 100%;
        }
        &:first-child {
            border-left: 0 none;
            .padded {
                padding-left: 0;
            }
        }
        &:last-child {
            border-right: 0 none;

            @include media('<=960px') {
                border-bottom: 0 none;
            }
            .padded {
                padding-right: 0;
            }
        }
        .contact-label {
            margin-top: 0;
            margin-bottom: 0;
        }
        .padded {
            padding-right: 16px;
            padding-left: 16px;

            @include media('<=960px') {
                padding: 24px 0;
            }
        }
        .contact-info {
            font-size: 24px;
            font-weight: 900;
            line-height: 1.62em;

            display: block;

            margin-bottom: .64em;

            color: $dark-slate-blue-two;
            a {
                font-size: 24px;
                font-weight: 900;
                line-height: 1.62em;

                color: $dark-slate-blue-two;
                &:hover {
                    color: lighten($dark-slate-blue-two,10%);
                }
                @include media("<=1100px") {
                    font-size:22px;
                }
            }
        }
        .contact-meta {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.43em;
            .meta-title {
                font-size: 16px;
                line-height: 1.38em;

                margin-top: 0;
            }
        }
        table {
            width: 100%;
        }
        td {
            padding-bottom: .6em;
        }
        td + td {
            text-align: right;
        }
        .img-family {
            float: right;

            width: 146px;
            height: 227px;
            margin-left: 1.5em;

            @include media('<=1100px') {
                width:100px;
                height:155px;
            }

            @include media('<=960px') {
                display: none;
            }
        }
    }
    #t-makeAClaim {
        .img-family {
            float: right;

            width: 260px;
            margin: 0 100px 0 40px;

            @include media('<1024px') {
                margin: 0 0 0 33px;
                width:200px;
            }
            @include media('<=phoneland') {
                margin: 0 0 0 24px;
                width: 100px;
            }
        }
        p {
            font-size: 26px;
            line-height: 1.5em;

            @include media('<=960px') {
                font-size: 22px;
            }

            @include media('<=phoneland') {
                font-size: 16px;
            }
        }
    }
    #t-haveAProblem {
        .headline {
            font-size: 26px;
            line-height: 1.5em;
        }
        @include media("<=tabport") {
            .flex-parent > .block{
                flex-basis:100%;
            }
        }
    }
}
