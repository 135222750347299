.stream-block{
    .block-title{
        @include font("body", 26px, $body-text, 500,  1.42);
        margin-top:0;
    }
    .article-readmore{
        opacity:1;
        padding-top:1em;
        color:$link-primary;
    }
    &:hover{
        .article-readmore{
            color:$hover-primary;
        }
    }
}