.insurances-edit{
    .chosen-container {
        //min-width: 250px;
    }
    body > div:not(#headband) {
        position: relative;
        padding-bottom: 110px;
    }
    .saveSection {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .jsonForm {
        position: static;
    }

    #changeStaff {
		/*
		Not needed. there is a class in _chosen.scss:31 that overrides all .jsonForm .chosen-container having inline style
		div#role_chosen{
			width: auto!important;
		}
		div#staff_chosen{
			width: auto!important;
		}
		*/

        div#wrapper-for-PrimaryContact {
            white-space: nowrap;
        }

        .jf-input-type-submit {
            min-width: 100px !important;
            max-width: 100px !important;
            width: 0.1%;

            input[type="submit"] {
                //padding: 9px 6px;
                padding: 6px 6px;
                height: 36px;
            }

            .deleterow {
                padding: 7px 6px;
                background: $white;
                border-color: #979797;
            }

            .icon-jf-delete {
                fill: #979797;
            }
        }

        .jf-input-type-delete {
            min-width: 44px !important;
            max-width: 44px !important;
            width: 0.1%;
        }
    }
}