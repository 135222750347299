.off-canvas-controls {
    min-width:100px;
    max-width: 200px;
    flex-basis:300px;
    padding-left:60px!important;
    display: block;
    background: map_get($headerFooter, bg-dark);

    @include media("<=wide-tab") {
        display: block;
    }

    .off-canvas-open-notifications{
        @if ($site == lsos) {
            @include media("<=450px") {
                display:none;
            }
        }
    }

    .off-canvas-open-login{
        @if ($site == lsos) {
            @include media("<=450px") {
                display:none;
            }
        }
    }

    .off-canvas-print #pdfdownloadbutton {
            vertical-align: unset!important;
    }

    background: linear-gradient(120deg, transparent 0%, transparent 60px, map_get($headerFooter, bg-dark) 60px); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    &:after {
        position: absolute;
        display: block;
        top: 0;
        left: 100%;
        right: -2500px;
        height: 100%;
        content: '';
        background: map_get($headerFooter, bg-dark);
    }
    ul {
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        width:100%;

        & > li.hasNotifications:after{
            display: block;
            background: $accent-secondary;
            position: absolute;
            top: 11px;
            right: 12px;
            width: 9px;
            height: 9px;
            border-radius: 100%;
            content:attr(data-notificationCount);
        }

        li {
            flex: 1 1 auto;
            color: $white;
            padding:0 9px;
            max-width:42px;
            text-align:center;
            &.off-canvas-open{
				 padding-right:0;
				 max-width:none;
				 text-align:right;
				 cursor:pointer;
			}
			&.off-canvas-open-notifications{
				min-width:50px
			}
			&.off-canvas-open-login{
				min-width:50px
			}
            &:hover{
                cursor:pointer;
            }
            svg {
                vertical-align: middle;
                fill:map_get($headerFooter, colour);
                transition:fill 300ms ease;
            }
            svg:hover{
                fill:$white;
            }
        }
    }

    &.off-canvas-controls_v2 {
        ul li span {
            display: none;
        }

        @media screen and (min-width: 569px) and (max-width: 991px) , screen and (min-width: 1200px) {
            max-width: 270px;
            min-height: 104px;

            .main-nav > & {
                padding-top: 40px;
                padding-bottom: 15px;
            }

            ul {
                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    flex: 0 1 auto;
                    max-width: none;
                    text-align: center;
                    color: #fff;

                    span {
                        display: inline-block;
                        margin-top: 6px;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 15px;
                    }

                    &.off-canvas-open {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}