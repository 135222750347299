.pageBlock{
    font-size:18px;
    font-weight: 300;
    line-height: 1.56;
    background-image:$sales-bg-img;
    background-size:cover;
    background-position-x: right;
    @include media("<=phoneland"){
        padding-bottom:0;
    }
    .article-heading{
        @include font("title",48px,$dark,400,1.21);
        margin-top:0;
        @include media("<=675"){
            font-size: 32px;
            line-height: 1.14em;
        }
    }
    .btn{
        margin-top:2em;
        text-align:center;
    }
    &.pageBlock_inverted{
        background:$b_background;
        background-image:none;
        color:$white;
        .article-heading{
            color:$white;
        }
    }
}


.pageBlock{

}
// Needs to be replaced once this block is refactored
//.sales-accountants-head{
//    font-size:18px;
//    font-weight: 300;
//    line-height: 1.56;
//    background-color: $cod-grey;
//
//    .intro-one{
//        color: $aqua-blue;
//        font-size: 20px;
//        font-weight: 500;
//        line-height: 1.8;
//
//        @include media("<=phoneland"){
//            font-size: 18px;
//        }
//
//    }
//
//    .intro-two{
//        color: $white;
//        font-size: 16px;
//        font-weight: 300;
//        line-height: 1.75;
//        @include media("<=phoneland"){
//            font-size: 15px;
//            line-height: 1.6;
//        }
//    }
//}

.icon-circle-bullet{
    fill:$accent-primary;
    margin-right:0.75em;
}

//Should be refactored into lists so they can be used everywhere (these currently exist in sales_medica.scss too
//.sales-list{
//    list-style-type: none;
//    li{
//        padding:3px 0;
//        position:relative;
//        svg{
//            position:absolute;
//            left:-20px;
//            top:12px;
//        }
//    }
//}
//
//.counter-list{
//    list-style-type: none;
//    margin-left:0;
//    padding-left:0;
//    padding-bottom:2em;
//    .counter{
//        width:36px;
//        line-height:36px;
//        color:$white;
//        display:inline-block;
//        text-align:center;
//        vertical-align: middle;
//        margin-right:.5em;
//        font-weight:400;
//        border-radius:100%;
//        background:#5f7f8d;
//
//        font-size: 20px;
//        font-weight: 700;
//        height: 36px;
//        @include media("<=phoneland"){
//            position:absolute;
//            left:0;
//        }
//    }
//    li{
//        margin-bottom:3px;
//        position:relative;
//        @include media("<=phoneland"){
//            margin-bottom:.5em;
//            padding-left:44px;
//        }
//    }
//}
