.ie9 .two-column-grid .block {	
	max-width: none !important;

	@media only screen and (min-width: 992px) {
		width: 50% !important;
	}
	@media only screen and (max-width: 991px) {
		width: 100% !important;
	}
}
.ie9 .three-column-grid .block  {
	max-width: none !important;

	@media only screen and (min-width: 768px) {
		width: 33.33% !important;
	}
	@media only screen and (max-width: 767px) {
		width: 100% !important;
	}
}

.ie9 .lockton-top-section .two-column-grid .block {
	@media only screen and (min-width: 569px) and (max-width: 991px) {
		width: 50% !important;
	}
}

.ie9 .main-nav-wrap {
	display:table-cell;
	text-align: right;
	vertical-align: middle;
}

.ie9 .main-nav-wrap .flex-ul {
	display: block;
}
.ie9 .main-nav .nav-icons-wrap {
	@media only screen and (max-width: 1040px) {
		display: none !important;
	}
}

.ie9 body.contact-page .contact-top-section .two-column-grid .left-column,
.ie9 body.member-detail-page .lockton-top-section .two-column-grid .left-column,
.ie9 body.get-a-quote-page .lockton-top-section .two-column-grid .left-column,
.ie9 body.insurance-services-index-page .lockton-top-section .two-column-grid .left-column,
.ie9 body.insurance-service-detail-page .lockton-top-section .two-column-grid .left-column,
.ie9 body.our-story-page .lockton-top-section .two-column-grid .left-column  {	

	@media only screen and (min-width: 992px) {
		width: 66% !important;
	}
	@media only screen and (max-width: 767px) {
		width: 100% !important;
	}
}
.ie9 body.contact-page .contact-top-section .two-column-grid .right-column,
.ie9 body.member-detail-page .lockton-top-section .two-column-grid .right-column,
.ie9 body.get-a-quote-page .lockton-top-section .two-column-grid .right-column,
.ie9 body.insurance-services-index-page .lockton-top-section .two-column-grid .right-column,
.ie9 body.insurance-service-detail-page .lockton-top-section .two-column-grid .right-column,
.ie9 body.our-story-page .lockton-top-section .two-column-grid .right-column  {	

	@media only screen and (min-width: 992px) {
		width: 34% !important;
	}
}
.ie9 .filtered-items-parent {
	overflow: hidden;
}
.ie9 body.contact-page .four-column-grid .block {
	width: 25% !important;
	

	@media only screen and (max-width: 1199px) {
		width: 29.33% !important;
		min-width: none !important;
		max-width: none !important;
		padding: 0 !important;
		margin: 2% !important;
		overflow: hidden !important;
	}
	@media only screen and (max-width: 870px) {
		width: 46% !important;
	}
	@media only screen and (max-width: 620px) {
		width: 96% !important;
	}

}

html {
	height: auto !important;
}
.contact-page, .member-detail-page {
	display: block !important;
	width: 100% !important;
	max-width: 100% !important;
	overflow-x: hidden !important;

	#main {
		max-width: 100% !important;
	}
}

.ie9 select {
	background-image: none !important;
}

.ie9 .auxiliary-page .page-container {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: table !important;
	vertical-align: middle;
}
.ie9 .auxiliary-page .page-container .main-container {
	display: table-cell !important;
	vertical-align: middle;
	max-width: none;
}
.ie9 .auxiliary-page .page-container .main-container .content-wrap {
	margin: 0 auto !important;
	width: 100%;
	max-width: 615px;
}
.ie9 .auxiliary-page.password-reset-page .page-container .main-container .content-wrap {
	max-width: 468px;
}
