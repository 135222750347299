/*
(borderline shame...)
 webshims is a static resource that live here:
 /Lockton-Pf-Administrator/src/main/resources/static/libs/webshims/shims/
 Its not very well styled and is inheriting thing from our main stylesheet
 This page is basically the override to that in order to not look so whack.
 */
.ws-popover {
	z-index:110000;
}
.pf-admin .ws-popover button {// all button plus next/prev arrows
	display: inline-block;
	color: #222222!important;
}
.pf-admin button.ws-popover-opener { //the calendar icon
	color: $aqua-blue!important;
}
.input-picker .ws-button-row>button {//'today' and 'clear' buttons
	width: inherit;
}
.input-picker .picker-list td>button:hover, .input-picker .picker-list td>button.checked-value {//selected date
	color: #fff!important;
	background: #000;
}
.ws-picker-controls>button {
	display: inline-block!important;
}