
.pf-admin {

  .list-menu {
    padding: 16px 16px 17px 16px;
    border-radius: 2px;
    box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px $gunmetal;
    background-color: $white;
    display: none;
    //width: 200px;
    //left: -200px;


    &.one-item {
      padding: 10px 16px 10px 16px;
    }

    ul {
      list-style-type: none;

      li {

        display: flex;
        align-items: center;
        margin: 8px 0;
        cursor: pointer;

        a,button {
          color: $dark-grey;
          font-family: Sarabun;
          font-size: 13px;
          font-weight: 300;
          line-height: 1.69;
          transition: all 250ms;
          white-space: nowrap;
        }

        button {
          padding: 2px 0;
          cursor: pointer;
          background-color: white;
          color: $dark-grey!important;
          border: none;
        }



        svg {
          transition: all 250ms;
        }

        .icon-wrap {
          width: 30px;
          display: flex;
          justify-content: flex-start;
        }


        &:hover {
          a {
            color: $accent-primary;
          }
          button {
            color: $accent-primary!important; // it's necessary add important
          }

          &.line {
            border-bottom: solid 1px $accent-primary;
          }

          svg {
            fill: $accent-primary;
          }
        }

        .icon-menu-paper {
          margin-top: 3px;
        }

        .icon-dashboard {
          width: 16px;
          height: 11px;
        }

        .icon-menu-upload {
          width: 16px;
          height: 11px;
        }

        .icon-cloud-upload{
          width: 17px;
        }

        .icon-renew{
          width : 17px;
        }

        .icon-tick-drawn, .icon-cross-drawn {
          fill: #373F45;
        }

        .icon-menu-vertical-dots {
          width: 5px;
          height: 20px;
        }

        .icon-reset-icon-01{
          width: 17px;
          height: 17px;
        }

        .icon-tick-drawn,.icon-cross-drawn{
          width:17px;
          height:17px;
        }



        &.line {
          width: 100%;
          border-bottom: solid 1px $brown-grey;
          padding-bottom: 6px;
          transition: all 250ms;
        }
      }

      padding-left: 0;
    }

    &.opened {
      transition: max-height 0.5s ease-out, opacity .7s ease-out;
      opacity: 1;
      max-height: 500px;
      display: block;
    }

    max-height: 0;
    transition: max-height 0.5s ease-out, opacity .3s ease-out;
    opacity: 0;
    position: absolute;
    z-index: 99999;
    margin-top: 10px;

    &.vertical-dots {
      margin-left: 25px;
    }

  }

  // remove later
  .dataTable_overflow_wrapper {
    overflow-y: visible;
    overflow-x: visible;
  }

}

.list-btn-menu {
  cursor: pointer;
  z-index: 0;

  &.selected{
    svg{
      fill: $azure;
    }
  }

  svg{
    transition: all 250ms;
  }

  &.vertical-dots{
    padding: 4px 12px;
  }

}

.list-menu-container {
  position: relative;

  &.vertical-dots-menu {
    margin-right: -10px;
    margin-top: -2px;
  }
  .icon-compare{
    width: 15px;
    height: 14px;
  }
}


.list-menu-main-wrap {
  display: flex;
  justify-content: flex-end;
}


