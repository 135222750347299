.header,
#mainheader.header {
    background: map_get($headerFooter, bg-light);
    z-index: 100;
    .flex-nav {
        padding: 0 32px;

        @include media("<=phoneland") {
            padding: 9px 16px;
            @if ($site == accountants) {
                padding-top: 5px;
                padding-bottom: 5px;
            }
            @if ($site == mobility) {
                padding: 0 16px;
            }
        }
    }
}

ul.main-nav-icons {
    position: static;
    display: block;
    font-size: 0;

    & > li {
        display: inline-block;
        width: 50%;
        vertical-align: middle;
        font-size: 16px;
        // padding: 15px;
        line-height: 42px;

        @if ($site == mobility) {
            width: 100%;
        }

        @include media("<=wide-tab") {
            width: 100%;

        }

        & > a {
            display: inline-block;
            width: 100%;
            line-height: 21px;
            vertical-align: middle;
            color: $white;

            @include media("<=wide-tab") {
                height: auto;
                // padding: 15px 25px 15px 0;
                overflow: hidden;
                text-align: left;
            }

            .nav-icon {
                transition: all 250ms;
            }

            &:hover, &:active {
                text-decoration: none;
                border-bottom: 2px #01bee5 solid;


                color: $link-primary;

                .nav-icon, svg {
                    fill: $link-primary;
                }
            }

            span {
                display: block;
                font-size: 10px;
                line-height: 22px;
                text-transform: uppercase;
                white-space: nowrap;

                @include media("<=wide-tab") {
                    float: left;
                    font-size: 16px;
                    line-height: 20px;

                    color: $link-primary;
                    text-transform: capitalize;
                    color: map_get($pushNav, text);
                    &:hover {
                        color: map_get($pushNav, hover);
                    }
                }
            }
            svg {
                transition: all 250ms;
                fill: map_get($header, navIconFill);

                @include media("<=wide-tab") {
                    float: right;
                }
            }
            .login {
                width: 14px;
                height: 20px;
            }
            .search {
                width: 21px;
                height: 20px;
            }
        }
    }
}

.mobile-nav-header {
    position: relative;
    padding-right: 58px;
    margin-bottom: 62px;

    @include media("<=phoneland") {
        margin-bottom: 35px;
        padding-right: 45px;
    }

    .mobile-nav-title {
        float: left;
        padding-top: 12px;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: map_get($pushNav, text);
    }
    .mobile-nav-close {
        position: absolute;
        right: 33px;
        top: 0;
        width: 14px;
        height: 14px;
        padding: 0;
        background: url("../img/close-icon.svg") no-repeat center center;
        background-size: cover;
        z-index: 100;

        &:hover {
            cursor: pointer;
        }
        @include media("<=phoneland") {
            right: 20px;
        }
    }
}

.site-lsos {
    .page-get-a-quote,
    .page-request-a-quote,
    .page-notify-a-claim{
        display: none !important;
    }
}

.site-locktonmobility {
    .page-insurance-services,
    .page-about-us {
        display: none !important;
    }
}

.readonlyMode #mainheader {
    margin-top: 79px !important;
}



.icon-create-policy{
    fill:map_get($pushNav, text);
}


.header-account-switcher{
    height: 28px;
    background-color: $accent-primary;
     display: flex;
    justify-content: center;
    align-items: center;
    span{
        font-size: 13px;
        font-weight: bold;
        color: $white;
        margin-right: 8px;
    }

    svg{
        fill: $white;
    }
}

