.pf-form_flex{
    #mainheader .svg-icon {
        position: relative;

        display: block;

        width: 20px;
        height: 20px;
        margin: 0 auto;

        transform-origin: 0 0 0;
    }
	#mainheader .menu {
        font-size: 0;

        position: absolute;
        top: 50%;
        right: 32px;

        display: none;

        transform: translateY(-50%);
        @include media("<1200px") {
            display: block!important;
        }
    }
    #mainheader .menu .svg-icon {
        fill: #B6BCB8;
    }
	#pushNav {
        font-size: 16px;

        position: fixed;
        z-index: 99999999999999999;
        top: 0;
        right: 0;
        bottom: 0;

        box-sizing: border-box;
        width: 100%;
        max-width: 384px;
        padding: 64px 0 32px 32px;

        transition: -webkit-transform .3s;
        transition:         transform .3s;
        -webkit-transform: translateX(100%);
            -ms-transform: translateX(100%);
                transform: translateX(100%);

        color: map_get($pushNav,text);
        background: map_get($pushNav,background);
        background-size: cover;
    }
    .ie9 #pushNav {
        border-top: 58px;
    }
    #pushNav .title {
        position: relative;

        display: block;

        padding-right: 32px;
    }
    #pushNav .menu {
        font-size: 0;

        display: block;
        float: right;

        width: 32px;
        height: 32px;
    }
    #pushNav .menu .svg-icon {
        width: 16px;
        height: 16px;

        fill: map_get($pushNav,text);
    }
    #pushNav ul {
        list-style: none;
    }
    #pushNav ul li {
        display: block;

        padding: 16px 32px 16px 0;

        border-bottom: 1px solid map_get($pushNav,border);
        a{
            color: map_get($pushNav,text);
            &:hover, &.on{
                color: map_get($pushNav,hover);
            }
        }
    }
    #pushNav .create span {
        display: inline-block;

        vertical-align: middle;
    }
    #pushNav .create .svg-icon {
        display: inline-block;

        margin-left: 16px;

        vertical-align: middle;

        fill: map_get($pushNav,text);
    }

    .bounds {
        transition: transform .3s;
        transform: translateX(0%);
    }

    &.openNav #pushNav {
        display: block!important;

        -ms-transform: translateX(0%)!important;
            transform: translateX(0%)!important;
    }

    &.openNav .bounds {
        -ms-transform: translateX(-384px)!important;
            transform: translateX(-384px)!important;
           @include media("<=384px") {
		        -ms-transform: translateX(0%);
		            transform: translateX(0%);
           }
    }

    &.openNav #headband .bounds,
    &.openNav #mainheader .bounds {
        -ms-transform: translateX(0%)!important;
            transform: translateX(0%)!important;
    }

}