.statusLegend {
    border: none;
    ul {
        display: flex;
        justify-content: flex-start;
        list-style-type: none;
        @include media("<1200px") {
            flex-wrap: wrap;
        }
    }
    li {
        white-space: nowrap;
        flex: 1 1 25%;
        max-width: 240px;
        padding-right: 32px;
        .legend-icon{
            padding:12px;
            svg{
                fill:$white;
            }
            display: inline-block;
            margin-right: 8px;
        }
        &.legend_incomplete .legend-icon{
            background-color:map_get($policyActionStates,needsaction);
        }
        &.legend_complete .legend-icon{
            background-color:map_get($policyActionStates,done);
        }
        &.legend_notStarted .legend-icon{
            background-color:map_get($policyActionStates,inprogress);
        }
        &.legend_issues .legend-icon{
            background-color:map_get($policyActionStates,warning);
        }
        &.legend_inactive .legend-icon{
            background-color:map_get($policyActionStates,inactive);
        }

        @include media("<phoneland") {
            flex-basis: 100%;
            margin-bottom: 12px;
        }

    }

    //.legend_incomplete{
    //    padding-left: 32px;
    //}
    //.legend_complete{
    //    padding-left: 32px;
    //}
    //.legend_notStarted{
    //    padding-left: 32px;
    //}
    //.legend_issues{
    //    padding-left: 32px;
    //}
    //.legend_inactive{
    //    padding-left: 32px;
    //}

    .legend_issues{
        .legend-icon{
            min-width: 56px;
            min-height: 56px;
            padding-left: 17px;
            padding-top: 15px;
        }
    }

    .legend_complete{
        .legend-icon{
            min-width: 56px;
            min-height: 56px;
            padding-left: 19px;
            padding-top: 16px;
        }
    }

    .legend_notStarted{
        .legend-icon{
            min-width: 56px;
            min-height: 56px;
            padding-left: 19px;
            padding-top: 16px;
        }
    }

    .legend_incomplete{
        .legend-icon{
            min-width: 56px;
            min-height: 56px;
            padding-left: 20px;
            padding-top: 16px;
        }
    }

    .legend_inactive{
        .legend-icon{
            min-width: 56px;
            min-height: 56px;
            padding-left: 21px;
            padding-top: 14px;
        }
    }


}

#summaryScreen .statusLegend {
     padding-top: 32px;
     //margin-left: -32px;
     ul{
         padding-left: 0px;
     }
}