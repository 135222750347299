
/* forwardQuestionButton */

.jsonForm {
    .forwardQuestionButton {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 3.5em;
        max-width: 25px;
        margin-left: 5px;
        padding: 3px 6px;
        line-height: $legend-lh - 6px;
        text-indent: 26px;
        white-space: nowrap;
        overflow: hidden;
        color: #666;
        background: transparent url('/proposalform/v2016v2/jsonForm.2.0.0/plugins/ForwardQuestion/forwardQuestion.svg') 3px 0 no-repeat;
        border-radius: 2px;
        transition: all .5s;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
            max-width: 600px;
            background-color: #eee;
        }
    }
}

.forwardQuestionIconOnly {
	display: inline-block;
	vertical-align: middle;
    max-width: 25px;
    padding: 3px 6px;
    text-indent: 26px;
    white-space: nowrap;
    overflow: hidden;
    color: #666;
    background:transparent url('/proposalform/v2016v2/jsonForm.2.0.0/plugins/ForwardQuestion/forwardQuestion.svg') 3px 0 no-repeat;
    border-radius: 2px;
	box-sizing: border-box;
}

form.forwardQuestion {
	margin: 0;
	padding: 0;
    text-align: left;

    p {
        margin: 0 0 10px 0;
        font-size: 1.2em;

        small {
            display: block;
            margin: 1em 0;
            font-size: 0.8em;
            color: #666;
        }

        &.fcfoot,
        &.fcfoot small {
            margin-bottom: 0;
        }
    }

    fieldset {
        margin: 0;
        padding: 0; 
        border: 0; 

        label {
            display: block;

            i {
                font-style: normal;
            }

            &.error {
                color: #fff;
                background-color: #BE1E2D;
            }
        }

        input,
        textarea {
            width: 100%;
            margin: 1px 0;
            padding: 4px;
            border: 1px solid #ccc;
            box-sizing: border-box;

            &:focus {
                outline: none;
            }
        }

        textarea {
            height: 10.5em;
        }

        input[type=checkbox] {
            width: inherit;
            margin-right: 10px;
            font-size: 1.2em;
        }

        &.error,
        .error {
            input,
            textarea {
                border: 1px solid #BE1E2D;
                border-color: #BE1E2D;
                box-shadow: 0 0 6px #BE1E2D;
            }
        }

        .error span {
            color: #fff;
            background-color: #BE1E2D;
        }

        .emailto {
            position: relative;

            span {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                bottom: 0;
                width: 3em;
                margin: 1px;
                padding: 4px;
                line-height: 1.5em;
                background-color: #ccc;
            }

            input {
                text-indent: 4em;
            }
        }
    }
}
