.pf-warning, .pf-agreement{
    .iad h1 {
        font-size: 22px;
    }
    .iad h2 {
        font-size: 19px;
    }
    .iad h3 {
        font-size: 16px;
    }
    span.embold {
        font-weight: 700;
        font-style: italic;
    }
}