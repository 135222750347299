

.jsonForm {
    input[type=date],
    input[type=month] {

        &::-webkit-datetime-edit-month-field {
            /*color: red;*/
        }
        &::-webkit-datetime-edit-day-field {
            /*color: white;*/
        }
        &::-webkit-datetime-edit-year-field{
            /*color: blue;*/
        }
        &::-webkit-clear-button {
            position: relative;
            margin-top: -3px;
            margin-right: 3px;
        }
        &::-webkit-inner-spin-button {
            /*background: transparent;*/
			opacity: 0.5;
        }
        &::-webkit-calendar-picker-indicator {
            /*background: transparent;*/
			opacity: 0.5;
        }
        &::-webkit-calendar-picker-indicator:hover {
            cursor: pointer;
        }
        &::-webkit-calendar-picker-indicator:active {
            /*color: blue;*/
        }
    }
}
