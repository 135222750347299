.btn { //This should really be a placeholder ie %btn (and extended as such for buttons below)
    font-size: 14px;
    line-height: 14px;

    display: block;

    max-width: 300px;
    width: 100%;

    box-sizing: border-box;
    padding: 20px 20px;

    text-align: center;

    color: $white;
    border: none;

    .multipleButtonWrapper & {
        display: inline-block;
        margin-right: 20px;
    }


    @if ($site == mobility) {
        border-radius: 28px;
    } @else {
        border-radius: 0;
    }

    &:hover,
    &:active {
        color: $white;
    }


    @at-root .aside & {
        max-width: none;
    }
}

.btn_a,
.btn_a:visited {
    @extend .btn;
    color: $a_btn-text !important; //Overriden by top-section link colour/dashboard link colour otherwise;
    border: solid 1px $a_btn-border;
    background-color: $a_btn-background;

    &:hover,
    &:active {
        color: $a_btn-hover-text !important;
        border: solid 1px $a_btn-hover-border;
        background-color: $a_btn-hover-background;
    }
}

.btn_b,
.btn_b:visited {
    @extend .btn;
    color: $b_btn-text !important; //Overriden by top-section link colour otherwise
    border: solid 1px $b_btn-border;
    background-color: $b_btn-background;

    &:hover,
    &:active {
        color: $b_btn-hover-text !important;
        border: solid 1px $b_btn-hover-border;
        background-color: $b_btn-hover-background;
    }
}

.btn_c,
.btn_c:visited {
    @extend .btn;
    color: $c_btn-text !important; //Overriden by top-section link colour otherwise
    border: solid 1px $c_btn-border;
    background-color: $c_btn-background;

    &:hover,
    &:active {
        color: $c_btn-hover-text !important;
        border: solid 1px $c_btn-hover-border;
        background-color: $c_btn-hover-background;
    }
}

.btn_d,
.btn_d:visited {
    @extend .btn;
    color: $d_btn-text !important; //Overriden by top-section link colour otherwise
    border: solid 1px $d_btn-border;
    background-color: $d_btn-background;

    &:hover,
    &:active {
        color: $d_btn-hover-text !important;
        border: solid 1px $d_btn-hover-border;
        background-color: $d_btn-hover-background;
    }
}

.btn_e,
.btn_e:visited {
    @extend .btn;
    color: $e_btn-text !important; //Overriden by top-section link colour otherwise
    border: solid 1px $e_btn-border;
    background-color: $e_btn-background;

    &:hover,
    &:active {
        color: $e_btn-hover-text !important;
        border: solid 1px $e_btn-hover-border;
        background-color: $e_btn-hover-background;
    }
}

.btn_login,
.btn_login:visited {
    @extend .btn;
    white-space: nowrap;
    color: $login_btn-text !important; //Overriden by top-section link colour otherwise
    border: solid 1px $login_btn-border;
    padding: 6px 10px;
    background-color: $login_btn-background;
    min-width: 58px;
    position: relative;
    top: -2px;

    &:hover,
    &:active {
        color: $login_btn-hover-text !important;
        border: solid 1px $login_btn-hover-border;
        background-color: $login_btn-hover-background;
    }
}

.large-btn {
    line-height: 17px;

    padding: 10px 25px;
}

.small-btn {
    padding: 12px 40px;
    width: auto;
    display: inline-block;
    text-transform: uppercase;
}
.page-container, .modal-content{
    button,
    input[type=button] {
        @extend .btn;
        color: $a_btn-text ; //!important; //Overriden by top-section link colour/dashboard link colour otherwise;
        border: solid 1px $a_btn-border;
        background-color: $a_btn-background;
        display: inline-block;

        &.btn_b,
        &.btn_c {
            display: inline-block;
        }

        &:hover,
        &:active {
            color: $a_btn-hover-text !important;
            border: solid 1px $a_btn-hover-border;
            background-color: $a_btn-hover-background;
        }
    }
}

button.ot-link-btn.category-host-list-handler{
    color: $link-primary-cookies !important;
}
button#onetrust-pc-btn-handler{
    color: black!important;
}

.filter-button {
    position: relative;
    fill: $white;

    &:before {
        position: absolute; 
        right: 16px;
        top: 75px;

        display: block;

        width: 26px;
        height: 26px;

        content: '';
        -webkit-transition: all .2s ease 0s;
        -moz-transition: all .2s ease 0s;
        -o-transition: all .2s ease 0s;
        transition: all .2s ease 0s;
        -webkit-animation: anim-rotate 1s infinite linear;
        -moz-animation: anim-rotate 1s infinite linear;
        -o-animation: anim-rotate 1s infinite linear;
        animation: anim-rotate 1s infinite linear;

        opacity: 0;
        border: 2px solid transparent;
        border: 2px solid rgba($b_accent, .6);
        border-left: 2px solid $b_accent;
        border-radius: 15px;
        background: transparent;
    }

    &:after {
        font-size: 14px;
        line-height: 24px;

        position: absolute;
        right: 0;
        top: 75px;

        display: block;

        width: auto;
        height: 26px;

        content: attr(data-count);
        // content: 'test';
        -webkit-transition: all .2s ease 0s;
        -moz-transition: all .2s ease 0s;
        -o-transition: all .2s ease 0s;
        transition: all .2s ease 0s;
        white-space: nowrap;

        opacity: 0;
        color: $body_text;
        background: transparent;
    }
}

.ajaxLoaded .filter-button:after,
.ajaxLoading .filter-button:before {
    opacity: 1;
}

.ajaxLoading.ajaxLoaded .filter-button:after {
    opacity: 0;
}

@keyframes anim-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes anim-rotate {
    0% {
        -webkit-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.btn-lookup {
	display: inline-block;
	bottom: 4px;
	right: 4px;
	position: absolute;
	border-radius: 0em;
	padding: 0.5em 0.75em;
	text-transform: uppercase;
	line-height: 1em;
	font-size: 14px;
	color: $white;
	opacity: 0.5;
	pointer-events: none;
	background-color: #cccccc;
}
input:valid + .btn-lookup {
	opacity: 1;
	pointer-events: all;
	cursor: pointer;
	background-color: $a_btn-background;
	&:hover,
	&:active {
		background-color: $a_btn-hover-background;
	}
}
input.busy + .btn-lookup {
	opacity: 0.2;
	cursor: wait;
	background-color: #cccccc;
	background: linear-gradient(-90deg, #9b9b9b 30%, #198fc2 50%, #9b9b9b 70%);
	background-size: 300% 400%;
	animation: gradient 2s ease infinite;
	/*&:before {
		content: url("/img/icons.svg#icon-tail-spin");
		display:block;
		position:absolute;
		width:20px;
		height:20px
	}*/
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.btn.disabled {
    filter: grayscale(1);
    pointer-events: none;
}