
.our-story-page {


	.main-section-container {
		position: relative;

		.bounds {
			position: relative;

			.video-container {
				margin-bottom: 0;
				.video-block {
					max-height: 230px;

					@include media("<1200px") {
						max-height: 182px;
					}
					@include media("<wide-tab") {
						max-height: 250px;
					}
					@include media("<=phoneland") {
						max-height: 173px;
					}
					iframe {
						width: 100%;
						min-height: 230px;
						height: auto;

						@include media("<1200px") {
							min-height: 182px;
						}
						@include media("<wide-tab") {
							min-height: 250px;
						}
						@include media("<=phoneland") {
							min-height: 173px;
						}
					}
				}
			}

			.main-section {

				.additional-section {
					padding: 15px 0 70px;
					overflow: hidden;

					@include media("<1200px") {
						padding: 15px 0 60px;
					}
					@include media("<wide-tab") {
						padding: 15px 0 30px;
					}
					@include media("<=phoneland") {
						padding: 0 0 30px;
					}

					.flex-parent {
						position: relative;
						padding: 0;
						margin: 0 -10px;

						.block {
							position: relative;
							-ms-flex: 1 1 33.33%;
							-webkit-flex: 1 1 33.33%;
							flex: 1 1 33.33%;
							width: 33.33%;
							max-width: 33.33%;
							min-width: 0;
							padding: 15px 10px;
							box-sizing: border-box;

							@include media("<=phoneland") {
								-ms-flex: 1 1 50%;
								-webkit-flex: 1 1 50%;
								flex: 1 1 50%;
								width: 50%;
								max-width: 50%;
							}

							&:nth-child(3n+2) {
								text-align: center;

								@include media("<=phoneland") {
									text-align: left;
								}
							}
							&:nth-child(3n+3) {
								text-align: right;

								@include media("<=phoneland") {
									text-align: left;
								}
							}
							&:nth-child(odd) {
								@include media("<=phoneland") {
									text-align: left;
								}
							}
							&:nth-child(even) {
								@include media("<=phoneland") {
									text-align: right;
								}
							}

							.content-container {
								display: inline-block;
								width: 170px;
								text-align: center;

								@include media("<1200px") {
									width: 150px;
								}
								@include media("<wide-tab") {
									width: 124px;
								}
								@include media("<=phoneland") {
									width: 100%;
								}

								.image-wrap {
									margin-bottom: 14px;

									img {
										width: 100%;
										height: auto;
									}
								}
								p {
									margin: 0;

									span {
										display: block;

										@include media("<1200px") {
											display: inline;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}