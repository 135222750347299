/* loading spinner */
.loadingSpinner {
    display:none;
    position: fixed;
    pointer-events:none;
    z-index:99999999;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin:auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(28, 117, 188, 0.2);
    border-right: 1.1em solid rgba(28, 117, 188, 0.2);
    border-bottom: 1.1em solid rgba(28, 117, 188, 0.2);
    border-left: 1.1em solid rgba(28, 117, 188, 1);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.0s infinite linear;
    animation: load8 1.0s infinite linear;
}
.loadingSpinner,
.loadingSpinner:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.jsonForm ~ .loadingSpinner {
    display:block;
    -webkit-transition: opacity 0.5s 0.25s ease;
    -moz-transition: opacity 0.5s 0.25s ease;
    -ms-transition: opacity 0.5s 0.25s ease;
    -o-transition: opacity 0.5s 0.25s ease;
    transition: opacity 0.5s 0.25s ease;
    opacity:1;
}
.jsonForm.ready ~ .loadingSpinner {
    opacity:0;
}