
.insurance-services-index-page {


	.main-content {
		position: relative;

		.bounds {
			.main-content-header {
				position: static;
				padding: 0 32px 0;

				@include media("<=phoneland") {
					padding: 0 16px 25px;
				}

				.select-wrap {
					float: right;

					@include media("<tabport") {
						float: none;
					}

					select {
						width: 218px;
						height: 30px;
						padding: 0 0 0 15px;
						font-size: 16px;
						line-height: 28px;
						color: $slate-grey;
						background-color: $white;
						background-image: url('../img/select-arrow.svg');
						background-position: 94% center;
						background-repeat: no-repeat;
						border: 1px solid $warm-grey-two;
						border-radius: 0;
						box-sizing: border-box;
						-webkit-appearance: none;
						-moz-appearance: none;
						-ms-appearance: none;
						appearance: none !important;

						&::-ms-expand {
						  display: none;
						}

						&:focus {
				            border: 1px solid rgba(0,134,139,0.5);

				            @include box-shadow(inset 0 0 5px rgba(0,134,139,0.3));
				        }

						option {
							font-size: 16px;
							color: $slate-grey;
						}
					}
				}
			}

		}
	}
}
