
.info-widget {
	padding: 0 20px 7px;
	margin-bottom: 3px;
	background-color: $side_background;
	color: $side_text;

	@include media("<1200px") {
		padding: 0 20px;
	}

	.widget-block {
		padding: 30px 0 23px;

		&:first-child {
			margin-bottom: 10px;
			border-bottom: 1px solid $white;

			@include media("<1200px") {
				margin-bottom: 7px;
			}
		}

		@include media("<1200px") {
			padding: 23px 0 15px;
		}

		.widget-title {
			margin: 0 0 25px;
			font-size: 28px;
			line-height: 34px;
		}
		.widget-list {
			list-style: none;

			li {
				position: relative;
				padding-left: 25px;
				margin-bottom: 20px;
				font-size: 16px;
				line-height: 18px;

				.list-icon {
					display: block;
					position: absolute;
					left: 0;
					top: 3px;
					font-size: 18px;
    				line-height: 0.9;
					color: $link-primary;
					z-index: 1;
					height: 14px;
  					width: 12px;

					@include media("<=phoneland") {
						top: 0;
						font-size: 20px;
						line-height: 18px;
					}
					svg {
						display: block;
						fill: $link-primary;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
