.section-title{
    @include font("body",20px,$dark,500,1.4);
    margin-top:0;
}

.column-title{
    @include font("title",42px,$dark,500,1.52);
    margin-top:0;

    @include media("<=phoneland"){
        font-size: 38px;
        line-height: 1.32;
    }
    @include media("<=phoneland"){
        font-size:34px;
        line-height:1.24;
    }
}
h1.article-heading,
h2.article-heading{
    font-weight: 500;
    line-height:1.42;
}

.title-mylockton, .aside-title h4.title-mylockton{
    color:$aqua-blue;
	span {
		color:white;
	}
}