
.jsonForm {

    input,
    textarea,
    select {
        display: block;
        width: 100%;
        padding: 7px 14px;
        font-size: $input-fs!important;
        color: $text-color;
    }
	.iad-submit {
		padding-left:0;
		input {
			font-size: 16px !important;
			float: left;
		}
	}

	input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    input[type="date"],
    input[type="month"],
    input[type="cash"],
    input[type="percent"],
    select,
    textarea {
        width: calc(100% - 6px);
        margin-left: 6px;
        background-color: $el-bg;
        border: 1px solid $border-color;
        box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color;
        &:required {
            border-color: $border-color-req;
            box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-req;
			&:valid {//valid
				border-color: $border-color-req;
				box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-val;
			}
		}
		&:invalid {//invalid
			//&:not(:placeholder-shown) {//and answered (might not have a placeholder)
			&[data-answered="true"] {//and answered
				border-color: $border-color-err;
				box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-err;
			}
		}
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    input[type="date"],
    input[type="month"],
    input[type="cash"],
    input[type="percent"],
    select {
        height: 36px;
        padding: 7px 14px;
        line-height: 20px;
    }
	
	input[type="date"] {
		padding: 4.5px 14px;
	}

    textarea {
        min-height: 36px;
        padding: 8px 14px;
        line-height: 18px;
    }

    input,
    textarea,
    select {
        &:required {
            background-color: $el-bg-req;
        }
    }

	input,
	textarea {
	    &:focus, 
	    &:required:focus {
	    	outline: none;
	    	background-color: $el-bg-focus;
	    }
	    &:required:focus:invalid,
		&.error:required:focus,
        &.valid:required:focus:invalid {
			color: $el-color-err;
		    border-color: $border-color-err;
		    box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-err;
		}
		&:required:focus:valid,
		&.valid:required:focus {
			box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-val;
		}
	}

	input[type="hideme"] {
        display: none !important;
    }
    input[type="checkbox"],
    input[type="radio"] {
        width: auto;
    }
	input[type="submit"] {
		&:focus,
		&:required:focus {
			outline: none;
			background-color: $warm-grey;
		}
    }

    select {
        appearance: none !important;

        &::-ms-expand {
            display: none;
        }
        /*&.error,
        &[data-answered="false"] {
            color: $placeholder-color;

            option {
                color: $text-color;
            }
            & ~ .chosen-container .chosen-single {
                color: $placeholder-color;
            }
        }
        &[data-answered="true"] {
            & ~ .chosen-container .chosen-single {
                box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-val;
            }
        }
        &[multiple="multiple"][value][data-answered="true"] {
            & ~ .chosen-container .chosen-choices {
                box-shadow: -3px 0 0 0 #fff, -6px 0 0 0 $line-color-val;
            }
        }*/
    }

    ::-webkit-input-placeholder {color: $placeholder-color;}
    ::-moz-placeholder          {color: $placeholder-color;}
    :-moz-placeholder           {color: $placeholder-color;}
    :-ms-input-placeholder      {color: $placeholder-color;}
	
	/* i think this whole media rule can be removed */
    @include media('<=desktop') {
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="tel"],
        input[type="number"],
        input[type="date"],
        input[type="month"],
        input[type="cash"],
        input[type="percent"],
        select,
        textarea {
            /*width: 100%;
            margin-left: 0;*/
            //border-left: 4px solid $line-color;
            //box-shadow: none;

            &:required {
                //border-left: 4px solid $line-color-req;
                //box-shadow: none;
            }
        }

        input,
        textarea {
            &:required:focus:invalid,
            &.error:required:focus,
            &.valid:required:focus:invalid {
                //border-left: 4px solid $line-color-err;
                //box-shadow: none;
            }
            &:required:focus:valid,
            &.valid:required:focus {
                //border-left: 4px solid $line-color-val;
                //box-shadow: none;
            }
        }

        select {
            &[data-answered="true"] {
                & ~ .chosen-container .chosen-single {
                    //border-left: 4px solid $line-color-val;
                    //box-shadow: none;
                }
            }
            &[multiple="multiple"][value] {
                & ~ .chosen-container .chosen-choices {
                    //border-left: 4px solid $line-color-val;
                    //box-shadow: none;
                }
            }
        }
    }
	
	
	
	
	
	
	&.jsonFormInverse {
		input,
		textarea,
		select {
			color: $white;
		}
		
		input[type="text"],
		input[type="email"],
		input[type="password"],
		input[type="tel"],
		input[type="number"],
		input[type="date"],
		input[type="month"],
		input[type="cash"],
		input[type="percent"],
		select,
		textarea {
			width: 100%!important;
			margin-left: 0!important;
			color: $white;
			background-color: $outer-space;
			border: none;
			/* box-shadow: -3px 0 0 0 $dark, -6px 0 0 0 $line-color; */
			box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
			&:required {
				background-color: $outer-space;
				/*box-shadow: -3px 0 0 0 $dark, -6px 0 0 0 $line-color-req, inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);*/
				box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
				&:valid {//valid
					background-color: $outer-space;
					/*box-shadow: -3px 0 0 0 $dark, -6px 0 0 0 $line-color-val, inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);*/
					box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
				}
				&:invalid {//invalid
					background-color: $outer-space;
					&[data-answered="true"] {//and answered
						/*box-shadow: -3px 0 0 0 $dark, -6px 0 0 0 $line-color-err, inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);*/
						box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
					}
				}
			}
		}
		
		input[type="text"],
		input[type="email"],
		input[type="password"],
		input[type="tel"],
		input[type="number"],
		input[type="date"],
		input[type="month"],
		input[type="cash"],
		input[type="percent"],
		select {
			height: 46px;
			line-height: 30px;
		}
		textarea {
			min-height: 46px;
			line-height: 28px;
		}
		input,
		textarea,
		select {
			&:required {
				background-color: $outer-space;
			}
		}
		
		input,
		textarea {
			&:focus,
			&:required:focus {
				outline: none;
				background-color: $el-bg-focus;
			}
			&:required:focus:invalid,
			&.error:required:focus,
			&.valid:required:focus:invalid {
				color: $el-color-err;
				border-color: $border-color-err;
				/*box-shadow: -3px 0 0 0 $dark, -6px 0 0 0 $line-color-err, inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);*/
				box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
			}
			&:required:focus:valid,
			&.valid:required:focus {
				/*box-shadow: -3px 0 0 0 $dark, -6px 0 0 0 $line-color-val, inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);*/
				box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
			}
		}
		input[type="submit"] {
			&:focus,
			&:required:focus {
				outline: none;
				background-color: $warm-grey;
			}
			
		}
		select {
			&.error,
			&[data-answered="false"] {
				color: $placeholder-color;
				
				option {
					color: $white;
				}
				& ~ .chosen-container .chosen-single {
					color: $white;
				}
			}
			&[data-answered="true"] {
				& ~ .chosen-container .chosen-single {
					/*box-shadow: -3px 0 0 0 $dark, -6px 0 0 0 $line-color-val, inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);*/
					box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
				}
			}
			&[multiple="multiple"][value] {
				& ~ .chosen-container .chosen-choices {
					/*box-shadow: -3px 0 0 0 $dark, -6px 0 0 0 $line-color-val, inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);*/
					box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.7);
				}
			}
		}
		
		
		.flex-wrap-row .one-half.left-block {
			margin-right: 20px;
		}
		.jf-row.jf-input-type-text.one-half {
			margin-bottom: 0;
		}
		
		
	}
	
	&.quote-form {
		input[type="text"],
		input[type="email"],
		input[type="password"],
		input[type="tel"],
		input[type="number"],
		input[type="date"],
		input[type="month"],
		input[type="cash"],
		input[type="percent"],
		select,
		textarea {
			box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color;
			&:required {
				box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color-req;
				&:valid {//valid
					box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color-val;
				}
				&:invalid {//invalid
					&[data-answered="true"] {//and answered
						box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color-err;
					}
				}
			}
		}
		
		select {
			box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color;
			&:required {
				&:invalid {//invalid
					color:$placeholder-color
				}
			}
		}
		
		
		input,
		textarea {
			&:required:focus:invalid,
			&.error:required:focus,
			&.valid:required:focus:invalid {
				box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color-err;
			}
			&:required:focus:valid,
			&.valid:required:focus {
				box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color-val;
			}
		}
		select {
			&[data-answered="true"] {
				& ~ .chosen-container .chosen-single {
					box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color-val;
				}
			}
			&[multiple="multiple"][value] {
				& ~ .chosen-container .chosen-choices {
					box-shadow: -3px 0 0 0 $corp-silver, -6px 0 0 0 $line-color-val;
				}
			}
		}
	}
}

.jsonFormInverseQuickQuote {
	input,
	textarea,
	select {
		color: $white;
	}


	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="tel"],
	input[type="number"],
	input[type="date"],
	input[type="month"],
	input[type="cash"],
	input[type="percent"],
	select {
		height: 44px;
		background-color: rgba(255, 255, 255, 0.05);
		-webkit-appearance: none;
		border: 1px solid rgba(255, 255, 255, 0.05);
		border-radius: 0px;
		line-height: 30px;
		width: calc(100% - 6px);
		margin-left: 6px;

		box-shadow: -3px 0 0 0 #4a4a4a, -6px 0 0 0 $line-color;
		&:required {
			box-shadow: -3px 0 0 0 #4a4a4a, -6px 0 0 0 $line-color-req;
			&:valid {//valid
				box-shadow: -3px 0 0 0 #4a4a4a, -6px 0 0 0 $line-color-val;
			}
			&:invalid {//invalid
				&[data-answered="true"] {//and answered
					box-shadow: -3px 0 0 0 #4a4a4a, -6px 0 0 0 $line-color-err;
				}
			}
		}

		&:focus,
		&:required:focus {
			outline: none;
			border-color: $border-color-req;
			box-shadow: -3px 0 0 0 #4a4a4a, -6px 0 0 0 $line-color-req;
		}
		&:required:focus:invalid,
		&.error:required:focus,
		&.valid:required:focus:invalid {
			border-color: $border-color-err;
			box-shadow: -3px 0 0 0 #4a4a4a, -6px 0 0 0 $line-color-err;
		}
		&:required:focus:valid,
		&.valid:required:focus {
			border-color: $border-color-req;
			box-shadow: -3px 0 0 0 #4a4a4a, -6px 0 0 0 $line-color-val;
		}

	}

	textarea {
		height: 111px;
		width: 100%;
		margin-left: 0;
		background-color: rgba(255, 255, 255, 0.05);
		-webkit-appearance: none;
		border: 1px rgba(255, 255, 255, 0.05);
		border-radius: 0px;
		@include font("body", 14px, #b2b1b2, 300, 1.29);
	}

    input[type="number"]{
		padding: 10px;
    }

	input[type="date"]{
		padding: 10px;
		@include font("body", 14px, #b2b1b2, 300, 1.29);
	}

	select {
		padding: 10px;
		@include font("body", 14px, #b2b1b2, 300, 1.29);
	}

	input[type="submit"] {
		&:focus,
		&:required:focus {
			outline: none;
			background-color: $warm-grey;
		}

	}

	.block-buttons{
		display: flex;
		justify-content: space-between;
		align-content: space-between;
		margin-left: 6px;
		width: calc(100% - 6px);
		.div-button{
			display: inline-block;
			width: 47%;
		}
	}

	.checkbox-container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		//font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			width: 25px;
			background-color: rgba(255, 255, 255, 0.05);
			border-radius: 50%;

			&:after {
				content: "";
				position: absolute;
				display: none;

				top: 6px;
				left: 6px;
				width: 13px;
				height: 13px;

				border-radius: 50%;
				background: red;

			}
		}

		&:hover input ~ .checkmark {
			background-color: #ccc;
		}

		input:checked ~ .checkmark {
			background-color: $white;
			box-shadow: none;

			&:after{
				display: block;
			}
		}

	}

	//span.collection {
	//	position: relative;
	//	left: -8px;
	//}

	.flex-wrap-row .one-half.left-block {
		margin-right: 20px;
	}
	.jf-row.jf-input-type-text.one-half {
		margin-bottom: 0;
	}


	//.end-question:after{
	//	content: "";
	//	display: inline-block;
	//	vertical-align: top;
	//	width: 100%;
	//	//border-top: 1px solid #6e6e6e;
	//	//margin-top: 30px;
	//	margin-bottom: 10px;
	//}

	ol{
		position: relative;
		list-style-position: inside;
		padding-left: 0px;
	}

	li{
		margin-bottom: 10px;
		@include font("body", 16px, $white, 300, 1.75);
		//position: relative;
		//left: -7px;
	}

}