.alertDialog {
    font-family: sans-serif;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #222222;
    background-color: rgba(0, 0, 0, .75);
    text-align: center;
    z-index: 99999999;
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em;
    }
    .dialog-box {
        background-color: #ffffff;
        width: 40%;
        min-width: 400px;
        max-width: 80%;
        margin: auto;
        display: inline-block;
        vertical-align: middle;
        max-height: 100%;
        overflow: auto;
    }
    .dialog-title {
        background: $background-grey;
        padding: 20px 40px;
        //font-family: AGaramondPro;
        @include font("title",30px,$almost-black,400,1.21);
        //font-size: 30px;
        text-align: left;
        //color: #060708;
        @at-root .get-a-quote-page &{
            //padding-bottom:0;
            display: flex;
            flex-direction: column;
            align-items: center;
			.getAQuoteOption-icon {
				margin-bottom: 10px;
			}
		}
    }
    .dialog-message {
        margin: 0;
        padding: 30px;
        text-align: center;
    }
    .dialog-button-row {
        background: none repeat scroll 0 0 rgba(100, 100, 100, 0.1);
        //background-color: #212f38;
        background-color: map_get($footer, secondaryBg);
        display: flex;
        flex-direction: row-reverse;
        padding-right: 40px;
        a {
            background: $a_btn-background;
            color: $a_btn-text;
            padding: 10px;
            display: inline-block;
            margin: 15px 0 15px 15px;
            text-decoration: none;
            min-width: 150px;
            font-size: 14px;
            text-transform: uppercase;
            &:hover {
                background: #666;
                box-shadow: none;
            }
        }
        a + a {
            background: transparent;
            box-shadow: 0 0 0 1px #9b9b9b;
        }
    }
    form {
        text-align: left;
        opacity: 1 !important;
        &.submitted input:invalid + label.mvalidation {
            display: block;
        }
        .jf-row > label.mvalidation {
            padding: 10px 10px 0px 40px !important;
            display: inline-block;
            min-width: 30%;
            max-width: 100%;
            width: auto;
            text-align: center;
        }

        #wrapper-for-name.jf-row > label.mvalidation {
            padding-top: 15px !important;;
        }
    }
}


body.alertIsOpen {
    overflow: hidden;
}

body.alertIsOpen {
    & section,
    & header,
    & footer,
    & .jf-header-section,
    & ul[attr^=jf-page-control] {
        filter: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZGVmcz4KICAgICAgICA8ZmlsdGVyIGlkPSJnYXVzc2lhbl9ibHVyIj4KICAgICAgICAgICAgPGZlR2F1c3NpYW5CbHVyIGluPSJTb3VyY2VHcmFwaGljIiBzdGREZXZpYXRpb249IjEuNiIgLz4KICAgICAgICA8L2ZpbHRlcj4KICAgIDwvZGVmcz4KPC9zdmc+#gaussian_blur);
        -webkit-filter: blur(2px);
        -moz-filter: blur(2px);
        -ms-filter: blur(2px);
        -o-filter: blur(2px);
        filter: blur(2px);
    }
}