
.jsonForm {
	fieldset {
		& > legend {
			//font-size: $legend-fs;
			//line-height: $legend-lh;
			//font-weight: $legend-fw;
            @include font('title', 18px,false, 400,  2.26);
			letter-spacing: .2px;

			cursor: default;

			&:hover {
				& ~ .fieldset-actions {
		            max-height: none;
		            overflow: visible;
		            opacity: 1;
		        }
			}
		}

		& > strong:first-child,
		& > legend strong,
		& > .fieldset-actions + strong {
			display: inline-block;
			margin-right: 7px;
			//font-size: $legend-fs * 0.8;
			//line-height: $legend-lh;
			//font-weight: $legend-fw;
            @include font('body', 12px,false, 400,  1.33);
			letter-spacing: .2px;

			& + dfn {
				display: inline-block;
				margin-left: 6px;
				margin-bottom: 12px;
                @include font('body', 12px,false, 400,  1.33);
				//font-size: $legend-fs;
				//line-height: $legend-lh;
				//font-weight: $legend-fw;
			}
		}
	}
}