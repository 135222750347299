
.two-col-list {
	position: relative;
	flex-wrap: nowrap;
	margin: 0 -15px;
	padding: 7px 0 0;

	.block {
		position: relative;
		flex: 0 1 auto;
		max-width: 50%;
		min-width: 0;
		padding: 15px;

		.ie10 &,
		.ie11 & {
			flex-basis: 350px;
		}

		h4 {
			margin: 0 0 13px;
			font-size: 18px;
			line-height: 26px;
			font-weight: bold;
			color: $dark;
		}
	}
	
	.right-block {
		&::before {
			position: absolute;
			display: block;
			content: "";
			top: 15px;
			left: 0;
			bottom: 17px;
			width: 2px;
			background: #D4DADB;
			z-index: 1;
		}
	}

	@include media(">phoneland") {
		.left-block {
			padding-right: 50px;
		}
		.right-block {
			padding-left: 50px;
		}
	}
	@include media("<=phoneland") {
		display: block;
		margin: 0;

		.block {
			display: block;
			max-width: 100%;
			padding: 15px 0 22px;
		}
		.right-block {
			padding-top: 36px;
			&::before {
				top: 0;
				bottom: auto;
				width: 100%;
				height: 2px;
			}
		}
	}
}