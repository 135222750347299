@use "sass:math";

/***************** MEDIA QUERIES**************/

$breakpoints: (
        'phoneport' : 320px,
        'phoneland' : 568px,
        'tabport' : 768px,
        'wide-tab' : 991px,
        'desktop' : 1024px,
        'desktop-basic' : 1200px
);

/***************** FONTS *******************/

$mp_proxima: 'proxima-nova', sans-serif;

$font-title: 'adobe-garamond-pro', serif;
$font-body: 'Gotham A',"Gotham B", Helvetica, Arial, sans-serif;

/***************** GLOBAL *******************/

$bounds: 1332px;
$half-bounds: $bounds/2;

$padding: 0 32px;

/***************** COLORS *******************/

// Global Styles
$background-grey: $light-grey-91 !default;
$body-text: $charcoal-grey !default;
$title-text: $almost-black !default;

// Main Colours
$accent-primary: $aqua-blue !default;
$accent-secondary: $aqua-blue !default;
$link-primary: $aqua-blue !default;
$hover-primary: $greyish-brown !default;
$link-primary-cookies: #00AeeF !default;
//Greys
$dark-grey: $greyish-brown !default;
$mid-grey: $cool-grey !default;
$light-grey: $light-grey-91 !default;

// Success/Error
$success-color: $corp-apple !default;
$error-color: $venetian-red !default;
$warn-color: $dull-orange!default;

// Section Colours

//Header
$header: (
        logoBg : transparent,
        navIconFill : $white
) !default;

$footer: (
        primaryBg : $almost-black,
        secondaryBg : $cod-grey,
        title : $alabaster,
        text : $alabaster,
        accent : $accent-primary
) !default;

// Default hedaer style
$a_background: transparent !default;
$a_text: $dark !default;
$a_accent: $aqua-blue !default;
$a_other: transparent !default;

// Detail header style
$b_background: $charcoal-grey !default;
$b_text: $white !default;
$b_accent: $aqua-blue !default;
$b_other: transparent !default;

//Sidebar widget Style
$side_background: $dark !default;
$side_text: $corp-white !default;
$side_background_b: $light-grey-91 !default;
$side_text_b: $corp-white !default;
$side_border: transparent !default;
$side-icons: $silver !default;

/****************************/
/*  	    IMAGES          */
/****************************/

// Banner
$banner-bg-img: "" !default;
$topsection-bg-img: "" !default;
$login-bg-img: "../img/corp-login-bg.jpg" !default;
$sales-bg-img: "../img/bg-sales.jpg" !default;

/****************************/
/* 	      COMPONENTS        */
/****************************/

// BUTTONS
// btn_a
$a_btn-border: $aqua-blue!default;
$a_btn-text: $white!default;
$a_btn-background: $aqua-blue!default;

$a_btn-hover-border: $turquoise-blue!default;
$a_btn-hover-text: $white !default;
$a_btn-hover-background: $turquoise-blue!default;

// btn_b
$b_btn-border: $warm-grey!default;
$b_btn-text: $almost-black !default;
$b_btn-background: transparent !default;

$b_btn-hover-border: $dark!default;
$b_btn-hover-text: $white !default;
$b_btn-hover-background: $dark!default;

// btn_c
$c_btn-border: $bright-red !default;
$c_btn-text: $white !default;
$c_btn-background: $bright-red !default;

$c_btn-hover-border: $dull-red !default;
$c_btn-hover-text: $a_btn-hover-text !default;
$c_btn-hover-background: $dull-red !default;

// btn_d
$d_btn-border: $bright-red !default;
$d_btn-text: $bright-red !default;
$d_btn-background: transparent !default;

$d_btn-hover-border: $dull-red !default;
$d_btn-hover-text: $a_btn-hover-text !default;
$d_btn-hover-background: $dull-red !default;

// btn_e
$e_btn-border: $bright-red !default;
$e_btn-text: $white !default;
$e_btn-background: $bright-red !default;

$e_btn-hover-border: $dull-red !default;
$e_btn-hover-text: $a_btn-hover-text !default;
$e_btn-hover-background: $dull-red !default;

// btn_login
$login_btn-border: $bright-red !default;
$login_btn-text: $white !default;
$login_btn-background: $bright-red !default;


$login_btn-hover-border: $dull-red !default;
$login_btn-hover-text: $a_btn-hover-text !default;
$login_btn-hover-background: $dull-red !default;

// FAQ
$faq-heading-color: $body-text !default;
$faq-plusminus-color: $body-text !default;
$faq-bordercolor: $light-grey !default;
$faq-header-bg_open: rgba($faq-bordercolor, .3) !default;
$faq-trigger-color: $body-text !default;
$faq-trigger-bg: $corp-silver !default;
$faq-trigger-bg_open: $charcoal-grey !default;
$faq-trigger-color_open: $white !default;

// Tabs
$tab-heading-color: $body-text !default;
$tab-heading-color_active: $body-text !default;
$tab-heading-bg: $white !default;
$tab-heading-bg_active: $white !default;

//Main Tabs Block (Homepage & Dashboard)  - DEPRECATED
$mainTabs: (
        tabTopBg : $light-grey,
        tabTriggerBg : $petrol,
        tabTriggerBgActive : $petrol,
        tabTriggerBorder : $accent-secondary,
        tabTriggerSpacer : $white,
        tabTriggerIconBg : $petrol,
        tabTriggerIconBgHov : $accent-primary,
        tabTriggerIconColor : $white,
        inactive : #CCCCCC,
        done : #93BE30
) !default;

// HEADERFOOTER
$headerFooter: (
        bg-light : $dark,
        bg-dark : $almost-black,
        bg-accent : $accent-primary,
        colour : $iron,
        hover : $a_accent
) !default;

// Location Tabs
$locationTabs: (
        btnBg : $white,
        btnText : $warm-grey,
        btnBgActive : $white,
        btnTextActive : $almost-black,
        sectionBorder : $silver-three,
        sectionBg : $side_background
) !default;

// HEADBAND - DEPRECATED
$headband: (
        background : $dark,
        text : $white,
        hover : $a_accent
) !default;

$datatables: (
        thBg : $charcoal-grey,
        thText : $white
) !default;

$coverTable: (
        thBg : $charcoal-grey,
        thText : $white,
        oddRowBg : rgba($corp-silver, .4),
        evenRowBg : rgba($corp-silver, .8)
) !default;

// pushNav - This is out dated and probably no-longer used. Can defo go for corporate skin
$pushNav: (
        background : $dark,
        text : $iron,
        hover : $accent_primary,
        border : $warm-grey-two
) !default;

$offCanvasNav: (
        background : $dark,
        text : $iron,
        header : $white,
        iconsOff : $purplish-grey,
        iconsOn : $white,
        hover : $cinder,
        border : $warm-grey,
        accent : $accent-primary
) !default;

/****************************/
/* 	      THEMES            */
/****************************/
//Content types
$newstype_news: $cerulean !default;
$newstype_event: $sick-green !default;
$newstype_guidance: $dull-orange !default;
$newstype_cpd: $link-primary !default;
$newstype_proposalform: $accent-primary !default;

$newstype-colors: (
        news: $cerulean,
        event: $sick-green,
        guidance: $dull-orange,
        cpd: $link-primary,
        proposalform: $accent-primary
) !default;

$news-download: $light-burgundy !default;

// Products and s4rvices
$insurance_product: $accent-primary !default;
$insurance_service: $link-primary !default;

//Banner styles
$banner-text: $dark !default;
$banner-strapline: $accent-primary !default;

/****************************/
/* 	      LAYOUTS           */
/****************************/

$login: (
        background : $dark,
        btnBg : $a_btn-background,
        btnBorder : $a_btn-border,
        btnText : $white,
        btnReset : $accent-primary
) !default;

$homepage: (
        main-content-bg : transparent,
        tabTopBg : $light-grey
) !default;

/********************************************/
// DASHBOARD
/********************************************/

//DASHBOARD VARIABLES
$policyActionStates: (
        warning : #d0021b,
        needsaction : #ff9933,
        inprogress : #198fc2,
        inactive : #67868e,
        done : #90ce51
) !default;

$policyStatuses: (
        status-not-started : map-get($policyActionStates, warning),
        status-open : map-get($policyActionStates, inprogress),
        status-started : map-get($policyActionStates, inprogress),
        status-locked : map-get($policyActionStates, warning),
        status-idle : map-get($policyActionStates, inactive),
        status-inactive : map-get($policyActionStates, needsaction),
        status-returned : map-get($policyActionStates, warning),
        status-complete : map-get($policyActionStates, done),
        status-retracted : map-get($policyActionStates, warning),
        status-submitted : map-get($policyActionStates, inprogress),
        status-not-renewed : map-get($policyActionStates, warning),
		status-unpaid : map-get($policyActionStates, warning)
) !default;



