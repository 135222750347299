
body {

	&::before {
		position: fixed;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		content: '';
		transition: opacity .5s, width .1s .5s, height .1s .5s;
		opacity: 0;
		background: rgba(0,0,0,.4);
	}

	&.is-offcanvas-open::before {
		position: fixed;
		z-index: 1000;
		transition: opacity .5s;
		opacity: 1;
	}

	font-size: 16px;
	line-height: 1.75;
	background: $white;
	color: $body-text;
	overflow-x: hidden;
	@if($theme == corporate) {
		font-family: "Gotham A","Gotham B",sans-serif;
	} @else{
		@include Font(Proxima-Light);
	}

	@include media("<=phoneland") {
		font-size: 15px;
		/*line-height: 19px;*/
	}


	h1,h2,h3,h4,h5,h6 {
		font-weight: normal;
		@include media("<tabport") {
			line-height: 1.3em;
		}
	}
	


	h5 {
		margin: 10px 0 18px;
		font-size: 18px;
		line-height: 18px;
		color: #212121;

		@include media("<=phoneland") {
			margin: 15px 0 18px;
			font-size: 14px;
			color: #464646;
		}
	}


	a, input, button, select, textarea {
		text-decoration: none;
		transition: all 250ms;

		&:focus, &:hover, &:active {
			outline: 0;
		}
	}

	a {
		color: $link-primary;
		font-weight: 400;

		&:visited{
			color: $link-primary;
		}

		&:hover, &:active {
			text-decoration: none;
		}
	}

	a{
		&:hover, &:active {
			color: $hover-primary;
		}
	}
	
	a.wizardLink {
		font-size:0.8rem;
		display: inline-block;
		margin: 0 0 0.5rem 0;
		/* border: 1px solid currentColor; */
		padding: 0 4px 0px 0;
		svg {
			height: 1.2rem;
			width: 1.5rem;
			fill: currentColor;
			vertical-align: middle;
		}
	}

	img{
		max-width:100%;
	}

	::-webkit-input-placeholder {color: #565656;}
	::-moz-placeholder          {color: #565656;}
	:-moz-placeholder           {color: #565656;}
	:-ms-input-placeholder      {color: #565656;}

	section {
		position: relative;
		display: block;
		width: 100%;
	}

	.bounds {
	    max-width: $bounds;
	    box-sizing: border-box;
	}
	.wide-bounds {
		width: 100%;
		margin: 0 auto;
		max-width: 1440px;
	    box-sizing: border-box;
	}

	.clearfix:after {
	    display: table;
	    clear: both;
	    content: '';
	}
	.page-container {
		position: relative;
		//display: block;
		width: 100%;
		margin-left: 0;
		transition: all 0.3s ease;
		background: #ffffff;
		z-index:0;

		@include media("<100px") {
			border: 1px solid black;
		}

		@include media("<wide-tab") {
			&.menu-opened {
				margin-left: -384px;
			}
		}
		@include media("<=phoneland") {
			&.menu-opened {
				margin-left: -100%;
			}
		}
	}

	.visible {
		display: block !important;
	}

	.clear {
		clear: both;
	}

	.hide-for-mobile {
		@include media("<=phoneland") {
			display: none;
		}
	}
	.hide-for-widephone {
		@include media("<tabport") {
			display: none;
		}
	}
	.hide-for-tablet {
		@include media("<wide-tab") {
			display: none;
		}
	}
	.hide-for-ipad {
		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}
	.hide-for-desktop {
		@media only screen and (min-width: 992px) {
			display: none;
		}
	}
	.tablet-visible {
		display: none;

		@media only screen and (min-width: 768px) and (max-width: 1199px) {
			display: block;
		}
	}
	.desktop-hidden {
		@media only screen and (min-width: 1200px) {
			display: none;
		}
	}
	.tablet-hidden {
		display: none;

		@media only screen and (min-width: 1200px) {
			display: block;
		}
		@media only screen and (max-width: 767px) {
			display: block;
		}
	}

	.dataTable_overflow_wrapper {
		max-width: 100%;
		overflow-x: scroll;
		overflow-y: hidden;
		width: 100%;
	}
}

.half-bounds {
	max-width: $half-bounds;
	width: 100%;
}

a, input, button, select, textarea {
	text-decoration: none;
	transition: all 250ms;

	&:focus, &:hover, &:active {
		outline: 0;
	}
}


a {
	color: $link-primary;
	font-weight: 400;

	&:visited{
		color: $link-primary;
	}

	&:hover, &:active {
		text-decoration: none;
	}
}

address{
	font-style:normal;
}

ul, li {
	margin: 0;
	padding: 0;
}
ul{
	padding-left:20px;
}
.main-article ul{
	margin-bottom:20px;
}
