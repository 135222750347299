
@mixin font($type: body, $size: false, $colour: false, $weight: false,  $lh: false) {
    @if($type == 'title'){
        font-family: $font-title;
    } @else if ($type == 'body'){
        font-family:$font-body;
    } @else{
        font-family: $font-body;
    }
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}