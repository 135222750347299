
.get-a-quote-page {

	.form-container {
		padding-bottom: 36px;
	}

	.get-a-quote-grid{
		@extend %grid-quarters;
        padding-top:0;
        position:relative;
        &:after{
            display:block;
            content:'';
            height:0;
            border-bottom: solid 1px $alto;
            position:absolute;
            bottom:0;
            width:calc(100% - 64px);
            left:32px;
            @include media("<=tabport"){
                width:calc(100% - 32px);
                left:16px;
            }
            @include media("<=phoneland"){
                width:calc(100% - 16px);
                left:8px;
            }
        }
	}

    a.drop-button {
      pointer-events: none;
      bottom: 10px;
      position: absolute;
      width: 169px;
      height: 40px;
      font-size: 14px;
      line-height: 14px;
      display: block;
      max-width: 300px;
      box-sizing: border-box;
      padding: 12px 12px;
      text-align: center;
      color:  #fff !important;
      border: none;
      border-radius: 0;
      background-color: #01bee5;
      left: 33%;
    }

    .dropzone {
      border-width:2px;
      border-style:dashed;
      border-image: none;
      max-width: 496px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 221px;
      background-color: #f7f7f7;
    }

    .ic-cloud-upload{
      width: 52px;
      height: 36px;
      object-fit: contain;
      fill: #01bee5;
    }

    .dropzone .dz-preview{
      width: 67px;
      height: 67px;
      min-height: 50px;
    }

    .dropzone .dz-preview .dz-image{
      width: 67px;
      height: 67px;
    }

    .dropzone .dz-preview .dz-details{
      height: 100%;
      font-size: 11px;
    }

    .dropzone .dz-preview .dz-details .dz-size{
      font-size: 12px;
    }

    .dropzone .dz-preview .dz-progress{
      width: 49px;
      left: 75%;
      top: 65%;
      height: 11px;
    }

    .dropzone-icon{
      pointer-events: none;
    }

    .dropzone .dz-preview .dz-remove{
      font-size: 12px;
    }

  .get-quote-block.flex-parent{
      @include media('<=620px') {
        flex-direction: column;
      }
  }

}

.get-a-quote-page_withUpload{
    .main-content{
        padding-top: 4em;
        padding-bottom:6em;
    }
}
