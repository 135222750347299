.icon-hamburger {
  fill: $white;
}

@if ($theme != corporate) {
  .mobile-menu-button {
    position: absolute;
    right: 32px;
    top: 50%;
    margin-top: -11px;
    padding: 0;
    z-index: 50;

    @include media(">=992px") {
      display: none;
    }

    @include media("<=wide-tab") {
      display: block;
    }

    @include media("<phoneland") {
      right: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@if ($theme == corporate) {
  //This will bee replaced by a persistent menu button and can be removed when the classic theme is no longer used
  .mobile-menu-button {
    display: none
  }

  //Corporate menu button
  .menu-button{
    position:relative;
    //top: 50%;
    //margin-top: -11px;
    padding: 0;
  }
}

