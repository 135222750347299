
.jsonForm {
	&.inIframe {
		.jf-header-bounds {
			width: 100%;
		}
		& > section {
			& > header > h2,
			& > .intro,
			& > fieldset,
			& > .not-applicable {
				width: 100%;
			}
		}
		&.jsonForm ul.jf-page-control-title {
			right: 10px;
		}
	}
}
