
/* 170316 Notes field */
.jsonForm {
    .notesDiv {
        textarea[data-notes-field] {
            display: block;
            width: 100%;
            max-height: 480px;
            height: 120px;
            margin: 0 0 15px;
            font-family: AGaramondPro, Georgia;
            font-style: italic;
            color: $text-color;
            background-color: $white;
            border: 1px dashed $shady-lady;
            box-shadow: none;
            transition: all .6s;

            &:focus {
                border-radius: 0;
                box-shadow: 0 1px 10px $alto;
            }

            &.ishidden {
                max-height: 0;
                min-height: 0;
                margin-bottom: 0 !important;
                padding-top: 0;
                padding-bottom: 0;
                border-width: 0;
                transition: all .3s;
                resize: none;
            }
        }

        // OLD STYLE (it's not used after restructuring)

        /*
        label.notesLabel {
            position: absolute;
            top: -2.5em;
            right: 0;
            max-width: 20px;
            min-width: 0;
            width: auto;
            height: 2em;
            margin-left: 0px;
            padding: 0 25px;
            font-size: .8em;
            line-height: 2em;
            text-align: right;
            text-indent: 25px;
            white-space: nowrap;
            overflow: hidden;
            color: #999;
            background: #EFF3F5;
            background-color: #eee;
            background: transparent url('/proposalform/v2016v2/jsonForm.2.0.0/img/notes.svg') center right no-repeat;
            box-sizing: border-box;

            &:hover {
                max-width: 300px;
            }
        }
        */
    }
}
