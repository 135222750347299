.progressBar{
    background-color:#9cd762;
    height:10px;
    position:relative;
    .progressBar-filled, b{
        background-color:#1e5f6f;
        height:10px;
        position:absolute;
        top:0;
        left:0;
		max-width: 100%;//dont allow anything
        &.valid{
            background-color:map_get($policyActionStates,"done");
        }
        &.unfilled,
        &.unstarted{
            background-color:map_get($policyActionStates,"needsaction");
        }
        &.invalid{
            background-color:map_get($policyActionStates,"warning");
        }
    }
}

#summaryScreen .progressBar{
    background-color:#1e5f6f;
    .progressBar-filled, b{
        background-color:#67868e;
        &.valid{
            background-color:map_get($policyActionStates,"done");
        }
        &.unfilled,
        &.unstarted{
            background-color:map_get($policyActionStates,"needsaction");
        }
        &.invalid{
            background-color:map_get($policyActionStates,"warning");
        }
    }
}
.progressBar .title {
    font-size: 0;
}
