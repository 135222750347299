.documents-list{
    .icon-tick-drawn, .icon-cross-drawn{
        fill: $accent-primary;
        vertical-align: top;
        height:15px;
        width:15px;
        margin-right:0.25em;
    }
}
.documents-fileUpload,
.documents-fileUpload-v2,
.asset-edit #assetMeta {

    #fileUploadContainer {
        width: 100%;
        margin: 30px auto;
    }
    #fileUploadWrapper {
        background: #ececec;
        text-align: center;
        vertical-align: middle;
        display: table;
        width: 100%;
        min-height: 300px;
        font-size: 1em;
        color: #999;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom:1em;
    }
    #fileUpload, #assetDocUpload {
        background: #ececec;
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        padding: 50px;
        border: none;
    }

    .dropzone.dz-started .dz-message {
        display: block;
    }

    .dropzone .dz-preview .dz-details {
        z-index: 20;
        position: inherit;
        top: 0;
        left: 0;
        opacity: 0;
        font-size: 1em;
        min-width: 100%;
        max-width: 100%;
        text-align: center;
        color: #999;
        line-height: 150%;
        padding: 0;
    }

    .dropzone .dz-preview {
        position: relative;
        display: inline-block;
        width: 180px;
        margin: 0.5em;
        padding: 10px;
        border-radius: 5px;
    }
    .dropzone .dz-preview .dz-progress {
        position: relative;
        display: block;
        height: 10px;
        border: 2px solid #ffffff;
        background-color: #848484;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 0 6px #dedede, 0px 1px 1px 0px #000 inset;
        box-shadow: 0 0 0 6px rgba(0,0,0,0.1), 0px 1px 1px 0px #000 inset;
        margin: 8px 0;
        width: 160px;
        left: 0;
    }
    .dz-preview.dz-file-preview.dz-success select {
        opacity: 0.3;
        pointer-events: none;
    }
    .dropzone .dz-preview .dz-progress .dz-upload {
        background: #addc3c;
    }
    .dropzone .dz-preview .dz-error-mark, .dropzone .dz-preview .dz-success-mark {
        position: absolute;
        left: auto;
        top: 0;
        width: auto;
        height: auto;
        right: 0;
        margin: 0;
        font-size: 1.6em;
    }

    .dropzone .dz-preview .dz-details .dz-filename:hover span {
        background-color: #ececec;
    }

    .dropzone .dz-preview .dz-error-mark {
        color:#bf1919;
    }
    .dropzone .dz-preview .dz-success-mark {
        color:#43bb47;
    }

    .dropzone .dz-preview .dz-details .dz-filename span {
        background-color: #ececec;
        white-space: normal;
        text-align: center;
        vertical-align: middle;
        display: table;
        width: 100%;
        font-size: 1em;
        color: #999;
        padding: 0;
    }

    .dropzone .dz-preview .dz-details .dz-filename:hover span {
        border: none;
    }

    .type-sel {
        background: 000;
        border: 1px solid #ccc;
        margin-top: 5px;
    }
}
.documents-fileUpload,
.documents-fileUpload-v2 {
	label {
		font-size: 16px;
		margin-bottom: 5px;
	}
}

.document-template-list {
    .buttons {
        display: flex;
        justify-content: flex-end;
        padding: 24px 0 32px 0;
    }
    .btn {
        margin-left: 16px;
    }
}

#docuploadform {
    margin-bottom:10px;
    .fieldwrap {
        margin-bottom: 9px;

        label {
            display: block;
        }
    }
}
