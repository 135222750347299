.kyc-detail{
    //h1{
    //  @include font("body", $size: 26px, $colour: false, $weight: false,  $lh: false);
    //}
    //
    //p{
    //  @include font("body", $size: 18px, $colour: false, $weight: false,  $lh: false);
    //  margin: 0 0 0 0;
    //}
    //.column-title{
    //  @include font("title", $size: 48px, $colour: false, $weight: false,  $lh: false);
    //}
    //.block-content{
    //  @include font("body", $size: 18px, $colour: false, $weight: false,  $lh: false);
    //}
    //.tabbed{
    //  background: white;
    //  .tabtrigger a{
    //    font-family: Gotham;
    //  }
    //}
    //.tabbed > section {
    //  background: #f7f7f7;
    //  border: none;
    //  border-bottom: none;
    //}
    //.tabbed > section > div > div {
    //  border-bottom:none;
    //}
    //.single-line table{
    //    &:after {
    //      content: '';
    //      width: 0px;
    //    }
    //}
  .stickyFootWrap .stickyFootMain{
      background:none;
    }

  .message-button {
    box-shadow: none;
    border: 1px solid transparent;
    font-size: 14px;
    outline: none;
    line-height: 100%;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    min-height: 38px;
  }

  #username-page {
    text-align: center;
  }

  .username-page-container {
    background: $white;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
    width: 100%;
    max-width: 500px;
    display: inline-block;
    margin-top: 42px;
    vertical-align: middle;
    position: relative;
    padding: 35px 55px 35px;
    min-height: 250px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: -160px;
  }

  .username-page-container .username-submit {
    margin-top: 10px;
  }


  #chat-page {
    position: relative;
    height: 100%;
  }

  .chat-container {
    max-width: 700px;
    //margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    margin-top: 30px;
    height: calc(100% - 60px);
    max-height: 600px;
    position: relative;
  }

  #chat-page ul {
    list-style-type: none;
    background-color: #FFF;
    margin: 0;
    overflow: auto;
    overflow-y: scroll;
    padding: 0 20px 0px 20px;
    height: calc(100% - 150px);
    max-height:500px;
  }

  #chat-page #messageForm {
    padding: 20px;
  }

  #chat-page ul li {
    line-height: 1.5rem;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid #f4f4f4;
  }

  #chat-page ul li p {
    margin: 0;
  }

  #chat-page .event-message {
    width: 100%;
    text-align: center;
    clear: both;
  }

  #chat-page .event-message p {
    color: #777;
    font-size: 14px;
    word-wrap: break-word;
  }

  #chat-page .chat-message {
    padding-left: 68px;
    position: relative;
  }

  #chat-page .chat-message i {
    position: absolute;
    width: 42px;
    height: 42px;
    overflow: hidden;
    left: 10px;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 42px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    font-style: normal;
    text-transform: uppercase;
  }

  #chat-page .chat-message span {
    color: #333;
    font-weight: 600;
  }

  #chat-page .chat-message p {
    color: #43464b;
  }

  #messageForm .input-group input {
    height: 38px;
    float: left;
    width: calc(100% - 217px);
  }

  #messageForm .input-group button {
    float: left;
    width: 80px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .addfile{
    width: 110px !important;
    border: solid 1px #9b9b9b;
    background: white;
    color: #9b9b9b !important;
  }

  .addfile:hover {
    border: solid 1px #9b9b9b;
    background: white;
    color: #9b9b9b !important;
  }

  .chat-header {
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #ececec;
  }

  .chat-header h2 {
    margin: 0;
    font-weight: 500;
  }

  .connecting {
    padding-top: 5px;
    text-align: center;
    color: #777;
    position: absolute;
    top: 65px;
    width: 100%;
  }

  .kyc .tabbed{
    background: white;
  }

  .kyc .tabbed>section{
    border:none;
  }

  .kyc .tabbed>section>div>div{
    border-bottom:none;
  }

  @media screen and (max-width: 730px) {

    .chat-container {
      //margin-left: 10px;
      //margin-right: 10px;
      //margin-top: 10px;
      margin: 10px 10px 0 10px;
    }
  }

  @media screen and (max-width: 480px) {
    .chat-container {
      height: calc(100% - 30px);
    }

    .username-page-container {
      width: auto;
      margin-left: 15px;
      margin-right: 15px;
      padding: 25px;
    }

    #chat-page ul {
      height: calc(100% - 120px);
    }

    #messageForm .input-group button {
      width: 65px;
    }

    #messageForm .input-group input {
      width: calc(100% - 200px);
    }

    .chat-header {
      padding: 10px;
    }

    .connecting {
      top: 60px;
    }

    .chat-header h2 {
      font-size: 1.1em;
    }
  }
}