
.logo-carousel-section {
    background: $cod-grey;
    padding: 0;
    border-top:1px solid $greyish-brown;
    .bounds {
        .carousel-button-wrapper {
            display: none;
            margin-right: 24px;
            overflow: hidden;

            @media only screen and (max-width: 1360px) {
                margin-right: 0;
            }
        }
        .carousel-button-prev,
        .carousel-button-next {
            display: none;
        }

        ul {
            padding: 15px 0 5px;
            font-size: 0;
            border: none;
            box-sizing: border-box;
            //display:flex;

            @include media("<tabport") {
                padding: 10px 0 5px;
            }

            li {
                display: inline-block;
                width: 12.5%;
                @at-root .site-locktonperformance &{
                    width: 16.66%;
                    .img-wrap{
                        text-align: center;
                    }
                }
                font-size: 16px;
                text-align: right;

                @media only screen and (max-width: 960px) {
                    width: 25%;
                }

                @media only screen and (max-width: 568px) {
                    width: 50%;
                }

            }

        }
    }
}