.tabbed-wrap .tabs-control {
  //display: block;
  display: flex;
  list-style-type: none;
  font-size: 0;
  background-color: transparent;

  @include media("<1200px") {
    padding: 0 32px;
    box-sizing: border-box;
  }
  @include media("<wide-tab") {
    padding: 0 16px;
    box-sizing: border-box;
  }

  .overflowTabs {
    li.overflow {
      display: block;
    }
  }

  li {
    display: inline-block;
    font-size: 18px;
    margin-right: 20px;
    margin-bottom: 8px;
    height: 44px;
    white-space: nowrap;

    @include media("<1200px") {
      width: 21.5%;
    }
    @include media("<wide-tab") {
      width: 24%;
    }
    @include media("<tabport") {
      width: 100%;
      &:not(:first-child) {
        margin-top: 1px;
      }
    }

    a,
    a:hover {
      display: block;
      width: 227px;
      padding: 11px 0px 0px 0px;
      font-size: 15px;
      line-height: 1;
      text-align: left;
      margin: 0;
      background-color: map-get($locationTabs, btnBg);
      color: map-get($locationTabs, btnText) !important;
      opacity: 1;
      border: none;
      border-top: 1px $warm-grey solid;
      border-right: 1px $warm-grey solid;
      border-radius: 0;

      @include media("<1200px") {
        width: 100%;
      }
      @include media("<wide-tab") {
        padding: 11px 12px;
      }
      @include media("<tabport") {
        text-align: center;
        font-size: 16px;
      }
      @include media("<=phoneland") {
        font-size: 12px;
      }



    }

    &.active {
      a {
        background-color: map-get($locationTabs, btnBgActive);
        color: map-get($locationTabs, btnTextActive) !important;
        border-top: 1px $black solid;
        border-right: 1px $black solid;
        font-weight: 400;
      }
    }
  }
}
.tabbed .tab-control_blocks{
  display:flex;
  flex-wrap:nowrap;

  li{
    flex:1 1 auto;
    padding-right:2px;
    position:relative;
    &:first-child{
      max-width:60px;
      @include media("<272px"){
        max-width: unset;
      }
    }
    &:last-child{
      padding-right:0;
    }
    a{
      background: $silver;
      border-color:$silver;
      color:$dark;
      padding: 16px;
      border-radius: 0;
      display:block;
      margin-left:0;
      @include font('body', 15px, $dark, 300,  1.6);
      svg{
        fill:$white;
      }
    }
    &.active {
      a {

        background-color: $dark;
        color: $white;
        border-color:$dark;
      }
      &:after{
        height:0;
        width:0;
        display:block;
        content:'';
        position:absolute;
        left:6px;
        top:99%;
        border-style: solid;
        border-width: 8px 6px 0 6px;
        border-color: $dark transparent transparent transparent;
        z-index: 1;
      }
    }

  }
}
