/* Tag image */
.chose-image {
    width:24px;
    height:24px;
	padding: 2px 5px 3px 0;
}

/* Image for Single mode */
.chose-image-small {
	width:24px;
	height:24px;
	vertical-align: middle;
	margin: -3px 1em 0 0;
	padding: 0 3px 0 0;
    opacity: 0.3;

}

/* Images appended to the li(s) */
.chose-image-list {
    width:24px;
    height:24px;
	vertical-align: middle;
	margin: -3px 1em 0 0;
	padding: 0 3px 0 0;
    opacity: 0.3;
}

/* Grey-out image for Multi mode */
div.chosen-container-multi .result-selected img {
	opacity: 0.3;
}
