
.account-switcher {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 200px;
  background-clip: content-box;
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  -webkit-flex: 1 1 33%;
  padding-left: 0!important;



  .content-body{

    background: $blue-nurse;
    @if $site == 'norway' {
      background: $nor-darkgrey;
    }
    padding:43px 68px 110px 38px;


    .title{
      @include font("title",38px,$white,400,1.75);
    }

    .valueText{
      font-family: 'Gotham A',"Gotham B", Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: bold;
      line-height: 0.92;
      color: #ffffff;
    }

    .acc-type {
      display: block;
      font-size: 14px;
      line-height: 1.57;
      color: #ffffff;
      font-family: 'Gotham A',"Gotham B", Helvetica, Arial, sans-serif;
      margin-bottom: 5px;
    }

}


  .contentButton .button{
    max-width: 100%;

    background-color: $a_btn-background;
    font-size: 14px;
    line-height: 1;
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
    text-align: center;
    color: $a_btn-text;
    border: none;
    border-radius: 0;
    &:hover{
      background-color:$a_btn-hover-background;
      color:$a_btn-hover-text;
    }
  }


}

.flex-parent-acc{
  padding-left: 0!important;
}

@media only screen and (min-width: 1030px){
  .flex-parent .account-switcher {
    -webkit-flex: 1 1 33%;
  }
}


.dialog-message-container{

  margin: -30px;
  text-align: left;
  .modal-header{
    @include font("title",30px,$almost-black,400,1.75);
    background-color: $light-grey-seven;
    padding: 16px 21px;
}


  .modal-body{
    margin-top: 40px;
    margin-bottom: 100px;
    padding: 13px 23px;
    .modal-body-container {
      display: flex;
      flex-direction: row;
      //justify-content: center;
      align-items: center;
      border: solid 1px #d0021b;
      color: #d0021b;
      padding: 13px 23px;


      .modal-icon {
        margin-right: 20px;
      }

      svg{
        fill: #d0021b;
      }

    }
  }

}


.off-canvas-open-account-switcher{
  cursor: pointer;
  display: flex;
}


@media only screen and (min-width: 1024px) {
  [data-tooltip]:before {
    position: absolute;
    content: attr(data-tooltip);
    opacity: 0;

    padding: 1px 3px;
    border-radius: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    background-color: #f4f4f4;
    font-size: 12px;
    text-align: center;
    color: #20262a;
    margin-top: 20px;
    margin-left: 10px;


  }

  [data-tooltip]:hover:before {
    opacity: 1;

  }
}