.manual-quote-edit{
    .column {
        float: left;
        min-width: 600px;
        /*width: 600px;*/
        width: 50%;
        padding: 7px;

        @media only screen and ("max-width:622px") {
            min-width: 500px;
        }
        @media only screen and ("max-width:520px") {
            min-width: 420px;
        }
    }

    .bounds content-padding:after {
        content: "";
        display: table;
        clear: both;
    }

    div.scrollmenu {
        background-color: white;
        overflow: auto;
        white-space: nowrap;

        .flex-parent {
            display: inline-block;
            color: white;
            text-align: center;
            padding: 14px;
            text-decoration: none;
        }
    }

    /* Manual quote table */

    #manualQuote-endorsement_filter{
        margin-right: 2px;
    }

    /*accordion */
    .accordion{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex-wrap: wrap;
        -ms-flex-direction: row;
        -ms-flex-wrap: wrap;
        //padding: 6px 0;
        margin-bottom: 3px;

        .accordion-item {
            .accordion-trigger{
                display: flex;
                flex-direction: row;
                align-items: stretch;
                justify-content: space-between;
                -ms-flex-direction: row;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                align-content: space-between;

                .quote-currency-value {
                    width: 60%;
                    height: 57px;
                    padding: 6px 0;
                    font-family: "adobe-garamond-pro",serif;
                    font-size: 26px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.69;
                    letter-spacing: 0.4px;
                    display: inline-block;
                    color: #414a51;
                }

                .quote-id-value {
                    width: 29%;
                    height: 57px;
                    font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                    font-size: 17px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.26px;
                    padding: 20px 20px 20px 0;
                    display: inline-block;
                    text-align: right;
                    color: #414a51;
                }

                .quote-open-close-icon {
                }
            }

            .accordion-content {
                //display: none;
                //overflow: hidden;
                max-width: 622px;
                width: 100%;
                border: solid 1px #979797;
                background-color: #ffffff;
                position: relative;
                transition: max-height .7s ease-out, opacity .3s ease-out, padding 0s ease .3s;


                .quote-main-content{
                    &:after{
                        content: "";
                        width: 80%;
                        position: absolute;
                        height: 2px;
                        margin: 21px 27px 20px 0;
                        background-color: #1c1c1b;
                    }

                    .quote-content-key-value{
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        -ms-flex-direction: row;
                        -ms-flex-wrap: wrap;
                        align-content: space-between;
                        padding: 6px 0;
                        margin-bottom: 3px;

                        .quote-key-block{
                            min-width: 100px;
                            width: 50%;
                            font-family: "Roboto Condensed", sans-serif;
                            font-size: 15px;
                            font-weight: 300;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.27;
                            letter-spacing: 0.23px;
                            color: #202427;
                            padding: 8px 16px;
                        }

                        .quote-value-block{
                            width: 50%;
                            font-family: "Roboto Condensed", sans-serif;
                            font-size: 15px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.47;
                            letter-spacing: 0.23px;
                            color: #1c1c1b;
                            padding: 8px 16px;
                        }
                    }
                }

                .quote-premium-content{
                    margin-top: 39px;

                    &:after{
                        content: "";
                        width: 80%;
                        position: absolute;
                        height: 1px;
                        margin: 10px 27px 20px 0;
                        background-color: #979797;
                    }

                    .quote-content-key-value{
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        -ms-flex-direction: row;
                        -ms-flex-wrap: wrap;
                        align-content: space-between;
                        padding: 6px 0;
                        margin-bottom: 3px;

                        .premium-gbp {
                            min-width: 100px;
                            width: 50%;
                            font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                            font-size: 15px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.47;
                            letter-spacing: 0.23px;
                            color: #1c1c1b;

                        }

                        .premium-gbp-value{
                            width: 50%;
                            font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                            font-size: 15px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.47;
                            letter-spacing: 0.23px;
                            color: #1c1c1b;
                        }

                    }

                }

                .quote-total-premium-content{
                    margin-top: 27px;
                    &:after{
                        content: "";
                        width: 80%;
                        position: absolute;
                        height: 1px;
                        margin: 10px 27px 20px 0;
                        background-color: #979797;
                    }

                    .quote-content-key-value {
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        -ms-flex-direction: row;
                        -ms-flex-wrap: wrap;
                        align-content: space-between;
                        padding: 6px 0;
                        margin-bottom: 3px;

                        .premium-gbp {
                            min-width: 100px;
                            width: 50%;
                            font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                            font-size: 15px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.47;
                            letter-spacing: 0.23px;
                            color: #1c1c1b;

                        }

                        .premium-gbp-value{
                            width: 50%;
                            font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                            font-size: 15px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.47;
                            letter-spacing: 0.23px;
                            color: #1c1c1b;
                        }
                    }
                }
            }

            &.accordion-item-open{
                .quote-currency-value {
                    color: #fff;
                }
                .quote-id-value {
                    color: #fff;
                }
                .accordion-content{
                    //-webkit-transition: all 1s ease-in 0s, opacity 0.4s ease 0.3s;
                    //-o-transition: all 1s ease-in 0s, opacity 0.4s ease 0.3s;
                    //transition: all 1s ease-in 0s, opacity 0.4s ease 0.3s;
                    //max-height: 1000px;
                    //opacity: 1;
                    transition: max-height .7s ease-out, opacity .3s ease-out, padding 0s ease 0s;
                    padding: 36px 30px 59px 30px;
                    max-height: unset;
                }
            }
        }
    }

    .loading-discount-block {
        .loading-discount-title {
            max-width: 457px;
            width: 100%;
            height: 32px;
            h2 {
                font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                font-size: 22px;
                color: #060708;
            }
        }

        fieldset {
            width: 100%;
            padding: 0;
            max-width: none;
        }

        section.saveSection {
            fieldset {
                input {
                    max-width: 138px;
                    width: 100%;
                    height: 48px;
                    padding: 13px 7px 13px 9px;
                }
            }
        }
    }

    .page-header {
        p.block-content{
            font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
            font-size: 16px;
            line-height: 1.75;
            color: #060708;
        }
    }

    .loaded .modal-content{
        text-align:left;
        padding: 20px;
    }

    //.pageHead {
    //    padding: 36px 0 39px 6px !important;
    //    background-color: #efeef0 !important;
    //    h2 {
    //        font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
    //        font-size: 22px;
    //        line-height: 1.27;
    //        color: #060708;
    //    }
    //}

    .document-block {
        overflow: auto;
        .document-table{
            .column-title {
                max-width: 457px;
                width: 100%;
                height: 31px;
                margin: 0 27px 14px 0;
                font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                font-size: 22px;
                line-height: 1.18;
                letter-spacing: normal;
                color: #060708;
            }

            .dataTables_length {
                display: none;
            }

            #datatable-document-template_filter {
                display: none;
            }

            .dataTables_info {
                display: none;
            }

            //.paginate_button {
            //    display: none;
            //}

            //.dataTables_paginate .paging_simple_numbers{
            //    display: none;
            //}

            #datatable-document-template {
                width: 100% !important;
                th {
                    font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    color: #ffffff;
                }

                td {
                    font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                    font-size: 14px;
                    line-height: 1;
                    color: #20262a;
                }
            }
        }
    }

    .endorsement-block {
        overflow: auto;
        .endorsement-table-header {
            .column-title {
                max-width: 457px;
                width: 100%;
                height: 31px;
                margin: 0 27px 14px 0;
                font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                font-size: 22px;
                line-height: 1.18;
                letter-spacing: normal;
                color: #060708;
            }

            .button-add-endorsement {
                max-width: 84px;
                width: 100px;
                height: 36px;
                margin: 0 1px 12px 36px;
                padding: 10px 5px 9px 11px;

                .button-text {
                    max-width: 40px;
                    width: 100%;
                    height: 15px;
                    margin: 0 0 2px 10px;
                    font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    color: $white;
                    vertical-align: super
                }

                svg {
                    width: 18px;
                    height: 17px;
                    #ic-plus {
                        g {
                            fill: $white !important;
                        }
                    }
                }
            }
        }

        .endorsement-table {
            .dataTables_length {
                display: none;
            }
            #manualQuote-endorsement_filter {
                display: none;
            }

            .dataTables_info {
                display: none;
            }

            .dataTables_paginate .paging_simple_numbers{
                display: none;
            }

            .paginate_button {
                display: none;
            }

            #manualQuote-endorsement {
                width: 100% !important;
                th {
                    font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    color: #ffffff;
                }

                td {
                    font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
                    font-size: 14px;
                    line-height: 1;
                    color: #20262a;
                }
            }
        }
    }

    .other-valid-quotes-block {
        .column-title {
            max-width: 457px;
            width: 100%;
        }
        span {
            font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
            font-size: 22px;
            line-height: 1.18;
            color: #060708;
        }
    }

    #manualQuote_submit{
        width: 138px;
        height: 48px;
        padding: 13px 7px 13px 9px;
        background-color: #01bee5;
        font-family: "Gotham A","Gotham B",Helvetica,Arial,sans-serif;
        font-size: 16px !important;
        font-weight: 500;
        line-height: 1.38;
        text-align: center;
        color: #ffffff !important;
    }


}