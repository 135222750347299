
/* Fieldset Actions */

.fieldset-actions {
	@extend .locktonContactActions; // almost all styles are inherited from contacts.scss
	left: 10px;

	svg {
		display: block;
		fill: $cerulean2;
	}

	a,
	label {
		&:hover {
			svg {
				fill: $cerulean3;
			}
		}
	}

	.notesLabel {
		min-width: 132px;
	}
}