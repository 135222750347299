
// Just Extension

.jf-btn {
    position: relative;
    display: inline-block;
    min-height: 36px;
    padding: 8px 10px;
    ////font: normal 500 13px/20px "Roboto Condensed",sans-serif;
    text-align: center;
    //text-transform: uppercase;
    //transition: all .25s ease-out;
    //cursor: pointer;
	@include media('<tabport') {
		min-height: 30px;
	}

}

.pf-admin,
.pf-dashboard{
    .jf-btn{
        @extend .btn;
        color: $a_btn-text!important; //Overridden by top-section link colour/dashboard link colour otherwise;
        border: solid 1px $a_btn-border;
        background-color: $a_btn-background;
        &:hover,
        &:active {
            color: $a_btn-hover-text!important;
            border: solid 1px $a_btn-hover-border;
            background-color: $a_btn-hover-background;
        }
    }
}

.person .img-wrap{
	display:none;
}

.button.deleterow{
    height:36px;
}

// OLD STYLE - I'm not sure whether it's used or not //

/* hovering save progress button */
button#main_summary,
button#main_submit_float{
/*    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px;
    z-index: 9999999;*/
    cursor:pointer;
	flex: 0;
	white-space: nowrap;
	@extend .btn;
	line-height:27px;
	padding:5px 20px;
	color: $a_btn-text!important;
	border: solid 1px $a_btn-border;
	background-color: $a_btn-background;
	
	text-transform: uppercase;
	font-family: 'Gotham A', Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: center;
	
	&:hover,
	&:active {
		color: $a_btn-hover-text!important;
		border: solid 1px $a_btn-hover-border;
		background-color: $a_btn-hover-background;
	}
}

button#summaryPanelCloseButton,
button#summaryPanelCloseButton:visited,
button#issuesPanelCloseButton,
button#issuesPanelCloseButton:visited{
	cursor:pointer;
	flex: 0;
	white-space: nowrap;
	@extend .btn_b;
	line-height:27px;
	padding:5px 20px;
	color:#ffffff!important;
	
	text-transform: uppercase;
	font-family: 'Gotham A', Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: center;
	&:hover,
	&:active {
		color: $almost-black!important;
		border: solid 1px white;
		background-color: white;
	}
}

//annoying need to set min-widths for IE (else the buttons collapse)
@media all and (-ms-high-contrast:none) {//IE-specific
	button#main_summary {
		min-width: 6em;
	}
	button#main_submit_float {
		min-width: 12em;
	}
	button#summaryPanelCloseButton,
	button#issuesPanelCloseButton {
		min-width: 9em;
	}
}


button#main_summary {
	margin-right:8px;
	padding:5px 10px;
}

button#main_summary {display:none; outline: none;}
button#main_summary svg {
	vertical-align: middle;
	fill:white;
}
button#main_summary span.issueCount {
	display: inline-block;
	border-left: 1px solid rgba(255,255,255,.5);
	vertical-align: middle;
	padding-left: 10px;
	padding-right: 5px;
	margin-left: 10px;
	color: #fff!important;
}
.invalid button#main_summary {background-color:$sick-red; border-color:$sick-red; display:block;
	&:hover,
	&:active {
		//background-color: rgba($sick-red,.5);
		background-color: darken($sick-red,20%);
	}
}
/*.invalid button#main_summary:after {
  border-color: rgba(190, 30, 45, 0);
  border-top-color: #BE1E2D;
}*/
.incomplete button#main_summary {background-color:$sick-amber; border-color:$sick-amber; display:block;
	&:hover,
	&:active {
		background-color: darken($sick-amber,20%);
	}
}
/*.incomplete button#main_summary:after {
  border-color: rgba(247, 148, 30, 0);
  border-top-color: #F7941E;
}*/
.complete button#main_summary {background-color:$sick-green; border-color:$sick-green; display:block;
	&:hover,
	&:active {
		background-color: darken($sick-green,20%);
	}
}
/*.complete button#main_summary:after {
  border-color: rgba(149, 189, 62, 0);
  border-top-color: #95BD3E;
}*/

/*// Probably need to add a class to these instead where appropriate
ul.jf-page-control li a[href="#summaryScreen"],
button#main_summary,
button#main_submit_float {
    background-color: #eeeeee;
    text-indent: 0;
    border: 1px solid #666666;
    color: #666666;
    font-family: "Roboto Condensed",sans-serif;
    font-size: 1em;
    font-weight: 700;
    font-style: normal;
    padding: 0 10px;
    line-height: 28px;
    width: auto;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
button#main_submit_float{
    width:116px;
    padding-left:0;
    padding-right:0;
    text-align:center;
}
button#main_submit_float.unsaved{
    background-color:#1C75BC;
    border-color:#1C75BC;
    color:#ffffff;
    cursor:pointer;
    pointer-events:auto;
}
button#main_summary,
button#main_submit_float{
    background-color:#eee;
    border-color:#eee;
    color:#666666;
    cursor:default;
}
button#main_summary {
    right:133px;
    padding-left:30px;
    color:white;
    min-height:30px;
    min-width:45px;
}*/

/*button#main_summary:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(247, 148, 30, 0);
    border-top-color: #F7941E;
    border-width: 9px;
    margin-left: -9px;
}*/


/*
// this is the summary button - it is now a little doughnut icon
ul.jf-page-control li a[href="#summaryScreen"] {
    background:#95BD3E;
    color:white;
    border:1px solid #95BD3E!important;
    transition: background-color .3s;
}

ul.jf-page-control li:hover a[href="#summaryScreen"] {
    background:#f1f2f2;
    border:1px solid #f1f2f2!important;
    color:#6D6E71;
}
ul.jf-page-control li.current a[href="#summaryScreen"] {
    margin:1px 1px 1px 0;
    border-width:1px!important;
}*/


// Add a class!!!!!!!
// No! Adding a class here is redundant and bad-form. Classes are styling hooks and probably shouldn't be used AT ALL unless absolutely necessary (see: https://www.smashingmagazine.com/2012/06/classes-where-were-going-we-dont-need-classes/)
// in this case we're styling an submit - surely the css for this should say input[type="submit"], button, .button, [role="button"] {style like a button} rather than us having to go though the entire website and say what each element should look like? Its daft. Next you'll be insisting that all <p> are give the class .para!
.jsonForm input[type="submit"] {
    //background-color: #1C75BC;
    //text-indent: 0;
    //border: 1px solid #1C75BC;
    //-webkit-border-radius: 0;
    //-moz-border-radius: 0;
    //border-radius: 0;
    //display: inline-block;
    //color: white;
    //font-family: 'Roboto Condensed', sans-serif;
    //font-size: 17px;
    //font-weight: 700;
    //font-style: normal;
    //padding: 0 15px;
    //
    //line-height: 30px;
    //width: auto;
    //text-decoration: none;
    //text-align: center;
    //
    //text-transform: uppercase;
    //-webkit-transition: all .5s;
    //-moz-transition: all .5s;
    //-ms-transition: all .5s;
    //-o-transition: all .5s;
    //transition: all .5s;

    @extend .btn;
    color: $a_btn-text!important; //Overridden by top-section link colour/dashboard link colour otherwise;
    border: solid 1px $a_btn-border;
    background-color: $a_btn-background;
    display:inline-block;
    float: right;
    margin-top: 10px;
    &:hover,
    &:active {
        color: $a_btn-hover-text!important;
        border: solid 1px $a_btn-hover-border;
        background-color: $a_btn-hover-background;
    }
	&.btn_c{
		color: $c_btn-text!important; //Overridden by top-section link colour/dashboard link colour otherwise;
		border: solid 1px $c_btn-border;
		background-color: $c_btn-background;
		display:block;

		margin-top: 10px;
		&:hover,
		&:active {
			color: $c_btn-hover-text!important;
			border: solid 1px $c_btn-hover-border;
			background-color: $c_btn-hover-background;
		}
	}
}

.jsonForm input[type="cancel"] {
	
	@extend .btn_c;
	display: inline-block;
	width: auto;
	text-align: center;
	cursor: pointer;
	color: $c_btn-text;
	border: solid 1px $c_btn-border;
	background-color: $c_btn-background;
	&:hover,
	&:active {
		color: $c_btn-hover-text!important;
		border: solid 1px $c_btn-hover-border;
		background-color: $c_btn-hover-background;
	}
}


//.jsonForm input[type="submit"]:hover {
//    background-color: #009444;
//    border: 1px solid #009444;
//}
//
//.jsonForm input[type="submit"]:active {
//    position: relative;
//    top: 1px;
//    -moz-box-shadow: inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
//    -webkit-box-shadow: inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
//    box-shadow: inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
//}
//
//@media only screen and (max-width: 955px) {
//    button#main_summary,
//    button#main_submit_float {
//        top: auto;
//        bottom: 9px;
//    }
//}

// End submit button that needs a class

// READ ONLY MODE - hide buttons
.readonly a.button.upload,
.readonly a.button.download + span {
    display: none;
}
.btn.disabled {
    filter: grayscale(1);
    pointer-events: none;
}