
// Not applicable box

.jsonForm {
    .not-applicable,
    .major-prop-not-applicable {
        position: relative;
        display: inline-block;
        padding: 9px 15px;
        font-size: $tooltip-fs;
        line-height: 16px;
        font-weight: $tooltip-fw;
        color: $na-box-color;
        background: $na-box-bg;   
        border: 1px solid $na-box-border;
    }
    
    /*
    .not-applicable {
        &::before {
            position: absolute;
            display: block;
            content: "";
            left: 10px;
            bottom: 100%;
            width: 0;
            height: 0;
            margin: auto;
            border: 8px solid transparent;
            border-bottom-color: rgba(255, 255, 255, .3);
        }
    }
    */
}

// end na box