.client-list{
    .inline-form {
        display: inline-block;
    }
    td.deleted {
        text-decoration: line-through;
        color: #ff0000;
    }
}
.client-edit{
	& > div {
	    position: relative;
	}
	.jsonForm {
	    position: static;
	}
  .tabbed > section.open > div{
    max-height:7000px;
  }
	#sector .jsonForm fieldset {
		margin: 0;
		padding: 10px 0;
        max-width:800px;
	}
}
.client-edit-login {
	&.access-full{
		#permissions{
			display:none;
		}
	}
    & > div:not(#headband) {
        padding-bottom: 110px;
    }
}
.title-sec{
	@include font('title', 30px, false, false, 1.3);
    margin-top:0;
}

#sector{
    .jf-input-type-submit{
        min-width: 100px !important;
        max-width: 100px !important;
        width: 0.1%;
        input[type="submit"]{
            //padding: 9px 6px;
				padding: 6px 6px;
				height: 36px;
        }
        .deleterow{
            padding: 7px 6px;
            background:$white;
            border-color:#979797;
        }
        .icon-jf-delete{
            fill:#979797;
        }
    }
    .jf-input-type-delete{
        min-width: 44px !important;
        max-width: 44px !important;
        width: 0.1%;
    }
}

#addSectorID{
    fieldset.add-sector-id-rows{
        margin-bottom: 50px;
    }

    .jf-input-type-submit {
        min-width: 100px !important;
        max-width: 100px !important;
        width: 0.1%;

        input[type="submit"] {
            //padding: 9px 6px;
            padding: 6px 6px;
            height: 36px;
        }

        .deleterow {
            padding: 7px 6px;
            background: $white;
            border-color: #979797;
        }

        .icon-jf-delete {
            fill: #979797;
        }
    }

    .jf-input-type-delete {
        min-width: 44px !important;
        max-width: 44px !important;
        width: 0.1%;
    }
}